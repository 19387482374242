import { LoginPage } from "./page/login";
import DashboardPage from "./page/dashboard";
import InvoicesPage from "./page/invoices";
import InvoicesByUserIdPage from "./page/invoicesByUserId";
import ZenPointsUserPage from "./page/zenPointsByUserId";
import InvoicesByIssuerIdPage from "./page/invoicesByIssuerId";
import InvoicePage from "./page/invoice";
import UsersPage from "./page/users";
import UserPage from "./page/user";
import { UserNotesPage } from "./page/userNotes";
import IssuersPage from "./page/issuers";
import IndustryCodesPage from "./page/industryCodes";
import BalancePage from "./page/balance";
import ZenBalancePage from "./page/zenBalance";
import ForecastPage from "./page/forecast";
import PointsPage from "./page/points";
import SnapshotPage from "./page/snapshots";
import JobsPage from "./page/jobs";
import StatisticsPage from "./page/statistics";
import SubscriptionsPage from "./page/subscriptions";
import PspTransactionsPage from "./page/pspTransactions";
import PspTransactionPage from "./page/pspTransaction";
import TranslationsPage from "./page/translations";
import UserGroupsPage from "./page/usergroups";
import ZenCostPage from "./page/zenCost";
import CouponsPage from "./page/coupons";
import OffersPage from "./page/offerList";
import { OfferPage } from "./page/offerDetail";
import { OfferCreationPage } from "./page/offerCreation";
import { OfferServiceCreationPage } from "./page/offerServiceCreation";
import { OfferServicePage } from "./page/offerServiceDetail";
import { OfferCategoriesList } from "./page/offerCategoriesList";
import { OfferCategoryCreationPage } from "./page/offerCategoryCreation";
import { OfferCategoryDetailPage } from "./page/offerCategoryDetail";
import { UserSubRole } from "./types/graphql";
import { IssuerDetail } from "./component/IssuerDetail";
import { RemittanceInvoicesPage } from "./page/invoiceRemittance";

// TODO: Export routes as constants, not default, easier to refactor/import!

export interface RouteDefinition {
    path: string;
    component: (
        setIsAuthenticated: (isAuthenticated: boolean) => void,
        props?: { [key: string]: any } // Any extra props
    ) => JSX.Element;
    tags?: Array<UserSubRole>;
}

export const publicRoutes: Array<RouteDefinition> = [
    {
        path: "/login",
        component: LoginPage,
    },
    {
        path: "/login/redirection/:redirection",
        component: LoginPage,
    },
    {
        path: "/auth/:status",
        component: LoginPage,
    },
];

export const privateRoutes: Array<RouteDefinition> = [
    {
        path: "/",
        component: DashboardPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/invoices/user/:userId",
        component: InvoicesByUserIdPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/zenpoints/user/:userId",
        component: ZenPointsUserPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/invoices/issuer/:issuerId",
        component: InvoicesByIssuerIdPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/invoices/:id",
        component: InvoicePage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/remittance/invoices",
        component: RemittanceInvoicesPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/users",
        component: UsersPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/users/name/:name",
        component: UsersPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/user/:id",
        component: UserPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/user/sid/:sid",
        component: UserPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/user/:userId/notes",
        component: UserNotesPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/issuers",
        component: IssuersPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/issuers/:id",
        component: IssuerDetail,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/issuers/name/:name",
        component: IssuersPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/industry-codes",
        component: IndustryCodesPage,
        tags: [UserSubRole.AdminSupport],
    },
    {
        path: "/invoices",
        component: InvoicesPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/balance/from/:fromDate/to/:toDate",
        component: BalancePage,
    },
    {
        path: "/zenbalance/from/:fromDate/to/:toDate",
        component: ZenBalancePage,
    },
    {
        path: "/balance/forecasting",
        component: ForecastPage,
    },
    {
        path: "/balance/points",
        component: PointsPage,
    },
    {
        path: "/balance/snapshots",
        component: SnapshotPage,
    },
    {
        path: "/system/jobs",
        component: JobsPage,
    },
    {
        path: "/system/statistics",
        component: StatisticsPage,
    },
    {
        path: "/system/subscriptions",
        component: SubscriptionsPage,
    },
    {
        path: "/system/psp-transactions",
        component: PspTransactionsPage,
    },
    {
        path: "/system/psp-transactions/:id",
        component: PspTransactionPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/system/coupons",
        component: CouponsPage,
        tags: [UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/system/translations",
        component: TranslationsPage,
        tags: [UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/system/usergroups",
        component: UserGroupsPage,
        tags: [UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/system/zencost",
        component: ZenCostPage,
        tags: [UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/ninja-power/offers",
        component: OffersPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/ninja-power/offer",
        component: OfferCreationPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/ninja-power/offers/:offerId",
        component: OfferPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/ninja-power/offers/:offerId/service/:region",
        component: OfferServiceCreationPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/ninja-power/offers/:offerId/services/:offerServiceId/:region",
        component: OfferServicePage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/ninja-power/categories",
        component: OfferCategoriesList,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/ninja-power/category",
        component: OfferCategoryCreationPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
    {
        path: "/ninja-power/categories/:categoryId",
        component: OfferCategoryDetailPage,
        tags: [UserSubRole.AdminSupport, UserSubRole.AdminSupportWithDiscount],
    },
];
