import React, {createContext, useContext, useEffect, useState} from 'react';
import {ProfileAdmin, ReadAdminProfileQuery, ReadAdminProfileQueryVariables, RegionEnum,} from "../types/graphql";
import {useLazyQuery} from "@apollo/client";
import {ReadAdminProfile} from "../graphql/profile";
import {AuthContext, AuthState} from "./auth";
import {useLogout, useRefresh} from "../hooks/auth";
import {ComponentEnum, log, logT} from "../log";

interface ContextValues {
  refresh: () => Promise<void>
  logout: () => void
  loading: boolean
  profile: ProfileAdmin | null
  region: RegionEnum
  switchRegion: (region: RegionEnum) => void
}

export const ProfileContext = createContext({
  refresh: () => new Promise<void>(() => {
  }),
  logout: () => {
  },
  loading: true,
  profile: null,
  region: RegionEnum.Notset,
  switchRegion: (region: RegionEnum) => {
  }
} as ContextValues);

interface IProps {
  children: any
}

export const ProfileProvider = ({children}: IProps) => {

  const {authenticated, setAuthenticated} = useContext(AuthContext)
  const {logout} = useLogout()
  const [region, setRegion] = useState<RegionEnum>(RegionEnum.Notset)

  useEffect(() => {
    log(logT.INFO, ComponentEnum.Profile, 'Mounting');
    return () => {
      log(logT.INFO, ComponentEnum.Profile, 'UnMounting');
    }
  }, []);

  const {
    refresh,
    success: refreshSuccess,
    error: refreshError,
    loading: refreshLoading
  } = useRefresh()

  const [readAdminProfile, {
    called: calledProfile,
    data: dataProfile,
    loading: loadingProfile
  }] = useLazyQuery<ReadAdminProfileQuery, ReadAdminProfileQueryVariables>(ReadAdminProfile)

  // Verify authentication and set authenticated here
  useEffect(() => {
    refresh().then(() => {
      console.debug("Refreshed tokens from PROFILE")
    }).catch(e => {
      console.debug("Error refreshing tokens", e)
    })
  }, [])

  // When tokens are refreshed, then set user as authenticated
  useEffect(() => {
    if (refreshSuccess) {
      console.debug("Authenticated ✅")
      setAuthenticated(AuthState.Authenticated)
    }
  }, [refreshSuccess, setAuthenticated])

  // When tokens cannot be refreshed, then set user as not authenticated
  useEffect(() => {
    if (refreshError) {
      console.debug("Not authenticated 🛑")
      setAuthenticated(AuthState.NotAuthenticated)
    }
  }, [refreshError, setAuthenticated])

  // When user is authenticated and profile is not available, fetch the profile info
  useEffect(() => {
    if (authenticated === AuthState.Authenticated && !dataProfile) {
      if (calledProfile) {
        log(logT.INFO, ComponentEnum.Profile, "Called Admin profile")
      } else {
        log(logT.INFO, ComponentEnum.Profile, "Finished reading Admin profile")
        readAdminProfile()
      }
    }
  }, [authenticated, dataProfile, readAdminProfile, refresh, calledProfile])

  // This provider will wrap the rest of the tree and we pass in the user in the
  // state and the updateUser function as well.
  return (
    <ProfileContext.Provider value={{
      refresh,
      loading: refreshLoading || loadingProfile,
      profile: dataProfile?.profile || null,
      logout: async () => {
        try {
          await logout()
          setAuthenticated(AuthState.NotAuthenticated)
        } catch (e) {
          console.debug(e)
        }
      },
      region,
      switchRegion: setRegion
    }}>
      {children}
    </ProfileContext.Provider>
  )
}
