import React, {useEffect, useMemo} from 'react';
import Moment from "react-moment";
import Table from "react-bootstrap/Table";
import {BadgeBool} from "./BadgeBool";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import {useLazyQuery, useQuery} from "@apollo/client";
import {ReadInvoiceHistory} from "../graphql/invoiceTool";
import {
  GetPspTransactionIdByInvoiceIdQuery, GetPspTransactionIdByInvoiceIdQueryVariables,
  ReadInvoiceHistoryQuery,
  ReadInvoiceHistoryQueryVariables,
  ReadUserByIdQuery, ReadUserByIdQueryVariables,
  Scalars
} from "../types/graphql";
import {MomentDate} from "./MomentDate";
import {InvoiceHistoryDetail} from "./InvoiceHistoryDetail";
import {Loading} from "./Loading";
import {Error} from "./Error";
import {InvoiceActions} from "./InvoiceActions";
import {ReadUserById} from "../graphql/user";
import {GetPspTransactionIdByInvoiceId} from "../graphql/pspTransaction";

interface IProps {
  invoiceId: string
}

export const InvoiceHistory = ({invoiceId}: IProps) => {

  const {
    data,
    loading,
    error
  } = useQuery<ReadInvoiceHistoryQuery, ReadInvoiceHistoryQueryVariables>(ReadInvoiceHistory, {
    variables: {
      invoiceId
    }
  })

  const [getTransactionId, {data: pspData}] = useLazyQuery<GetPspTransactionIdByInvoiceIdQuery, GetPspTransactionIdByInvoiceIdQueryVariables>(GetPspTransactionIdByInvoiceId)

  const userId = data?.result?.userId;

  useEffect(() => {
    if (data?.result) {
      getTransactionId({
        variables: {
          invoiceId: data?.result?.id
        }
      })
    }
  }, [data]);

  const {data: userData} = useQuery<ReadUserByIdQuery, ReadUserByIdQueryVariables>(ReadUserById, {
    variables: {
      userId: userId as Scalars['ID']
    },
    skip: !userId,
    fetchPolicy: "cache-and-network"
  });

  const currentStatus = useMemo(() => {
    if (!data) return
    const currentStatus = data?.result?.lastTransaction.status;
    if (currentStatus === "scheduled") {
      const currentStatusDate = data?.result?.lastTransaction.statusDate;
      const date = moment(currentStatusDate).format("DD MMMM YYYY");
      return "scheduled for " + date;
    } else {
      return currentStatus;
    }
  }, [data]);

  const transactions = useMemo(() => {
    const txs = data?.result?.transactions || []
    return txs.map((tx, i) => {
      return (
        <tr key={i}>
          <td>
            <MomentDate
              value={tx?.time}
              format={"DD MMMM YYYY HH:mm:ss"}
              tooltipId={`tr_${i}`}
              tooltipFromNow={true}
            />
          </td>
          <td>{tx?.event}</td>
          <td>{tx?.status}</td>
          <td>{tx?.remitStatus}</td>
          <td>{tx?.remitStatusDescription}</td>
          <td>{tx?.amount}</td>
          <td>{tx?.reference}</td>
          <td>
            <InvoiceHistoryDetail>{tx?.details}</InvoiceHistoryDetail>
          </td>
          <td>{tx?.actor}</td>
          <td>{tx?.source}</td>
        </tr>
      );
    });
  }, [data]);

  const lastUpdate = useMemo(() => {
    const txs = data?.result?.transactions || [];
    if (txs.length === 0) {
      return "N.A.";
    }
    const time = txs[0]?.time;
    return <Moment fromNow>{time}</Moment>;
  }, [data]);

  if (loading || !data?.result) return <Loading isLoading={loading}/>;
  if (error) return <Error error={error.graphQLErrors[0].message}/>;

  const d = data?.result;

  return (
    <>
      <InvoiceActions
        invoiceId={d?.id}
        status={d?.lastTransaction!.status}
        remitStatus={d?.lastTransaction!.remitStatus}
      />

      <h4>Overview</h4>
      <Table responsive bordered hover size="sm">
        <thead>
        <tr>
          <th>Invoice ID</th>
          <th>Status</th>
          <th>Remit status</th>
          <th>Amount</th>
          <th>PSP Tx</th>
          <th>Owner</th>
          <th>Incoming</th>
          <th>Outgoing</th>
          <th>Last update</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{d!.id}</td>
          <td>
            <Badge bg={"secondary"}>{currentStatus}</Badge>
          </td>
          <td>
            <Badge bg={"secondary"}>{d?.lastTransaction.remitStatus}</Badge>
          </td>
          <td>
            {d?.amount.current} NOK <Badge bg={"light"}>
            Original: {d?.amount.original} NOK
          </Badge>
          </td>
          <td>
            {pspData?.pspTransactionId ? (
              <a href={`/system/psp-transactions/${pspData.pspTransactionId}`} target="_blank">
                {pspData.pspTransactionId}
              </a>
            ) : null}
          </td>
          <td>
            {userId && userData?.result?.firstname && (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a href={`/user/${d?.userId}`} target="_blank"
                 style={{color: 'blue', textDecoration: 'none'}}>
                {userData?.result?.firstname} {userData?.result?.lastname} (id: {d?.userId})
              </a>
            )}
          </td>
          <td>
            <BadgeBool isOk={d?.payment!.incoming}
                       textOnTrue="received"
                       textOnFalse="not received"/>
          </td>
          <td>
            <BadgeBool isOk={d?.payment!.outgoing}
                       textOnTrue="sent"
                       textOnFalse="not sent"/>
          </td>
          <td>
            {lastUpdate}
          </td>
        </tr>
        </tbody>
      </Table>

      <h4>Transactions</h4>
      <Table responsive striped bordered hover size="sm">
        <thead>
        <tr>
          <th>Time</th>
          <th>Event</th>
          <th>Invoice Status</th>
          <th>Remit Status</th>
          <th>Remit Description</th>
          <th>Amount</th>
          <th>Reference</th>
          <th>Details</th>
          <th>Actor</th>
          <th>Source</th>
        </tr>
        </thead>
        <tbody>
        {transactions}
        </tbody>
      </Table>

    </>
  );

};
