import React from 'react';
import {Container} from "react-bootstrap";
import {OfferDetail} from "../component/OfferDetail";
import {useNavigate} from "react-router-dom";

export const OfferCreationPage = () => {

    const history = useNavigate();

    return (
        <Container>
            <OfferDetail
                onCreatedOffer={() => {
                    history(-1);
                }}
            />
        </Container>
    );
}


