import React, { useState } from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {
  CreateOfferServiceMutationVariables,
  DeleteOfferServiceMutationVariables,
  OfferServiceWithTranslations,
  UpdateOfferServiceMutationVariables,
} from "../types/graphql";

interface IProps {
  data?: OfferServiceWithTranslations;
  offerId: string;
  onCreate?: (offerToCreate: CreateOfferServiceMutationVariables) => void;
  onUpdate?: (offerToUpdate: UpdateOfferServiceMutationVariables) => void;
  onDelete?: (offerToDelete: DeleteOfferServiceMutationVariables) => void;
}

export const OfferServiceForm = ({
  data,
  offerId,
  onCreate,
  onUpdate,
  onDelete,
}: IProps) => {
  // Form validation
  const [validated, setValidated] = useState(false);

  // Form fields
  const [nameEn, setNameEn] = useState(data?.nameEn || "");
  const [nameNo, setNameNo] = useState(data?.nameNo || "");
  const [nameSv, setNameSv] = useState(data?.nameSv || "");
  const [descriptionEn, setDescriptionEn] = useState(data?.descriptionEn || "");
  const [descriptionNo, setDescriptionNo] = useState(data?.descriptionNo || "");
  const [descriptionSv, setDescriptionSv] = useState(data?.descriptionSv || "");
  const [url, setUrl] = useState(data?.url || "");
  const [email, setEmail] = useState(data?.email || "");
  const [emailDescriptionEn, setEmailDescriptionEn] = useState(
    data?.emailDescriptionEn || ""
  );
  const [emailDescriptionNo, setEmailDescriptionNo] = useState(
    data?.emailDescriptionNo || ""
  );
  const [emailDescriptionSv, setEmailDescriptionSv] = useState(
      data?.emailDescriptionSv || ""
  );

  const [phone, setPhone] = useState(data?.phone || "");

  const isCreationMode = !data;

  // Form submit handler
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    const isFormValid = form.checkValidity();
    setValidated(true);
    if (!isFormValid) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (isCreationMode) {
      // Create offer callback
      onCreate?.({
        offerId,
        nameEn,
        nameNo,
        nameSv,
        descriptionEn,
        descriptionNo,
        descriptionSv,
        url,
        email,
        emailDescriptionEn,
        emailDescriptionNo,
        phone,
      });
    } else if (data) {
      // Update offer callback
      onUpdate?.({
        offerServiceId: data.id,
        offerId,
        nameEn,
        nameNo,
        nameSv,
        descriptionEn,
        descriptionNo,
        descriptionSv,
        url,
        email,
        emailDescriptionEn,
        emailDescriptionNo,
        emailDescriptionSv,
        phone,
      });
    }
  };

  const onDeleteClick = () => {
    if (data) {
      onDelete?.({
        offerId,
        offerServiceId: data.id,
      });
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row>
        <Form.Group as={Col} md="6" controlId="offerForm.name">
          <Form.Label>Name 🇬🇧</Form.Label>
          <Form.Control
            type="text"
            placeholder="Service"
            value={nameEn}
            onChange={(e) => setNameEn(e.currentTarget.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid service name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="offerForm.name">
          <Form.Label>Name 🇳🇴</Form.Label>
          <Form.Control
            type="text"
            placeholder="Service"
            value={nameNo}
            onChange={(e) => setNameNo(e.currentTarget.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid service name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="offerForm.name">
          <Form.Label>Name 🇸🇪</Form.Label>
          <Form.Control
              type="text"
              placeholder="Service"
              value={nameSv}
              onChange={(e) => setNameSv(e.currentTarget.value)}
              required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid service name.
          </Form.Control.Feedback>
        </Form.Group>

      </Row>

      <Row>
        <Form.Group as={Col} md="6" controlId="offerForm.descriptionEn">
          <Form.Label>Description 🇬🇧</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={descriptionEn}
            onChange={(e) => setDescriptionEn(e.currentTarget.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid description for english language.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="offerForm.descriptionNo">
          <Form.Label>Description 🇳🇴</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={descriptionNo}
            onChange={(e) => setDescriptionNo(e.currentTarget.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid description for norwegian language.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6" controlId="offerForm.descriptionSv">
          <Form.Label>Description 🇸🇪</Form.Label>
          <Form.Control
              as="textarea"
              rows={3}
              value={descriptionSv}
              onChange={(e) => setDescriptionSv(e.currentTarget.value)}
              required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid description for norwegian language.
          </Form.Control.Feedback>
        </Form.Group>

      </Row>

      <Form.Label>
        <p>
          In order to create an offer service, you have to fill at least one of
          the following parameters:
        </p>
        <ul>
          <li>
            <strong>URL</strong>: used to redirect the user to the specified URL
            when the service is clicked;
          </li>
          <li>
            <strong>Email</strong>: used to present/send a contact form to the
            specified email when the service is clicked;
          </li>
          <li>
            <strong>Phone</strong>: used to call the offer provider about this
            service.
          </li>
        </ul>
        <p>
          If you set an email, you may be interested to have a custom text over
          the email form.
          <br />
          Fill the <strong>Email descriptions</strong> below for Norwegian and
          English versions.
        </p>
      </Form.Label>

      <Row>
        <Form.Group as={Col} md="6" controlId="offerForm.name">
          <Form.Label>Email description 🇬🇧</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Some text"
            value={emailDescriptionEn}
            onChange={(e) => setEmailDescriptionEn(e.currentTarget.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid service name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="offerForm.name">
          <Form.Label>Email description 🇳🇴</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Some text"
            value={emailDescriptionNo}
            onChange={(e) => setEmailDescriptionNo(e.currentTarget.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid service name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="offerForm.name">
          <Form.Label>Email description 🇸🇪</Form.Label>
          <Form.Control
              as="textarea"
              rows={3}
              placeholder="Some text"
              value={emailDescriptionSv}
              onChange={(e) => setEmailDescriptionSv(e.currentTarget.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid service name.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} md="12" controlId="offerForm.url">
          <Form.Label>URL</Form.Label>
          <Form.Control
            type="url"
            placeholder="https://join.company.as"
            value={url}
            onChange={(e) => setUrl(e.currentTarget.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid URL.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} md="6" controlId="offerForm.email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="join@company.as"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="offerForm.phone">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            type="phone"
            placeholder="9001234567"
            value={phone}
            onChange={(e) => setPhone(e.currentTarget.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid phone.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Form.Group>
        {isCreationMode ? (
          <Button type="submit" variant={"primary"}>
            Create
          </Button>
        ) : (
          <>
            <Button type="submit" variant={"primary"}>
              Update
            </Button>{" "}
            <Button variant={"secondary"} onClick={onDeleteClick}>
              Delete
            </Button>
          </>
        )}
      </Form.Group>
    </Form>
  );
};
