import React from 'react';
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { OfferServiceDetail } from "../component/OfferServiceDetail";
import { RegionFlag } from "../component/RegionFlag";

type IParams = {
    offerId: string
    offerServiceId: string
    region: string
}

export const OfferServicePage = () => {

    const history = useNavigate();
    const { offerId, offerServiceId, region } = useParams<IParams>();

    return (
        <Container>
            <div className="light" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 style={{ margin: 0 }}>Service detail</h1>
                <div style={{ alignSelf: 'right' }}>
                    <RegionFlag region={region} headerLevel="h1"/>
                </div>
            </div>
            <OfferServiceDetail
                offerId={offerId || ''}
                offerServiceId={offerServiceId}
                onUpdated={() => {
                    history(-1);
                }}
                onDeleted={() => {
                    history(-1);
                }}
            />
        </Container>
    );
}


