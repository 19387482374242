import React, {useCallback, useContext, useMemo, useState} from 'react';
import {Link} from "react-router-dom";
import {Table} from "react-bootstrap";
import {BadgeBool} from "./BadgeBool";
import Button from "react-bootstrap/Button";
import {useLazyQuery, useQuery} from '@apollo/client'
import {ReadBalanceHistory, ReadBalanceHistoryAsExcel} from '../graphql/invoiceTool'
import {
  ReadBalanceHistoryAsExcelQuery, ReadBalanceHistoryAsExcelQueryVariables,
  ReadBalanceHistoryQuery,
  ReadBalanceHistoryQueryVariables,
} from '../types/graphql'
import {MomentDate} from "./MomentDate";
import {Error} from "./Error";
import {LoadingPage} from "../page/loading";
import {CSSProperties} from 'react'
import {FormatDateAsISO} from "../helper/system";
import {ProfileContext} from "../context/profile";

interface IProps {
  fromDate: Date,
  toDate: Date,
}

export const BalanceHistory = ({fromDate, toDate}: IProps) => {

  const {region} = useContext(ProfileContext)

  const {
    data,
    loading,
    error
  } = useQuery<ReadBalanceHistoryQuery, ReadBalanceHistoryQueryVariables>(ReadBalanceHistory, {
    variables: {
      fromDate: FormatDateAsISO(fromDate),
      toDate: FormatDateAsISO(toDate),
      region
    }
  });

  const [showTransactions, setShowTransactions] = useState<boolean>(false);

  const toggleTransactionsVisibility = useCallback(() => {
    setShowTransactions(!showTransactions);
  }, [showTransactions]);

  const transactions = useMemo(() => {
    if (!data?.result) return [];

    if (!showTransactions) {
      return (
        <tr>
          <td colSpan={10}>
            <Button variant="primary" type="submit" onClick={toggleTransactionsVisibility}>
              Show all transactions ({data.result.transactions.length})
            </Button>
          </td>
        </tr>
      );
    }

    const transactions = data.result.transactions;
    return transactions.map((tx, i) => {
      return (
        <tr key={i}>
          <td>
            <MomentDate
              value={tx.lastTransactionTime}
              format={"DD MMMM YYYY HH:mm:ss"}
              tooltipId={`tr_${i}`}
              tooltipFromNow={true}
            />
          </td>
          <td>
            <MomentDate
              value={tx.paymentTime}
              format={"DD MMMM YYYY HH:mm:ss"}
            />
          </td>
          <td>
            <MomentDate
              value={tx.remitTime}
              format={"DD MMMM YYYY HH:mm:ss"}
            />
          </td>
          <td>
            <Link to={`/invoices/${tx.invoiceIdentifier}`}>{tx.invoiceId}</Link>
          </td>
          <td>
            <Link to={`/user/${tx.userId}`}>{tx.userName}</Link>
          </td>
          <td>
            <Link to={`/issuers/${tx.issuerId}`}>{tx.issuerName}</Link>
          </td>
          <td>{tx.balanceInvoiceAmount}</td>
          <td>{tx.balanceCardAmount}</td>
          <td>{tx.balanceFeeAmount}</td>
          {/* poeng man har brukt på gebyr, i kroner*/}
          <td>{(tx.balanceZenFee / 1000).toFixed(2)}</td>
          {/*Amount + Fees Paid - Fees from Points*/}
          <td>{tx.balanceGross}</td>
          <td>{tx.balanceRemitAmount}</td>
        </tr>
      );
    });

  }, [data, showTransactions, toggleTransactionsVisibility]);

  const [getAsExcel, {
    loading: getAsExcelLoading,
    error: getAsExcelError,
    data: getAsExcelData
  }] = useLazyQuery<ReadBalanceHistoryAsExcelQuery, ReadBalanceHistoryAsExcelQueryVariables>(ReadBalanceHistoryAsExcel, {
    variables: {
      fromDate: FormatDateAsISO(fromDate),
      toDate: FormatDateAsISO(toDate),
      region
    }
  });


  useMemo(() => {
    if (getAsExcelData && getAsExcelData.result && getAsExcelData.result.data) {
      let formatDate = function (d: Date) {
        let month = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
        let day = d.getDate() > 9 ? d.getDate() : '0' + d.getDate();
        return "" + d.getFullYear() + "-" + month + "-" + day;
      };
      let a = document.createElement("a");
      a.href = "data:image/png;base64," + getAsExcelData.result.data;
      a.download = "billkill_balance_txs" + formatDate(fromDate) + "_" + formatDate(toDate) + ".xlsx";
      a.click();
    }
  }, [getAsExcelData, fromDate, toDate])

  const downloadAsExcel = useCallback(() => {
    getAsExcel()
  }, [getAsExcel]);

  const total = useMemo(() => {
    if (!data?.result) return;
    const h = data.result;
    return (
      <tr>
        <td colSpan={6}>TOTAL</td>
        <td><strong>{h.sumIncomingWithoutFees} NOK</strong></td>
        <td><strong>{h.sumFees} NOK </strong></td>
        <td><strong>{h.sumOutgoing} NOK</strong></td>
        <td><strong>{h.sumZenFeeNOK.toFixed(2)} NOK</strong></td>
        <td><strong>{h.sumGrossAmount.toFixed(2)} NOK</strong></td>
      </tr>
    );
  }, [data]);

  const balance = useMemo(() => {
    if (!data?.result) return;
    const h = data.result;
    const isBalanceUp = h.balance >= 0;
    const text = isBalanceUp ? `${h.balance} NOK` : `(${h.balance} NOK)`;
    return (
      <tr>
        <td><strong>{h.sumIncoming} NOK</strong></td>
        <td><strong>({h.sumOutgoing} NOK)</strong></td>
        <td><strong>({h.sumFees} NOK)</strong></td>
        <td>
          <BadgeBool isOk={isBalanceUp} text={text}/>
        </td>
      </tr>
    )
  }, [data])

  const fees = useMemo(() => {
    if (!data?.result) return;
    return data.result.fees.map((fee, index) =>
      <tr key={index}>
        <td>{fee.reason}</td>
        <td>{fee.total} NOK</td>
      </tr>
    );
  }, [data]);

  if (loading) return <LoadingPage variant={'secondary'}/>;
  if (error) return <Error error={error.graphQLErrors[0].message}/>;

  const downloadStyles: CSSProperties = {
    float: 'right',
    marginBottom: '10px'
  }

  return (
    <>
      <Table responsive striped bordered hover size="sm">
        <thead>
        <tr>
          <th>Incoming (with fees)</th>
          <th>Outgoing (remittance)</th>
          <th>Outgoing (fees)</th>
          <th>Balances</th>
        </tr>
        </thead>
        <tbody>
        {balance}
        </tbody>
      </Table>

      <div style={downloadStyles}>
        <Button variant="primary" type="button" onClick={downloadAsExcel} disabled={getAsExcelLoading}>
          {getAsExcelLoading ? 'Loading...' : 'Download transactions as Excel'}
        </Button>
      </div>

      <Table responsive striped bordered hover size="sm">
        <thead>
        <tr>
          <th>LastUpdatedAt</th>
          <th>PaymentAt</th>
          <th>RemittanceAt</th>
          <th>Invoice ID</th>
          <th>User</th>
          <th>Issuer</th>
          <th>Amount</th>
          <th>Incoming</th>
          <th>Fees Paid</th>
          <th>Zen Fee</th>
          <th>Gross Amount</th>
          <th>Outgoing</th>
        </tr>
        </thead>
        <tbody>
        {transactions}
        {total}
        </tbody>
      </Table>

      <Table responsive striped bordered hover size="sm">
        <thead>
        <tr>
          <th>Fee type</th>
          <th>Total</th>
        </tr>
        </thead>
        <tbody>
        {fees}
        </tbody>
      </Table>

    </>
  );
};
