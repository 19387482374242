import React, {useEffect, useState} from 'react';
import {DoManualZenCorrection} from '../graphql/zenPoints';
import {Card, Form, InputGroup} from "react-bootstrap";
import {ComponentEnum, log, logT} from "../log";
import Button from "react-bootstrap/Button";
import {useMutation} from "@apollo/client";
import {ManualZenCorrectionMutation, ManualZenCorrectionMutationVariables} from "../types/graphql";

interface IProps {
  userId: string;
}

const inputStyle = {width: '10em', marginLeft: 'auto'};
const inputWrapperStyle = {width: '30em', margin: '0.4em'};

const Component = ComponentEnum.ZenManualCorrection;

export const ZenManualCorrection = ({userId}: IProps) => {

  const [basicPointsStr, setBasicPointsStr] = useState<string>('');
  const [extraPointsStr, setExtraPointsStr] = useState<string>('');

  const [memo, setMemo] = useState<string>('');
  const [alertText, setAlertText] = useState<string>('');
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const [doManualZenCorrection, {
    loading: saving,
    error
  }] = useMutation<ManualZenCorrectionMutation, ManualZenCorrectionMutationVariables>(DoManualZenCorrection);

  useEffect(() => {
    if (error) {
      log(logT.ERROR, Component, 'Mutation error:', error);
      setAlertText('Failed to update: ' + error?.message);
      setShowWarning(true);
    }
  }, [error]);

  useEffect(() => {
    log(logT.INFO, Component, "Loading");
    return () => {
      log(logT.INFO, Component, "UnMounting");
    }
  }, []);

  function saveAction() {
    let extra = parseInt(extraPointsStr || '0');
    let basic = parseInt(basicPointsStr || '0');
    if (memo === '' || (extra === 0 && basic === 0)) {
      setAlertText('Memo and points are required, either extra or basic');
      setShowWarning(true);
      return;
    }
    doManualZenCorrection({
      variables: {
        userId: userId,
        extra: extra,
        basic: basic,
        memo: memo,
      },
    })
      .then(({data}) => {
        if (data?.manualZenCorrection?.isOk) {
          setAlertText('Successfully updated');
          setShowSuccess(true);
        } else {
          log(logT.ERROR, Component, 'Operation not successful, GraphQL says:', data);
          setAlertText('Operation was not successful, GraphQL says: ' + error?.message);
          setShowWarning(true);
        }
      }).catch((e) => {
      log(logT.ERROR, Component, 'DoManualZenCorrection error:', e?.message);
      setAlertText(`Failed to update: ${e.message}`);
      setShowWarning(true);
    });
  }

  return (
    <div style={inputWrapperStyle}>
      <Card style={{marginTop: "1rem"}}>
        <Card.Header>
          <h5 className="dark">Manual Zen Correction</h5>
        </Card.Header>
        <Card.Body>
          <InputGroup style={{width: "20em;", margin: "0.4em 0.4em 0.4em 0.4em"}}>
            <InputGroup.Text>Extra Points</InputGroup.Text>
            <Form.Control
              type="text"
              style={inputStyle}
              value={extraPointsStr}
              onChange={(e) => {
                if (/^-?\d*$/.test(e.target.value)) {
                  setAlertText('')
                  setShowWarning(false);
                  setExtraPointsStr(e.target.value);
                }
              }}
            />

          </InputGroup>
          <InputGroup style={{width: "20em;", margin: "0.4em 0.4em 0.4em 0.4em"}}>
            <InputGroup.Text>Basic Points</InputGroup.Text>
            <Form.Control
              style={inputStyle}
              type="text"
              value={basicPointsStr}
              onChange={(e) => {
                if (/^-?\d*$/.test(e.target.value)) {
                  setAlertText('')
                  setShowWarning(false);
                  log(logT.INFO, Component, "onChange", e.target.value);
                  setBasicPointsStr(e.target.value);
                }
              }}
            />
          </InputGroup>
          <InputGroup style={{width: "20em;", margin: "0.4em 0.4em 0.4em 0.4em"}}>
            <InputGroup.Text>Memo</InputGroup.Text>
            <Form.Control
              style={inputStyle}
              value={memo}
              onChange={(e) => {
                setAlertText('')
                setShowWarning(false);
                setMemo(e.target.value);
              }}
            />
          </InputGroup>

          <div style={{display: "flex", justifyContent: "flex-end", margin: "0.4em -0.4em 0.4em 0.4em"}}>
            <Button onClick={saveAction} disabled={saving}>Save Correction</Button>
          </div>
          {
            showSuccess &&
              <div style={{margin: "0.4em -0.4em 0.4em 0.4em"}}
                   className="alert alert-success">{alertText}</div>
          }
          {
            showWarning &&
              <div style={{margin: "0.4em -0.4em 0.4em 0.4em"}}
                   className="alert alert-warning">{alertText}</div>
          }
        </Card.Body>
      </Card>
    </div>
  );
};
