import React, {useContext} from "react";
import {ProfileContext} from "../context/profile";
import {RegionEnum} from "../types/graphql";
import Select from "react-select";

type RegionOption = {
  value: RegionEnum;
  label: string;
};

const options: RegionOption[] = [
  {value: RegionEnum.Notset, label: "All regions"},
  {value: RegionEnum.No, label: "🇳🇴 Norway"},
  {value: RegionEnum.Sv, label: "🇸🇪 Sweden"}
];

export function RegionPicker () {
  const {region, switchRegion} = useContext(ProfileContext)
  return (
    <Select
      defaultValue={null}
      onChange={(v) => {
        switchRegion(v?.value as RegionEnum)
      }}
      placeholder={"Region"}
      options={options}
      className="basic-single-select"
      classNamePrefix="select"
      value={options.find(o => o.value === region)}
    />
  )
}
