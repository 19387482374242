import React, { useMemo } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { ReadPspTransactionById, ReadPspTransactionLogsById } from '../graphql/pspTransaction'
import { useQuery } from '@apollo/client'
import {
  ReadPspTransactionByIdQuery,
  ReadPspTransactionByIdQueryVariables,
  ReadPspTransactionLogsByIdQuery,
  ReadPspTransactionLogsByIdQueryVariables,
} from '../types/graphql'
import { LoadingPage } from './loading'
import { MomentDate } from '../component/MomentDate'

type IParams = {
  id: string
}

export default () => {

  const { id } = useParams<IParams>()

  const {
    data,
    loading,
    error,
  } = useQuery<ReadPspTransactionByIdQuery, ReadPspTransactionByIdQueryVariables>(ReadPspTransactionById, {
    variables: {
      id: parseInt(id || '', 10),
    },
  })

  const {
    data: logsData,
    loading: logsLoading,
    error: logsError,
  } = useQuery<ReadPspTransactionLogsByIdQuery, ReadPspTransactionLogsByIdQueryVariables>(ReadPspTransactionLogsById, {
    variables: {
      id: parseInt(id || '', 10),
    },
  })

  const logs = useMemo(() => {
    const txs = logsData?.result?.logs || []
    const formatData = (v: string|null) => {
      if (!v) {
        return '(No data)'
      }
      if (v.indexOf('{') === 0 || v.indexOf('[{') === 0) {
        return JSON.stringify(JSON.parse(v), null, 2)
      }
      return v
    }
    return txs.map((log, i) => {
      return (
        <div key={i} style={{ marginBottom: '20px', paddingBottom: '20px', borderBottom: '1px solid #ccc' }}>
          <div>
            <div style={{ float: 'right', marginLeft: '30px' }}>
              <MomentDate
                value={log?.time}
                format={"YYYY-MM-DD HH:mm:ss.SS"}
                tooltipId={`tr_${i}`}
                tooltipFromNow={true}
                parse={"x"}
              />
            </div>
            <div style={{ float: 'right' }}>
              {log?.ipAddress || '(No IP)'}
            </div>
            <div style={{ fontWeight: 'bold' }}>
              {log?.event}
            </div>
          </div>
          <div style={{ color: '#888' }}>
            {log?.url || '(No URL)'}
          </div>
          <div style={{ marginTop: '15px' }}>
            <pre>{formatData(log?.data)}</pre>
          </div>
        </div>
      )
    })
  }, [logsData, logsError])


  if (loading || !data?.result) {
    return <LoadingPage/>
  }

  return (
    <Container fluid>
      <Card style={CardStyle}>

        <Card.Header>
          <h5 className="dark">PSP Transaction {id}</h5>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col style={LabelStyle}>
              ID
            </Col>
            <Col style={LabelStyle}>
              PaymentID
            </Col>
            <Col style={LabelStyle}>
              CreatedAt
            </Col>
            <Col style={LabelStyle}>
              Status
            </Col>
          </Row>
          <Row>
            <Col style={ValueStyle}>
              {data?.result?.id}
            </Col>
            <Col style={ValueStyle}>
              {data?.result?.paymentId}
            </Col>
            <Col style={ValueStyle}>
              {data?.result?.remoteCreatedAt} / {data?.result?.localCreatedAt}
            </Col>
            <Col style={ValueStyle}>
              {data?.result?.bkStatus}
            </Col>
          </Row>
          <Row>
            <Col style={LabelStyle}>
              User
            </Col>
            <Col style={LabelStyle}>
              Provider
            </Col>
            <Col style={LabelStyle}>
              Issuer
            </Col>
            <Col style={LabelStyle}>
              Type
            </Col>
          </Row>
          <Row>
            <Col style={ValueStyle}>
              {data?.result?.userName}
            </Col>
            <Col style={ValueStyle}>
              {data?.result?.bkProvider}
            </Col>
            <Col style={ValueStyle}>
              {data?.result?.issuerName}
            </Col>
            <Col style={ValueStyle}>
              {data?.result?.bkType}
            </Col>
          </Row>
          <Row>
            <Col style={LabelStyle}>
              Amount
            </Col>
            <Col style={LabelStyle}>
              VatAmount
            </Col>
            <Col style={LabelStyle}>
              InvoiceID
            </Col>
            <Col style={LabelStyle}>
              UserSubscriptionID
            </Col>
          </Row>
          <Row>
            <Col style={ValueStyle}>
              {data?.result?.amount}
            </Col>
            <Col style={ValueStyle}>
              {data?.result?.vatAmount}
            </Col>
            <Col style={ValueStyle}>
              {data?.result?.bkInvoiceId}
            </Col>
            <Col style={ValueStyle}>
              {data?.result?.bkUserSubscriptionId}
            </Col>
          </Row>
          <Row>
            <Col style={LabelStyle}>
              ReconciliationID
            </Col>
          </Row>
          <Row>
            <Col style={ValueStyle}>
              {data?.result?.reconciliationId}
            </Col>
          </Row>

          <div style={{ fontSize: '24px', borderBottom: '1px solid #ccc', marginBottom: '30px', marginTop: '30px' }}>
            Logs
          </div>

          {logs}

        </Card.Body>

      </Card>

    </Container>
  )

}

const CardStyle = {
  marginTop: '2.5%',
  marginBottom: '2.5%',
}

const LabelStyle = {
  marginBottom: '5px',
  fontWeight: 'bold',
} as React.CSSProperties

const ValueStyle = {
  marginBottom: '20px',
} as React.CSSProperties
