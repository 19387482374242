import React from 'react';
import {useParams} from 'react-router-dom';
import {Container} from "react-bootstrap";
import {InvoicesOverview} from "../component/InvoicesOverview";

type IParams = {
    issuerId: string;
}

export default () => {

    const {issuerId} = useParams<IParams>();

    return (
        <div className="InvoicesPage">
            <Container>
                <InvoicesOverview issuerId={issuerId}/>
            </Container>
        </div>
    );

}


