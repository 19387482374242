import {useCallback, useEffect, useRef, useState} from "react";
import {AccessTokenStorage, BankIdSessionStorage, RefreshTokenStorage} from "../core/security";
import {useMutation} from "@apollo/client";
import {
    CreateAccessFromSessionMutation, CreateAccessFromSessionMutationVariables,
    CreateBankIdSessionMutation, CreateBankIdSessionMutationVariables,
    RefreshTokenMutation, RefreshTokenMutationVariables, UserRoleEnum
} from "../types/graphql";
import {CreateAccessFromSession, CreateBankIdSession, RefreshToken} from "../graphql/auth";
import {useParams} from "react-router-dom";

const currentHost = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

const authCallbacks = {
    cbSuccess: `${currentHost}/auth/success`,
    cbFailure: `${currentHost}/auth/failure`,
    cbAbort: `${currentHost}/auth/abort`,
};

type IParams = {
    status: string
}

export const useLogin = () => {

    const isFirstFetch = useRef(true);
    const {status} = useParams<IParams>();
    const [loading, setLoading] = useState(false)
    const [sessionUrl, setSessionUrl] = useState<string | null>(null)
    const [success, setSuccess] = useState(false)

    const [createSession, {
        data: dataCreateSession,
        loading: loadingCreateSession,
        error: errorCreateSession
    }] = useMutation<CreateBankIdSessionMutation, CreateBankIdSessionMutationVariables>(CreateBankIdSession)

    const [createAccessFromSession, {
        data: dataAccessFromSession,
        loading: loadingAccessFromSession,
        error: errorAccessFromSession
    }] = useMutation<CreateAccessFromSessionMutation, CreateAccessFromSessionMutationVariables>(CreateAccessFromSession)

    const createBankIdSession = useCallback(async (mobile: string) => {
        const {cbSuccess, cbFailure, cbAbort} = authCallbacks
        try {
            await createSession({
                variables: {
                    mobile,
                    cbSuccess,
                    cbFailure,
                    cbAbort
                }
            })
        } catch (e) {
            console.error(e)
        }
    }, [createSession]);

    const createAccess = useCallback(async () => {
        // Get and check session ID
        const bankIdSessionStorage = new BankIdSessionStorage();
        const sessionId = await bankIdSessionStorage.get();
        if (sessionId) {
            // If session data is available, create tokens using that session
            const hasSuccessfulSession = status === "success" && sessionId !== "";
            if (hasSuccessfulSession) {
                try {
                    await createAccessFromSession({
                        variables: {
                            sessionId,
                            role: UserRoleEnum.Admin
                        }
                    });
                } catch (e) {
                    console.error(e)
                }
            }
        }
    }, [createAccessFromSession, status]);

    // Effect - init
    useEffect(() => {
        if (isFirstFetch.current) {
            isFirstFetch.current = false
            createAccess().catch(e => {
                console.debug(e)
            })
        }
    }, [createAccess])

    // Effect - On create session
    useEffect(() => {
        if (!loadingCreateSession && !errorCreateSession && dataCreateSession) {
            // TODO: They should not be nullable - Fix backend (if it is not a breaking change)
            setLoading(true)
            const sessionId = dataCreateSession.result?.id || ''
            const bankIdSessionStorage = new BankIdSessionStorage();
            bankIdSessionStorage.set(sessionId).then(() => {
                setSessionUrl(dataCreateSession?.result?.url || '');
            }).catch(e => {
                console.error(e)
            })
        }
        return () => {
            // Set to false when unmounted (even if unnecessary as redirected to an external page)
            setLoading(false)
        }
    }, [loadingCreateSession, errorCreateSession, dataCreateSession])

    // Effect - On create access from session
    useEffect(() => {
        if (!loadingAccessFromSession && !errorAccessFromSession && dataAccessFromSession) {
            setLoading(true)
            // TODO: They should not be nullable - Fix backend (if it is not a breaking change)
            const token = dataAccessFromSession?.result?.token || '';
            const refreshToken = dataAccessFromSession?.result?.refreshToken || '';
            (async () => {
                const accessTokenStorage = new AccessTokenStorage();
                const refreshTokenStorage = new RefreshTokenStorage();
                await accessTokenStorage.set(token)
                await refreshTokenStorage.set(refreshToken)
                setSuccess(true)
                setLoading(false)
            })()
        }
    }, [loadingAccessFromSession, errorAccessFromSession, dataAccessFromSession])

    return {
        login: createBankIdSession,
        success,
        sessionUrl,
        loading: loading || loadingAccessFromSession || loadingCreateSession,
        error: errorCreateSession || errorAccessFromSession || null
    }
}

export const useRefresh = () => {

    const [refreshToken, {
        data: dataRefreshToken,
        loading: loadingRefreshToken,
        error: errorRefreshToken
    }] = useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshToken)

    // On refresh token
    useEffect(() => {
        if (dataRefreshToken) {
            // TODO: They should not be nullable - Fix backend (without breaking changes)
            const token = dataRefreshToken?.result?.token || '';
            const refreshToken = dataRefreshToken?.result?.refreshToken || '';
            (async () => {
                const accessTokenStorage = new AccessTokenStorage();
                const refreshTokenStorage = new RefreshTokenStorage();
                await accessTokenStorage.set(token)
                await refreshTokenStorage.set(refreshToken)
            })()
        }
    }, [dataRefreshToken, errorRefreshToken])

    const refresh = useCallback(async () => {
        if (!loadingRefreshToken) {
            const refreshTokenStorage = new RefreshTokenStorage();
            const isRefreshTokenSet = await refreshTokenStorage.isSet()
            if (isRefreshTokenSet) {
                const t = await refreshTokenStorage.get() || ''
                try {
                    await refreshToken({
                        variables: {
                            refreshToken: t
                        }
                    });
                }
                catch (e) {
                    console.error('Auth - Error refreshing tokens:', e)
                    await refreshTokenStorage.reset()
                }
            }
        }
    }, [loadingRefreshToken, refreshToken])

    return {
        refresh,
        loading: loadingRefreshToken,
        error: errorRefreshToken,
        success: !loadingRefreshToken && !errorRefreshToken && !!dataRefreshToken?.result
    }
};

export const useLogout = () => {
    const accessTokenStorage = new AccessTokenStorage();
    const refreshTokenStorage = new RefreshTokenStorage();
    const bankIdSessionStorage = new BankIdSessionStorage();

    const logout = useCallback(async () => {
        console.debug('Logout')
        await accessTokenStorage.reset()
        await refreshTokenStorage.reset()
        await bankIdSessionStorage.reset()
    }, [accessTokenStorage, refreshTokenStorage, bankIdSessionStorage])

    return {
        logout
    }
}

