import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Card, Table } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { OfferStatus, ReadOffersQuery, ReadOffersQueryVariables } from "../types/graphql";
import { Error } from "./Error";
import { ReadOffers } from "../graphql/offer";
import { LoadingPage } from "../page/loading";
import { BadgeBool } from "./BadgeBool";
import { useNavigate } from "react-router-dom";
import { RegionFlag } from "./RegionFlag";
import { ComponentEnum, log, logT } from "../log";

const Component = ComponentEnum.OffersOverview;

export const OffersOverview = () => {

    const history = useNavigate();

    useEffect(() => {
        log(logT.INFO, Component, 'Mounting');
        return () => {
            log(logT.INFO, Component, 'UnMounting');
        }
    }, []);

    const {
        data: dataOffers,
        loading: loadingOffers,
        error: errOffers
    } = useQuery<ReadOffersQuery, ReadOffersQueryVariables>(ReadOffers);

    const onItemClick = useCallback((id: string) => {
        history(`/ninja-power/offers/${id}`)
    }, [history])

    const onItemCreate = useCallback(() => {
        history(`/ninja-power/offer`)
    }, [history])

    const itemsToRender = useMemo(() => {
        log(logT.INFO, Component, 'dataOffers:', dataOffers);
        if (!dataOffers?.offers) return;
        return dataOffers.offers.map(v => (
                <tr
                    key={v.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onItemClick(v.id)}
                >
                    <td>{v.id}</td>
                    <td style={{ textAlign: 'center' }}>
                        <img
                            src={v.picture}
                            width={25}
                            height={25}/>
                    </td>
                    <td>{v.category.nameEn}</td>
                    <td>{v.name}</td>
                    <td style={{ textAlign: 'center' }}>
                        <BadgeBool
                            isOk={v.status === OfferStatus.Active}
                            textOnTrue={'Active'}
                            textOnFalse={'Inactive'}
                        />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <RegionFlag region={v.category?.region} hideText normalSize/>
                    </td>
                </tr>
            )
        )
    }, [dataOffers, onItemClick]);

    if (loadingOffers) {
        return <LoadingPage/>
    }

    return (
        <Card>
            <Card.Header>
                <h5 className="dark" style={{ margin: 0 }}>Offers</h5>
            </Card.Header>
            <Card.Header>
                <Button variant={'primary'} onClick={onItemCreate}>Create new offer</Button>
            </Card.Header>
            <Card.Body>
                <Error error={errOffers?.graphQLErrors[0].message}/>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th style={{ textAlign: 'center' }}>
                            Picture
                        </th>
                        <th>Category</th>
                        <th>Name</th>
                        <th style={{ textAlign: 'center' }}>
                            Status
                        </th>
                        <th style={{ textAlign: 'center' }}>
                            Region
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {itemsToRender}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};
