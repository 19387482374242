import React, { useEffect } from 'react';
import { Card, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { OfferServiceDetail } from "../component/OfferServiceDetail";
import { ComponentEnum, log, logT } from "../log";
import { RegionFlag } from "../component/RegionFlag";

type IParams = {
    offerId: string;
    region: string;
}

const Component = ComponentEnum.OfferServiceCreationPage;

export const OfferServiceCreationPage = () => {
    const history = useNavigate();
    const { offerId, region } = useParams<IParams>();

    useEffect(() => {
        log(logT.INFO, Component, 'Mounting w/region:', region);
        return () => {
            log(logT.INFO, Component, 'UnMounting');
        }
    }, []);

    return (
        <Container>
            <div className="light" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 style={{ margin: 0 }}>New service</h1>
                <div style={{ alignSelf: 'right' }}>
                    <RegionFlag region={region} headerLevel="h1"/>
                </div>
            </div>
            <OfferServiceDetail
                offerId={offerId || ''}
                onCreated={() => {
                    history(-1);
                }}
            />
        </Container>
    );
}


