import React, { useMemo, useState } from 'react';
import { Badge, Button, Card, Container, FormControl, Table } from "react-bootstrap";
import { Error } from '../component/Error';
import { Loading } from '../component/Loading';
import CopyToClipboard from 'react-copy-to-clipboard';

import {
    RemitStatus,
    RemittanceInvoiceAlert,
    useReadRejectedInvoicesFromRemittanceQuery,
    useSetInvoiceArchivedMutation, useSetSupportNoteMutation
} from '../types/graphql';
import moment from "moment";
import { ModalUpdateCustomerUid } from '../component/ModalUpdateCustomerUId';
import { ModalSendInvoicePush } from "../component/ModalSendInvoicePush";
import { Toast } from 'react-bootstrap';
import { ComponentEnum, log, logT } from "../log";

const Component = ComponentEnum.InvoiceRemittance;

export const RemittanceInvoicesPage = () => {

    const [copiedText, setCopiedText] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [filterText, setFilterText] = useState('');

    const handleCopy = (text: string) => {
        setCopiedText(text)
        setShowToast(true);
        setTimeout(() => setShowToast(false), 2000); // hide after 2 seconds
    };

    const [focusedInvoice, setFocusedInvoice] = useState<RemittanceInvoiceAlert | null>(null)
    const [showArchived, setShowArchived] = useState<boolean>(false)
    const [editingInvoiceId, setEditingInvoiceId] = useState<string | null>(null);
    const [editingInvoiceNote, setEditingInvoiceNote] = useState<string | null>(null);
    const [generalError, setGeneralError] = useState<string | null>(null);

    const {
        data: dataRead,
        loading: loadingRead,
        error: errRead,
        refetch: refetchRead
    } = useReadRejectedInvoicesFromRemittanceQuery({
        variables: {
            showArchived: showArchived,
        },
    });

    const [setInvoiceSupportNote,
        {
            loading: supportNoteLoading,
            error: supportNoteErr
        }
    ] = useSetSupportNoteMutation();

    const setSupportNote = async (invoiceId: string, supportNote: string, pushUserId: string) => {
        try {
            const result = await setInvoiceSupportNote({
                variables: {
                    userId: pushUserId,
                    invoiceIdentifier: invoiceId,
                    supportNote,
                },
            });
            if (result?.data?.result?.isOk) {
                setGeneralError(null);
                await refetchRead();
            } else {
                setGeneralError(`Error setting support note for invoice ${invoiceId}`);
            }
        } catch (e: any) {
            setGeneralError(`Error setting support note for invoice ${invoiceId}: ${e?.message || e}`);
        }
    };


    const [showInvoicePushNoteModal, setShowInvoicePushNoteModal] = useState(false);
    const [pushNoteInvoiceId, setPushNoteInvoiceId] = useState('');
    const [pushNoteUserName, setPushNoteUserName] = useState('');
    const [pushNoteUserId, setPushNoteUserId] = useState('');
    const [pushCustomerUid, setPushCustomerUid] = useState('');

    const openInvoicePushNoteModal = (userId: string, invoiceId: string, userName: string, customerUid: string) => {
        log(logT.INFO, Component, 'openInvoicePushNoteModal, "userId":', userId, 'invoiceId:', invoiceId, 'userName:', userName, 'customerUid:', customerUid);
        setPushNoteInvoiceId(invoiceId);
        setPushNoteUserName(userName);
        setPushNoteUserId(userId)
        setPushCustomerUid(customerUid);
        setShowInvoicePushNoteModal(true);
    };

    const closeInvoicePushNoteModal = () => {
        setShowInvoicePushNoteModal(false);
    };

    const [setInvoiceArchived,
        {
            loading: archivedLoading,
            error: errArchived
        }
    ] = useSetInvoiceArchivedMutation();

    const setArchived = async (invoiceId: string, archived: boolean, pushUserId: string) => {
        try {
            const result = await setInvoiceArchived({
                variables: {
                    userId: pushUserId,
                    invoiceIdentifier: invoiceId,
                    archived,
                },
            });
            if (result?.data?.result?.isOk) {
                setGeneralError(null);
                await refetchRead();
            } else {
                setGeneralError(`Error setting invoice ${invoiceId} as archived.`);
            }
        } catch (e: any) {
            setGeneralError(`Error setting invoice ${invoiceId} as archived: ${e?.message || e}`);
        }
    };

    const itemsToRender = useMemo(() => {
        return dataRead?.result
            .filter(v => {
                return JSON.stringify(v).toLowerCase().includes(filterText.toLowerCase());
            }).map((v) => (
                <tr
                    key={v.id}
                >
                    <CopyToClipboard text={JSON.stringify(v, null, 2) || ''}
                                     onCopy={() => handleCopy(JSON.stringify(v, null, 2) || '')}>
                        <td style={{ cursor: 'pointer' }}>
                            {v.id}
                        </td>
                    </CopyToClipboard>
                    <td>
                        <a href={`/invoices/${v.invoiceId}`} target="_blank">
                            {v.invoiceId}
                        </a>
                    </td>
                    <td>
                        {v.amount}
                    </td>
                    <td>
                        <a href={`/user/${v.userId}`} target="_blank">{
                            v.userName}
                        </a>
                    </td>
                    <CopyToClipboard text={v.userEmail || ''} onCopy={() => handleCopy(v.userEmail || '')}>
                        <td style={{ cursor: 'pointer' }}>
                            {v.userEmail}
                        </td>
                    </CopyToClipboard>
                    <td>
                        <Badge style={{ fontSize: '1rem' }}
                               bg={v.remitStatus === RemitStatus.Stopped ? "danger" : "warning"}>
                            {v.remitStatus}
                        </Badge>
                    </td>
                    <td>{v.remitStatusDescription}</td>
                    <td>
                        {v.remitAttempt}
                    </td>
                    <td
                        onClick={() => {
                            setFocusedInvoice(v)
                        }}>
                        <Button>KID/Note</Button>
                    </td>
                    <CopyToClipboard text={v.customerUId || ''} onCopy={() => handleCopy(v.customerUId || '')}>
                        <td style={{ cursor: 'pointer' }}>
                            <p>{v.customerUId}</p>
                        </td>
                    </CopyToClipboard>
                    <td>
                        <Button onClick={() => {
                            setArchived(v.invoiceId, !v.archived, v.userId);
                        }}>
                            {v.archived ? 'UnArchive' : 'Archive'}
                        </Button>
                    </td>
                    <td onClick={() => {
                        setEditingInvoiceId(v.id);
                        setEditingInvoiceNote(v.supportNote?.toString() || '');
                    }}>
                        {v.id === editingInvoiceId ? (
                            <input
                                type="text"
                                value={editingInvoiceNote || ''}
                                onChange={(e) => setEditingInvoiceNote(e.target.value)}
                                onBlur={() => {
                                    setSupportNote(v.invoiceId, editingInvoiceNote || '', v.userId);
                                    setEditingInvoiceId(null);
                                }}
                                autoFocus={true}
                                onKeyDown={(e) => {
                                    if (e.key === 'Escape') setEditingInvoiceId(null);
                                    if (e.key === 'Enter') {
                                        setSupportNote(v.invoiceId, editingInvoiceNote || '', v.userId);
                                        setEditingInvoiceId(null);
                                    }
                                }}
                            />
                        ) : (
                            <>
                                <p>{v.supportNote ? v.supportNote.toString() : ''}</p>
                            </>
                        )}
                    </td>
                    <td>
                        <Badge bg={"secondary"} style={{ fontSize: '1rem' }}>
                            {v.customerUIdType.toString()}
                        </Badge>
                    </td>
                    <td>
                        <Badge bg={"secondary"} style={{ fontSize: '1rem' }}>
                            {moment(v.createdAt).format("DD MMM YYYY HH:mm")}
                        </Badge>
                    </td>
                    <td>
                        <Button
                            onClick={() => openInvoicePushNoteModal(v.userId, v.invoiceId, v.userName, v.customerUId)}
                            title={v.pushNote || ''}  // Tooltip will show the content of v.pushNote
                        >
                            Push
                        </Button>
                    </td>
                </tr>
            ));
    }, [dataRead, editingInvoiceId, editingInvoiceNote, filterText]);

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <h5 className="dark">Remittance problems</h5>
                </Card.Header>
                <Card.Header>
                    <p>
                        These invoice have been rejected/stopped because of the following remittance problems. The list
                        includes <b>rejected</b> and <b>stopped</b> invoices in addition
                        to <b>processing</b> if more than 5 days old:
                    </p>
                    <ul>
                        <li><b>rejected</b>: remittance failed, backend tries to remit again;</li>
                        <li><b>stopped</b>: remittance failed, backend stops trying to remit since it reaches the
                            max. attempts (10x)
                        </li>
                        <li>
                            <b>processing</b>: remittance has frozen for more than 5 days.
                        </li>
                    </ul>
                    <p>
                        When payment is stopped, support/developers have to fix manually according to the problem.
                        If the problem (Reason) is related to <i>Creditor Account requires
                        OCR/StructuredCreditorReference.</i>, then you can update it using the KID/Note button.
                    </p>
                </Card.Header>

                <Toast onClose={() => setShowToast(false)} show={showToast} delay={1400} autohide style={{
                    position: 'fixed',
                    top: '50%',
                    right: '45%',
                    border: '1px solid darkblue',
                    minWidth: 200,
                    zIndex: 9999
                }}>
                    <Toast.Body><b>Copied to clipboard:</b><br/>{copiedText}</Toast.Body>
                </Toast>
                <Card.Body>
                    <Error error={errRead?.graphQLErrors[0].message}/>
                    <Error error={errArchived?.graphQLErrors[0].message}/>
                    <Error error={supportNoteErr?.graphQLErrors[0].message}/>
                    <Error error={generalError || ''}/>

                    <Loading isLoading={loadingRead || archivedLoading || supportNoteLoading}/>
                    <div style={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-between' }}>
                        <Button
                            onClick={() => {
                                setShowArchived(!showArchived);
                            }}
                        >
                            {showArchived ? 'Hide' : 'Show'} archived
                        </Button>
                        <FormControl
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Escape') {
                                    setFilterText('')
                                }
                            }}
                            type="text"
                            placeholder="Source Filter..."
                            value={filterText}
                            onChange={e => setFilterText(e.target.value)}
                            style={{ marginLeft: '10px', width: '24em' }}
                        />
                    </div>


                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Invoice ID</th>
                            <th>Amount</th>
                            <th>User</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Reason</th>
                            <th>Attempts</th>
                            <th>Update</th>
                            <th>Reference</th>
                            <th>Archive</th>
                            <th>Note</th>
                            <th>Type</th>
                            <th>Last update</th>
                            <th>Push</th>
                        </tr>
                        </thead>
                        <tbody>{itemsToRender}</tbody>
                    </Table>
                </Card.Body>
            </Card>

            {focusedInvoice && (
                <ModalUpdateCustomerUid
                    visible
                    invoiceId={focusedInvoice.invoiceId}
                    userId={focusedInvoice.userId}
                    currentCustomerUid={focusedInvoice.customerUId}
                    currentCustomerUidType={focusedInvoice.customerUIdType}
                    onHide={() => {
                        setFocusedInvoice(null)
                        refetchRead()
                    }}
                />
            )}
            {showInvoicePushNoteModal && (
                <ModalSendInvoicePush
                    customerUid={pushCustomerUid}
                    pushUserId={pushNoteUserId}
                    invoiceId={pushNoteInvoiceId}
                    userName={pushNoteUserName}
                    onHide={closeInvoicePushNoteModal}
                />
            )}

        </Container>
    )
}
