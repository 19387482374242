import React, { useEffect } from 'react';
import { Container } from "react-bootstrap";
import { OfferDetail } from "../component/OfferDetail";
import { useNavigate, useParams } from "react-router-dom";
import { ComponentEnum, log, logT } from "../log";

type IParams = {
    offerId: string
}

const Component = ComponentEnum.OfferPage;

export const OfferPage = () => {

    const history = useNavigate();
    const { offerId } = useParams<IParams>();

    useEffect(() => {
        log(logT.INFO, Component, 'OfferPage offerId: ', offerId);
        return () => {
            log(logT.INFO, Component, 'UnMounting');
        }
    }, []);

    return (
        <Container>
            <OfferDetail
                offerId={offerId}
                onUpdatedOffer={() => {
                    history(-1);
                }}
                onDeletedOffer={() => {
                    history(-1);
                }}
            />
        </Container>
    );
}


