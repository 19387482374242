import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Button, Card, Container, Modal} from "react-bootstrap";
import {useMutation} from "@apollo/client";
import {
	CreateUserNoteMutation,
	CreateUserNoteMutationVariables,
	DeleteUserNoteMutation,
	DeleteUserNoteMutationVariables,
	UpdateUserNoteMutation,
	UpdateUserNoteMutationVariables,
	UserNote
} from "../types/graphql";
import {Error} from "../component/Error";
import {Loading} from "../component/Loading";
import {CreateUserNote, DeleteUserNote, ReadUserNotes, UpdateUserNote} from "../graphql/userNote";
import {UserNoteList} from "../component/UserNoteList";
import {FormUserNote} from "../component/FormUserNote";
import moment from "moment";

type IParams = {
	userId: string
}

export const UserNotesPage = () => {

	const {userId} = useParams<IParams>();
	const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>();
	const [activeNote, setActiveNote] = useState<UserNote | null>(null);

	const [createUserNote, {
		loading: loadingCreateNote,
		error: errorCreateNote
	}] = useMutation<CreateUserNoteMutation, CreateUserNoteMutationVariables>(CreateUserNote, {
		refetchQueries: [{
			query: ReadUserNotes,
			variables: {userId}
		}]
	});

	const [updateUserNote, {
		loading: loadingUpdateNote,
		error: errorUpdateNote
	}] = useMutation<UpdateUserNoteMutation, UpdateUserNoteMutationVariables>(UpdateUserNote, {
		refetchQueries: [{
			query: ReadUserNotes,
			variables: {userId}
		}]
	});

	const [deleteUserNote, {
		loading: loadingDeleteNote,
		error: errorDeleteNote
	}] = useMutation<DeleteUserNoteMutation, DeleteUserNoteMutationVariables>(DeleteUserNote, {
		refetchQueries: [{
			query: ReadUserNotes,
			variables: {userId}
		}]
	});

	return (
		<Container>
			<h1>User notes</h1>
			<Card>

				<Error error={errorCreateNote?.graphQLErrors[0].message} />
				<Error error={errorUpdateNote?.graphQLErrors[0].message} />
				<Error error={errorDeleteNote?.graphQLErrors[0].message} />

				<Loading
					isLoading={loadingCreateNote || loadingUpdateNote || loadingDeleteNote}
					type={'spinner'}
				/>

				<Card.Header>
					<Button
						variant='secondary'
						size={'sm'}
						onClick={() => {
							setIsCreateModalVisible(true);
						}}>
						Add note
					</Button>
				</Card.Header>


				<Card.Body>
					<UserNoteList
						userId={userId || ''}
						onClick={setActiveNote}
					/>
				</Card.Body>
			</Card>

			{isCreateModalVisible && (
				<Modal
					size={'xl'}
					show
					scrollable
					centered
					onHide={() => {
						setIsCreateModalVisible(false);
					}}>
					<Modal.Body>
						<FormUserNote
							onSubmit={async ({content, noteTime}) => {
								try {
									await createUserNote({
										variables: {
											userId: userId || '',
											content,
											noteTime: moment(noteTime).toISOString()
										}
									});
								} catch (e) {
									console.error(e)
								} finally {
									setIsCreateModalVisible(false);
								}
							}}
						/>
					</Modal.Body>
				</Modal>
			)}

			{activeNote && (
				<Modal
					size={'xl'}
					show
					scrollable
					centered
					onHide={() => {
						setActiveNote(null);
					}}>
					<Modal.Body>
						<FormUserNote
							note={activeNote}
							onSubmit={async ({id, content, noteTime}) => {
								try {
									await updateUserNote({
										variables: {
											id,
											userId: userId || '',
											content,
											noteTime
										}
									});
								} catch (e) {
									console.error(e)
								} finally {
									setActiveNote(null);
								}
							}}
							onDelete={async ({id}) => {
								try {
									await deleteUserNote({
										variables: {
											id,
											userId: userId || ''
										}
									});
								} catch (e) {
									console.error(e)
								} finally {
									setActiveNote(null);
								}
							}}
						/>
					</Modal.Body>
				</Modal>
			)}

		</Container>
	);

};
