import React from 'react';
import {Container} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {OfferCategoryDetail} from "../component/OfferCategoryDetail";

type IParams = {
    categoryId: string
}

export const OfferCategoryDetailPage = () => {

    const history = useNavigate();
    const {categoryId} = useParams<IParams>();

    return (
        <Container>
            <OfferCategoryDetail
                createMode={false}
                categoryId={categoryId}
                onUpdated={() => {
                    history(-1);
                }}
                onDeleted={() => {
                    history(-1);
                }}
            />
        </Container>
    );
}


