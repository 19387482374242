import React from 'react';
import {Container} from "react-bootstrap";
import { ZenPointsOverview } from "../component/ZenPointsOverview";
import { useParams } from "react-router-dom";

type IParams = {
    userId: string;
}

export default () => {

    const {userId} = useParams<IParams>();

    return (
        <Container fluid>
            <ZenPointsOverview userId={userId || ''}/>
        </Container>
    );
}


