import React from 'react';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import {Container, Card} from "react-bootstrap";
import {BalanceHistory} from "../component/BalanceHistory";

type IParams = {
    fromDate: string,
    toDate: string
}

export default () => {
    const {fromDate, toDate} = useParams<IParams>();

    // Dates
    const fromDateObj = moment(fromDate).toDate();
    const toDateObj = moment(toDate).toDate();

    // Labels
    const fromDateString = moment(fromDate).format("DD MMMM YYYY");
    const toDateString = moment(toDate).format("DD MMMM YYYY");

    return (
        <Container fluid>
            <Card>
                <Card.Header>
                    <h5 className="dark">Balance</h5>
                </Card.Header>
                <Card.Header>
                    <h6 className="dark">{fromDateString} - {toDateString}</h6>
                </Card.Header>
                <Card.Body>
                    <BalanceHistory fromDate={fromDateObj} toDate={toDateObj}/>
                </Card.Body>
            </Card>
        </Container>
    );
};


