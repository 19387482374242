import React, { useState } from "react";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {
    CreateOfferCategoryMutationVariables,
    DeleteOfferCategoryMutationVariables,
    OfferCategoryWithTranslations,
    UpdateOfferCategoryMutationVariables,
} from "../types/graphql";
import { RegionFlag } from "./RegionFlag";

interface IProps {
    data?: OfferCategoryWithTranslations;
    onCreate?: (toCreate: CreateOfferCategoryMutationVariables) => void;
    onUpdate?: (toUpdate: UpdateOfferCategoryMutationVariables) => void;
    onDelete?: (toDelete: DeleteOfferCategoryMutationVariables) => void;
    createMode?: boolean;
}

export const OfferCategoryForm = ({
                                      data,
                                      onCreate,
                                      onUpdate,
                                      onDelete,
                                      createMode = false,
                                  }: IProps) => {
    // Modal for deletion
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Form validation
    const [validated, setValidated] = useState(false);

    // Form fields
    const [region, setRegion] = useState(data?.region || "no");
    const [nameEn, setNameEn] = useState(data?.nameEn || "");
    const [nameNo, setNameNo] = useState(data?.nameNo || "");
    const [nameSv, setNameSv] = useState(data?.nameSv || "");
    const [descriptionEn, setDescriptionEn] = useState(data?.descriptionEn || "");
    const [descriptionNo, setDescriptionNo] = useState(data?.descriptionNo || "");
    const [descriptionSv, setDescriptionSv] = useState(data?.descriptionSv || "");

    const isCreationMode = !data;

    // Form submit handler
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        const isFormValid = form.checkValidity();
        setValidated(true);
        if (!isFormValid) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        if (isCreationMode) {
            // Create offer callback
            onCreate?.({
                nameEn,
                nameNo,
                nameSv,
                descriptionEn,
                descriptionNo,
                descriptionSv,
                region,
            });
        } else if (data) {
            // Update offer callback
            onUpdate?.({
                offerCategoryId: data.id,
                nameEn,
                nameNo,
                nameSv,
                descriptionEn,
                descriptionNo,
                descriptionSv,
            });
        }
    };

    const onDeleteClick = () => {
        if (data) {
            onDelete?.({
                offerCategoryId: data.id,
            });
        }
    };

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    The deletion of a category involves the deletion of all the offers and
                    services related to this category. Do you really want to delete the
                    category <code>{data?.nameEn}</code>?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={onDeleteClick}>
                        I understand, delete {data?.nameEn}!
                    </Button>
                </Modal.Footer>
            </Modal>

            <Row>
                <Form.Group as={Col} md="6" controlId="nameEn">
                    <Form.Label>Name 🇬🇧</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Category"
                        value={nameEn}
                        onChange={(e) => setNameEn(e.currentTarget.value)}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid category name.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="nameNo">
                    <Form.Label>Name 🇳🇴</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Category"
                        value={nameNo}
                        onChange={(e) => setNameNo(e.currentTarget.value)}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid category name.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="nameSv">
                    <Form.Label>Name 🇸🇪</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Category"
                        value={nameSv}
                        onChange={(e) => setNameSv(e.currentTarget.value)}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid category name.
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Row>
                <Form.Group as={Col} md="6" controlId="descriptionEn">
                    <Form.Label>Description 🇬🇧</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={descriptionEn}
                        onChange={(e) => setDescriptionEn(e.currentTarget.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid description for english language.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="descriptionNo">
                    <Form.Label>Description 🇳🇴</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={descriptionNo}
                        onChange={(e) => setDescriptionNo(e.currentTarget.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid description for norwegian language.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="descriptionSv">
                    <Form.Label>Description 🇸🇪</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={descriptionSv}
                        onChange={(e) => setDescriptionSv(e.currentTarget.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid description for swedish language.
                    </Form.Control.Feedback>
                </Form.Group>
                {!createMode ? null : (
                    <Form.Group as={Col} md="6" controlId="region">
                        <Form.Label>
                            <RegionFlag region={region} normalSize/>
                        </Form.Label>
                        <Form.Control
                            as="select"
                            value={region}
                            onChange={(e) => setRegion(e.currentTarget.value)}
                            required>
                            <option value="no">Norway</option>
                            <option value="sv">Sweden</option>
                        </Form.Control>
                    </Form.Group>
                )}
            </Row>

            <Form.Group>
                {isCreationMode ? (
                    <Button type="submit" variant={"primary"}>
                        Create
                    </Button>
                ) : (
                    <>
                        <Button type="submit" variant={"primary"}>
                            Update
                        </Button>{" "}
                        <Button variant={"secondary"} onClick={handleShow}>
                            Delete
                        </Button>
                    </>
                )}
            </Form.Group>
        </Form>
    );
};
