import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import logo from './logo-white.png';
import './header.scss';
import {Button, Container, Image} from "react-bootstrap";
import {colors} from "../../theme";
import {ProfileContext} from "../../context/profile";
import {RegionPicker} from "../RegionPicker";

export default () => {
  const {profile, logout} = useContext(ProfileContext)
  return (
    <div className="Header">

      <Link to="/">
        <Image srcSet={logo} alt="billkill-logo"/>
      </Link>


      {profile && (
        <Container style={{
          marginTop: '1em',
          marginBottom: '2em',
          display: "flex",
          flexDirection: 'column',
          alignItems: 'center',
          gap: "1em"
        }}>

          <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: "1em"
          }}>

            <div style={{
              width: '220px',
              alignSelf: 'flex-start',
            }}>
              <RegionPicker/>
            </div>

            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
              gap: "1em"
            }}>

              <h5 style={{
                margin: 0,
                color: colors.white,
              }}>{profile?.firstname} {profile?.lastname}</h5>

              <Button
                style={{display: 'block'}}
                onClick={logout}
                variant={'outline-light'}
                size={'sm'}>
                Logout
              </Button>
            </div>
          </div>
        </Container>
      )}

    </div>
  );
};


