import gql from "graphql-tag";
import {UserFragments} from "./fragments/user";

export const ReadUsers = gql`
    query readAllUsers(
        $first: Int,
        $last: Int,
        $before: String,
        $after: String,
        $filterByName: String,
        $filterByCheckedEmail: Boolean,
        $filterByCheckedMobile: Boolean,
        $filterByActivity: UserActivityEnum,
        $filterByRegion: RegionEnum,
        $descId: Boolean,
        $acceptEfaktura: Boolean
    ) {
        result: readAllUsers(
            first: $first,
            last: $last, before: $before,
            after: $after,
            filterByName: $filterByName,
            filterByCheckedEmail: $filterByCheckedEmail,
            filterByCheckedMobile: $filterByCheckedMobile,
            filterByActivity: $filterByActivity,
            filterByRegion: $filterByRegion,
            descId: $descId,
            acceptEfaktura: $acceptEfaktura
        ) {
            edges{
                cursor
                node {
                    ...UserResult
                    region
                }
            }
            pageInfo{startCursor endCursor hasNextPage hasPreviousPage}
        }
    }
    ${UserFragments.result}
`;

export const ReadUserById = gql`
    query readUserById($userId: ID!) {
        result: readUserById(userId: $userId) {
            ...UserDetail
        }
    }
    ${UserFragments.detailed}
`;

export const ReadUserActivities = gql`
    query readUserActivities($userId: ID!, $fromDate: DateTime!, $toDate: DateTime!) {
        result: readUserActivities(userId: $userId, fromDate: $fromDate, toDate: $toDate) {
            time
            component
            event
            status
            error
            details {context, stackTrace}
            userId
            issuerId
            cardId
            invoiceId
        }
    }`;

export const ReadEmailsForNewsletter = gql`
    query readEmailsForNewsletter(
        $acceptEInvoice: Boolean,
        $filterByActivity: UserActivityEnum,
        $filterByCheckedMobile: Boolean,
        $filterByName: String,
        $descId: Boolean,
        $isDeleted: Boolean,
        $region: RegionEnum
    ) {
        readEmailsForNewsletter(
            filterByName: $filterByName,
            filterByCheckedMobile: $filterByCheckedMobile,
            filterByActivity: $filterByActivity,
            acceptEInvoice: $acceptEInvoice
            descId: $descId
            isDeleted: $isDeleted
            region: $region
        ) {
            emails
        }
    }
`;


export const UpdateUserStatus = gql`
    mutation updateUserStatus ($userId: ID!, $status: UserStatusEnum!) {
        user: updateUserStatus(userId: $userId, status: $status) {
            ...UserDetail
        }
    }
    ${UserFragments.detailed}
`
