import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import moment from "moment";

import {
    CouponRecipient,
    CouponRule,
    useReadUserGroupsQuery,
    useCreateCouponMutation,
    useUpdateCouponMutation,
    useDeleteCouponMutation,
    CouponProduct,
    ReadCouponsDocument,
    useReadCouponByIdQuery, RegionEnum, UserGroup
} from "../types/graphql";
import SelectTranslation from "./SelectTranslation";
import { Error } from "./Error";
import { RegionFlag } from "./RegionFlag";

interface Props {
    open: boolean;
    couponId: string | null;
    onHide?: () => void;
}

type OptionRuleType = {
    value: CouponRule;
    label: string;
};

const ruleOptions: OptionRuleType[] = [{
    value: CouponRule.UsersNeverSubscribed,
    label: "Users who have never been subscribed"
}, {
    value: CouponRule.UsersNotActivelySubscribed,
    label: "Users who are currently not subscribed (never been subscribed or previously subscribed)"
}];

const CouponDetail = (props: Props) => {

    const [validated, setValidated] = useState(false);
    const [code, setCode] = useState("");
    const [recipient, setRecipient] = useState<CouponRecipient>(CouponRecipient.Public);
    const [product, setProduct] = useState<CouponProduct>(CouponProduct.Undefined);
    const [amount, setAmount] = useState<string>("0");
    const [period, setPeriod] = useState<string>("1");
    const [isExpiration, setIsExpiration] = useState(false);
    const [descriptionId, setDescriptionId] = useState<string | null>("");
    const [startsAt, setStartsAt] = useState<Date | null>(new Date());
    const [endsAt, setEndsAt] = useState<Date | null>();
    const [recipientGroupId, setRecipientGroupId] = useState<string | null>(null);
    const [rule, setRule] = useState<CouponRule | null>(null);
    const [region, setRegion] = useState<RegionEnum>(RegionEnum.All);
    const [filteredUserGroups, setFilteredUserGroups] = useState<UserGroup[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<{ id: string, region: RegionEnum } | null>(null);

    const [createCoupon, { error: createError }] = useCreateCouponMutation({
        refetchQueries: [{ query: ReadCouponsDocument }],
    });
    const [updateCoupon] = useUpdateCouponMutation({
        refetchQueries: [{ query: ReadCouponsDocument }],
    });
    const [deleteCoupon, { error: deleteError }] = useDeleteCouponMutation({
        refetchQueries: [{ query: ReadCouponsDocument }],
    });

    const { data } = useReadCouponByIdQuery({
        variables: { id: props.couponId! },
        skip: !props.couponId,
    });

    useEffect(() => {
        if (props.open) {
            initState()
        }
    }, [props.open]);

    useEffect(() => {
        switch (recipient) {
            case CouponRecipient.Public:
                setRecipientGroupId(null)
                setRule(null)
                break
            case CouponRecipient.Group:
                setRule(null)
                break
            case CouponRecipient.Rule:
                setRecipientGroupId(null)
                break
        }
    }, [recipient])

    useEffect(() => {
        if (data?.coupon) {
            setDescriptionId(data.coupon.descriptionId);
            setCode(data.coupon.code);
            setRecipient(data.coupon.recipient);
            setProduct(data.coupon.product);
            setAmount(data.coupon.amount.toString());
            setPeriod(data.coupon.period.toString());
            setStartsAt(new Date(data?.coupon.startsAt));
            if (data?.coupon.endsAt) {
                setEndsAt(new Date(data?.coupon.endsAt));
                setIsExpiration(true);
            }
            setRecipientGroupId(data.coupon.recipientGroup?.id || null);
            setRule(data.coupon.rule || null);
            setRegion(data.coupon.region);
        }
        return initState
    }, [data]);

    const initState = () => {
        setValidated(false);
        setDescriptionId(null);
        setCode("");
        setAmount("0");
        setPeriod("1");
        setStartsAt(new Date());
        setEndsAt(null);
        setIsExpiration(false);
        setRecipient(CouponRecipient.Public);
        setProduct(CouponProduct.Undefined);
        setRecipientGroupId(null);
        setRule(null);
        setSelectedGroup(null);
    }

    useEffect(() => {
        if (isExpiration && !endsAt) {
            setEndsAt(new Date());
        }
    }, [endsAt, isExpiration]);

    const { data: userGroups } = useReadUserGroupsQuery({
        skip: recipient !== CouponRecipient.Group,
    });

    useEffect(() => {
        if (userGroups?.userGroups) {
            let filtered = filterUserGroupsByRegion(userGroups.userGroups, region)
            setFilteredUserGroups(filtered);
            // if the selected group is not in the filtered list, reset the selected group
            if (!filtered.find(group => group.id === recipientGroupId)) {
                setRecipientGroupId(null);
                setSelectedGroup(null);
            }
        }
    }, [userGroups, region]);

    const handleGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = e.currentTarget.value;
        const selectedGroup = filteredUserGroups.find(group => group.id === selectedId);
        if (selectedGroup) {
            setRecipientGroupId(selectedId);
            setSelectedGroup(selectedGroup);
        } else {
            setRecipientGroupId(null);
            setSelectedGroup(null);
        }
    };

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        try {
            e.preventDefault();
            if (props.couponId) {
                await updateCoupon({
                    variables: {
                        couponId: props.couponId,
                        startsAt: startsAt ? startsAt.toISOString() : null,
                        endsAt: isExpiration && !!endsAt ? endsAt?.toISOString() : null,
                        noExpiration: !isExpiration
                    },
                });
            } else {
                if (!e.currentTarget.checkValidity()) {
                    setValidated(true);
                    return;
                }
                await createCoupon({
                    variables: {
                        code,
                        descriptionId: descriptionId!,
                        recipient,
                        product,
                        startsAt: startsAt!.toISOString(),
                        endsAt: endsAt?.toISOString(),
                        amount: parseInt(amount),
                        period: parseInt(period),
                        recipientGroupId,
                        rule,
                        region,
                    }
                });
            }
            props.onHide!();
        } catch (e) {
            console.error(e);
        }
    };

    const handleDelete = async () => {
        await deleteCoupon({
            variables: {
                couponId: props.couponId!,
            },
        });
        props.onHide!();
    };

    const handleChangeStartsAt: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setStartsAt(e.currentTarget.valueAsDate);
    };


    const filterUserGroupsByRegion = (userGroups: UserGroup[], region: RegionEnum) => {
        return userGroups.filter(group =>
            region === RegionEnum.All || group.region === region
        );
    };

    const handleChangeEndsAt: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEndsAt(e.currentTarget.valueAsDate);
    };

    const isReadOnly = !!props.couponId;

    return (
        <Modal show={props.open} onHide={props.onHide}>

            <Error error={createError?.graphQLErrors[0].message}/>
            <Error error={deleteError?.graphQLErrors[0].message}/>

            <Form noValidate onSubmit={handleSubmit} validated={validated}>
                <Modal.Header>
                    <Modal.Title>
                        {props.couponId ? "Discount code detail" : "Create a discount code"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <SelectTranslation
                            value={descriptionId}
                            onChange={(option) => setDescriptionId(option.value)}
                            disabled={isReadOnly}
                        />
                        <Form.Text muted>
                            Choose a translated description that will be shown to the user.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="code">
                        <Form.Label>Code</Form.Label>
                        <Form.Control
                            name="code"
                            placeholder="BK_1_FREE_MONTH"
                            readOnly={isReadOnly}
                            required
                            value={code}
                            onChange={(e) => setCode(e.currentTarget.value)}
                        />
                        <Form.Text muted>
                            A unique code that is used by users to apply the discount code.
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                            Please provide a unique code
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="product">
                        <Form.Label>Product</Form.Label>
                        <Form.Control
                            required
                            as="select"
                            name="product"
                            readOnly={isReadOnly}
                            disabled={isReadOnly}
                            value={product}
                            isValid={!isReadOnly && !!product && product !== CouponProduct.Undefined}
                            onChange={(e) => {
                                setProduct(e.currentTarget.value as CouponProduct)
                            }}
                        >
                            <option key='blankChoice' hidden value="">Choose a product ...</option>
                            {Object.values(CouponProduct)
                                .filter((product) => product !== CouponProduct.Undefined)
                                .map((product, i) => (
                                    <option key={i} value={product}>
                                        {product}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="amount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                            name="amount"
                            type="number"
                            placeholder="25 kr"
                            value={amount}
                            min={1}
                            readOnly={isReadOnly}
                            required
                            onChange={(e) => setAmount(e.currentTarget.value)}
                        />
                        <Form.Text muted>
                            Amount that is subtracted to the price of the subscription
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                            Please provide amount
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="region">
                        <Form.Label>
                            <RegionFlag region={region} normalSize/>
                        </Form.Label>
                        <Form.Control
                            as="select"
                            value={region}
                            readOnly={isReadOnly}
                            disabled={isReadOnly}
                            onChange={(e) => setRegion(e.currentTarget.value as RegionEnum)}
                            required>
                            <option value={RegionEnum.No}>Norway</option>
                            <option value={RegionEnum.Sv}>Sweden</option>
                            <option value={RegionEnum.All}>All</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="period">
                        <Form.Label>Period</Form.Label>
                        <Form.Control
                            name="period"
                            type="number"
                            placeholder="How many free months?"
                            readOnly={isReadOnly}
                            min={1}
                            required
                            value={period}
                            onChange={(e) => setPeriod(e.currentTarget.value)}
                        />
                        <Form.Text muted>
                            Discount code is applied every period. A period is every time the
                            subscription starts or is renewed.
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                            Please provide free months
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="startsAt">
                        <Form.Label>Starts on</Form.Label>
                        <Form.Control
                            required
                            name="startsAt"
                            type="date"
                            value={moment(startsAt!).format("YYYY-MM-DD")}
                            onChange={handleChangeStartsAt}
                        />
                        <Form.Text muted>
                            When discount code starts to be valid and usable from other users
                        </Form.Text>
                        <Form.Control.Feedback>
                            Please provide start date
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            type="switch"
                            id="isExpiration"
                            label="With expiration"
                            checked={isExpiration}
                            onChange={(e) => setIsExpiration(e.currentTarget.checked)}
                        />
                    </Form.Group>
                    {isExpiration && (
                        <Form.Group controlId="endsAt">
                            <Form.Label>Ends on</Form.Label>
                            <Form.Control
                                required
                                name="endsAt"
                                type="date"
                                value={moment(endsAt!).format("YYYY-MM-DD")}
                                onChange={handleChangeEndsAt}
                            />
                            <Form.Text muted>
                                When discount code ends to be valid and usable from other users
                            </Form.Text>
                        </Form.Group>
                    )}
                    <Form.Group controlId="recipient">
                        <Form.Label>Recipient</Form.Label>
                        <Form.Control
                            required
                            as="select"
                            name="recipient"
                            value={recipient}
                            readOnly={isReadOnly}
                            disabled={isReadOnly}
                            onChange={(e) =>
                                setRecipient(e.currentTarget.value as CouponRecipient)
                            }
                        >
                            <option key='blankChoice' hidden value="">Choose a recipient ...</option>
                            {Object.values(CouponRecipient).map((recip) => (
                                <option key={recip} value={recip}>
                                    {recip}
                                </option>
                            ))}
                        </Form.Control>
                        <Form.Text muted>
                            Choose a recipient. It can be one of the following choices.
                            <br/>
                            - public: Alle, ingen filter;
                            <br/>
                            - group: Et spesifikt individ eller liten gruppe, nyttig for dedikerte / personlige
                            rabatter;
                            <br/>
                            - rule: En stor gruppe brukere som følge en regel (dynamisk) f.eks. ALLE aktive bruker uten
                            MBK.
                        </Form.Text>
                    </Form.Group>
                    {recipient === CouponRecipient.Group && (
                        <Form.Group controlId="recipientGroupId">
                            <Form.Label>Choose a group
                                {isReadOnly && (
                                    <RegionFlag region={data?.coupon?.recipientGroup?.region} hideText noContainerStyle normalSize/>
                                )}
                                {!isReadOnly && selectedGroup && (
                                    <RegionFlag region={selectedGroup.region} hideText noContainerStyle normalSize/>
                                )}
                            </Form.Label>
                            <Form.Control
                                name="recipientGroupId"
                                as="select"
                                readOnly={isReadOnly}
                                disabled={isReadOnly}
                                value={recipientGroupId || undefined}
                                required
                                onChange={() => handleGroupChange}
                            >
                                <option key='blankChoice' hidden value="">Choose a group ...</option>
                                {filteredUserGroups?.map((group) => (
                                    <option key={group.id} value={group.id}>
                                        {group.name} - {group.region}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Text muted>
                                Discount code will be valid only for people included in the
                                chosen group.
                            </Form.Text>
                        </Form.Group>
                    )}
                    {recipient === CouponRecipient.Rule && (
                        <Form.Group controlId="rule">
                            <Form.Label>Choose a rule</Form.Label>
                            <Form.Control
                                name="rule"
                                as="select"
                                readOnly={isReadOnly}
                                disabled={isReadOnly}
                                value={rule?.toString() || ''}
                                required
                                onChange={(e) =>
                                    setRule(e.currentTarget.value as CouponRule)
                                }
                            >
                                <option key='blankChoice' hidden value="">Choose a rule ...</option>
                                {ruleOptions.map(({ value, label }, i) => (
                                    <option key={i} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Text muted>
                                Discount code will be valid only for people included in the
                                chosen rule.
                            </Form.Text>
                        </Form.Group>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {!!props.couponId && (
                        <Button variant="danger" onClick={handleDelete}>
                            Delete
                        </Button>
                    )}
                    <Button type="submit">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
        ;
};

export default CouponDetail;
