import React, {useEffect, useMemo, useState} from 'react';
import {useLazyQuery} from "@apollo/client";
import {Card, Row, Col, ListGroup, Container} from "react-bootstrap";
import {ReadSubscriptionIncomeOverview} from "../graphql/subscription";
import {
  ReadSubscriptionIncomeOverviewQuery,
  ReadSubscriptionIncomeOverviewQueryVariables
} from "../types/graphql";
import {Error} from "../component/Error";
import {Loading} from "../component/Loading";
import moment from "moment";
import Chart from "react-google-charts";
import Table from "react-bootstrap/Table";
import {FilterByMonthYear} from "../component/FilterByMonthYear";
import {SubscriptionsManager} from "../component/SubscriptionsManager";

export default () => {

  const [startsAt, setStartsAt] = useState<string>()
  const [endsAt, setEndsAt] = useState<string>()

  const [readOverview, {
    data,
    loading,
    error
  }] = useLazyQuery<ReadSubscriptionIncomeOverviewQuery, ReadSubscriptionIncomeOverviewQueryVariables>(ReadSubscriptionIncomeOverview);

  useEffect(() => {
    if (startsAt && endsAt) {
      readOverview({
        variables: {
          startsAt,
          endsAt
        }
      })
    }
  }, [readOverview, startsAt, endsAt])

  const subscriptionDistribution = useMemo(() => {
    if (!data?.result) return null;
    const {subtotals} = data?.result;
    return (
      <Chart
        width={'100%'}
        height={'400px'}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['Subscription', 'Count'],
          ...subtotals?.map(s => [s.subscription.name, s.count]) as any
        ]}
        options={{
          title: 'Subscriptions'
        }}
        rootProps={{'data-testid': '1'}}
      />
    );
  }, [data]);

  const couponDistribution = useMemo(() => {
    if (!data?.result) return null;
    const {subtotalsCoupons} = data?.result;
    return (
      <Chart
        width={'100%'}
        height={'400px'}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['Subscription', 'Count'],
          ...subtotalsCoupons?.map(s => [s.code, s.count]) as any
        ]}
        options={{
          title: 'Discount codes'
        }}
        rootProps={{'data-testid': '1'}}
      />

    );
  }, [data]);

  return (
    <Container>
      <Error error={error?.graphQLErrors[0].message}/>

      <Card style={CardStyle}>

        <Card.Header>
          <h5 className="dark">Subscriptions</h5>
        </Card.Header>

        <Card.Body>
          <SubscriptionsManager/>
        </Card.Body>

        <Card.Header>
          <h5 className="dark">Statistics</h5>
        </Card.Header>

        <Card.Body>

          <FilterByMonthYear onChange={(startsAt, endsAt) => {
            setStartsAt(startsAt)
            setEndsAt(endsAt)
          }}/>

          <ListGroup variant="flush">
            {loading && (
              <Loading isLoading/>
            )}

            <h6>
              {moment(startsAt).format("D MMM YYYY")} - {moment(endsAt).format("D MMM YYYY")}
            </h6>

            <Row>
              <Col sm={12} md={6}>
                {subscriptionDistribution}
              </Col>
              <Col sm={12} md={6}>
                {couponDistribution}
              </Col>
            </Row>

            <ListGroup.Item>

              <Table striped bordered hover>
                <thead>
                <tr>
                  <th>#</th>
                  <th>Subscription</th>
                  <th>Count</th>
                  <th>Amount</th>
                  <th>Discount codes</th>
                  <th>Discount</th>
                </tr>
                </thead>
                <tbody>
                {
                  data?.result.subtotals?.map(({subscription, amount, count, countCoupon, amountCoupon}) => (
                    <tr key={subscription.id}>
                      <td>{subscription.id}</td>
                      <td>{subscription.name}</td>
                      <td>{count}</td>
                      <td>{amount} kr</td>
                      <td>{countCoupon}</td>
                      <td>{amountCoupon} kr</td>
                    </tr>
                  ))
                }
                <tr>
                  <td colSpan={2}>
                    Total
                  </td>
                  <td>{data?.result.subtotals?.map(x => x.count).reduce((a, b) => a + b, 0)}</td>
                  <td>{data?.result.total} kr</td>
                  <td>{data?.result.subtotals?.map(x => x.countCoupon).reduce((a, b) => a + b, 0)}</td>
                  <td>{data?.result.totalCoupon} kr</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    Effective Total
                  </td>
                  <td colSpan={4}>{data?.result.totalEffective} kr</td>
                </tr>
                </tbody>
              </Table>
            </ListGroup.Item>

          </ListGroup>
        </Card.Body>

      </Card>

    </Container>
  );

}

const CardStyle = {
  marginTop: '2.5%',
  marginBottom: '2.5%'
}
