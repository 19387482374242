import React, {useCallback, useEffect, useState} from 'react'
import {Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Modal, Row, Toast} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import {
  Issuer,
  useCopyIssuerInfoMutation,
  useReadIssuerByIdLazyQuery,
  useReadIssuerByIdQuery,
  useUpdateIssuerEmailMutation,
  useUpdateIssuerLoanEmailMutation,
  useUpdateIssuerPictureMutation,
  useUpdateIssuerIdentifiersMutation,
} from '../types/graphql'
import {MomentDate} from './MomentDate'
import {FormIssuerUpdateEmail} from './FormIssuerUpdateEmail'
import {FormPictureUpdate} from './FormPictureUpdate'
import {IssuersSuggestions} from './IssuerSuggestions'
import {ReadIssuerById} from '../graphql/issuer'
import {LoadingPage} from '../page/loading'
import {FormIssuerTransactionRule} from './FormIssuerTransactionRule'
import {IssuerWhitelistOverview} from './IssuerWhitelistOverview'
import {IssuerPicture} from './IssuerPicture'
import {RegionFlag} from "./RegionFlag";

type IRouteParams = {
  id: string
}

export const IssuerDetail = () => {
  const history = useNavigate()

  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastHeader, setToastHeader] = useState('');

  const showToast = (header: string, text: string) => {
    setToastVisible(true);
    setToastMessage(text)
    setToastHeader(header)
    setTimeout(() => setToastVisible(false), 2000); // hide after 2 seconds
  };

  // Params
  const {id: issuerId} = useParams<IRouteParams>()
  // TODO: Handle loading and errors

  // Destination issuer (issuer is lacking of info or must be updated)
  const {
    data: issuer,
    loading: loadingIssuer,
    error: errIssuer,
  } = useReadIssuerByIdQuery({
    variables: {
      issuerId: issuerId || '',
    },
    fetchPolicy: 'cache-and-network',
  })

  // Source issuer (issuer that is used for copying information to an another issuer)
  const [readIssuerSource, {data: issuerSource}] =
    useReadIssuerByIdLazyQuery()

  // Mutation APIs
  const [
    copyIssuerInfo,
    {loading: loadingCopyIssuerInfo, data: updatedIssuer},
  ] = useCopyIssuerInfoMutation()

  const [updateEmail, {loading: loadingUpdateEmail, error: errUpdateEmail}] =
    useUpdateIssuerEmailMutation({
      refetchQueries: [{query: ReadIssuerById, variables: {issuerId}}],
    })

  const [
    updateLoanEmail,
    {loading: loadingUpdateLoanEmail, error: errUpdateLoanEmail},
  ] = useUpdateIssuerLoanEmailMutation({
    refetchQueries: [{query: ReadIssuerById, variables: {issuerId}}],
  })

  const [
    updatePicture,
    {loading: loadingUpdatePicture, error: errUpdatePicture},
  ] = useUpdateIssuerPictureMutation({
    refetchQueries: [{query: ReadIssuerById, variables: {issuerId}}],
  })

  const [
    updateIdentifiers,
    {loading: loadingUpdateIdentifiers, error: errUpdateIdentifiers},
  ] = useUpdateIssuerIdentifiersMutation({
    refetchQueries: [{query: ReadIssuerById, variables: {issuerId}}],
  })

  // States
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [showSuggestionsModal, setShowSuggestionsModal] = useState(false)
  const [suggestionIssuerId, setSuggestionIssuerId] = useState('')
  const [realOrgNumber, setRealOrgNumber] = useState('')
  const [subMerchantMcc, setSubMerchantMcc] = useState('')

  useEffect(() => {
    if (suggestionIssuerId && issuerSource) {
      setShowSuggestionsModal(true)
    }
  }, [suggestionIssuerId, issuerSource])

  useEffect(() => {
    if (errIssuer) {
      alert(errIssuer)
    }
  }, [errIssuer])

  useEffect(() => {
    setRealOrgNumber(issuer?.result?.realOrgNumber || '')
    setSubMerchantMcc(issuer?.result?.subMerchantMcc || '')
  }, [issuer, setRealOrgNumber, setSubMerchantMcc])

  const onInvoicesClick = useCallback(
    (id: string) => {
      history(`/invoices/issuer/${id}`)
    },
    [history],
  )

  const closeSuggestionsModal = () => {
    setSuggestionIssuerId('')
    setShowSuggestionsModal(false)
  }

  const confirmSuggestionsModal = async () => {
    await copyIssuerInfo({
      variables: {
        issuerIdDestination: issuerId || '',
        issuerIdSource: suggestionIssuerId,
      },
    })
    // onUpdate();
    closeSuggestionsModal()
    setShowSuggestions(false)
  }

  const onSuggestionsChoice = async (issuerId: string) => {
    setSuggestionIssuerId(issuerId)
    await readIssuerSource({
      variables: {
        issuerId,
      },
    })
  }

  const onImportConfiguration = () => {
    setShowSuggestions(!showSuggestions)
  }

  const issuerData = (updatedIssuer?.result || issuer?.result) as Issuer
  if (
    !issuerData ||
    loadingIssuer ||
    loadingCopyIssuerInfo ||
    loadingUpdateEmail ||
    loadingUpdateLoanEmail ||
    loadingUpdatePicture
  ) {
    return <LoadingPage/>
  }

  const handleUpdateBrreg = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    updateIdentifiers({
      variables: {
        issuerId: issuerData.id,
        realOrgNumber: realOrgNumber || '',
        subMerchantMcc: subMerchantMcc || '',
      }
    })
  }

  let addressFormatted = '(Missing address)'
  if (issuerData.address && (issuerData.address.street || issuerData.address.postalCode || issuerData.address.city)) {
    addressFormatted = issuerData.address.street + ', ' + issuerData.address.postalCode + ' ' + issuerData.address.city + ', ' + issuerData.address.country?.name
  }

  return (
    <Container>
      <Toast
        show={toastVisible}
        delay={1400}
        autohide
        style={{
          position: 'fixed',
          backgroundColor: 'white',
          color: 'black',
          top: '50%',
          right: '45%',
          border: '3px solid darkblue',
          minWidth: 240,
          zIndex: 9999
        }}>
        <Toast.Body><b>{toastHeader}</b><br/>{toastMessage}</Toast.Body>
      </Toast>
      <Card>
        <Card.Header style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <IssuerPicture picture={issuerData.picture}/>
            <span style={{fontWeight: 500, marginLeft: '10px'}}>{issuerData.name}</span>
          </div>
          <div style={{alignSelf: 'right'}}>
            <RegionFlag region={issuerData?.region}/>
          </div>
        </Card.Header>

        <Card.Body>
          <Row noGutters>
            <Col sm={12} md={4}>
              <Card.Header>Organisation number</Card.Header>
              <ListGroup className="list-group-flush">
                <ListGroupItem>{issuerData.orgNumber}</ListGroupItem>
              </ListGroup>
            </Col>
            <Col sm={12} md={4}>
              <Card.Header>Registration date</Card.Header>
              <ListGroup className="list-group-flush">
                <ListGroupItem>
                  <MomentDate
                    value={issuerData.createdAt}
                    format={'DD MMMM YYYY HH:mm:ss'}
                    tooltipId={'createdAt_' + issuerData.id}
                    tooltipFromNow
                  />
                </ListGroupItem>
              </ListGroup>
            </Col>
            <Col sm={12} md={4}>
              <Card.Header>Last update date</Card.Header>
              <ListGroup className="list-group-flush">
                <ListGroupItem>
                  <MomentDate
                    value={issuerData.updatedAt}
                    format={'DD MMMM YYYY HH:mm:ss'}
                    tooltipId={'updatedAt_' + issuerData.id}
                    tooltipFromNow
                  />
                </ListGroupItem>
              </ListGroup>
            </Col>
          </Row>

          <Row noGutters>
            <Col sm={12} md={4}>
              <Card.Header>Email for invoices</Card.Header>
              <ListGroup className="list-group-flush">
                <ListGroupItem>
                  <FormIssuerUpdateEmail required={false}
                                         description={
                                           'The issuer email dedicated to invoices support.'
                                         }
                                         onSubmit={async (email) => {
                                           await updateEmail({
                                             variables: {
                                               issuerId: issuerId || '',
                                               email,
                                             },
                                           }).then(() => {
                                             if (email === '') {
                                               showToast('Removed', 'Email was empty.')
                                             } else {
                                               showToast('Saved', 'Email updated to ' + email + '.')
                                             }
                                           })
                                           // onUpdate();
                                         }}
                                         email={issuerData?.email || ''}
                  />
                </ListGroupItem>
              </ListGroup>
            </Col>
            <Col sm={12} md={4}>
              <Card.Header>Email for loans</Card.Header>
              <ListGroup className="list-group-flush">
                <ListGroupItem>
                  <FormIssuerUpdateEmail required={false}
                                         description={'The issuer email dedicated to loans support.'}
                                         onSubmit={async (email) => {
                                           await updateLoanEmail({
                                             variables: {
                                               issuerId: issuerId || '',
                                               emailLoan: email,
                                             },
                                           }).then(() => {
                                             if (email === '') {
                                               showToast('Removed', 'Email was empty.')
                                             } else {
                                               showToast('Saved', 'Email updated to ' + email + '.')
                                             }
                                           })
                                           // onUpdate();
                                         }}
                                         email={issuerData?.emailLoan || ''}
                  />
                </ListGroupItem>
              </ListGroup>
            </Col>
            <Col sm={12} md={4}>
              <Card.Header>Logo</Card.Header>
              <ListGroup className="list-group-flush">
                <ListGroupItem>
                  <FormPictureUpdate
                    onSubmit={async (pictureBase64) => {
                      await updatePicture({
                        variables: {
                          issuerId: issuerId || '',
                          pictureBase64,
                        },
                      })
                      // onUpdate();
                    }}
                  />
                </ListGroupItem>
              </ListGroup>
            </Col>
          </Row>
        </Card.Body>

        <Card.Body>
          <Card.Header>Brreg update</Card.Header>
          <div style={{margin: '10px'}}>
            <Form onSubmit={handleUpdateBrreg}>
              <Form.Group as={Row}>
                <Form.Label column sm="4">Brreg update info</Form.Label>
                <Col sm="8">
                  <Form.Control plaintext readOnly value={issuerData.brregStatusDesc}></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">Address</Form.Label>
                <Col sm="8">
                  <Form.Control plaintext readOnly value={addressFormatted}></Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">Industry code (næringskode)</Form.Label>
                <Col sm="4">
                  <Form.Control plaintext readOnly
                                value={issuerData.industryCode || '(Missing industry code)'}></Form.Control>
                </Col>
                <Col sm="4">
                  <Form.Text>
                    <a href={'/industry-codes'}>
                      {issuerData.implicitSubMerchantMcc ? 'Mapped to MCC code ' + issuerData.implicitSubMerchantMcc : 'Not mapped to any MCC code'}
                    </a>
                  </Form.Text>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">Merchant category code (override)</Form.Label>
                <Col sm="4">
                  <Form.Control
                    value={subMerchantMcc}
                    onChange={(event) => setSubMerchantMcc(event.currentTarget.value)}
                  ></Form.Control>
                </Col>
                <Col sm="4">
                  <Form.Text>If empty decided from industry code (above)</Form.Text>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">Real organization number</Form.Label>
                <Col sm="4">
                  <Form.Control
                    value={realOrgNumber}
                    onChange={(event) => setRealOrgNumber(event.currentTarget.value)}
                  ></Form.Control>
                </Col>
              </Form.Group>
              <Button variant="secondary" type="submit">
                Update
              </Button>
            </Form>
          </div>
        </Card.Body>

        <Card.Body>
          <h5>Rules for transactions</h5>
          <FormIssuerTransactionRule issuerId={issuerId || ''}/>
        </Card.Body>

        <Card.Footer>
          <Button
            variant={showSuggestions ? 'light' : 'success'}
            style={{marginRight: '15px'}}
            onClick={onImportConfiguration}
          >
            Import configuration
          </Button>
          <Button
            variant="secondary"
            onClick={() => onInvoicesClick(issuerData.id)}
          >
            See issuer's invoices
          </Button>
        </Card.Footer>

        {showSuggestions && (
          <Card.Footer>
            <IssuersSuggestions
              defaultFilterByName={issuerData.name}
              onClick={onSuggestionsChoice}
            />
          </Card.Footer>
        )}

        <IssuerWhitelistOverview issuerId={issuerId || ''}/>

        <Modal show={showSuggestionsModal} onHide={closeSuggestionsModal}>
          <Modal.Header closeButton>
            <Modal.Title>Do you want to use this configuration?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              You are going to set the picture and the emails of{' '}
              <strong>{issuerData.name}</strong> using the information of{' '}
              <strong>{issuerSource?.result?.name}</strong>
            </p>
            {issuerSource?.result?.picture && (
              <IssuerPicture picture={issuerSource?.result.picture}/>
            )}
            <span style={{fontWeight: 500}}>
              {issuerSource?.result?.name}
            </span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeSuggestionsModal}>
              No
            </Button>
            <Button variant="success" onClick={confirmSuggestionsModal}>
              Yes, use it!
            </Button>
          </Modal.Footer>
        </Modal>
      </Card>
    </Container>
  )
}
