import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Moment from "react-moment";
import {Table, Tooltip, OverlayTrigger, Button} from "react-bootstrap";
import {useLazyQuery} from "@apollo/client";
import {ReadUserActivities} from "../graphql/user";
import {
  LogWithReference,
  ReadUserActivitiesQuery,
  ReadUserActivitiesQueryVariables,
} from "../types/graphql";
import InfiniteScroll from "react-infinite-scroll-component";
import {Error} from "./Error";
import {Loading} from "./Loading";
import moment from "moment";

interface IProps {
  userId: string;
}

const maxNoLogs = 10;

type DateRange = {
  fromDate: Date;
  toDate: Date;
}

export const UserActivity = ({userId}: IProps) => {
  const [items, setItems] = useState<Array<LogWithReference>>([]);
  const [dateRange, setDateRange] = useState<DateRange>({
    fromDate: moment().subtract(1, "day").toDate(),
    toDate: moment().toDate(),
  });
  const [countNoLogs, setCountNoLogs] = useState<number>(0);
  const [readUserActivities, {data, loading, error}] = useLazyQuery<
    ReadUserActivitiesQuery,
    ReadUserActivitiesQueryVariables
  >(ReadUserActivities);

  const fetchData = useCallback(async () => {
    await readUserActivities({
      variables: {
        userId,
        fromDate: dateRange.fromDate.toISOString(),
        toDate: dateRange.toDate.toISOString(),
      },
    });
  }, [readUserActivities, userId, dateRange]);

  useEffect(() => {
    fetchData().catch(e => {
      console.error(e);
    });
  }, [dateRange, fetchData]);

  useEffect(() => {
    const result = data?.result;
    if (!loading && !error && data && result) {

      // In case there are no logs, we add a single log entry to force items to change the length,
      // so the loader can fire next function.
      // This item will be filtered out in the rendering process.
      if (result.length === 0) {
        setCountNoLogs((prev) => prev + 1)
        setItems((prev) => [
          ...prev,
          {
            event: "No logs found",
            status: "INVISIBLE",
            details: {
              context: "",
              stackTrace: "",
            },
          } as LogWithReference
        ])
        return
      }

      setCountNoLogs(0)
      setItems((prev) => [
        ...prev,
        ...result.map((v) => v as LogWithReference),
      ])

    }
  }, [loading, error, data]);

  const rows = useMemo(() => {
    return items.filter(
      (v) => v.status !== "INVISIBLE"
    ).map((v, i) => {
      return (
        <tr key={i}>
          <td>
            <OverlayTrigger
              key={i}
              placement="top"
              overlay={
                <Tooltip id={i.toString()}>
                  <Moment fromNow={true}>{v.time}</Moment>
                </Tooltip>
              }
            >
              <Moment format="DD MMMM YYYY HH:mm:ss">{v.time}</Moment>
            </OverlayTrigger>
          </td>
          <td>{v.event}</td>
          <td>{v.status}</td>
          <td>{v.error}</td>
          <td>{v.component}</td>
          <td>{v.details.context}</td>
          <td>{v.details.stackTrace}</td>
        </tr>
      );
    });
  }, [items]);

  function next() {
    const newToDate = moment(dateRange.fromDate).subtract(1, "second")
    let newFromDate = moment(newToDate).subtract(1, "day")
    if (countNoLogs > 0) {
      // If we fetched no logs in the previous request, we extend the time range.
      newFromDate = moment(newToDate).subtract(countNoLogs, "day")
      newFromDate.subtract(countNoLogs, "day")
      console.log("Extending time range to", newFromDate);
    }
    setDateRange({
      fromDate: newFromDate.toDate(),
      toDate: newToDate.toDate(),
    });
  }

  return (
    <>
      <Error error={error?.graphQLErrors[0].message}/>

      <InfiniteScroll
        // We cannot really know if there is more data to load or not since logs can
        // be generated at any time and some time gaps can be empty.
        // Therefore, we just allow the user to scroll back in time while countNoLogs is less than maxNoLogs.
        hasMore={countNoLogs < maxNoLogs}
        loader={<Loading type="spinner" isLoading={loading}/>}
        dataLength={items.length}
        next={next}
      >
        {rows.length > 0 && (
          <>
            <Table responsive striped bordered hover size="sm">
              <thead>
              <tr>
                <th>Time</th>
                <th>Event</th>
                <th>Status</th>
                <th>Error</th>
                <th>Component</th>
                <th>Context</th>
                <th>Stack trace</th>
              </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </>
        )}
      </InfiniteScroll>

      {/* This button allows manual fetch in the case of a limited number of logs that does not trigger the loader. */}
      <Button disabled={loading} variant={'dark'} onClick={() => {
        next()
      }}>
        Load logs before {moment(dateRange.fromDate).format("DD MMMM YYYY HH:mm:ss")}
      </Button>
    </>
  );
};
