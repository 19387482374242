import React, {useMemo} from 'react';
import {Card, Container, ListGroup, Table} from "react-bootstrap";
import {Loading} from "../component/Loading";
import {Error} from "../component/Error";
import {useReadJobsQuery} from "../types/graphql";
import moment from "moment";

const parser = require('cron-parser');

export default () => {

  const {data, loading, error} = useReadJobsQuery()

  const rows = useMemo(() => {
    if (loading || error) return null;
    const jobs = data?.result || []
    return jobs.map((j, i) => {
      return (
        <tr key={i}>
          <td>{j?.name}</td>
          <td>{j?.description}</td>
          <td>
            <ListGroup>
              {getIntervalItem(j?.when as string[])}
            </ListGroup>
          </td>
        </tr>
      );

    });
  }, [data, error, loading])

  return (
    <Container>
      <Card>
        <Card.Header>
          <h5 className="dark">Jobs</h5>
        </Card.Header>
        <Card.Body>
          <Loading isLoading={loading}/>
          <Error error={error?.graphQLErrors[0].message}/>
          <Table responsive bordered striped hover size="sm">
            <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>When</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );

};

function getIntervalItem(when: string[]) {
  const values = when.map(w => {
    const interval = parser.parseExpression(w, {utc: true});
    return [
      interval.prev().toString(),
      interval.next().toString(),
    ]
  })

  const vv = values.flat().sort((a, b) => moment(a).isBefore(moment(b)) ? -1 : 1);
  return vv.map((i, k) => {
    const m = moment(i)
    const variant = m.isBefore(moment.now()) ? "light" : "success";
    return (
      <ListGroup.Item key={k} variant={variant}>
        {m.format("HH:mm:ss")}
        <span className="text-muted"><br/><i>{m.fromNow()}</i></span>
      </ListGroup.Item>
    );
  });
}

