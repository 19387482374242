import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { UserNote } from "../types/graphql";
import moment from "moment";
import {Row} from "react-bootstrap";

interface IProps {
  note?: UserNote;
  onSubmit: (note: UserNote) => void;
  onDelete?: (note: UserNote) => void;
}

export const FormUserNote = ({ note, onSubmit, onDelete }: IProps) => {
  const [content, setContent] = useState<string>(note?.content || "");
  const [noteTime, setNoteTime] = useState<string>(
    moment(note?.noteTime).format("YYYY-MM-DD")
  );
  const [validated, setValidated] = useState<boolean>(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const isValid = form.checkValidity();

    setValidated(isValid);
    isValid &&
      onSubmit &&
      onSubmit({
        __typename: "UserNote",
        id: note?.id || "",
        content,
        noteTime: moment(noteTime).toISOString(),
      });
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row>
        <Form.Group as={Col}>
          <Form.Control
            required
            type="date"
            placeholder="Note time"
            value={noteTime}
            onChange={(event) => setNoteTime(event.currentTarget.value)}
            name="noteTime"
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid time.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Control
            required
            type="text"
            placeholder="Note"
            value={content}
            onChange={(event) => setContent(event.currentTarget.value)}
            name="content"
            as={"textarea"}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid note.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Button variant="secondary" type="submit">
            {note ? "Update note" : "Create note"}
          </Button>
          {note && onDelete && (
            <Button variant="danger" onClick={() => onDelete(note)}>
              Delete note
            </Button>
          )}
        </Form.Group>
      </Row>
    </Form>
  );
};
