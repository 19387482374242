import React, { useCallback, useMemo, useState } from 'react';
import {useNavigate, useParams } from 'react-router-dom';
import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import { UserActivity } from "../component/UserActivity";
import { UserDetail } from "../component/UserDetail";
import { useMutation, useQuery } from "@apollo/client";
import { ReadUserById, UpdateUserStatus } from "../graphql/user";
import {
    HandlePepRequestMutation,
    HandlePepRequestMutationVariables,
    PepDecision,
    PepStatus,
    ReadUserByIdQuery,
    ReadUserByIdQueryVariables, ReadUserNotesQuery, ReadUserNotesQueryVariables,
    UpdateUserStatusMutation,
    UpdateUserStatusMutationVariables,
    UserStatusEnum
} from "../types/graphql";
import { LoadingPage } from "./loading";
import { Error } from "../component/Error";
import { HandlePEPRequest } from "../graphql/permission";
import { UserSubscriptions } from "../component/UserSubscriptions";
import { Loading } from "../component/Loading";
import { ReadUserNotes } from "../graphql/userNote";
import { ModalDeleteAccount } from '../component/ModalDeleteAccount';
import { RegionFlag } from "../component/RegionFlag";

type IParams = {
    id: string
}

export default () => {

    const history = useNavigate();
    const { id } = useParams<IParams>();
    const [isDeleteMode, setIsDeleteMode] = useState(false);

    const {
        data,
        loading,
        error
    } = useQuery<ReadUserByIdQuery, ReadUserByIdQueryVariables>(ReadUserById, {
        variables: {
            userId: id || ''
        },
        fetchPolicy: "cache-and-network"
    });

    const {
        data: dataNotes,
        loading: loadingNotes,
        error: errorNotes
    } = useQuery<ReadUserNotesQuery, ReadUserNotesQueryVariables>(ReadUserNotes, {
        variables: {
            userId: id || ''
        }
    });

    const [updateUserStatus, {
        loading: loadingUpdateStatus,
        error: errorUpdateStatus
    }] = useMutation<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>(UpdateUserStatus);

    const [handlePEPRequest, {
        loading: loadingHandlePEPRequest,
        error: errorHandlePEPRequest
    }] = useMutation<HandlePepRequestMutation, HandlePepRequestMutationVariables>(HandlePEPRequest);

    const onInvoicesClick = useCallback((id: string) => {
        history(`/invoices/user/${id}`)
    }, [history]);

    const onZenPointsClick = useCallback((id: string) => {
        history(`/zenpoints/user/${id}`)
    }, [history]);

    const onNotesClick = useCallback((id: string) => {
        history(`/user/${id}/notes`)
    }, [history]);

    const onPEPDecision = useCallback(async (isAccepted: boolean) => {

        if (!data?.result) return;

        // Send PEP decision
        await handlePEPRequest({
            variables: {
                userId: data.result.id,
                decision: isAccepted ? PepDecision.Accept : PepDecision.Reject
            }
        })

    }, [data, handlePEPRequest]);

    const howManyNotes: number = useMemo(() => {
        console.log(dataNotes)
        return dataNotes?.notes?.edges?.length || 0;
    }, [dataNotes]);

    const unblockUser = async () => {
        if (!data?.result) return;
        try {
            await updateUserStatus({
                variables: {
                    userId: data.result.id,
                    status: UserStatusEnum.Enabled
                }
            })
        } catch (e) {
            console.error(e)
        }
    }

    const blockUser = async () => {
        if (!data?.result) return;
        try {
            await updateUserStatus({
                variables: {
                    userId: data.result.id,
                    status: UserStatusEnum.Blocked
                }
            })
        } catch (e) {
            console.error(e)
        }
    }

    if (loading || !data?.result) {
        return <LoadingPage/>
    }

    const user = data?.result;

    return (
        <div className="UserPage">
            <Container fluid>
                <Card>
                    <Card.Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h5 className="dark">{data.result.firstname} {data.result.lastname}</h5>
                        <div style={{ alignSelf: 'right' }}>
                            <RegionFlag region={data?.result.region}/>
                        </div>
                    </Card.Header>

                    <Error error={errorUpdateStatus?.graphQLErrors[0].message}/>
                    <Error error={errorNotes?.graphQLErrors[0].message}/>
                    <Loading isLoading={loadingUpdateStatus} type={'spinner'}/>

                    <Card.Header>
                        <Row>
                            <Col sm={"auto"}>
                                <Button
                                    variant='secondary'
                                    size={'sm'}
                                    onClick={() => onInvoicesClick(user.id)}>
                                    See user's invoices
                                </Button>
                            </Col>
                            <Col sm={"auto"}>
                                <Button
                                    variant='secondary'
                                    size={'sm'}
                                    onClick={() => onZenPointsClick(user.id)}>
                                    See user's Zen Points
                                </Button>
                            </Col>
                            <Col sm={"auto"}>
                                <Button
                                    variant='secondary'
                                    size={'sm'}
                                    onClick={() => onNotesClick(user.id)}>
                                    Notes {" "}
                                    <Badge pill bg={howManyNotes > 0 ? "success" : "light"}>
                                        {howManyNotes}
                                    </Badge>
                                </Button>
                            </Col>
                            <Col sm={"auto"}>
                                {user.status === UserStatusEnum.Blocked ? (
                                    <Button
                                        variant={'warning'}
                                        size={'sm'}
                                        onClick={unblockUser}>
                                        Unblock
                                    </Button>
                                ) : (
                                    <Button
                                        variant={'danger'}
                                        size={'sm'}
                                        onClick={blockUser}>
                                        Block
                                    </Button>
                                )}
                            </Col>
                            <Col sm={"auto"}>
                                {user.status !== UserStatusEnum.Disabled && (
                                    <Button
                                        variant={'danger'}
                                        size={'sm'}
                                        onClick={() => {
                                            setIsDeleteMode(true)
                                        }}>
                                        Delete account
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Card.Header>

                    {
                        user.permission.pep.status === PepStatus.Pending &&
                      <Card.Header>
                        <h5>PEP decision</h5>
                        <Button
                          variant='success'
                          size={'sm'}
                          disabled={loadingHandlePEPRequest}
                          onClick={() => onPEPDecision(true)}>
                          Accept PEP user
                        </Button>
                        <span>&nbsp;</span>
                        <Button
                          variant='danger'
                          size={'sm'}
                          disabled={loadingHandlePEPRequest}
                          onClick={() => onPEPDecision(false)}>
                          Reject PEP user
                        </Button>
                      </Card.Header>
                    }

                    <Card.Body>
                        <Error
                            error={error?.graphQLErrors[0].message || errorHandlePEPRequest?.graphQLErrors[0].message}/>

                        <h5>Info</h5>
                        <UserDetail user={data.result}/>

                        <h5>Subscriptions (last five)</h5>
                        <UserSubscriptions userId={user.id}/>

                        <h5>Logs</h5>
                        <UserActivity userId={user.id}/>
                    </Card.Body>
                </Card>

                <ModalDeleteAccount
                    user={user}
                    visible={isDeleteMode}
                    onExit={() => setIsDeleteMode(false)}
                    onSuccess={() => setIsDeleteMode(false)}
                />

            </Container>
        </div>
    );

}
