import React, { ComponentProps, useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import {Row} from "react-bootstrap";

interface IProps extends ComponentProps<any> {
  onSubmit: (amount: number, time: Date) => void;
}

export const FormBalanceSnapshot = ({ onSubmit }: IProps) => {
  const [balance, setBalance] = useState<string>("");
  const [time, setTime] = useState<string>("");
  const [validated, setValidated] = useState<boolean>(false);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const isValid = form.checkValidity();

    setValidated(isValid);
    isValid && onSubmit && onSubmit(parseFloat(balance), new Date(time));
  };

  return (
    <>
      <h5>Add balance snapshot</h5>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Form.Group as={Col}>
            <InputGroup className="mb-3">
              <Form.Control
                required
                min="0"
                step="any"
                type="number"
                placeholder="Amount"
                value={balance}
                onChange={(event) => setBalance(event.currentTarget.value)}
                name="balance"
              />
                <InputGroup.Text>kr</InputGroup.Text>
            </InputGroup>
            <Form.Text className="text-muted">
              The amount expressed in NOK present on bank account
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              Please provide a valid invoice id.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col}>
            <InputGroup className="mb-3">
              <Form.Control
                required
                type="date"
                placeholder="Time"
                value={time}
                onChange={(event) => setTime(event.currentTarget.value)}
                name="time"
              />
                <InputGroup.Text>Date</InputGroup.Text>
            </InputGroup>
            <Form.Text className="text-muted">
              The date when amount was available on bank account
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              Please provide a valid date.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Button variant="primary" type="submit">
              Add balance snapshot
            </Button>
          </Form.Group>
        </Row>
      </Form>
    </>
  );
};
