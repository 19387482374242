import {OfferServiceWithTranslations} from "../types/graphql";
import {Button, Table} from "react-bootstrap";
import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import { RegionType } from "../helper/system";

interface IProps {
    offerId: string
    services: Array<OfferServiceWithTranslations>
    region: RegionType
}

export const OfferServicesTable = ({offerId, services, region}: IProps) => {

    const history = useNavigate();

    const onItemClick = useCallback((serviceId: string) => {
        history(`/ninja-power/offers/${offerId}/services/${serviceId}/${region}`)
    }, [history, offerId, region])

    const onAddClick = useCallback(() => {
        history(`/ninja-power/offers/${offerId}/service/${region}`)
    }, [history, offerId, region]);

    const itemsToRender = services.map(v => (
            <tr
                key={v.id}
                style={{cursor: 'pointer'}}
                onClick={() => onItemClick(v.id)}
            >
                <td>{v.id}</td>
                <td>{v.nameEn}</td>
                <td>{v.descriptionEn}</td>
            </tr>
        )
    );

    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Description</th>
            </tr>
            </thead>

            <tfoot>
            <tr>
                <td colSpan={3}>
                    <Button onClick={onAddClick} variant={"outline-dark"} size={'sm'}>Add new service</Button>
                </td>
            </tr>
            </tfoot>

            <tbody>
            {itemsToRender}
            </tbody>

        </Table>
    );
}
