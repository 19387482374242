import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  DateTimeCustom: any;
};

export type Address = {
  __typename: 'Address';
  /** @deprecated  */
  city?: Maybe<Scalars['String']>;
  /** @deprecated  */
  country?: Maybe<Country>;
  /** @deprecated  */
  countryId?: Maybe<Scalars['Int']>;
  /** @deprecated  */
  postalCode?: Maybe<Scalars['String']>;
  /** @deprecated  */
  street?: Maybe<Scalars['String']>;
};

export type AppIncompatibility = {
  __typename: 'AppIncompatibility';
  /**
   * Action to resolve the incompatibility
   * @deprecated
   */
  action: AppIncompatibilityAction;
  /**
   * Reason for the incompatibility
   * @deprecated
   */
  reason: Scalars['String'];
  /**
   * Title of the incompatibility
   * @deprecated
   */
  title: Scalars['String'];
};

export type AppIncompatibilityAction = {
  __typename: 'AppIncompatibilityAction';
  /**
   * Name of the action
   * @deprecated
   */
  name: Scalars['String'];
  /**
   * URL to the action
   * @deprecated
   */
  url: Scalars['String'];
};

export type Balance = {
  __typename: 'Balance';
  /** @deprecated  */
  Basic: Scalars['Int'];
  /** @deprecated  */
  Extra: Scalars['Int'];
};

export type BalanceHistory = {
  __typename: 'BalanceHistory';
  /**
   * Final balance
   * @deprecated
   */
  balance: Scalars['Float'];
  /**
   * List of fees grouped by reason
   * @deprecated
   */
  fees: Array<BalanceHistoryFeeSubtotal>;
  /**
   * Sum of fees amount
   * @deprecated
   */
  sumFees: Scalars['Float'];
  /**
   * Sum of gross amount
   * @deprecated
   */
  sumGrossAmount: Scalars['Float'];
  /**
   * Sum of incoming amount including fees
   * @deprecated
   */
  sumIncoming: Scalars['Float'];
  /**
   * Sum of incoming amount without fees
   * @deprecated
   */
  sumIncomingWithoutFees: Scalars['Float'];
  /**
   * Sum of outgoing amount
   * @deprecated
   */
  sumOutgoing: Scalars['Float'];
  /**
   * Sum of Zen Fees in NOK
   * @deprecated
   */
  sumZenFeeNOK: Scalars['Float'];
  /**
   * List of transactions
   * @deprecated
   */
  transactions: Array<BalanceHistoryTransaction>;
};

export type BalanceHistoryAsExcel = {
  __typename: 'BalanceHistoryAsExcel';
  /**
   * Excel data as base64
   * @deprecated
   */
  data?: Maybe<Scalars['String']>;
};

export type BalanceHistoryFeeSubtotal = {
  __typename: 'BalanceHistoryFeeSubtotal';
  /**
   * Reason of the fee for the transaction.
   * @deprecated
   */
  reason: InvoiceTransactionFeeReason;
  /**
   * Total amount for the specific reason.
   * @deprecated
   */
  total: Scalars['Float'];
};

export type BalanceHistoryTransaction = {
  __typename: 'BalanceHistoryTransaction';
  /**
   * Amount withdrawn from user's card, ex. fee
   * @deprecated
   */
  balanceCardAmount: Scalars['Float'];
  /**
   * Fee amount
   * @deprecated
   */
  balanceFeeAmount: Scalars['Float'];
  /**
   * Fee percentage
   * @deprecated
   */
  balanceFeePercentage: Scalars['Float'];
  /**
   * Reason that a fee was charged
   * @deprecated
   */
  balanceFeeReason: Scalars['String'];
  /**
   * Gross Amount (Amount + Fees Paid + Fees from Points in NOK)
   * @deprecated
   */
  balanceGross: Scalars['Float'];
  /**
   * Invoice amount
   * @deprecated
   */
  balanceInvoiceAmount: Scalars['Float'];
  /**
   * Amount remitted to issuer
   * @deprecated
   */
  balanceRemitAmount: Scalars['Float'];
  /**
   * Zen Fee Points
   * @deprecated
   */
  balanceZenFee: Scalars['Int'];
  /** @deprecated  */
  invoiceId: Scalars['ID'];
  /** @deprecated  */
  invoiceIdentifier?: Maybe<Scalars['String']>;
  /** @deprecated  */
  invoiceKind: Scalars['String'];
  /** @deprecated  */
  invoiceReference: Scalars['String'];
  /** @deprecated  */
  invoiceReferenceProvider: Scalars['String'];
  /**
   * Whether the invoice is paid
   * @deprecated
   */
  invoiceStatus: Scalars['String'];
  /**
   * Time of change for invoiceStatus
   * @deprecated
   */
  invoiceStatusDate?: Maybe<Scalars['DateTime']>;
  /**
   * Who received the payment
   * @deprecated
   */
  issuerId: Scalars['ID'];
  /** @deprecated  */
  issuerName: Scalars['String'];
  /** @deprecated  */
  lastTransactionId: Scalars['ID'];
  /** @deprecated  */
  lastTransactionTime: Scalars['DateTime'];
  /** @deprecated  */
  paymentAttempt: Scalars['String'];
  /**
   * ID of user's card used for payment
   * @deprecated
   */
  paymentCardId?: Maybe<Scalars['ID']>;
  /**
   * Reference to payment provider transaction
   * @deprecated
   */
  paymentId: Scalars['String'];
  /**
   * Whether withdrawal from user's card was successful
   * @deprecated
   */
  paymentStatus: Scalars['String'];
  /**
   * Time of successful withdrawal from user's card
   * @deprecated
   */
  paymentTime?: Maybe<Scalars['DateTime']>;
  /**
   * Reconciliation ID
   * @deprecated
   */
  reconciliationId: Scalars['String'];
  /** @deprecated  */
  remitAttempt: Scalars['String'];
  /** @deprecated  */
  remitBatchId: Scalars['String'];
  /**
   * Whether remittance to issuer was successful
   * @deprecated
   */
  remitStatus: Scalars['String'];
  /** @deprecated  */
  remitStatusDescription: Scalars['String'];
  /**
   * Time of successful remittance to issuer
   * @deprecated
   */
  remitTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated  */
  userEmail: Scalars['String'];
  /**
   * Who paid
   * @deprecated
   */
  userId: Scalars['ID'];
  /** @deprecated  */
  userName: Scalars['String'];
};

export type BalanceSnapshot = {
  __typename: 'BalanceSnapshot';
  /**
   * Balance amount
   * @deprecated
   */
  amount: Scalars['Float'];
  /**
   * Snapshot id
   * @deprecated
   */
  id: Scalars['Int'];
  /**
   * Balance date
   * @deprecated
   */
  time: Scalars['DateTime'];
};

/** A connection to a list of items. */
export type BalanceSnapshotConnection = {
  __typename: 'BalanceSnapshotConnection';
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  edges?: Maybe<Array<Maybe<BalanceSnapshotEdge>>>;
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  pageInfo: PageInfo;
};

/** An edge in a connection */
export type BalanceSnapshotEdge = {
  __typename: 'BalanceSnapshotEdge';
  /**
   *  cursor for use in pagination
   * @deprecated
   */
  cursor: Scalars['String'];
  /**
   * The item at the end of the edge
   * @deprecated
   */
  node?: Maybe<BalanceSnapshot>;
};

export type BalanceZenHistoryType = {
  __typename: 'BalanceZenHistoryType';
  /** @deprecated  */
  TotalEarnedExtra: Scalars['Int'];
  /** @deprecated  */
  TotalEarnedExtraByInvitation: Scalars['Int'];
  /** @deprecated  */
  TotalEarnedExtraByInvoice: Scalars['Int'];
  /** @deprecated  */
  TotalEarnedExtraStandaloneSubscription: Scalars['Int'];
  /** @deprecated  */
  TotalUnusedExtra: Scalars['Int'];
  /** @deprecated  */
  TotalUsedExtra: Scalars['Int'];
  /** @deprecated  */
  ZenPointExt?: Maybe<Array<ZenPointExtType>>;
};

export type BlockedIssuer = {
  __typename: 'BlockedIssuer';
  /**
   * Permission EInvoice id, used to unblock a blocked issuer.
   * @deprecated
   */
  id: Scalars['ID'];
  /**
   * Information about issuer
   * @deprecated
   */
  issuer: Issuer;
};

export type BooleanResult = {
  __typename: 'BooleanResult';
  /**
   * Returns the success or failure of operation
   * @deprecated
   */
  isOk: Scalars['Boolean'];
};

export type BufferForecasting = {
  __typename: 'BufferForecasting';
  /** @deprecated  */
  buffer: Scalars['Float'];
  /** @deprecated  */
  incoming: Scalars['Float'];
  /** @deprecated  */
  outgoing: Scalars['Float'];
  /** @deprecated  */
  time: Scalars['DateTime'];
};

export type CallbacksBankId = {
  __typename: 'CallbacksBankId';
  /** @deprecated  */
  abort: Scalars['String'];
  /** @deprecated  */
  failure: Scalars['String'];
  /** @deprecated  */
  success: Scalars['String'];
};

export type CallbacksPsp = {
  __typename: 'CallbacksPsp';
  /** @deprecated  */
  cancel: Scalars['String'];
  /** @deprecated  */
  complete: Scalars['String'];
};

export type Card = {
  __typename: 'Card';
  /** @deprecated Use the new typed field `circuit` from app-version 2.1.0 (hybrid). */
  brand: Scalars['String'];
  /** @deprecated  */
  circuit: CardCircuit;
  /**
   * Custom name set by user
   * @deprecated
   */
  customName: Scalars['String'];
  /** @deprecated  */
  expiration: Scalars['String'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  isDefault: Scalars['Boolean'];
  /** @deprecated  */
  isExpired: Scalars['Boolean'];
  /**
   * Masked credit card number
   * @deprecated
   */
  name: Scalars['String'];
  /** @deprecated  */
  provider?: Maybe<Scalars['String']>;
  /** @deprecated  */
  type: CardType;
};

export enum CardCircuit {
  /** @deprecated  */
  Amex = 'amex',
  /** @deprecated  */
  Mastercard = 'mastercard',
  /** @deprecated  */
  Undefined = 'undefined',
  /** @deprecated  */
  Visa = 'visa'
}

export enum CardDeletionMode {
  /**
   * Card is deleted and related active (scheduled) invoices will use the new default card for the payment.
   * @deprecated
   */
  KeepPaymentsWithAnotherCard = 'keepPaymentsWithAnotherCard',
  /**
   * Card is deleted and related active (scheduled) invoices will be stopped becoming unpaid invoices.
   * @deprecated
   */
  RemovePayments = 'removePayments'
}

export type CardForm = {
  __typename: 'CardForm';
  /** @deprecated  */
  callbacks: CallbacksPsp;
  /** @deprecated  */
  paymentId: Scalars['String'];
  /** @deprecated  */
  provider: Scalars['Int'];
  /** @deprecated  */
  redirectionUrl: Scalars['String'];
  /** @deprecated  */
  sessionId: Scalars['String'];
};

export type CardNameGeneration = {
  __typename: 'CardNameGeneration';
  /** @deprecated  */
  generatedName: Scalars['String'];
};

export enum CardType {
  /** @deprecated  */
  Credit = 'credit',
  /** @deprecated  */
  Debit = 'debit',
  /** @deprecated  */
  Undefined = 'undefined'
}

export type CheckAppIncompatibilityResponse = {
  __typename: 'CheckAppIncompatibilityResponse';
  /**
   * Incompatibility details if any. If no incompatibility, returns null.
   * @deprecated
   */
  incompatibility?: Maybe<AppIncompatibility>;
};

export type ChurnStat = {
  __typename: 'ChurnStat';
  /** @deprecated  */
  Count: Scalars['Int'];
  /** @deprecated  */
  Month: Scalars['Int'];
  /** @deprecated  */
  Year: Scalars['Int'];
};

export type ClanMember = {
  __typename: 'ClanMember';
  /**
   * The accepted invitation ID. 0 is returned for the 'isMe' record.
   * @deprecated
   */
  invitationId: Scalars['ID'];
  /**
   * Flag to check if the position is related to the request's user.
   * @deprecated
   */
  isMe: Scalars['Boolean'];
  /**
   * The clan member's ID.
   * @deprecated
   */
  memberId: Scalars['ID'];
  /**
   * The clan member's name.
   * @deprecated
   */
  name: Scalars['String'];
  /**
   * The clan member's points'.
   * @deprecated
   */
  points: Scalars['Int'];
};

export type ClanOverview = {
  __typename: 'ClanOverview';
  /** @deprecated  */
  standings?: Maybe<Array<Maybe<ClanMember>>>;
};

export type ClientFeature = {
  __typename: 'ClientFeature';
  /**
   * Minimum recommended version; semantic versioning like v2.4.0
   * @deprecated
   */
  minRecommendedVersion: Scalars['String'];
};

export type Country = {
  __typename: 'Country';
  /** @deprecated  */
  code?: Maybe<Scalars['String']>;
  /** @deprecated  */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated  */
  name?: Maybe<Scalars['String']>;
};

export type Coupon = {
  __typename: 'Coupon';
  /**
   * Amount is expressed in NOK.
   * @deprecated
   */
  amount: Scalars['Float'];
  /**
   * A unique code which identifies coupon.
   * @deprecated
   */
  code: Scalars['String'];
  /**
   * A translatable explanation of coupon for users.
   * @deprecated
   */
  description: Scalars['String'];
  /** @deprecated  */
  descriptionId: Scalars['ID'];
  /**
   * When coupon ends to be available. If not specified, it is always valid.
   * @deprecated
   */
  endsAt?: Maybe<Scalars['DateTimeCustom']>;
  /** @deprecated  */
  id: Scalars['ID'];
  /**
   * Period is every time a subscription renews (included the first time it starts).
   * @deprecated
   */
  period: Scalars['Int'];
  /**
   * The product accepting/using the coupon.
   * @deprecated
   */
  product: CouponProduct;
  /**
   * Who can use the coupon.
   * @deprecated
   */
  recipient: CouponRecipient;
  /**
   * If the recipient is `Group`, `recipientGroup` is required.
   * @deprecated
   */
  recipientGroup?: Maybe<UserGroup>;
  /**
   * The region where the coupon is valid.
   * @deprecated
   */
  region: RegionEnum;
  /**
   * If the recipient is `Rule`, `rule` is required.
   * @deprecated
   */
  rule?: Maybe<CouponRule>;
  /**
   * When coupon starts to be available.
   * @deprecated
   */
  startsAt: Scalars['DateTime'];
};

/** A connection to a list of items. */
export type CouponConnection = {
  __typename: 'CouponConnection';
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  edges?: Maybe<Array<Maybe<CouponEdge>>>;
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  pageInfo: PageInfo;
};

/** An edge in a connection */
export type CouponEdge = {
  __typename: 'CouponEdge';
  /**
   *  cursor for use in pagination
   * @deprecated
   */
  cursor: Scalars['String'];
  /**
   * The item at the end of the edge
   * @deprecated
   */
  node?: Maybe<Coupon>;
};

export enum CouponProduct {
  /** @deprecated  */
  SubscriptionMaster = 'subscriptionMaster',
  /** @deprecated  */
  SubscriptionMaster1Month = 'subscriptionMaster1Month',
  /** @deprecated  */
  SubscriptionMaster6And12Months = 'subscriptionMaster6And12Months',
  /** @deprecated  */
  SubscriptionMaster6Months = 'subscriptionMaster6Months',
  /** @deprecated  */
  SubscriptionMaster12Months = 'subscriptionMaster12Months',
  /** @deprecated  */
  Undefined = 'undefined'
}

export enum CouponRecipient {
  /** @deprecated  */
  Group = 'group',
  /** @deprecated  */
  Public = 'public',
  /** @deprecated  */
  Rule = 'rule'
}

export enum CouponRule {
  /**
   * Users who have never been subscribed
   * @deprecated
   */
  UsersNeverSubscribed = 'usersNeverSubscribed',
  /**
   * Users who are not subscribed at this time even if they were previously subscribed.
   * @deprecated
   */
  UsersNotActivelySubscribed = 'usersNotActivelySubscribed'
}

export type Currency = {
  __typename: 'Currency';
  /** @deprecated  */
  code: Scalars['String'];
  /** @deprecated  */
  id: Scalars['Int'];
  /** @deprecated  */
  name: Scalars['String'];
  /** @deprecated  */
  symbol: Scalars['String'];
};

export enum CustomerUidType {
  /** @deprecated  */
  Kid = 'kid',
  /** @deprecated  */
  Note = 'note'
}

export type DateRange = {
  __typename: 'DateRange';
  /** @deprecated  */
  endDate: Scalars['DateTime'];
  /** @deprecated  */
  startDate: Scalars['DateTime'];
};

export type Debt = {
  __typename: 'Debt';
  /**
   * Used for payment
   * @deprecated
   */
  accountNumber: Scalars['String'];
  /** @deprecated  */
  balance: Scalars['Int'];
  /** @deprecated  */
  cardId: Scalars['ID'];
  /** @deprecated  */
  consentId: Scalars['ID'];
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /** @deprecated  */
  creditLimit: Scalars['Int'];
  /**
   * Used for payment (KID/OCR)
   * @deprecated
   */
  customerUid: Scalars['String'];
  /** @deprecated  */
  hasCoBorrower: Scalars['Boolean'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  installmentChargePeriod: InstallmentChargePeriod;
  /** @deprecated  */
  installmentCharges: Scalars['Int'];
  /** @deprecated  */
  interestBearingBalance: Scalars['Int'];
  /** @deprecated  */
  isBankOwnerOfDebt: Scalars['Boolean'];
  /** @deprecated  */
  isCoBorrower: Scalars['Boolean'];
  /** @deprecated  */
  issuer: IssuerIdentityMinimal;
  /** @deprecated  */
  name: Scalars['String'];
  /** @deprecated  */
  nominalInterestRate: Scalars['Int'];
  /** @deprecated  */
  nonInterestBearingBalance: Scalars['Int'];
  /** @deprecated  */
  originalBalance: Scalars['Int'];
  /** @deprecated  */
  processedTime: Scalars['DateTime'];
  /** @deprecated  */
  receivedTime: Scalars['DateTime'];
  /** @deprecated  */
  terms: Scalars['String'];
  /** @deprecated  */
  type: LoanType;
};

export type DebtConsent = {
  __typename: 'DebtConsent';
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  totalBalanceRepaymentLoan: Scalars['Int'];
  /** @deprecated  */
  totalCreditLimitCreditFacility: Scalars['Int'];
  /** @deprecated  */
  totalCreditLimitRepaymentLoan: Scalars['Int'];
  /** @deprecated  */
  totalInterestBearingBalanceChargeCard: Scalars['Int'];
  /** @deprecated  */
  totalInterestBearingBalanceCreditFacility: Scalars['Int'];
  /** @deprecated  */
  totalNonInterestBearingBalanceChargeCard: Scalars['Int'];
  /** @deprecated  */
  totalNonInterestBearingBalanceCreditFacility: Scalars['Int'];
};

export type DebtPaymentSuggestions = {
  __typename: 'DebtPaymentSuggestions';
  /**
   * All the invoices related to the given debt by organisation number.
   * @deprecated
   */
  others?: Maybe<Array<Maybe<InvoiceDebtPaymentSuggestion>>>;
  /**
   * Invoices suggestions.
   * @deprecated
   */
  suggestions?: Maybe<Array<Maybe<InvoiceDebtPaymentSuggestion>>>;
};

export type Device = {
  __typename: 'Device';
  /** @deprecated  */
  appVersion: Scalars['String'];
  /** @deprecated  */
  deviceName: Scalars['String'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  isBlocked: Scalars['Boolean'];
  /** @deprecated  */
  macAddress: Scalars['String'];
};

export enum DeviceOs {
  /** @deprecated  */
  Android = 'android',
  /** @deprecated  */
  Ios = 'ios'
}

export type DocumentRequest = {
  __typename: 'DocumentRequest';
  /** @deprecated  */
  callbacks: CallbacksBankId;
  /** @deprecated  */
  id: Scalars['String'];
  /**
   * Redirection url where document has to be signed
   * @deprecated
   */
  url: Scalars['String'];
};

export type EInvoice = {
  __typename: 'EInvoice';
  /** @deprecated  */
  acceptAllIssuers?: Maybe<Scalars['Boolean']>;
  /** @deprecated  */
  alias?: Maybe<Scalars['String']>;
  /** @deprecated  */
  blockedIssuers?: Maybe<Array<Maybe<BlockedIssuer>>>;
};

export type Favorite = {
  __typename: 'Favorite';
  /** @deprecated  */
  accountNumber: Scalars['String'];
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /**
   * It represents the Norwegian KID or a custom note
   * @deprecated
   */
  customerUId?: Maybe<Scalars['String']>;
  /**
   * It represents the type of customer UID. It can be a KID or a note.
   * @deprecated
   */
  customerUIdType: Scalars['String'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  issuerId: Scalars['ID'];
  /** @deprecated  */
  name: Scalars['String'];
  /** @deprecated  */
  orgNumber: Scalars['String'];
  /** @deprecated  */
  picture?: Maybe<Scalars['String']>;
  /** @deprecated  */
  realOrgNumber?: Maybe<Scalars['String']>;
  /** @deprecated  */
  region: Scalars['String'];
  /** @deprecated  */
  userId: Scalars['ID'];
  /** @deprecated  */
  whitelistId: Scalars['ID'];
};

export type Fetch = {
  __typename: 'Fetch';
  /** @deprecated  */
  id: Scalars['Int'];
  /** @deprecated  */
  pageNumber: Scalars['Int'];
  /** @deprecated  */
  pageSize: Scalars['Int'];
  /** @deprecated  */
  timeEnd: Scalars['DateTime'];
  /** @deprecated  */
  timeStart: Scalars['DateTime'];
};

export type GameOverview = {
  __typename: 'GameOverview';
  /**
   * Extra assigned to user.
   * @deprecated
   */
  extrapoints: GamePoints;
  /**
   * Points assigned to user.
   * @deprecated
   */
  points: GamePoints;
};

export type GamePoints = {
  __typename: 'GamePoints';
  /**
   * Sum of all granted points.
   * @deprecated
   */
  granted: Scalars['Int'];
  /**
   * Sum of all pending points.
   * @deprecated
   */
  pending: Scalars['Int'];
  /**
   * Sum of all points earned by user.
   * @deprecated
   */
  total: Scalars['Int'];
};

export type HistogramAccess = {
  __typename: 'HistogramAccess';
  /** @deprecated  */
  count: Scalars['Int'];
  /** @deprecated  */
  countBankIdSession: Scalars['Int'];
  /** @deprecated  */
  countBankIdSignature: Scalars['Int'];
  /** @deprecated  */
  countDeviceAndroid: Scalars['Int'];
  /** @deprecated  */
  countDeviceIos: Scalars['Int'];
  /** @deprecated  */
  countLogout: Scalars['Int'];
  /** @deprecated  */
  time: Scalars['DateTime'];
};

export type HistogramInvoicesCountCumulative = {
  __typename: 'HistogramInvoicesCountCumulative';
  /** @deprecated  */
  count: Scalars['Int'];
  /** @deprecated  */
  paidCount: Scalars['Int'];
  /** @deprecated  */
  time: Scalars['DateTime'];
};

export type HistogramInvoicesPaidAmount = {
  __typename: 'HistogramInvoicesPaidAmount';
  /** @deprecated  */
  amount: Scalars['Float'];
  /** @deprecated  */
  count: Scalars['Int'];
  /** @deprecated  */
  time: Scalars['DateTime'];
};

export type HistogramIssuersRegistration = {
  __typename: 'HistogramIssuersRegistration';
  /** @deprecated  */
  count: Scalars['Int'];
  /** @deprecated  */
  time: Scalars['DateTime'];
};

export type HistogramIssuersRegistrationCumulative = {
  __typename: 'HistogramIssuersRegistrationCumulative';
  /** @deprecated  */
  count: Scalars['Int'];
  /** @deprecated  */
  logoCount: Scalars['Int'];
  /** @deprecated  */
  supportCount: Scalars['Int'];
  /** @deprecated  */
  time: Scalars['DateTime'];
};

export type HistogramIssuersRequests = {
  __typename: 'HistogramIssuersRequests';
  /** @deprecated  */
  debtCount: Scalars['Int'];
  /** @deprecated  */
  invoiceCount: Scalars['Int'];
  /** @deprecated  */
  time: Scalars['DateTime'];
};

export type HistogramIssuersRequestsCumulative = {
  __typename: 'HistogramIssuersRequestsCumulative';
  /** @deprecated  */
  debtCount: Scalars['Int'];
  /** @deprecated  */
  invoiceCount: Scalars['Int'];
  /** @deprecated  */
  time: Scalars['DateTime'];
};

export type HistogramSubscriptionsOverview = {
  __typename: 'HistogramSubscriptionsOverview';
  /** @deprecated  */
  activeCount: Scalars['Int'];
  /** @deprecated  */
  count: Scalars['Int'];
  /** @deprecated  */
  refundedCount: Scalars['Int'];
  /** @deprecated  */
  time: Scalars['DateTime'];
};

export type HistogramUsersRegistration = {
  __typename: 'HistogramUsersRegistration';
  /** @deprecated  */
  activeCount: Scalars['Int'];
  /** @deprecated  */
  ageAverage: Scalars['Int'];
  /** @deprecated  */
  count: Scalars['Int'];
  /** @deprecated  */
  deletedCount: Scalars['Int'];
  /** @deprecated  */
  inactiveCount: Scalars['Int'];
  /** @deprecated  */
  time: Scalars['DateTime'];
};

export type IndustryCode = {
  __typename: 'IndustryCode';
  /** @deprecated  */
  country: Scalars['String'];
  /** @deprecated  */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated  */
  description: Scalars['String'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  industryCode: Scalars['String'];
  /** @deprecated  */
  merchantCategoryCode: Scalars['String'];
  /** @deprecated  */
  region: Scalars['String'];
};

/** A connection to a list of items. */
export type IndustryCodeConnection = {
  __typename: 'IndustryCodeConnection';
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  edges?: Maybe<Array<Maybe<IndustryCodeEdge>>>;
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  pageInfo: PageInfo;
};

/** An edge in a connection */
export type IndustryCodeEdge = {
  __typename: 'IndustryCodeEdge';
  /**
   *  cursor for use in pagination
   * @deprecated
   */
  cursor: Scalars['String'];
  /**
   * The item at the end of the edge
   * @deprecated
   */
  node?: Maybe<IndustryCode>;
};

export enum InstallmentChargePeriod {
  /** @deprecated  */
  HalfYearly = 'halfYearly',
  /** @deprecated  */
  Monthly = 'monthly',
  /** @deprecated  */
  Quarterly = 'quarterly',
  /** @deprecated  */
  Unknown = 'unknown',
  /** @deprecated  */
  Yearly = 'yearly'
}

export type Invitation = {
  __typename: 'Invitation';
  /** @deprecated  */
  acceptedBy?: Maybe<Scalars['Boolean']>;
  /** @deprecated  */
  email?: Maybe<Scalars['String']>;
  /**
   * Invitation id
   * @deprecated
   */
  id: Scalars['ID'];
  /**
   * E.164 format
   * @deprecated
   */
  mobile?: Maybe<Scalars['String']>;
  /** @deprecated  */
  name: Scalars['String'];
};

export type InvitationContact = {
  /** Email used to send an email with the invitation. */
  email?: InputMaybe<Scalars['String']>;
  /** Mobile used to send a SMS with the invitation. It must follow the E.164 formatting. */
  mobile?: InputMaybe<Scalars['String']>;
  /** The friends'name. */
  name: Scalars['String'];
};

export type InvitationItem = {
  __typename: 'InvitationItem';
  /** @deprecated  */
  email?: Maybe<Scalars['String']>;
  /**
   * Invitation id
   * @deprecated
   */
  id: Scalars['ID'];
  /**
   * E.164 format
   * @deprecated
   */
  mobile?: Maybe<Scalars['String']>;
  /** @deprecated  */
  name: Scalars['String'];
};

export type Invoice = {
  __typename: 'Invoice';
  /** @deprecated  */
  accountNumber?: Maybe<Scalars['String']>;
  /** @deprecated  */
  amount: Scalars['Float'];
  /** @deprecated  */
  archived: Scalars['Boolean'];
  /** @deprecated  */
  currency: Currency;
  /**
   * Custom note set by the customer.
   * @deprecated
   */
  customNote?: Maybe<Scalars['String']>;
  /**
   * It represents a value generated by the issuer to identify the user internally on their side.
   * @deprecated
   */
  customerNumber?: Maybe<Scalars['String']>;
  /**
   * It represents the Norwegian KID or a custom note
   * @deprecated
   */
  customerUId: Scalars['String'];
  /**
   * It represents the type of customer UID. It can be a KID or a note.
   * @deprecated
   */
  customerUIdType: Scalars['String'];
  /** @deprecated  */
  dueDate: Scalars['DateTime'];
  /** @deprecated  */
  fee?: Maybe<InvoiceTransactionFee>;
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  invoiceDate: Scalars['DateTime'];
  /** @deprecated  */
  issuer: IssuerIdentity;
  /** @deprecated  */
  kind: InvoiceKind;
  /** @deprecated  */
  lastTransaction: InvoiceTransaction;
  /** @deprecated  */
  net?: Maybe<Scalars['Float']>;
  /** @deprecated  */
  reference: Scalars['String'];
  /** @deprecated  */
  referenceProvider: Scalars['String'];
  /**
   * Type of invoice if non-standard: inkassovarsel i.e
   * @deprecated
   */
  subDocType?: Maybe<Scalars['String']>;
  /** @deprecated  */
  support?: Maybe<Array<Maybe<InvoiceSupport>>>;
  /** @deprecated  */
  supportNote: Scalars['String'];
  /** @deprecated  */
  user: UserIdentity;
  /** @deprecated  */
  vat?: Maybe<Scalars['Float']>;
  /** @deprecated  */
  zenFee?: Maybe<Scalars['Int']>;
  /** @deprecated  */
  zenReward?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type InvoiceConnection = {
  __typename: 'InvoiceConnection';
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  edges?: Maybe<Array<Maybe<InvoiceEdge>>>;
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  pageInfo: PageInfo;
};

export enum InvoiceDateContext {
  /** @deprecated  */
  DueDate = 'dueDate',
  /** @deprecated  */
  InvoiceDate = 'invoiceDate',
  /** @deprecated  */
  StatusDate = 'statusDate'
}

export enum InvoiceDateContextSort {
  /** @deprecated  */
  Newest = 'newest',
  /** @deprecated  */
  Oldest = 'oldest'
}

export type InvoiceDebtPaymentSuggestion = {
  __typename: 'InvoiceDebtPaymentSuggestion';
  /** @deprecated  */
  accountNumber: Scalars['String'];
  /** @deprecated  */
  amount: Scalars['Float'];
  /** @deprecated  */
  currency: Currency;
  /** @deprecated  */
  customerUid: Scalars['String'];
  /** @deprecated  */
  dueDate: Scalars['DateTime'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  invoiceDate: Scalars['DateTime'];
  /** @deprecated  */
  issuer: IssuerIdentity;
  /** @deprecated  */
  kind: InvoiceKind;
  /** @deprecated  */
  lastTransaction: InvoiceTransaction;
  /** @deprecated  */
  reference: Scalars['String'];
  /** @deprecated  */
  referenceProvider: Scalars['String'];
};

/** An edge in a connection */
export type InvoiceEdge = {
  __typename: 'InvoiceEdge';
  /**
   *  cursor for use in pagination
   * @deprecated
   */
  cursor: Scalars['String'];
  /**
   * The item at the end of the edge
   * @deprecated
   */
  node?: Maybe<Invoice>;
};

export type InvoiceHistory = {
  __typename: 'InvoiceHistory';
  /**
   * Invoice amounts
   * @deprecated
   */
  amount: InvoiceHistoryAmount;
  /**
   * Invoice id
   * @deprecated
   */
  id: Scalars['ID'];
  /**
   * Last transaction
   * @deprecated
   */
  lastTransaction: InvoiceTransaction;
  /**
   * Invoice payments
   * @deprecated
   */
  payment: InvoiceHistoryPayment;
  /**
   * List of transactions
   * @deprecated
   */
  transactions?: Maybe<Array<Maybe<InvoiceHistoryTransaction>>>;
  /**
   * User id
   * @deprecated
   */
  userId?: Maybe<Scalars['ID']>;
};

export type InvoiceHistoryAmount = {
  __typename: 'InvoiceHistoryAmount';
  /**
   * Current amount. The invoice amount can be updated when a scheduling occurs.
   * @deprecated
   */
  current: Scalars['Float'];
  /**
   * Original invoice amount
   * @deprecated
   */
  original: Scalars['Float'];
};

export type InvoiceHistoryPayment = {
  __typename: 'InvoiceHistoryPayment';
  /**
   * Incoming payment status
   * @deprecated
   */
  incoming: Scalars['Boolean'];
  /**
   * Outgoing payment status
   * @deprecated
   */
  outgoing: Scalars['Boolean'];
};

export type InvoiceHistoryTransaction = {
  __typename: 'InvoiceHistoryTransaction';
  /** @deprecated  */
  actor: Scalars['String'];
  /** @deprecated  */
  amount: Scalars['Float'];
  /** @deprecated  */
  details?: Maybe<Scalars['String']>;
  /** @deprecated  */
  event: Scalars['String'];
  /** @deprecated  */
  reference: Scalars['String'];
  /** @deprecated  */
  remitStatus?: Maybe<RemitStatus>;
  /** @deprecated  */
  remitStatusDescription?: Maybe<Scalars['String']>;
  /** @deprecated  */
  source: Scalars['String'];
  /** @deprecated  */
  status?: Maybe<TransactionStatus>;
  /** @deprecated  */
  time: Scalars['DateTime'];
};

export enum InvoiceKind {
  /** @deprecated  */
  Debt = 'debt',
  /** @deprecated  */
  Default = 'default',
  /** @deprecated  */
  Einvoice = 'einvoice'
}

export type InvoiceManualInfo = {
  __typename: 'InvoiceManualInfo';
  /** @deprecated  */
  amount: Scalars['Float'];
  /**
   * KID number (for Norway) or a simple note (even empty).
   * @deprecated
   */
  customerUid: Scalars['String'];
  /**
   * The type of the customer UID. Verification changes according to the type.
   * @deprecated
   */
  customerUidType: CustomerUidType;
  /**
   * Whitelisted issuer
   * @deprecated
   */
  whitelistedIssuer: IssuerWhitelistSearch;
};

export type InvoiceRandom = {
  __typename: 'InvoiceRandom';
  /**
   * Returns the success or failure of operation
   * @deprecated
   */
  isGenerated: Scalars['Boolean'];
};

export type InvoiceStatistics = {
  __typename: 'InvoiceStatistics';
  /** @deprecated  */
  paid?: Maybe<InvoiceStatisticsDetail>;
  /** @deprecated  */
  scheduled?: Maybe<InvoiceStatisticsDetail>;
  /** @deprecated  */
  unpaid?: Maybe<InvoiceStatisticsDetail>;
};

export type InvoiceStatisticsDetail = {
  __typename: 'InvoiceStatisticsDetail';
  /** @deprecated  */
  amount: Scalars['Float'];
  /** @deprecated  */
  count: Scalars['Int'];
};

export type InvoiceSupport = {
  __typename: 'InvoiceSupport';
  /**
   * When the support has been requested by user.
   * @deprecated
   */
  requestedAt: Scalars['DateTime'];
  /**
   * The type of support that user requested.
   * @deprecated
   */
  type?: Maybe<InvoiceSupportType>;
};

export enum InvoiceSupportType {
  /** @deprecated  */
  Installment = 'installment',
  /** @deprecated  */
  Opposition = 'opposition',
  /** @deprecated  */
  Postponement = 'postponement',
  /** @deprecated  */
  Undefined = 'undefined'
}

export type InvoiceTransaction = {
  __typename: 'InvoiceTransaction';
  /**
   * PaidAmount value can equal to the original invoice amount or be different in case of a custom amount set by user.
   * @deprecated
   */
  amount: Scalars['Float'];
  /** @deprecated  */
  cardId?: Maybe<Scalars['Int']>;
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /** @deprecated  */
  event?: Maybe<TransactionEvent>;
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  orderRef?: Maybe<Scalars['String']>;
  /** @deprecated  */
  paymentStatus: PaymentStatus;
  /** @deprecated  */
  remitStatus: RemitStatus;
  /** @deprecated  */
  status: TransactionStatus;
  /**
   * Status date adds a temporal information relating the status.
   * 				For example, if the status is scheduled, the statusDate value is the scheduled date.
   * @deprecated
   */
  statusDate?: Maybe<Scalars['DateTimeCustom']>;
  /** @deprecated  */
  transactionRef?: Maybe<Scalars['String']>;
  /** @deprecated  */
  zenFee?: Maybe<Scalars['Int']>;
  /** @deprecated  */
  zenReward?: Maybe<Scalars['Int']>;
};

export type InvoiceTransactionFee = {
  __typename: 'InvoiceTransactionFee';
  /**
   * Fee amount.
   * @deprecated
   */
  amount: Scalars['Float'];
  /**
   * Fee percentage.
   * @deprecated
   */
  percentage: Scalars['Float'];
  /**
   * Reason of the fee for the transaction.
   * @deprecated
   */
  reason: InvoiceTransactionFeeReason;
  /**
   * Invoice amount + fee
   * @deprecated
   */
  total: Scalars['Float'];
};

export type InvoiceTransactionFeeOverview = {
  __typename: 'InvoiceTransactionFeeOverview';
  /**
   * How many transactions with fees
   * @deprecated
   */
  count: Scalars['Int'];
  /**
   * Amount + Fee
   * @deprecated
   */
  total: Scalars['Float'];
  /**
   * Total amount
   * @deprecated
   */
  totalAmount: Scalars['Float'];
  /**
   * Total fee
   * @deprecated
   */
  totalFee: Scalars['Float'];
};

export enum InvoiceTransactionFeeReason {
  /** @deprecated  */
  Amex = 'amex',
  /** @deprecated  */
  Master = 'master',
  /** @deprecated  */
  Undefined = 'undefined',
  /** @deprecated  */
  Visa = 'visa'
}

export type InvoicesWithoutPagination = {
  __typename: 'InvoicesWithoutPagination';
  /** @deprecated  */
  count: Scalars['Int'];
  /**
   * When the support has been requested by user.
   * @deprecated
   */
  list: Array<Invoice>;
  /** @deprecated  */
  totalAmount: Scalars['Float'];
};

export type Issuer = {
  __typename: 'Issuer';
  /** @deprecated  */
  address?: Maybe<Address>;
  /** @deprecated  */
  brregForceUpdate: Scalars['Boolean'];
  /** @deprecated  */
  brregStatusDesc: Scalars['String'];
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /** @deprecated  */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated  */
  email?: Maybe<Scalars['String']>;
  /** @deprecated  */
  emailLoan?: Maybe<Scalars['String']>;
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  implicitSubMerchantMcc: Scalars['String'];
  /** @deprecated  */
  industryCode: Scalars['String'];
  /** @deprecated  */
  name: Scalars['String'];
  /** @deprecated  */
  orgNumber: Scalars['String'];
  /** @deprecated  */
  picture?: Maybe<Scalars['String']>;
  /** @deprecated  */
  realOrgNumber: Scalars['String'];
  /** @deprecated  */
  region?: Maybe<Scalars['String']>;
  /** @deprecated  */
  subMerchantMcc: Scalars['String'];
  /** @deprecated  */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** A connection to a list of items. */
export type IssuerConnection = {
  __typename: 'IssuerConnection';
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  edges?: Maybe<Array<Maybe<IssuerEdge>>>;
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  pageInfo: PageInfo;
};

export type IssuerCustomerUids = {
  __typename: 'IssuerCustomerUids';
  /** @deprecated  */
  customerUids: Array<Scalars['String']>;
};

/** An edge in a connection */
export type IssuerEdge = {
  __typename: 'IssuerEdge';
  /**
   *  cursor for use in pagination
   * @deprecated
   */
  cursor: Scalars['String'];
  /**
   * The item at the end of the edge
   * @deprecated
   */
  node?: Maybe<Issuer>;
};

export type IssuerIdentity = {
  __typename: 'IssuerIdentity';
  /** @deprecated  */
  address?: Maybe<Address>;
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  name: Scalars['String'];
  /** @deprecated  */
  orgNumber: Scalars['String'];
  /** @deprecated  */
  picture?: Maybe<Scalars['String']>;
};

export type IssuerIdentityMinimal = {
  __typename: 'IssuerIdentityMinimal';
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  name: Scalars['String'];
  /** @deprecated  */
  orgNumber: Scalars['String'];
  /** @deprecated  */
  picture?: Maybe<Scalars['String']>;
};

export type IssuerMixSearch = {
  __typename: 'IssuerMixSearch';
  /** @deprecated  */
  accountNumber?: Maybe<Scalars['String']>;
  /**
   * The ID of the whitelist. It is actually always defined and unique to guarantee the uniqueness of the dataset. It can be used as reference just when the property `whitelisted` is true.
   * @deprecated
   */
  id?: Maybe<Scalars['ID']>;
  /**
   * An nullable field ID cannot return a valid ID if we consider it in the schema type in Go as *uint64. Because of this limitation in GraphQL library, we have to return either 0 or a defined value. Null is never returned.
   * @deprecated
   */
  issuerId?: Maybe<Scalars['ID']>;
  /** @deprecated  */
  name: Scalars['String'];
  /** @deprecated  */
  orgNumber: Scalars['String'];
  /** @deprecated  */
  picture?: Maybe<Scalars['String']>;
  /** @deprecated  */
  region: Scalars['String'];
  /** @deprecated  */
  whitelisted: Scalars['Boolean'];
};

export type IssuerSearch = {
  __typename: 'IssuerSearch';
  /** @deprecated  */
  results?: Maybe<Array<Maybe<IssuerSearchResult>>>;
};

export type IssuerSearchResult = {
  __typename: 'IssuerSearchResult';
  /** @deprecated  */
  name: Scalars['String'];
  /** @deprecated  */
  orgNumber: Scalars['String'];
  /** @deprecated  */
  region: Scalars['String'];
};

export type IssuerTransactionRule = {
  __typename: 'IssuerTransactionRule';
  /** @deprecated  */
  allowAmexCard: Scalars['Boolean'];
  /** @deprecated  */
  allowCreditCard: Scalars['Boolean'];
  /** @deprecated  */
  allowDebitCard: Scalars['Boolean'];
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /** @deprecated  */
  isCustom: Scalars['Boolean'];
  /** @deprecated  */
  issuerId: Scalars['ID'];
  /** @deprecated  */
  maxAmountAmexCard?: Maybe<Scalars['Int']>;
  /** @deprecated  */
  maxAmountCreditCard?: Maybe<Scalars['Int']>;
  /** @deprecated  */
  maxAmountDebitCard?: Maybe<Scalars['Int']>;
  /** @deprecated  */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IssuerTransactionalStatistics = {
  __typename: 'IssuerTransactionalStatistics';
  /** @deprecated  */
  average: Scalars['Float'];
  /** @deprecated  */
  highestTransaction: IssuerTransactionalStatisticsItem;
  /** @deprecated  */
  lowestTransaction: IssuerTransactionalStatisticsItem;
  /** @deprecated  */
  total: Scalars['Float'];
  /** @deprecated  */
  transactions: Array<Maybe<IssuerTransactionalStatisticsItem>>;
  /** @deprecated  */
  trendPercentage: Scalars['Float'];
};

export type IssuerTransactionalStatisticsItem = {
  __typename: 'IssuerTransactionalStatisticsItem';
  /** @deprecated  */
  amount: Scalars['Float'];
  /** @deprecated  */
  date: Scalars['DateTime'];
};

export type IssuerWhitelist = {
  __typename: 'IssuerWhitelist';
  /** @deprecated  */
  accountNumber: Scalars['String'];
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  issuerId: Scalars['ID'];
  /** @deprecated  */
  origin: IssuerWhitelistOrigin;
};

export enum IssuerWhitelistOrigin {
  /** @deprecated  */
  App = 'app',
  /** @deprecated  */
  Efaktura = 'efaktura',
  /** @deprecated  */
  Portal = 'portal'
}

export type IssuerWhitelistSearch = {
  __typename: 'IssuerWhitelistSearch';
  /** @deprecated  */
  accountNumber: Scalars['String'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  issuerId: Scalars['ID'];
  /** @deprecated  */
  name: Scalars['String'];
  /** @deprecated  */
  orgForm?: Maybe<Scalars['String']>;
  /** @deprecated  */
  orgNumber: Scalars['String'];
  /** @deprecated  */
  picture?: Maybe<Scalars['String']>;
  /** @deprecated  */
  region: Scalars['String'];
};

export type Job = {
  __typename: 'Job';
  /**
   * Job description
   * @deprecated
   */
  description: Scalars['String'];
  /**
   * Job name
   * @deprecated
   */
  name: Scalars['String'];
  /**
   * Cron format
   * @deprecated
   */
  when: Array<Scalars['String']>;
};

export type Language = {
  __typename: 'Language';
  /**
   * Language code
   * @deprecated
   */
  code: Scalars['String'];
  /**
   * Language ID
   * @deprecated
   */
  id: Scalars['ID'];
  /**
   * Language name
   * @deprecated
   */
  name: Scalars['String'];
};

/** ISO 639-1 language format https://www.loc.gov/standards/iso639-2/php/code_list.php */
export enum LanguageCodeEnum {
  /**
   * English
   * @deprecated
   */
  En = 'en',
  /**
   * Norwegian
   * @deprecated
   */
  No = 'no',
  /**
   * Swedish
   * @deprecated
   */
  Sv = 'sv'
}

export enum LoanType {
  /** @deprecated  */
  ChargeCard = 'chargeCard',
  /** @deprecated  */
  CreditFacility = 'creditFacility',
  /** @deprecated  */
  RepaymentLoan = 'repaymentLoan',
  /** @deprecated  */
  Unknown = 'unknown'
}

export enum LogAccessMode {
  /** @deprecated  */
  LoginViaBankIdSession = 'loginViaBankIdSession',
  /** @deprecated  */
  LoginViaBankIdSignature = 'loginViaBankIdSignature',
  /** @deprecated  */
  LoginViaBiometrics = 'loginViaBiometrics',
  /** @deprecated  */
  LoginViaPin = 'loginViaPin',
  /** @deprecated  */
  Logout = 'logout'
}

export type LogDetails = {
  __typename: 'LogDetails';
  /** @deprecated  */
  context: Scalars['String'];
  /** @deprecated  */
  stackTrace: Scalars['String'];
};

export type LogWithReference = {
  __typename: 'LogWithReference';
  /** @deprecated  */
  cardId?: Maybe<Scalars['ID']>;
  /** @deprecated  */
  component: Scalars['String'];
  /** @deprecated  */
  details: LogDetails;
  /** @deprecated  */
  error: Scalars['String'];
  /** @deprecated  */
  event: Scalars['String'];
  /** @deprecated  */
  invoiceId?: Maybe<Scalars['ID']>;
  /** @deprecated  */
  issuerId?: Maybe<Scalars['ID']>;
  /** @deprecated  */
  status: Scalars['String'];
  /** @deprecated  */
  time: Scalars['DateTime'];
  /** @deprecated  */
  userId?: Maybe<Scalars['ID']>;
};

export type ManualInvoiceFeatureAvailable = {
  __typename: 'ManualInvoiceFeatureAvailable';
  /**
   * Is manual invoice allowed.
   * @deprecated
   */
  isAllowed: Scalars['Boolean'];
  /**
   * Days left of trial period.
   * @deprecated
   */
  remainingTrialPeriodInDays?: Maybe<Scalars['Int']>;
};

export type NewsletterEmails = {
  __typename: 'NewsletterEmails';
  /** @deprecated  */
  emails: Array<Scalars['String']>;
};

export type OidcAuthResponse = {
  __typename: 'OIDCAuthResponse';
  /** @deprecated  */
  callback: Scalars['String'];
  /**
   * URL for client redirection for authentication purpose.
   * @deprecated
   */
  url: Scalars['String'];
};

export type OidcTokenResponse = {
  __typename: 'OIDCTokenResponse';
  /**
   * Token returned from OIDC using authorization code.
   * @deprecated
   */
  idToken: Scalars['String'];
};

export type Offer = {
  __typename: 'Offer';
  /** @deprecated  */
  category: OfferCategory;
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /** @deprecated  */
  description: Scalars['String'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  name: Scalars['String'];
  /** @deprecated  */
  picture: Scalars['String'];
  /** @deprecated  */
  services?: Maybe<Array<Maybe<OfferService>>>;
  /** @deprecated  */
  status: OfferStatus;
  /** @deprecated  */
  updatedAt?: Maybe<Scalars['DateTimeCustom']>;
  /** @deprecated  */
  url?: Maybe<Scalars['String']>;
};

export type OfferCategory = {
  __typename: 'OfferCategory';
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /** @deprecated  */
  description: Scalars['String'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  name: Scalars['String'];
  /** @deprecated  */
  region: Scalars['String'];
  /** @deprecated  */
  updatedAt?: Maybe<Scalars['DateTimeCustom']>;
};

export type OfferCategoryNewPosition = {
  offerCategoryId: Scalars['ID'];
  /** Position that category must assume. Priority starts from 0 to 255. */
  position: Scalars['Int'];
};

export type OfferCategoryWithTranslations = {
  __typename: 'OfferCategoryWithTranslations';
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /** @deprecated  */
  descriptionEn: Scalars['String'];
  /** @deprecated  */
  descriptionNo: Scalars['String'];
  /** @deprecated  */
  descriptionSv: Scalars['String'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  nameEn: Scalars['String'];
  /** @deprecated  */
  nameNo: Scalars['String'];
  /** @deprecated  */
  nameSv: Scalars['String'];
  /** @deprecated  */
  position: Scalars['Int'];
  /** @deprecated  */
  region: Scalars['String'];
  /** @deprecated  */
  updatedAt?: Maybe<Scalars['DateTimeCustom']>;
};

export type OfferLead = {
  __typename: 'OfferLead';
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /** @deprecated  */
  event: OfferLeadEvent;
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  offerId: Scalars['ID'];
  /** @deprecated  */
  offerServiceId?: Maybe<Scalars['ID']>;
};

export enum OfferLeadEvent {
  /** @deprecated  */
  Click = 'click',
  /** @deprecated  */
  SubmitEmail = 'submitEmail',
  /** @deprecated  */
  View = 'view'
}

export type OfferLeadStatistics = {
  __typename: 'OfferLeadStatistics';
  /** @deprecated  */
  offers?: Maybe<Array<OfferLeadStatisticsOffer>>;
  /** @deprecated  */
  totalClickCount: Scalars['Int'];
  /** @deprecated  */
  totalEmailSubmitCount: Scalars['Int'];
  /** @deprecated  */
  totalViewCount: Scalars['Int'];
};

export type OfferLeadStatisticsOffer = {
  __typename: 'OfferLeadStatisticsOffer';
  /** @deprecated  */
  clickCount: Scalars['Int'];
  /** @deprecated  */
  emailSubmitCount: Scalars['Int'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  name: Scalars['String'];
  /** @deprecated  */
  services?: Maybe<Array<OfferLeadStatisticsService>>;
  /** @deprecated  */
  viewCount: Scalars['Int'];
};

export type OfferLeadStatisticsService = {
  __typename: 'OfferLeadStatisticsService';
  /** @deprecated  */
  clickCount: Scalars['Int'];
  /** @deprecated  */
  emailSubmitCount: Scalars['Int'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  name: Scalars['String'];
};

export type OfferService = {
  __typename: 'OfferService';
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /** @deprecated  */
  description: Scalars['String'];
  /** @deprecated  */
  email?: Maybe<Scalars['String']>;
  /** @deprecated  */
  emailDescription?: Maybe<Scalars['String']>;
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  name: Scalars['String'];
  /** @deprecated  */
  phone?: Maybe<Scalars['String']>;
  /** @deprecated  */
  updatedAt?: Maybe<Scalars['DateTimeCustom']>;
  /** @deprecated  */
  url?: Maybe<Scalars['String']>;
};

export type OfferServiceWithTranslations = {
  __typename: 'OfferServiceWithTranslations';
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /** @deprecated  */
  descriptionEn: Scalars['String'];
  /** @deprecated  */
  descriptionNo: Scalars['String'];
  /** @deprecated  */
  descriptionSv: Scalars['String'];
  /** @deprecated  */
  email?: Maybe<Scalars['String']>;
  /** @deprecated  */
  emailDescriptionEn?: Maybe<Scalars['String']>;
  /** @deprecated  */
  emailDescriptionNo?: Maybe<Scalars['String']>;
  /** @deprecated  */
  emailDescriptionSv?: Maybe<Scalars['String']>;
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  nameEn: Scalars['String'];
  /** @deprecated  */
  nameNo: Scalars['String'];
  /** @deprecated  */
  nameSv: Scalars['String'];
  /** @deprecated  */
  phone?: Maybe<Scalars['String']>;
  /** @deprecated  */
  region: Scalars['String'];
  /** @deprecated  */
  updatedAt?: Maybe<Scalars['DateTimeCustom']>;
  /** @deprecated  */
  url?: Maybe<Scalars['String']>;
};

export enum OfferStatus {
  /** @deprecated  */
  Active = 'active',
  /** @deprecated  */
  Inactive = 'inactive'
}

export type OfferWithTranslations = {
  __typename: 'OfferWithTranslations';
  /** @deprecated  */
  category: OfferCategoryWithTranslations;
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /** @deprecated  */
  descriptionEn: Scalars['String'];
  /** @deprecated  */
  descriptionNo: Scalars['String'];
  /** @deprecated  */
  descriptionSv: Scalars['String'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  name: Scalars['String'];
  /** @deprecated  */
  picture: Scalars['String'];
  /** @deprecated  */
  services?: Maybe<Array<OfferServiceWithTranslations>>;
  /** @deprecated  */
  status: OfferStatus;
  /** @deprecated  */
  updatedAt?: Maybe<Scalars['DateTimeCustom']>;
  /** @deprecated  */
  url?: Maybe<Scalars['String']>;
};

export type OffersGroupedByCategory = {
  __typename: 'OffersGroupedByCategory';
  /** @deprecated  */
  category: OfferCategory;
  /** @deprecated  */
  offers?: Maybe<Array<Offer>>;
};

export type Pep = {
  __typename: 'PEP';
  /** @deprecated  */
  status: PepStatus;
};

export enum PepDecision {
  /** @deprecated  */
  Accept = 'accept',
  /** @deprecated  */
  Reject = 'reject'
}

export enum PepStatus {
  /** @deprecated  */
  Accepted = 'accepted',
  /** @deprecated  */
  NotLiable = 'notLiable',
  /** @deprecated  */
  Pending = 'pending',
  /** @deprecated  */
  Rejected = 'rejected'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /**
   * When paginating forwards, the cursor to continue.
   * @deprecated
   */
  endCursor?: Maybe<Scalars['String']>;
  /**
   * When paginating forwards, are there more items?
   * @deprecated
   */
  hasNextPage: Scalars['Boolean'];
  /**
   * When paginating backwards, are there more items?
   * @deprecated
   */
  hasPreviousPage: Scalars['Boolean'];
  /**
   * When paginating backwards, the cursor to continue.
   * @deprecated
   */
  startCursor?: Maybe<Scalars['String']>;
};

export enum PaymentStatus {
  /** @deprecated  */
  Failure = 'failure',
  /** @deprecated  */
  Refunded = 'refunded',
  /** @deprecated  */
  Stopped = 'stopped',
  /** @deprecated  */
  Success = 'success',
  /** @deprecated  */
  Undefined = 'undefined'
}

export type Permission = {
  __typename: 'Permission';
  /**
   * It includes all the information about eFaktura
   * @deprecated
   */
  einvoice?: Maybe<EInvoice>;
  /**
   * It includes all the information about PEP
   * @deprecated
   */
  pep?: Maybe<Pep>;
};

export type Points = {
  __typename: 'Points';
  /** @deprecated  */
  Basic: Scalars['Int'];
  /** @deprecated  */
  Extra: Scalars['Int'];
  /** @deprecated  */
  Proposal: Scalars['Int'];
};

export type ProcessState = {
  __typename: 'ProcessState';
  /** @deprecated  */
  Name: Scalars['String'];
  /** @deprecated  */
  Value: Scalars['Int'];
};

export type Profile = {
  __typename: 'Profile';
  /** @deprecated  */
  address?: Maybe<Address>;
  /** @deprecated  */
  birthdate: Scalars['DateTime'];
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /** @deprecated  */
  email?: Maybe<Scalars['String']>;
  /** @deprecated  */
  emailCheck: Scalars['Boolean'];
  /** @deprecated  */
  firstname: Scalars['String'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  invitationCode: Scalars['String'];
  /** @deprecated  */
  lastname: Scalars['String'];
  /** @deprecated  */
  mobile?: Maybe<Scalars['String']>;
  /** @deprecated  */
  mobileCheck: Scalars['Boolean'];
  /** @deprecated  */
  region: Scalars['String'];
  /** @deprecated  */
  role?: Maybe<UserRoleEnum>;
  /** @deprecated  */
  sid: Scalars['String'];
  /**
   * It contains all the active subscriptions for the current user.
   * @deprecated
   */
  subscriptions?: Maybe<Array<ProfileSubscription>>;
};

export type ProfileAdmin = {
  __typename: 'ProfileAdmin';
  /** @deprecated  */
  firstname: Scalars['String'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  lastname: Scalars['String'];
  /** @deprecated  */
  subRole?: Maybe<UserSubRole>;
};

export type ProfileSubscription = {
  __typename: 'ProfileSubscription';
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  type: SubscriptionType;
};

export type PspTransactionList = {
  __typename: 'PspTransactionList';
  /**
   * List of transactions
   * @deprecated
   */
  transactions: Array<PspTransactionType>;
};

export type PspTransactionListAsExcel = {
  __typename: 'PspTransactionListAsExcel';
  /**
   * Excel data as base64
   * @deprecated
   */
  data: Scalars['String'];
};

export type PspTransactionLogListType = {
  __typename: 'PspTransactionLogListType';
  /**
   * List of logs
   * @deprecated
   */
  logs: Array<PspTransactionLogType>;
};

export type PspTransactionLogType = {
  __typename: 'PspTransactionLogType';
  /** @deprecated  */
  data: Scalars['String'];
  /** @deprecated  */
  event: Scalars['String'];
  /** @deprecated  */
  ipAddress: Scalars['String'];
  /** @deprecated  */
  time?: Maybe<Scalars['String']>;
  /** @deprecated  */
  url: Scalars['String'];
};

export type PspTransactionType = {
  __typename: 'PspTransactionType';
  /** @deprecated  */
  amount: Scalars['String'];
  /** @deprecated  */
  bkAction?: Maybe<Scalars['String']>;
  /** @deprecated  */
  bkInvoiceId?: Maybe<Scalars['Int']>;
  /** @deprecated  */
  bkInvoiceTransactionId?: Maybe<Scalars['Int']>;
  /** @deprecated  */
  bkProvider?: Maybe<Scalars['String']>;
  /** @deprecated  */
  bkStatus?: Maybe<Scalars['String']>;
  /** @deprecated  */
  bkType?: Maybe<Scalars['String']>;
  /** @deprecated  */
  bkUserId?: Maybe<Scalars['Int']>;
  /** @deprecated  */
  bkUserSubscriptionId?: Maybe<Scalars['Int']>;
  /** @deprecated  */
  circuit: CardCircuit;
  /** @deprecated  */
  currency: Scalars['String'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  issuerName: Scalars['String'];
  /** @deprecated  */
  localCreatedAt: Scalars['DateTime'];
  /** @deprecated  */
  paymentId: Scalars['String'];
  /** @deprecated  */
  paymentMethod: Scalars['String'];
  /**
   * Reconciliation ID
   * @deprecated
   */
  reconciliationId: Scalars['String'];
  /** @deprecated  */
  remoteCreatedAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated  */
  subSite: Scalars['String'];
  /** @deprecated  */
  subscriptionDuration?: Maybe<Scalars['Int']>;
  /** @deprecated  */
  transactionNumber?: Maybe<Scalars['String']>;
  /** @deprecated  */
  type: Scalars['String'];
  /** @deprecated  */
  userEmail: Scalars['String'];
  /** @deprecated  */
  userName: Scalars['String'];
  /** @deprecated  */
  userSubscriptionEndsAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated  */
  userSubscriptionStartsAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated  */
  vatAmount: Scalars['String'];
};

export type ReadDebtsResponse = {
  __typename: 'ReadDebtsResponse';
  /**
   * Summary about debts.
   * @deprecated
   */
  consent?: Maybe<DebtConsent>;
  /**
   * List of debts.
   * @deprecated
   */
  debts?: Maybe<Array<Maybe<Debt>>>;
};

export type ReadOwnUserStatistics = {
  __typename: 'ReadOwnUserStatistics';
  /** @deprecated  */
  paidInvoices: Scalars['Int'];
  /** @deprecated  */
  paidInvoicesWithinDueDate: Scalars['Int'];
  /** @deprecated  */
  totalSumOfInvoicesPaid: Scalars['Float'];
};

export type Reason = {
  __typename: 'Reason';
  /** @deprecated  */
  Name: Scalars['String'];
  /** @deprecated  */
  Value: Scalars['Int'];
};

export type RegionDebug = {
  __typename: 'RegionDebug';
  /** @deprecated  */
  debug: Scalars['Boolean'];
  /** @deprecated  */
  region: Scalars['String'];
};

export enum RegionEnum {
  /** @deprecated  */
  All = 'all',
  /** @deprecated  */
  No = 'no',
  /** @deprecated  */
  Notset = 'notset',
  /** @deprecated  */
  Sv = 'sv'
}

export enum RemitStatus {
  /** @deprecated  */
  Accepted = 'accepted',
  /** @deprecated  */
  Ignored = 'ignored',
  /** @deprecated  */
  New = 'new',
  /** @deprecated  */
  Processing = 'processing',
  /** @deprecated  */
  Refunded = 'refunded',
  /** @deprecated  */
  Rejected = 'rejected',
  /** @deprecated  */
  Stopped = 'stopped'
}

export type RemittanceInvoiceAlert = {
  __typename: 'RemittanceInvoiceAlert';
  /** @deprecated  */
  amount: Scalars['Float'];
  /** @deprecated  */
  archived: Scalars['Boolean'];
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /** @deprecated  */
  customerUId: Scalars['String'];
  /** @deprecated  */
  customerUIdType: CustomerUidType;
  /**
   * Invoice Transaction ID
   * @deprecated
   */
  id: Scalars['ID'];
  /** @deprecated  */
  invoiceId: Scalars['ID'];
  /** @deprecated  */
  pushNote?: Maybe<Scalars['String']>;
  /** @deprecated  */
  remitAttempt: Scalars['Int'];
  /** @deprecated  */
  remitBatchId?: Maybe<Scalars['String']>;
  /** @deprecated  */
  remitStatus: RemitStatus;
  /** @deprecated  */
  remitStatusDescription?: Maybe<Scalars['String']>;
  /** @deprecated  */
  supportNote?: Maybe<Scalars['String']>;
  /** @deprecated  */
  userEmail?: Maybe<Scalars['String']>;
  /** @deprecated  */
  userId: Scalars['ID'];
  /** @deprecated  */
  userName: Scalars['String'];
};

export type RoleType = {
  __typename: 'RoleType';
  /** @deprecated  */
  Name: Scalars['String'];
  /** @deprecated  */
  Value: Scalars['Int'];
};

export enum ScaMethod {
  /** @deprecated  */
  Biometrics = 'biometrics',
  /** @deprecated  */
  Pin = 'pin'
}

export type ScaOverview = {
  __typename: 'ScaOverview';
  /** @deprecated  */
  present: Scalars['Boolean'];
};

export enum ScaReason {
  /** @deprecated  */
  PaymentInvoice = 'paymentInvoice',
  /** @deprecated  */
  PaymentIssuer = 'paymentIssuer',
  /** @deprecated  */
  PaymentSubscription = 'paymentSubscription'
}

export type SessionRequest = {
  __typename: 'SessionRequest';
  /** @deprecated  */
  callbacks: CallbacksBankId;
  /** @deprecated  */
  id: Scalars['String'];
  /**
   * Redirection url where session has to be validated by access via BankId authentication
   * @deprecated
   */
  url: Scalars['String'];
};

export type StatisticsAccess = {
  __typename: 'StatisticsAccess';
  /** @deprecated  */
  histograms: StatisticsAccessHistograms;
  /** @deprecated  */
  lastUpdate?: Maybe<Scalars['DateTimeCustom']>;
};

export type StatisticsAccessHistograms = {
  __typename: 'StatisticsAccessHistograms';
  /** @deprecated  */
  access?: Maybe<Array<Maybe<HistogramAccess>>>;
};

export type StatisticsInvoices = {
  __typename: 'StatisticsInvoices';
  /** @deprecated  */
  histograms: StatisticsInvoicesHistograms;
  /** @deprecated  */
  lastUpdate?: Maybe<Scalars['DateTimeCustom']>;
  /** @deprecated  */
  overview: StatisticsInvoicesOverview;
};

export type StatisticsInvoicesHistograms = {
  __typename: 'StatisticsInvoicesHistograms';
  /** @deprecated  */
  cumulativeCount?: Maybe<Array<Maybe<HistogramInvoicesCountCumulative>>>;
  /** @deprecated  */
  paidAmount?: Maybe<Array<Maybe<HistogramInvoicesPaidAmount>>>;
};

export type StatisticsInvoicesOverview = {
  __typename: 'StatisticsInvoicesOverview';
  /** @deprecated  */
  totalAmount: Scalars['Float'];
  /** @deprecated  */
  totalCount: Scalars['Int'];
  /** @deprecated  */
  totalPaidCount: Scalars['Int'];
};

export type StatisticsIssuers = {
  __typename: 'StatisticsIssuers';
  /** @deprecated  */
  histograms: StatisticsIssuersHistograms;
  /** @deprecated  */
  lastUpdate?: Maybe<Scalars['DateTimeCustom']>;
  /** @deprecated  */
  overview: StatisticsIssuersOverview;
};

export type StatisticsIssuersHistograms = {
  __typename: 'StatisticsIssuersHistograms';
  /** @deprecated  */
  cumulativeRegistration?: Maybe<Array<Maybe<HistogramIssuersRegistrationCumulative>>>;
  /** @deprecated  */
  cumulativeRequests?: Maybe<Array<Maybe<HistogramIssuersRequestsCumulative>>>;
  /** @deprecated  */
  registration?: Maybe<Array<Maybe<HistogramIssuersRegistration>>>;
  /** @deprecated  */
  requests?: Maybe<Array<Maybe<HistogramIssuersRequests>>>;
};

export type StatisticsIssuersOverview = {
  __typename: 'StatisticsIssuersOverview';
  /** @deprecated  */
  totalCount: Scalars['Int'];
  /** @deprecated  */
  totalDebtRequests: Scalars['Int'];
  /** @deprecated  */
  totalInvoiceRequests: Scalars['Int'];
  /** @deprecated  */
  totalLogoCount: Scalars['Int'];
  /** @deprecated  */
  totalSupportCount: Scalars['Int'];
};

export type StatisticsOfPaidInvoices = {
  __typename: 'StatisticsOfPaidInvoices';
  /** @deprecated  */
  data?: Maybe<Array<Maybe<StatisticsOfPaidInvoicesItem>>>;
  /**
   * Total amount paid to the issuers
   * @deprecated
   */
  totalAmount: Scalars['Float'];
};

export type StatisticsOfPaidInvoicesItem = {
  __typename: 'StatisticsOfPaidInvoicesItem';
  /** @deprecated  */
  issuer: IssuerIdentityMinimal;
  /**
   * Total amount paid to the issuer
   * @deprecated
   */
  totalAmount: Scalars['Float'];
};

export type StatisticsSubscriptionInfo = {
  __typename: 'StatisticsSubscriptionInfo';
  /** @deprecated  */
  amount: Scalars['Float'];
  /** @deprecated  */
  id: Scalars['ID'];
  /**
   * Expressed in months.
   * @deprecated
   */
  period: Scalars['Int'];
  /** @deprecated  */
  type: SubscriptionType;
};

export type StatisticsSubscriptions = {
  __typename: 'StatisticsSubscriptions';
  /** @deprecated  */
  histograms: StatisticsSubscriptionsHistograms;
  /** @deprecated  */
  lastUpdate?: Maybe<Scalars['DateTimeCustom']>;
  /** @deprecated  */
  overview: StatisticsSubscriptionsOverview;
};

export type StatisticsSubscriptionsDistribution = {
  __typename: 'StatisticsSubscriptionsDistribution';
  /** @deprecated  */
  count: Scalars['Int'];
  /** @deprecated  */
  subscription: StatisticsSubscriptionInfo;
};

export type StatisticsSubscriptionsHistograms = {
  __typename: 'StatisticsSubscriptionsHistograms';
  /** @deprecated  */
  overview: Array<HistogramSubscriptionsOverview>;
};

export type StatisticsSubscriptionsOverview = {
  __typename: 'StatisticsSubscriptionsOverview';
  /** @deprecated  */
  activeCount: Scalars['Int'];
  /** @deprecated  */
  activeDistribution: Array<StatisticsSubscriptionsDistribution>;
  /** @deprecated  */
  totalCount: Scalars['Int'];
  /** @deprecated  */
  totalRefundedCount: Scalars['Int'];
};

export type StatisticsUsers = {
  __typename: 'StatisticsUsers';
  /** @deprecated  */
  histograms: StatisticsUsersHistograms;
  /** @deprecated  */
  lastUpdate?: Maybe<Scalars['DateTimeCustom']>;
  /** @deprecated  */
  overview: StatisticsUsersOverview;
};

export type StatisticsUsersHistograms = {
  __typename: 'StatisticsUsersHistograms';
  /** @deprecated  */
  cumulativeRegistration?: Maybe<Array<Maybe<HistogramUsersRegistration>>>;
  /** @deprecated  */
  registration?: Maybe<Array<Maybe<HistogramUsersRegistration>>>;
};

export type StatisticsUsersOverview = {
  __typename: 'StatisticsUsersOverview';
  /** @deprecated  */
  accessAverage: Scalars['Int'];
  /** @deprecated  */
  ageAverage: Scalars['Int'];
  /** @deprecated  */
  totalActiveCount: Scalars['Int'];
  /** @deprecated  */
  totalCount: Scalars['Int'];
  /** @deprecated  */
  totalDeletedCount: Scalars['Int'];
  /** @deprecated  */
  totalInactiveCount: Scalars['Int'];
};

export type Status = {
  __typename: 'Status';
  /** @deprecated  */
  Name: Scalars['String'];
  /** @deprecated  */
  Value: Scalars['Int'];
};

export type Subscription = {
  __typename: 'Subscription';
  /** @deprecated  */
  amount: Scalars['Float'];
  /**
   * The type of product used for validation of a coupon.
   * @deprecated
   */
  couponProduct: CouponProduct;
  /** @deprecated  */
  id: Scalars['ID'];
  /**
   * Expressed in months.
   * @deprecated
   */
  period: Scalars['Int'];
  /** @deprecated  */
  type: SubscriptionType;
  /**
   * Points that are granted at the first subscription.
   * @deprecated
   */
  welcomePoints: Scalars['Int'];
};

export enum SubscriptionAutoRenewal {
  /** @deprecated  */
  Off = 'off',
  /** @deprecated  */
  On = 'on'
}

/** A connection to a list of items. */
export type SubscriptionConnection = {
  __typename: 'SubscriptionConnection';
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  edges?: Maybe<Array<Maybe<SubscriptionEdge>>>;
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  pageInfo: PageInfo;
};

export type SubscriptionCouponSubtotal = {
  __typename: 'SubscriptionCouponSubtotal';
  /** @deprecated  */
  code: Scalars['String'];
  /** @deprecated  */
  count: Scalars['Int'];
};

/** An edge in a connection */
export type SubscriptionEdge = {
  __typename: 'SubscriptionEdge';
  /**
   *  cursor for use in pagination
   * @deprecated
   */
  cursor: Scalars['String'];
  /**
   * The item at the end of the edge
   * @deprecated
   */
  node?: Maybe<UserSubscription>;
};

export type SubscriptionEligibility = {
  __typename: 'SubscriptionEligibility';
  /** @deprecated  */
  canFreeTrial: Scalars['Boolean'];
};

export type SubscriptionIncomeInfo = {
  __typename: 'SubscriptionIncomeInfo';
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  name: Scalars['String'];
};

export type SubscriptionIncomeOverview = {
  __typename: 'SubscriptionIncomeOverview';
  /** @deprecated  */
  subtotals?: Maybe<Array<SubscriptionIncomeSubtotal>>;
  /** @deprecated  */
  subtotalsCoupons?: Maybe<Array<SubscriptionCouponSubtotal>>;
  /** @deprecated  */
  total: Scalars['Float'];
  /** @deprecated  */
  totalCoupon: Scalars['Float'];
  /**
   * Total amount (subscriptions) - Total coupon
   * @deprecated
   */
  totalEffective: Scalars['Float'];
};

export type SubscriptionIncomeSubtotal = {
  __typename: 'SubscriptionIncomeSubtotal';
  /** @deprecated  */
  amount: Scalars['Float'];
  /** @deprecated  */
  amountCoupon: Scalars['Float'];
  /** @deprecated  */
  count: Scalars['Int'];
  /** @deprecated  */
  countCoupon: Scalars['Int'];
  /** @deprecated  */
  subscription: SubscriptionIncomeInfo;
};

export enum SubscriptionRenewalStatus {
  /** @deprecated  */
  PaidAutomatically = 'paidAutomatically',
  /** @deprecated  */
  PaidManually = 'paidManually',
  /** @deprecated  */
  Rejected = 'rejected',
  /** @deprecated  */
  Waiting = 'waiting'
}

export enum SubscriptionReplaceMode {
  /** @deprecated  */
  KeepSubscription = 'keepSubscription',
  /** @deprecated  */
  RenewSubscription = 'renewSubscription'
}

export enum SubscriptionStatus {
  /** @deprecated  */
  Active = 'active',
  /** @deprecated  */
  Expired = 'expired',
  /** @deprecated  */
  ManualRenewal = 'manualRenewal',
  /** @deprecated  */
  Refunded = 'refunded',
  /** @deprecated  */
  Renewed = 'renewed'
}

export enum SubscriptionType {
  /** @deprecated  */
  Master = 'master'
}

export type SyncDebtsResponse = {
  __typename: 'SyncDebtsResponse';
  /**
   * True if debts were successfully synced.
   * @deprecated
   */
  synced: Scalars['Boolean'];
};

export type SystemFeature = {
  __typename: 'SystemFeature';
  /**
   * Enable app debug mode?
   * @deprecated
   */
  debugAppEnable: Scalars['Boolean'];
  /**
   * Do system support region signup at new user registration?
   * @deprecated
   */
  regionSignature: Scalars['Boolean'];
};

export type Token = {
  __typename: 'Token';
  /**
   * Use refresh token to get new access token when that is expired.
   * @deprecated
   */
  refreshToken: Scalars['String'];
  /** @deprecated  */
  token: Scalars['String'];
};

export enum TransactionEvent {
  /** @deprecated  */
  Cancellation = 'cancellation',
  /** @deprecated  */
  ChangeCard = 'changeCard',
  /** @deprecated  */
  Creation = 'creation',
  /** @deprecated  */
  Deletion = 'deletion',
  /** @deprecated  */
  PaymentEnd = 'paymentEnd',
  /** @deprecated  */
  PaymentStart = 'paymentStart',
  /** @deprecated  */
  PaymentStopped = 'paymentStopped',
  /** @deprecated  */
  RemittanceChange = 'remittanceChange',
  /** @deprecated  */
  RemittanceEnd = 'remittanceEnd',
  /** @deprecated  */
  RemittanceStart = 'remittanceStart',
  /** @deprecated  */
  Scheduling = 'scheduling',
  /** @deprecated  */
  SchedulingUpdate = 'schedulingUpdate',
  /** @deprecated  */
  Undefined = 'undefined'
}

export enum TransactionStatus {
  /** @deprecated  */
  Deleted = 'deleted',
  /** @deprecated  */
  Paid = 'paid',
  /** @deprecated  */
  Scheduled = 'scheduled',
  /** @deprecated  */
  Unpaid = 'unpaid'
}

export type Translation = {
  __typename: 'Translation';
  /**
   * Code ID
   * @deprecated
   */
  codeId: Scalars['ID'];
  /**
   * Translation ID
   * @deprecated
   */
  id: Scalars['ID'];
  /**
   * Language ID
   * @deprecated
   */
  languageId: Scalars['ID'];
  /**
   * Translation
   * @deprecated
   */
  translation: Scalars['String'];
};

export type TranslationCode = {
  __typename: 'TranslationCode';
  /**
   * Language code
   * @deprecated
   */
  code: Scalars['String'];
  /**
   * Language ID
   * @deprecated
   */
  id: Scalars['ID'];
};

/** A connection to a list of items. */
export type TranslationCodesConnection = {
  __typename: 'TranslationCodesConnection';
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  edges?: Maybe<Array<Maybe<TranslationCodesEdge>>>;
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  pageInfo: PageInfo;
};

/** An edge in a connection */
export type TranslationCodesEdge = {
  __typename: 'TranslationCodesEdge';
  /**
   *  cursor for use in pagination
   * @deprecated
   */
  cursor: Scalars['String'];
  /**
   * The item at the end of the edge
   * @deprecated
   */
  node?: Maybe<TranslationCode>;
};

export type Translations = {
  __typename: 'Translations';
  /** @deprecated  */
  code: TranslationCode;
  /** @deprecated  */
  translations: Array<TranslationsItem>;
};

export type TranslationsItem = {
  __typename: 'TranslationsItem';
  /** @deprecated  */
  language: Language;
  /** @deprecated  */
  translation: Translation;
};

export type User = {
  __typename: 'User';
  /** @deprecated  */
  address?: Maybe<Address>;
  /** @deprecated  */
  birthdate: Scalars['DateTime'];
  /** @deprecated  */
  createdAt: Scalars['DateTime'];
  /** @deprecated  */
  deletedAt?: Maybe<Scalars['DateTimeCustom']>;
  /** @deprecated  */
  email?: Maybe<Scalars['String']>;
  /** @deprecated  */
  emailCheck: Scalars['Boolean'];
  /** @deprecated  */
  firstname: Scalars['String'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  invitationCode: Scalars['String'];
  /** @deprecated  */
  lastname: Scalars['String'];
  /** @deprecated  */
  mobile?: Maybe<Scalars['String']>;
  /** @deprecated  */
  mobileCheck: Scalars['Boolean'];
  /** @deprecated  */
  permission: UserPermission;
  /** @deprecated  */
  region?: Maybe<Scalars['String']>;
  /** @deprecated  */
  role: UserRoleEnum;
  /** @deprecated  */
  sid: Scalars['String'];
  /** @deprecated  */
  status: UserStatusEnum;
  /** @deprecated  */
  updatedAt?: Maybe<Scalars['DateTimeCustom']>;
};

export enum UserActivityEnum {
  /** @deprecated  */
  Active = 'active',
  /** @deprecated  */
  Deleted = 'deleted',
  /** @deprecated  */
  Inactive = 'inactive',
  /**
   * Active + Inactive users
   * @deprecated
   */
  Present = 'present'
}

/** A connection to a list of items. */
export type UserConnection = {
  __typename: 'UserConnection';
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  pageInfo: PageInfo;
};

/** An edge in a connection */
export type UserEdge = {
  __typename: 'UserEdge';
  /**
   *  cursor for use in pagination
   * @deprecated
   */
  cursor: Scalars['String'];
  /**
   * The item at the end of the edge
   * @deprecated
   */
  node?: Maybe<User>;
};

export type UserGroup = {
  __typename: 'UserGroup';
  /** @deprecated  */
  description: Scalars['String'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  name: Scalars['String'];
  /** @deprecated  */
  region: RegionEnum;
};

export type UserGroupMember = {
  __typename: 'UserGroupMember';
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  name: Scalars['String'];
  /** @deprecated  */
  userId: Scalars['ID'];
};

export type UserIdentity = {
  __typename: 'UserIdentity';
  /** @deprecated  */
  address: Address;
  /** @deprecated  */
  firstname: Scalars['String'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  lastname: Scalars['String'];
  /** @deprecated  */
  sid: Scalars['String'];
};

export type UserNote = {
  __typename: 'UserNote';
  /** @deprecated  */
  content: Scalars['String'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  noteTime: Scalars['DateTime'];
};

/** A connection to a list of items. */
export type UserNoteConnection = {
  __typename: 'UserNoteConnection';
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  edges?: Maybe<Array<Maybe<UserNoteEdge>>>;
  /**
   * Information to aid in pagination.
   * @deprecated
   */
  pageInfo: PageInfo;
};

/** An edge in a connection */
export type UserNoteEdge = {
  __typename: 'UserNoteEdge';
  /**
   *  cursor for use in pagination
   * @deprecated
   */
  cursor: Scalars['String'];
  /**
   * The item at the end of the edge
   * @deprecated
   */
  node?: Maybe<UserNote>;
};

export type UserOwnZenTotalPoints = {
  __typename: 'UserOwnZenTotalPoints';
  /**
   * Sum of points available to be used.
   * @deprecated
   */
  available: Scalars['Int'];
  /**
   * Sum of basic points.
   * @deprecated
   */
  basic: Scalars['Int'];
  /**
   * The current belt level.
   * @deprecated
   */
  beltLevel: Scalars['Int'];
  /**
   * The current belt name.
   * @deprecated
   */
  beltName: Scalars['String'];
  /**
   * Sum of extra points.
   * @deprecated
   */
  extra: Scalars['Int'];
  /**
   * Sum of negative proposals not yet accounted for; fee on an invoice.
   * @deprecated
   */
  negativeProposal: Scalars['Int'];
  /**
   * Sum of positive proposals not yet accounted for; bonus and rewards i.e
   * @deprecated
   */
  positiveProposal: Scalars['Int'];
  /**
   * The user id.
   * @deprecated
   */
  userId: Scalars['ID'];
};

export type UserPermission = {
  __typename: 'UserPermission';
  /** @deprecated  */
  einvoice: UserPermissionEInvoice;
  /** @deprecated  */
  pep: UserPermissionPep;
};

export type UserPermissionEInvoice = {
  __typename: 'UserPermissionEInvoice';
  /** @deprecated  */
  acceptAllIssuers: Scalars['Boolean'];
};

export type UserPermissionPep = {
  __typename: 'UserPermissionPEP';
  /** @deprecated  */
  status: PepStatus;
};

export enum UserRoleEnum {
  /** @deprecated  */
  Admin = 'admin',
  /** @deprecated  */
  Issuer = 'issuer',
  /** @deprecated  */
  User = 'user'
}

export enum UserStatusEnum {
  /** @deprecated  */
  Blocked = 'blocked',
  /** @deprecated  */
  Disabled = 'disabled',
  /** @deprecated  */
  Enabled = 'enabled',
  /** @deprecated  */
  Test = 'test'
}

export enum UserSubRole {
  /** @deprecated  */
  AdminSupport = 'adminSupport',
  /** @deprecated  */
  AdminSupportWithDiscount = 'adminSupportWithDiscount'
}

export type UserSubscription = {
  __typename: 'UserSubscription';
  /** @deprecated  */
  autoRenewal: SubscriptionAutoRenewal;
  /** @deprecated  */
  coupon?: Maybe<Coupon>;
  /** @deprecated  */
  couponAmount: Scalars['Float'];
  /** @deprecated  */
  endsAt: Scalars['DateTime'];
  /** @deprecated  */
  grossAmount: Scalars['Float'];
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  isFreeTrial: Scalars['Boolean'];
  /** @deprecated  */
  isRefunded: Scalars['Boolean'];
  /** @deprecated  */
  netAmount: Scalars['Float'];
  /** @deprecated  */
  paymentCard: Card;
  /** @deprecated  */
  renewalCard: Card;
  /** @deprecated  */
  renewalStatus: SubscriptionRenewalStatus;
  /** @deprecated  */
  startsAt: Scalars['DateTime'];
  /** @deprecated  */
  status: SubscriptionStatus;
  /** @deprecated  */
  subscription: Subscription;
  /** @deprecated  */
  subscriptionAmount: Scalars['Float'];
  /** @deprecated  */
  transactionRef: Scalars['String'];
  /** @deprecated  */
  userId: Scalars['ID'];
  /** @deprecated  */
  vatAmount: Scalars['Float'];
  /** @deprecated  */
  vatPercent: Scalars['Float'];
};

export type UserZenPointsHistory = {
  __typename: 'UserZenPointsHistory';
  /** @deprecated  */
  Balance: Balance;
  /** @deprecated  */
  FriendId?: Maybe<Scalars['ID']>;
  /** @deprecated  */
  Id: Scalars['ID'];
  /** @deprecated  */
  InvitationCode?: Maybe<Scalars['String']>;
  /** @deprecated  */
  Invoice?: Maybe<Invoice>;
  /** @deprecated  */
  InvoiceIdentifier?: Maybe<Scalars['ID']>;
  /** @deprecated  */
  Memo?: Maybe<Scalars['String']>;
  /** @deprecated  */
  Points: Points;
  /** @deprecated  */
  ProcessDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated  */
  ProcessState: ProcessState;
  /** @deprecated  */
  Reason: Reason;
  /** @deprecated  */
  Role: RoleType;
  /** @deprecated  */
  Status: Status;
  /** @deprecated  */
  TS: Scalars['DateTime'];
  /** @deprecated  */
  Type: TypeType;
  /** @deprecated  */
  UpdatedAt: Scalars['DateTime'];
};

export type ZenCostType = {
  __typename: 'ZenCostType';
  /** @deprecated  */
  id: Scalars['ID'];
  /** @deprecated  */
  multiplier: Scalars['Int'];
  /** @deprecated  */
  static: Scalars['Int'];
  /** @deprecated  */
  ts: Scalars['DateTime'];
  /** @deprecated  */
  typ: Scalars['Int'];
  /** @deprecated  */
  typeName: Scalars['String'];
};

export type ZenExtrabalanceHistoryAsExcelType = {
  __typename: 'ZenExtrabalanceHistoryAsExcelType';
  /**
   * Excel data as base64
   * @deprecated
   */
  data?: Maybe<Scalars['String']>;
};

export type ZenPointExtType = {
  __typename: 'ZenPointExtType';
  /** @deprecated  */
  Amount?: Maybe<Scalars['Float']>;
  /** @deprecated  */
  CurrencyCode?: Maybe<Scalars['String']>;
  /** @deprecated  */
  FeeAmount?: Maybe<Scalars['Float']>;
  /** @deprecated  */
  InvoiceReference?: Maybe<Scalars['String']>;
  /** @deprecated  */
  IssuerId?: Maybe<Scalars['ID']>;
  /** @deprecated  */
  IssuerName?: Maybe<Scalars['String']>;
  /** @deprecated  */
  Reference?: Maybe<Scalars['String']>;
  /** @deprecated  */
  RemitDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated  */
  UserEmail: Scalars['String'];
  /** @deprecated  */
  UserName: Scalars['String'];
  /** @deprecated  */
  ZenFee?: Maybe<Scalars['Int']>;
  /** @deprecated  */
  ZenPoint: ZenPointType;
};

export type ZenPointType = {
  __typename: 'ZenPointType';
  /** @deprecated  */
  Balance: Balance;
  /** @deprecated  */
  BeltNo: Scalars['Int'];
  /** @deprecated  */
  CreatedAt: Scalars['DateTime'];
  /** @deprecated  */
  FriendId?: Maybe<Scalars['ID']>;
  /** @deprecated  */
  Id: Scalars['ID'];
  /** @deprecated  */
  InvitationCode?: Maybe<Scalars['String']>;
  /** @deprecated  */
  InviterId?: Maybe<Scalars['ID']>;
  /** @deprecated  */
  InvoiceId?: Maybe<Scalars['ID']>;
  /** @deprecated  */
  Memo?: Maybe<Scalars['String']>;
  /** @deprecated  */
  Points: Points;
  /** @deprecated  */
  ProcessDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated  */
  ProcessState: ProcessState;
  /** @deprecated  */
  Reason: Reason;
  /** @deprecated  */
  RefId?: Maybe<Scalars['ID']>;
  /** @deprecated  */
  Role: RoleType;
  /** @deprecated  */
  Status: Status;
  /** @deprecated  */
  Type: TypeType;
  /** @deprecated  */
  UpdatedAt: Scalars['DateTime'];
  /** @deprecated  */
  UserId: Scalars['ID'];
};

export type BillkillMutation = {
  __typename: 'billkillMutation';
  /**
   * Accept an invitation. Inviter and the invited person will be added mutually to each other's clan.
   * 				If the invited person is new in Bill Kill and there is a prior invitation (before the registration date
   * 				of the new user), then inviter gets the invitation points.
   * @deprecated
   */
  acceptInvitation?: Maybe<Array<Maybe<Invitation>>>;
  /**
   * Add a new favorite for this user. If the favorite already exist true is returned regardless.
   * @deprecated
   */
  addFavorite: Scalars['Boolean'];
  /**
   * Add a verified card created via `createForm` API using paymentId reference. If no active default card is present, the new card is automatically set as the default one.
   * @deprecated
   */
  addVerifiedCard?: Maybe<Card>;
  /**
   * Change subscription length and autoRenewal.
   * @deprecated
   */
  adminChangeSubscription: UserSubscription;
  /**
   * Update the position of offer categories.
   * @deprecated
   */
  arrangeOfferCategories?: Maybe<Array<OfferCategoryWithTranslations>>;
  /**
   * Send a request to the creditor in order to block debt spending.
   * @deprecated
   */
  blockDebtSpending?: Maybe<Debt>;
  /**
   * Block a device
   * @deprecated
   */
  blockDevice?: Maybe<Device>;
  /**
   * Block einvoices incoming from a specific issuer
   * @deprecated
   */
  blockEInvoicesFromIssuer?: Maybe<Permission>;
  /**
   * It safely cancels a payment that is scheduled and not yet executed and returns the cancelled invoice.
   * @deprecated
   */
  cancelPayment?: Maybe<Invoice>;
  /**
   * It allows to update the auto-renewal and change the card used for the renewal.
   * @deprecated
   */
  changeSubscription: UserSubscription;
  /**
   * It allows to copy basic issuer information (picture, emails) to a specific issuer.
   * @deprecated
   */
  copyIssuerInfo?: Maybe<Issuer>;
  /**
   * Create access after signature via BankID. User is registered (if new) or updated.
   * 					This operation acts like a register/login. The access is provided by a token of type user even if the user
   * 					has a different role (e.g. issuer/admin). This happens because this API is exclusively dedicated to the app login.
   * @deprecated
   */
  createAccess?: Maybe<Token>;
  /**
   * Create access from session. User must be already registered. This operation acts like a login.
   * 				The type of access token is provided by the role parameter that is checked against user role.
   * @deprecated
   */
  createAccessFromSession?: Maybe<Token>;
  /**
   * Create test access using different user roles skipping BankID authentication
   * @deprecated
   */
  createAccessTest?: Maybe<Token>;
  /**
   * Add bank account balance information for the calculations of balance forecasting.
   * @deprecated
   */
  createBalanceSnapshot?: Maybe<BooleanResult>;
  /**
   * Create document to sign via BankID
   * @deprecated
   */
  createBankIdDocument?: Maybe<DocumentRequest>;
  /**
   * Create BankId session
   * @deprecated SID replaces mobile now for the login.
   */
  createBankIdSession?: Maybe<SessionRequest>;
  /**
   * Create BankId session using SID (fødselsnummer)
   * @deprecated
   */
  createBankIdSessionV2: SessionRequest;
  /**
   * Create a URL for user redirection to an external form where user can add a credit card. Depending on the 'provider', various params may or may not be required.
   * @deprecated
   */
  createCardSession?: Maybe<CardForm>;
  /**
   * It creates a new coupon.
   * @deprecated
   */
  createCoupon: Coupon;
  /**
   * Create document to sign via BankID
   * @deprecated Use `createBankIdDocument` API where callbacks are not required. It has exactly the same resolver since arguments are not evaluated.
   */
  createDocument?: Maybe<DocumentRequest>;
  /**
   * Create an invoice manually.
   * @deprecated
   */
  createInvoice?: Maybe<Invoice>;
  /**
   * Add an account number to the whitelist of an issuer.
   * @deprecated
   */
  createIssuerWhitelist: IssuerWhitelist;
  /**
   * It allows to log accesses (e.g. login, logout) keeping reference of mode, device, and app.
   * @deprecated
   */
  createLogAccess: BooleanResult;
  /**
   * It returns an URL to perform an OAuth2 authentication.
   * @deprecated Use the new API `createOIDCAuthentication`. `redirectUri` cannot be changed by the client, so this parameter is removed since useless.
   */
  createOIDCAuth?: Maybe<OidcAuthResponse>;
  /**
   * It returns an URL to perform an OAuth2 authentication.
   * @deprecated Use the new API `createOIDCAuthentication`.
   */
  createOIDCAuthentication?: Maybe<OidcAuthResponse>;
  /**
   * It returns an ID token using an authorization code returned from OAuth2 authentication.
   * @deprecated
   */
  createOIDCToken?: Maybe<OidcTokenResponse>;
  /**
   * It returns an ID token with user info using a normal OIDC token.
   * @deprecated
   */
  createOIDCTokenWithInfo?: Maybe<OidcTokenResponse>;
  /**
   * Create an offer.
   * @deprecated
   */
  createOffer: OfferWithTranslations;
  /**
   * Create an offer category.
   * @deprecated
   */
  createOfferCategory: OfferCategoryWithTranslations;
  /**
   * Create an offer lead for analytics. It should be called every time an offer is viewed or a service is clicked.
   * @deprecated
   */
  createOfferLead: OfferLead;
  /**
   * Create an offer service.
   * @deprecated
   */
  createOfferService: OfferServiceWithTranslations;
  /**
   * Pay invoice with credit card. Payment is scheduled for the passed payment date.
   * 					A custom amount is also accepted. Possible fees can be added to that amount by backend.
   * 					To show the fees in advance to the user, please use the calculateInvoiceTransactionFee API.
   * 					To get the Zen Points Reward use the ForecastByInvoiceId API.
   * @deprecated
   */
  createPaymentV2: Invoice;
  /**
   * Generate multiple demo invoices.
   * @deprecated
   */
  createRandomTestInvoice?: Maybe<InvoiceRandom>;
  /**
   * Generate multiple demo unpaid invoices.
   * @deprecated
   */
  createRandomUnpaidTestInvoice?: Maybe<InvoiceRandom>;
  /**
   * It allows to authorize an issuer via SCA using different methods.
   * 			An authorized issuer can skip further authorization steps at the invoice-payment process.
   * @deprecated
   */
  createSCA: ScaOverview;
  /**
   * It creates a new subscription type. It must replace a current subscription.
   * @deprecated
   */
  createSubscription?: Maybe<Array<Subscription>>;
  /**
   * Create user group.
   * @deprecated
   */
  createUserGroup: UserGroup;
  /**
   * Create user group.
   * @deprecated
   */
  createUserGroupMember: UserGroupMember;
  /**
   * Create user note.
   * @deprecated
   */
  createUserNote: UserNote;
  /**
   * Decline an invitation. Inviter and the invited person will not be added mutually to each other's clan.
   * 				Possible pending points for invitation are deleted. Invitation is deleted.
   * @deprecated
   */
  declineInvitation?: Maybe<Array<Maybe<Invitation>>>;
  /**
   * It deletes a coupon.
   * @deprecated
   */
  deleteCoupon: Coupon;
  /**
   * Delete offer.
   * @deprecated
   */
  deleteOffer?: Maybe<Array<OfferWithTranslations>>;
  /**
   * Delete offer category and related offers and services.
   * @deprecated
   */
  deleteOfferCategory?: Maybe<Array<OfferCategoryWithTranslations>>;
  /**
   * Delete offer service.
   * @deprecated
   */
  deleteOfferService?: Maybe<Array<OfferServiceWithTranslations>>;
  /**
   * Delete code and related translations by code ID.
   * @deprecated
   */
  deleteTranslationsByCode: Array<TranslationCode>;
  /**
   * Delete user group.
   * @deprecated
   */
  deleteUserGroup: Array<UserGroup>;
  /**
   * Delete user-group member.
   * @deprecated
   */
  deleteUserGroupMember: Array<UserGroupMember>;
  /**
   * Delete user note.
   * @deprecated
   */
  deleteUserNote: UserNote;
  /**
   * Execute invoice payment
   * @deprecated
   */
  executeInvoicePayment?: Maybe<BooleanResult>;
  /**
   * Execute a remittance request per region (Norway by default)
   * @deprecated
   */
  executeRemittanceRequest?: Maybe<BooleanResult>;
  /**
   * Execute a remittance response control per region (Norway by default)
   * @deprecated
   */
  executeRemittanceResponse?: Maybe<BooleanResult>;
  /**
   * Gets an issuer whitelist or creates it if it doesn't exist.
   * @deprecated
   */
  getOrCreateIssuerWhitelist: IssuerWhitelistSearch;
  /**
   * Administration department must choose how to handle a PEP request accepting or rejecting it.
   * @deprecated
   */
  handlePEPRequest?: Maybe<Permission>;
  /**
   * It executes job `sanctionsUpdate` at the request time.
   * @deprecated
   */
  importSanctionsLists?: Maybe<BooleanResult>;
  /**
   * Invite friend using email and/or mobile contacts.
   * 					Returned invitations are order alphabetically by name.
   * @deprecated
   */
  inviteFriend?: Maybe<Array<Maybe<Invitation>>>;
  /**
   * Manually correct / add Zen points for a single user.
   * @deprecated
   */
  manualZenCorrection?: Maybe<BooleanResult>;
  /**
   * Ninja sign up. The invited and inviter is added basic points after 3 months.
   * @deprecated
   */
  ninjaSignUpByCode?: Maybe<BooleanResult>;
  /**
   * Create an invoice for debts that will immediately be scheduled for payment (today).
   * @deprecated
   */
  payDownDebt?: Maybe<Invoice>;
  /**
   * Get new tokens using a valid unexpired refresh token. Region is for profile switching.
   * @deprecated
   */
  refreshToken?: Maybe<Token>;
  /**
   * Updates the internal states to "refunded". Still need to manually refund through PayEx
   * @deprecated
   */
  refundInvoice?: Maybe<BooleanResult>;
  /**
   * It refunds a subscription in the first 14 days. Possible points are removed and the money
   * 			is returned to the customer.
   * @deprecated
   */
  refundSubscription: UserSubscription;
  /**
   * Delete an account removing all the unnecessary info (some data are temporarily kept for accounting).
   * @deprecated
   */
  removeAccount: Profile;
  /**
   * Remove balance snapshot by snapshot id.
   * @deprecated
   */
  removeBalanceSnapshot?: Maybe<BooleanResult>;
  /**
   * Remove card by id
   * @deprecated Use removeCardById which allows different types of deletion.
   */
  removeByCardId?: Maybe<Array<Maybe<Card>>>;
  /**
   * Remove card by ID setting a new default from the remaining active cards (the most recent is taken).
   * @deprecated
   */
  removeCardById?: Maybe<Array<Maybe<Card>>>;
  /**
   * Remove clan member from a clan. It affects both users for the respective clans.
   * @deprecated
   */
  removeClanMember?: Maybe<ClanOverview>;
  /**
   * Remove a favorite by issuerId for this user. If the favorite does not exist true is returned either way.
   * @deprecated
   */
  removeFavorite: Scalars['Boolean'];
  /**
   * Remove an invoice by id and returns the deleted invoice.
   * 					If the invoice is a normal invoice kind, it will be safely and softly deleted from db.
   * 					If the invoice is a einvoice kind, this means that the user doesn't want to pay it here (ignore einvoice).
   * @deprecated
   */
  removeInvoice?: Maybe<Invoice>;
  /**
   * Remove an account number of a issuer from the whitelist.
   * 				Deletion is allowed only for whitelists	added manually on the portal.
   * @deprecated
   */
  removeIssuerWhitelist: IssuerWhitelist;
  /**
   * Delete current account removing all the unnecessary info (some data are temporarily kept for accounting).
   * @deprecated
   */
  removeMyAccount?: Maybe<Profile>;
  /**
   * It pays a subscription manually. User can decide to retry the payment with the same card or
   * 				using a different card. The result of the payment is immediately returned. This API can be called on a
   * 				subscription that is rejected and having the auto-renewal set to ON.
   * 			
   * @deprecated
   */
  renewManually: UserSubscription;
  /**
   * Sends an email to the support requesting the insertion of an account number to the whitelist.
   * 				It also creates a issuer automatically with some basic info.
   * @deprecated Deprecated in favor of getOrCreateIssuerWhitelist
   */
  requestIssuerWhitelist: Issuer;
  /**
   * Restart the remittance for a stopped invoice creating a new transaction with remit status set to `new`.
   * @deprecated
   */
  restartRemittance?: Maybe<BooleanResult>;
  /**
   * Send verification to registered email to confirm identity
   * @deprecated
   */
  sendEmailCheck?: Maybe<Scalars['Boolean']>;
  /**
   * Send an email to the issuer asking for an invoice installment.
   * @deprecated Use v2 that returns the invoice object (cache-friendly).
   */
  sendEmailOfInstallment?: Maybe<BooleanResult>;
  /**
   * Send an email to the issuer asking for an invoice installment returning the invoice updated with support.
   * @deprecated
   */
  sendEmailOfInstallmentV2?: Maybe<Invoice>;
  /**
   * Send an email to the issuer asking for an invoice opposition.
   * @deprecated Use v2 that returns the invoice object (cache-friendly).
   */
  sendEmailOfOpposition?: Maybe<BooleanResult>;
  /**
   * Send an email to the issuer asking for an invoice opposition returning the invoice updated with support.
   * @deprecated
   */
  sendEmailOfOppositionV2?: Maybe<Invoice>;
  /**
   * Send an email to the issuer asking for an invoice postponement.
   * @deprecated Use v2 that returns the invoice object (cache-friendly).
   */
  sendEmailOfPostponement?: Maybe<BooleanResult>;
  /**
   * Send an email to the issuer asking for an invoice postponement returning the invoice updated with support.
   * @deprecated
   */
  sendEmailOfPostponementV2?: Maybe<Invoice>;
  /**
   * Send invoice payment receipt by email.
   * @deprecated
   */
  sendInvoiceReceipt?: Maybe<BooleanResult>;
  /**
   * Send an email to the email specified in the offer service.
   * @deprecated
   */
  sendOfferServiceEmail: OfferLead;
  /**
   * Send PEP form if the user is PEP-liable. An email will be sent to the support.
   * @deprecated
   */
  sendPEPForm?: Maybe<Permission>;
  /**
   * Send push message to user.
   * @deprecated
   */
  sendPushMessageToUser?: Maybe<BooleanResult>;
  /**
   * Update card by id as default
   * @deprecated
   */
  setCardAsDefault?: Maybe<Array<Maybe<Card>>>;
  /**
   * Update custom card name
   * @deprecated
   */
  setCardCustomName: Card;
  /**
   * Update or create the information about user device
   * @deprecated
   */
  setDevice?: Maybe<Device>;
  /**
   * Set invoice archived or not.
   * @deprecated
   */
  setInvoiceArchived?: Maybe<BooleanResult>;
  /**
   * Set custom note of invoice.
   * @deprecated
   */
  setInvoiceCustomNote?: Maybe<Invoice>;
  /**
   * It creates or updates the transaction-rule for a specific issuer.
   * @deprecated
   */
  setIssuerTransactionRule: IssuerTransactionRule;
  /**
   * Set merchant category code for an industry code.
   * @deprecated
   */
  setMerchantCategoryCode?: Maybe<IndustryCode>;
  /**
   * Set preference for einvoice permission about einvoice reception from all issuers
   * @deprecated
   */
  setPermissionEInvoice?: Maybe<Permission>;
  /**
   * Set points when the user invites a friend.
   * @deprecated Use inviteFriends API.
   */
  setPointsForInviteFriend?: Maybe<GameOverview>;
  /**
   * Set points when the user watches a video.
   * @deprecated
   */
  setPointsForWatchVideo?: Maybe<GameOverview>;
  /**
   * Set support note on invoice.
   * @deprecated
   */
  setSupportNote?: Maybe<BooleanResult>;
  /**
   * Create or update a translation.
   * @deprecated
   */
  setTranslation: Translation;
  /**
   * It starts a new subscription. Payment is immediately executed if the subscription is not free-trial eligible.
   * 				If the type of subscription is no longer available because replaced by a new subscription, the latest one is used.
   * @deprecated
   */
  startSubscription: UserSubscription;
  /**
   * Stop the remittance of an invoice creating a new transaction with remit status set to `stopped`.
   * @deprecated
   */
  stopRemittance?: Maybe<BooleanResult>;
  /**
   * Synchronize debts from the debt provider (Gjeldsregisteret).
   * @deprecated
   */
  syncDebts?: Maybe<SyncDebtsResponse>;
  /**
   * Send a request to the creditor in order to terminate the debt.
   * @deprecated
   */
  terminateDebt?: Maybe<Debt>;
  /**
   * It triggers the execution of the job `bankgirot`.
   * @deprecated
   */
  triggerBankgirotJob?: Maybe<BooleanResult>;
  /**
   * It triggers the execution of the `invoice-payment`.
   * @deprecated
   */
  triggerInvoicePaymentJob?: Maybe<BooleanResult>;
  /**
   * It triggers the execution of the `issuer-sync`.
   * @deprecated
   */
  triggerIssuerSyncJob?: Maybe<BooleanResult>;
  /**
   * It triggers the execution of the job `statistics`.
   * @deprecated
   */
  triggerStatisticsJob?: Maybe<BooleanResult>;
  /**
   * Unblock a device
   * @deprecated
   */
  unblockDevice?: Maybe<Device>;
  /**
   * Unblock einvoices incoming from a specific blocked issuer
   * @deprecated
   */
  unblockEInvoicesFromIssuer?: Maybe<Permission>;
  /**
   * Unpair the device from the user.
   * 					This operation is particularly useful when the device changes owner.
   * 					If the previous owner does not unset that device, the new owner is not able to use BillKill and
   * 					must contact the support or the previous owner.
   * 				
   * @deprecated
   */
  unsetDevice?: Maybe<BooleanResult>;
  /**
   * It removes the transaction-rule for a specific issuer.
   * @deprecated
   */
  unsetIssuerTransactionRule: IssuerTransactionRule;
  /**
   * It updates the time validity of a coupon.
   * @deprecated
   */
  updateCoupon: Coupon;
  /**
   * Update KID used for debt pay-down.
   * @deprecated
   */
  updateDebtCustomerUid?: Maybe<Debt>;
  /**
   * Update account number, KID and card (optional) used for debt paydown using a
   * 							suggested invoice. If cardid is provided it takes precedence over invoice card.
   * @deprecated
   */
  updateDebtPaymentInfo?: Maybe<Debt>;
  /**
   * Update issuer email for invoice support.
   * @deprecated
   */
  updateIssuerEmail?: Maybe<Issuer>;
  /**
   * Update realOrgNumber and subMerchantMcc.
   * @deprecated
   */
  updateIssuerIdentifiers: Issuer;
  /**
   * Update issuer email for loan support.
   * @deprecated
   */
  updateIssuerLoanEmail?: Maybe<Issuer>;
  /**
   * Update issuer picture uploading it for app use.
   * @deprecated
   */
  updateIssuerPicture?: Maybe<Issuer>;
  /**
   * Update an offer.
   * @deprecated
   */
  updateOffer?: Maybe<Array<OfferWithTranslations>>;
  /**
   * Update an offer category.
   * @deprecated
   */
  updateOfferCategory?: Maybe<Array<OfferCategoryWithTranslations>>;
  /**
   * Update an offer service.
   * @deprecated
   */
  updateOfferService?: Maybe<Array<OfferServiceWithTranslations>>;
  /**
   * Update an invoice payment with a payment date and a custom amount. If custom amount is not
   * 					provided, the original amount is used. Possible fees can be added to that amount by backend.
   * 					To show the fees in advance to the user, please use the calculateInvoiceTransactionFee API.
   * @deprecated
   */
  updatePayment?: Maybe<Invoice>;
  /**
   * Update profile email and/or mobile. If email is changed, a verification email will be sent.
   * @deprecated API has been split into two different APIs: updateProfileEmail and updateProfileMobile.
   */
  updateProfile?: Maybe<Profile>;
  /**
   * Update profile address.
   * @deprecated
   */
  updateProfileAddress: Profile;
  /**
   * Update profile email. If email changes, a verification email will be sent.
   * @deprecated
   */
  updateProfileEmail: Profile;
  /**
   * Update profile mobile. If mobile changes, a verification SMS will be sent.
   * @deprecated
   */
  updateProfileMobile: Profile;
  /**
   * It allows to update the customer UID of a invoice having remittance problems. For swedish bankgiro numbers use the isValidOCRWithIssuer before this.
   * @deprecated
   */
  updateRemittedInvoiceCustomerUId: Invoice;
  /**
   * Update user group.
   * @deprecated
   */
  updateUserGroup: UserGroup;
  /**
   * Update user note.
   * @deprecated
   */
  updateUserNote: UserNote;
  /**
   * Update user status.
   * @deprecated
   */
  updateUserStatus: User;
  /**
   * Update Zen Cost.
   * @deprecated
   */
  updateZenCost: ZenCostType;
  /**
   * Verify profile mobile with the 2FA code sent via SMS.
   * @deprecated
   */
  verifyProfileMobile: Profile;
};


export type BillkillMutationAcceptInvitationArgs = {
  invitationId: Scalars['ID'];
};


export type BillkillMutationAddFavoriteArgs = {
  invoiceIdentifier: Scalars['String'];
};


export type BillkillMutationAddVerifiedCardArgs = {
  paymentId: Scalars['String'];
  provider?: InputMaybe<Scalars['Int']>;
  sessionId?: InputMaybe<Scalars['String']>;
};


export type BillkillMutationAdminChangeSubscriptionArgs = {
  autoRenewal?: InputMaybe<Scalars['Boolean']>;
  endsAt?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
  userSubscriptionId: Scalars['ID'];
};


export type BillkillMutationArrangeOfferCategoriesArgs = {
  positions?: InputMaybe<Array<OfferCategoryNewPosition>>;
};


export type BillkillMutationBlockDebtSpendingArgs = {
  debtId: Scalars['ID'];
};


export type BillkillMutationBlockDeviceArgs = {
  deviceId: Scalars['ID'];
};


export type BillkillMutationBlockEInvoicesFromIssuerArgs = {
  orgNumber: Scalars['String'];
};


export type BillkillMutationCancelPaymentArgs = {
  invoiceId: Scalars['ID'];
};


export type BillkillMutationChangeSubscriptionArgs = {
  autoRenewal: SubscriptionAutoRenewal;
  renewalCardId: Scalars['ID'];
  userSubscriptionId: Scalars['ID'];
};


export type BillkillMutationCopyIssuerInfoArgs = {
  issuerIdDestination: Scalars['ID'];
  issuerIdSource: Scalars['ID'];
};


export type BillkillMutationCreateAccessArgs = {
  documentId: Scalars['String'];
};


export type BillkillMutationCreateAccessFromSessionArgs = {
  role: UserRoleEnum;
  sessionId: Scalars['String'];
};


export type BillkillMutationCreateAccessTestArgs = {
  region?: InputMaybe<Scalars['String']>;
  role: UserRoleEnum;
};


export type BillkillMutationCreateBalanceSnapshotArgs = {
  amount: Scalars['Float'];
  time: Scalars['DateTime'];
};


export type BillkillMutationCreateBankIdDocumentArgs = {
  os?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};


export type BillkillMutationCreateBankIdSessionArgs = {
  cbAbort?: InputMaybe<Scalars['String']>;
  cbFailure?: InputMaybe<Scalars['String']>;
  cbSuccess?: InputMaybe<Scalars['String']>;
  mobile: Scalars['String'];
};


export type BillkillMutationCreateBankIdSessionV2Args = {
  cbAbort?: InputMaybe<Scalars['String']>;
  cbFailure?: InputMaybe<Scalars['String']>;
  cbSuccess?: InputMaybe<Scalars['String']>;
  os?: InputMaybe<Scalars['String']>;
  sid: Scalars['String'];
};


export type BillkillMutationCreateCardSessionArgs = {
  cardHolderName?: InputMaybe<Scalars['String']>;
  cardNumber?: InputMaybe<Scalars['String']>;
  cvv?: InputMaybe<Scalars['String']>;
  debug?: InputMaybe<Scalars['String']>;
  expiryMonth?: InputMaybe<Scalars['String']>;
  expiryYear?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['Int']>;
};


export type BillkillMutationCreateCouponArgs = {
  amount: Scalars['Float'];
  code: Scalars['String'];
  descriptionId: Scalars['ID'];
  endsAt?: InputMaybe<Scalars['DateTime']>;
  period: Scalars['Int'];
  product: CouponProduct;
  recipient: CouponRecipient;
  recipientGroupId?: InputMaybe<Scalars['ID']>;
  region: Scalars['String'];
  rule?: InputMaybe<CouponRule>;
  startsAt: Scalars['DateTime'];
};


export type BillkillMutationCreateDocumentArgs = {
  callbackAbort: Scalars['String'];
  callbackFailure: Scalars['String'];
  callbackSuccess: Scalars['String'];
};


export type BillkillMutationCreateInvoiceArgs = {
  amount: Scalars['Float'];
  customNote?: InputMaybe<Scalars['String']>;
  customerUid: Scalars['String'];
  customerUidType?: InputMaybe<CustomerUidType>;
  dueDate: Scalars['DateTime'];
  whitelistId: Scalars['ID'];
};


export type BillkillMutationCreateIssuerWhitelistArgs = {
  accountNumber: Scalars['String'];
  issuerId: Scalars['ID'];
};


export type BillkillMutationCreateLogAccessArgs = {
  appVersion: Scalars['String'];
  deviceName: Scalars['String'];
  deviceOs: DeviceOs;
  deviceVersion: Scalars['String'];
  mode: LogAccessMode;
};


export type BillkillMutationCreateOidcAuthArgs = {
  redirectUri: Scalars['String'];
  state: Scalars['String'];
};


export type BillkillMutationCreateOidcAuthenticationArgs = {
  state: Scalars['String'];
};


export type BillkillMutationCreateOidcTokenArgs = {
  authCode: Scalars['String'];
};


export type BillkillMutationCreateOidcTokenWithInfoArgs = {
  accessToken: Scalars['String'];
};


export type BillkillMutationCreateOfferArgs = {
  categoryId: Scalars['ID'];
  descriptionEn: Scalars['String'];
  descriptionNo: Scalars['String'];
  descriptionSv: Scalars['String'];
  name: Scalars['String'];
  pictureBase64: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};


export type BillkillMutationCreateOfferCategoryArgs = {
  descriptionEn: Scalars['String'];
  descriptionNo: Scalars['String'];
  descriptionSv: Scalars['String'];
  nameEn: Scalars['String'];
  nameNo: Scalars['String'];
  nameSv: Scalars['String'];
  region: Scalars['String'];
};


export type BillkillMutationCreateOfferLeadArgs = {
  event: OfferLeadEvent;
  offerId: Scalars['ID'];
  offerServiceId?: InputMaybe<Scalars['ID']>;
};


export type BillkillMutationCreateOfferServiceArgs = {
  descriptionEn: Scalars['String'];
  descriptionNo: Scalars['String'];
  descriptionSv: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  emailDescriptionEn?: InputMaybe<Scalars['String']>;
  emailDescriptionNo?: InputMaybe<Scalars['String']>;
  emailDescriptionSv?: InputMaybe<Scalars['String']>;
  nameEn: Scalars['String'];
  nameNo: Scalars['String'];
  nameSv: Scalars['String'];
  offerId: Scalars['ID'];
  phone?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};


export type BillkillMutationCreatePaymentV2Args = {
  cardId: Scalars['ID'];
  customAmount?: InputMaybe<Scalars['Float']>;
  customNote?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['ID'];
  paymentDate: Scalars['DateTime'];
  zenFee?: InputMaybe<Scalars['Boolean']>;
};


export type BillkillMutationCreateRandomTestInvoiceArgs = {
  dueDate: Scalars['DateTime'];
  howManyInvoices: Scalars['Int'];
  issuerOrgNumber: Scalars['String'];
  status: TransactionStatus;
  userSid: Scalars['String'];
};


export type BillkillMutationCreateRandomUnpaidTestInvoiceArgs = {
  dueDate: Scalars['DateTime'];
  howManyInvoices: Scalars['Int'];
  issuerOrgNumber: Scalars['String'];
};


export type BillkillMutationCreateScaArgs = {
  issuerId?: InputMaybe<Scalars['ID']>;
  method: ScaMethod;
  reason: ScaReason;
  reference?: InputMaybe<Scalars['ID']>;
};


export type BillkillMutationCreateSubscriptionArgs = {
  amount: Scalars['Float'];
  replaceMode: SubscriptionReplaceMode;
  subscriptionIdToReplace: Scalars['ID'];
  welcomePoints: Scalars['Int'];
};


export type BillkillMutationCreateUserGroupArgs = {
  description: Scalars['String'];
  name: Scalars['String'];
  region: RegionEnum;
};


export type BillkillMutationCreateUserGroupMemberArgs = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type BillkillMutationCreateUserNoteArgs = {
  content: Scalars['String'];
  noteTime: Scalars['DateTime'];
  userId: Scalars['ID'];
};


export type BillkillMutationDeclineInvitationArgs = {
  invitationId: Scalars['ID'];
};


export type BillkillMutationDeleteCouponArgs = {
  couponId: Scalars['ID'];
};


export type BillkillMutationDeleteOfferArgs = {
  categoryId: Scalars['ID'];
  offerId: Scalars['ID'];
};


export type BillkillMutationDeleteOfferCategoryArgs = {
  offerCategoryId: Scalars['ID'];
};


export type BillkillMutationDeleteOfferServiceArgs = {
  offerId: Scalars['ID'];
  offerServiceId: Scalars['ID'];
};


export type BillkillMutationDeleteTranslationsByCodeArgs = {
  codeId: Scalars['ID'];
};


export type BillkillMutationDeleteUserGroupArgs = {
  groupId: Scalars['ID'];
};


export type BillkillMutationDeleteUserGroupMemberArgs = {
  groupId: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type BillkillMutationDeleteUserNoteArgs = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
};


export type BillkillMutationExecuteRemittanceRequestArgs = {
  region?: InputMaybe<Scalars['String']>;
};


export type BillkillMutationExecuteRemittanceResponseArgs = {
  region?: InputMaybe<Scalars['String']>;
};


export type BillkillMutationGetOrCreateIssuerWhitelistArgs = {
  accountNumber: Scalars['String'];
  name: Scalars['String'];
  orgNumber: Scalars['String'];
};


export type BillkillMutationHandlePepRequestArgs = {
  decision: PepDecision;
  userId: Scalars['ID'];
};


export type BillkillMutationInviteFriendArgs = {
  contact: InvitationContact;
};


export type BillkillMutationManualZenCorrectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  basic?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  extra?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  memo: Scalars['String'];
  userId: Scalars['ID'];
};


export type BillkillMutationNinjaSignUpByCodeArgs = {
  code?: InputMaybe<Scalars['String']>;
};


export type BillkillMutationPayDownDebtArgs = {
  amount: Scalars['Float'];
  cardId?: InputMaybe<Scalars['ID']>;
  debtId: Scalars['ID'];
};


export type BillkillMutationRefreshTokenArgs = {
  refreshToken: Scalars['String'];
  region?: InputMaybe<Scalars['String']>;
};


export type BillkillMutationRefundInvoiceArgs = {
  invoiceId: Scalars['ID'];
};


export type BillkillMutationRefundSubscriptionArgs = {
  amount: Scalars['String'];
  userId: Scalars['ID'];
  userSubscriptionId: Scalars['ID'];
};


export type BillkillMutationRemoveAccountArgs = {
  message?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};


export type BillkillMutationRemoveBalanceSnapshotArgs = {
  snapshotId: Scalars['ID'];
};


export type BillkillMutationRemoveByCardIdArgs = {
  cardId: Scalars['ID'];
};


export type BillkillMutationRemoveCardByIdArgs = {
  cardId: Scalars['ID'];
  deletionMode: CardDeletionMode;
};


export type BillkillMutationRemoveClanMemberArgs = {
  invitationId: Scalars['ID'];
  memberId: Scalars['ID'];
};


export type BillkillMutationRemoveFavoriteArgs = {
  issuerId: Scalars['Int'];
};


export type BillkillMutationRemoveInvoiceArgs = {
  invoiceId: Scalars['ID'];
};


export type BillkillMutationRemoveIssuerWhitelistArgs = {
  accountNumber: Scalars['String'];
  issuerId: Scalars['ID'];
};


export type BillkillMutationRemoveMyAccountArgs = {
  message?: InputMaybe<Scalars['String']>;
};


export type BillkillMutationRenewManuallyArgs = {
  renewalCardId: Scalars['ID'];
  userSubscriptionId: Scalars['ID'];
};


export type BillkillMutationRequestIssuerWhitelistArgs = {
  accountNumber: Scalars['String'];
  name: Scalars['String'];
  orgNumber: Scalars['String'];
};


export type BillkillMutationRestartRemittanceArgs = {
  invoiceId: Scalars['ID'];
};


export type BillkillMutationSendEmailOfInstallmentArgs = {
  invoiceId: Scalars['ID'];
};


export type BillkillMutationSendEmailOfInstallmentV2Args = {
  invoiceId: Scalars['ID'];
};


export type BillkillMutationSendEmailOfOppositionArgs = {
  invoiceId: Scalars['ID'];
  message: Scalars['String'];
};


export type BillkillMutationSendEmailOfOppositionV2Args = {
  invoiceId: Scalars['ID'];
  message: Scalars['String'];
};


export type BillkillMutationSendEmailOfPostponementArgs = {
  invoiceId: Scalars['ID'];
};


export type BillkillMutationSendEmailOfPostponementV2Args = {
  invoiceId: Scalars['ID'];
};


export type BillkillMutationSendInvoiceReceiptArgs = {
  invoiceId: Scalars['ID'];
};


export type BillkillMutationSendOfferServiceEmailArgs = {
  message?: InputMaybe<Scalars['String']>;
  offerServiceId: Scalars['ID'];
  reference?: InputMaybe<Scalars['String']>;
};


export type BillkillMutationSendPepFormArgs = {
  acquaintance: Array<InputMaybe<Scalars['Boolean']>>;
  coworker: Array<InputMaybe<Scalars['Boolean']>>;
  general: Array<InputMaybe<Scalars['Boolean']>>;
};


export type BillkillMutationSendPushMessageToUserArgs = {
  header: Scalars['String'];
  invoiceIdentifier: Scalars['ID'];
  message: Scalars['String'];
  userId: Scalars['ID'];
};


export type BillkillMutationSetCardAsDefaultArgs = {
  cardId: Scalars['ID'];
};


export type BillkillMutationSetCardCustomNameArgs = {
  cardId: Scalars['ID'];
  customName: Scalars['String'];
};


export type BillkillMutationSetDeviceArgs = {
  appVersion: Scalars['String'];
  deviceName: Scalars['String'];
  macAddress: Scalars['String'];
  pushId?: InputMaybe<Scalars['String']>;
  pushToken?: InputMaybe<Scalars['String']>;
};


export type BillkillMutationSetInvoiceArchivedArgs = {
  archived: Scalars['Boolean'];
  invoiceIdentifier: Scalars['ID'];
  userId: Scalars['ID'];
};


export type BillkillMutationSetInvoiceCustomNoteArgs = {
  customNote: Scalars['String'];
  invoiceId: Scalars['ID'];
};


export type BillkillMutationSetIssuerTransactionRuleArgs = {
  allowAmexCard: Scalars['Boolean'];
  allowCreditCard: Scalars['Boolean'];
  allowDebitCard: Scalars['Boolean'];
  issuerId: Scalars['ID'];
  maxAmountAmexCard?: InputMaybe<Scalars['Int']>;
  maxAmountCreditCard?: InputMaybe<Scalars['Int']>;
  maxAmountDebitCard?: InputMaybe<Scalars['Int']>;
};


export type BillkillMutationSetMerchantCategoryCodeArgs = {
  id: Scalars['ID'];
  merchantCategoryCode: Scalars['String'];
};


export type BillkillMutationSetPermissionEInvoiceArgs = {
  acceptAll: Scalars['Boolean'];
};


export type BillkillMutationSetPointsForInviteFriendArgs = {
  invitedEmail: Scalars['String'];
};


export type BillkillMutationSetPointsForWatchVideoArgs = {
  videoId: Scalars['String'];
};


export type BillkillMutationSetSupportNoteArgs = {
  invoiceIdentifier: Scalars['ID'];
  supportNote: Scalars['String'];
  userId: Scalars['ID'];
};


export type BillkillMutationSetTranslationArgs = {
  code: Scalars['String'];
  languageId: Scalars['ID'];
  translation: Scalars['String'];
};


export type BillkillMutationStartSubscriptionArgs = {
  cardId: Scalars['ID'];
  couponId?: InputMaybe<Scalars['ID']>;
  invitationCode?: InputMaybe<Scalars['String']>;
  subscriptionId: Scalars['ID'];
  withFreeTrial: Scalars['Boolean'];
};


export type BillkillMutationStopRemittanceArgs = {
  invoiceId: Scalars['ID'];
};


export type BillkillMutationTerminateDebtArgs = {
  debtId: Scalars['ID'];
};


export type BillkillMutationUnblockDeviceArgs = {
  deviceId: Scalars['ID'];
};


export type BillkillMutationUnblockEInvoicesFromIssuerArgs = {
  blockedIssuerId: Scalars['ID'];
  issuerId: Scalars['ID'];
};


export type BillkillMutationUnsetDeviceArgs = {
  deviceId: Scalars['ID'];
};


export type BillkillMutationUnsetIssuerTransactionRuleArgs = {
  issuerId: Scalars['ID'];
};


export type BillkillMutationUpdateCouponArgs = {
  couponId: Scalars['ID'];
  endsAt?: InputMaybe<Scalars['DateTime']>;
  noExpiration?: InputMaybe<Scalars['Boolean']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
};


export type BillkillMutationUpdateDebtCustomerUidArgs = {
  customerUid: Scalars['String'];
  debtId: Scalars['ID'];
};


export type BillkillMutationUpdateDebtPaymentInfoArgs = {
  cardId?: InputMaybe<Scalars['ID']>;
  debtId: Scalars['ID'];
  invoiceId: Scalars['ID'];
};


export type BillkillMutationUpdateIssuerEmailArgs = {
  email: Scalars['String'];
  issuerId: Scalars['ID'];
};


export type BillkillMutationUpdateIssuerIdentifiersArgs = {
  issuerId: Scalars['ID'];
  realOrgNumber: Scalars['String'];
  subMerchantMcc: Scalars['String'];
};


export type BillkillMutationUpdateIssuerLoanEmailArgs = {
  emailLoan: Scalars['String'];
  issuerId: Scalars['ID'];
};


export type BillkillMutationUpdateIssuerPictureArgs = {
  issuerId: Scalars['ID'];
  pictureBase64: Scalars['String'];
};


export type BillkillMutationUpdateOfferArgs = {
  categoryId: Scalars['ID'];
  descriptionEn: Scalars['String'];
  descriptionNo: Scalars['String'];
  descriptionSv: Scalars['String'];
  name: Scalars['String'];
  offerId: Scalars['ID'];
  pictureBase64?: InputMaybe<Scalars['String']>;
  status: OfferStatus;
  url?: InputMaybe<Scalars['String']>;
};


export type BillkillMutationUpdateOfferCategoryArgs = {
  descriptionEn: Scalars['String'];
  descriptionNo: Scalars['String'];
  descriptionSv: Scalars['String'];
  nameEn: Scalars['String'];
  nameNo: Scalars['String'];
  nameSv: Scalars['String'];
  offerCategoryId: Scalars['ID'];
};


export type BillkillMutationUpdateOfferServiceArgs = {
  descriptionEn: Scalars['String'];
  descriptionNo: Scalars['String'];
  descriptionSv: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  emailDescriptionEn?: InputMaybe<Scalars['String']>;
  emailDescriptionNo?: InputMaybe<Scalars['String']>;
  emailDescriptionSv?: InputMaybe<Scalars['String']>;
  nameEn: Scalars['String'];
  nameNo: Scalars['String'];
  nameSv: Scalars['String'];
  offerId: Scalars['ID'];
  offerServiceId: Scalars['ID'];
  phone?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};


export type BillkillMutationUpdatePaymentArgs = {
  cardId: Scalars['ID'];
  customAmount?: InputMaybe<Scalars['Float']>;
  invoiceId: Scalars['ID'];
  paymentDate: Scalars['DateTime'];
};


export type BillkillMutationUpdateProfileArgs = {
  email?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
};


export type BillkillMutationUpdateProfileAddressArgs = {
  city: Scalars['String'];
  countryCode: Scalars['String'];
  postalCode: Scalars['String'];
  street: Scalars['String'];
};


export type BillkillMutationUpdateProfileEmailArgs = {
  email: Scalars['String'];
};


export type BillkillMutationUpdateProfileMobileArgs = {
  forceVerification?: InputMaybe<Scalars['Boolean']>;
  mobile: Scalars['String'];
};


export type BillkillMutationUpdateRemittedInvoiceCustomerUIdArgs = {
  customerUId: Scalars['String'];
  customerUIdType: CustomerUidType;
  invoiceId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type BillkillMutationUpdateUserGroupArgs = {
  description: Scalars['String'];
  groupId: Scalars['ID'];
  name: Scalars['String'];
};


export type BillkillMutationUpdateUserNoteArgs = {
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  noteTime?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['ID'];
};


export type BillkillMutationUpdateUserStatusArgs = {
  status: UserStatusEnum;
  userId: Scalars['ID'];
};


export type BillkillMutationUpdateZenCostArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  multiplier?: InputMaybe<Scalars['Int']>;
  static?: InputMaybe<Scalars['Int']>;
  typeId: Scalars['Int'];
};


export type BillkillMutationVerifyProfileMobileArgs = {
  code: Scalars['String'];
};

export type BillkillQuery = {
  __typename: 'billkillQuery';
  /**
   * Get PSP transaction id by invoiceId.
   * @deprecated
   */
  GetPspTransactionIdByInvoiceId?: Maybe<Scalars['Int']>;
  /**
   * Admin get invoice by identifier and user id. Fixes the bug of readInvoiceById.
   * @deprecated
   */
  adminReadInvoiceByIdentifier?: Maybe<Invoice>;
  /**
   * It returns fee information according to the type of invoice payment the user wants to perform.
   * @deprecated
   */
  calculateInvoiceTransactionFee?: Maybe<InvoiceTransactionFee>;
  /**
   * get points calculation for the schedule invoice game.
   * @deprecated
   */
  calculatePointsForScheduleInvoice?: Maybe<Scalars['Int']>;
  /**
   * It returns a potential app incompatibility with the current backend APIs. This incompatibility normally triggers a blocking screen to the user.
   * @deprecated
   */
  checkAppIncompatibility?: Maybe<CheckAppIncompatibilityResponse>;
  /**
   * It returns the info about a valid and verified coupon using the related coupon code.
   * 				This API checks the validity of the recipients and the product returning the coupon ID which is used at the moment
   * 				of checkout for that specific product (e.g. startSubscription API for subscription products).
   * 			
   * @deprecated
   */
  checkCoupon: Coupon;
  /**
   * Check if the current device can be used from user
   * @deprecated
   */
  checkDevice?: Maybe<Device>;
  /**
   * Client App Features.
   * @deprecated
   */
  clientFeature: ClientFeature;
  /**
   * Forecast the Zen Extra Points reward on eInvoice or manual invoice payment.
   * @deprecated
   */
  forecast?: Maybe<Scalars['Int']>;
  /**
   * Forecast the Zen Points reward by invoice id (reference-referenceProvider-kind-issuerId).
   * @deprecated
   */
  forecastByInvoiceId?: Maybe<Scalars['Int']>;
  /**
   * Generate random card name based on Japanese swords
   * @deprecated
   */
  generateCardName: CardNameGeneration;
  /**
   * It returns info useful for manual-invoice creation.
   * @deprecated
   */
  getPaidInvoiceInfo: InvoiceManualInfo;
  /**
   * Checks if user is allowed to create manual invoices.
   * @deprecated
   */
  isManualInvoiceFeatureAvailable: ManualInvoiceFeatureAvailable;
  /**
   * List all favorites for given user in alphabetical order.
   * @deprecated
   */
  listFavorites?: Maybe<Array<Maybe<Favorite>>>;
  /**
   * Trigger a test push notification for the event onNewInvoice
   * @deprecated
   */
  onNewInvoiceTest?: Maybe<BooleanResult>;
  /**
   * Read info about an admin profile.
   * @deprecated
   */
  readAdminProfile: ProfileAdmin;
  /**
   * List of cards
   * @deprecated
   */
  readAllCards?: Maybe<Array<Maybe<Card>>>;
  /**
   * List of devices
   * @deprecated
   */
  readAllDevices?: Maybe<Array<Maybe<Device>>>;
  /**
   * List of industryCodes
   * @deprecated
   */
  readAllIndustryCodes?: Maybe<IndustryCodeConnection>;
  /**
   * get paginated list of invoices.
   * @deprecated
   */
  readAllInvoices?: Maybe<InvoiceConnection>;
  /**
   * List of issuers - regardless region
   * @deprecated
   */
  readAllIssuers?: Maybe<IssuerConnection>;
  /**
   * List of users.
   * @deprecated
   */
  readAllUsers?: Maybe<UserConnection>;
  /**
   * Read buffer forecasting from the last buffer record for the next 30 days
   * @deprecated
   */
  readBalanceForecasting?: Maybe<Array<Maybe<BufferForecasting>>>;
  /**
   * Read balance history from fromDate up to and including toDate.
   * @deprecated
   */
  readBalanceHistory?: Maybe<BalanceHistory>;
  /**
   * Download balance history as .xlsx from fromDate up to and including toDate.
   * @deprecated
   */
  readBalanceHistoryAsExcel?: Maybe<BalanceHistoryAsExcel>;
  /**
   * Read all balance snapshots from the newest to the oldest.
   * @deprecated
   */
  readBalanceSnapshots?: Maybe<BalanceSnapshotConnection>;
  /**
   * Read card by id
   * @deprecated
   */
  readCardById?: Maybe<Card>;
  /**
   * Read my clan fetching clan's members with points ordered by the highest to the lowest.
   * @deprecated
   */
  readClan?: Maybe<ClanOverview>;
  /**
   * It returns a coupon by its ID.
   * @deprecated
   */
  readCouponById: Coupon;
  /**
   * It returns a paginated list of coupons.
   * @deprecated
   */
  readCoupons?: Maybe<CouponConnection>;
  /**
   * It returns possible customer UIDs (KID) used to pay an invoice manually.
   * @deprecated
   */
  readCustomerUidsByIssuer: IssuerCustomerUids;
  /**
   * Read debt by identifier.
   * @deprecated
   */
  readDebtById?: Maybe<Debt>;
  /**
   * get invoices suggestions for debt down payment.
   * @deprecated
   */
  readDebtPaymentSuggestions?: Maybe<DebtPaymentSuggestions>;
  /**
   * Read the latest fetched debts.
   * @deprecated
   */
  readDebts?: Maybe<ReadDebtsResponse>;
  /**
   * Read default card
   * @deprecated
   */
  readDefaultCard?: Maybe<Card>;
  /**
   * List of checked emails of active users for newsletter.
   * @deprecated
   */
  readEmailsForNewsletter?: Maybe<NewsletterEmails>;
  /**
   * Read all sent invitations. Invitations are order alphabetically by name.
   * @deprecated Use readReceivedInvitations and readSentInvitations APIs.
   */
  readInvitations?: Maybe<Array<Maybe<Invitation>>>;
  /**
   * get invoice by id, but NOT another user except the one requesting it [Probably BUG]
   * @deprecated
   */
  readInvoiceById?: Maybe<Invoice>;
  /**
   * Read invoice history
   * @deprecated
   */
  readInvoiceHistory?: Maybe<InvoiceHistory>;
  /**
   * Read fees of invoice transactions for a specific period of time.
   * @deprecated
   */
  readInvoiceTransactionFees: InvoiceTransactionFeeOverview;
  /**
   * get invoice URL by id
   * @deprecated
   */
  readInvoiceUrlById?: Maybe<Scalars['String']>;
  /**
   * Returns a users invoice by url
   * @deprecated
   */
  readInvoiceUrlByUserId?: Maybe<Scalars['String']>;
  /**
   * Read invoices statistics
   * @deprecated
   */
  readInvoicesStatistics?: Maybe<InvoiceStatistics>;
  /**
   * Read issuer by id
   * @deprecated
   */
  readIssuerById?: Maybe<Issuer>;
  /**
   * Read issuer by org number
   * @deprecated
   */
  readIssuerByOrgNumber?: Maybe<Issuer>;
  /**
   * get statistics of a specific issuer about transactions.
   * @deprecated
   */
  readIssuerStatistics?: Maybe<IssuerTransactionalStatistics>;
  /**
   * Read issuer-transaction-rule by issuer id
   * @deprecated
   */
  readIssuerTransactionRule: IssuerTransactionRule;
  /**
   * Regardless region
   * @deprecated
   */
  readIssuerWhitelist: Array<IssuerWhitelist>;
  /**
   * Read jobs
   * @deprecated
   */
  readJobs?: Maybe<Array<Maybe<Job>>>;
  /**
   * Read languages
   * @deprecated
   */
  readLanguages: Array<Language>;
  /**
   * get the last successful fetch
   * @deprecated
   */
  readLastFetch?: Maybe<Fetch>;
  /**
   * Read last Pending Ninja Signup Invitation Code, if any.
   * @deprecated
   */
  readLastNinjaSignupCode?: Maybe<Scalars['String']>;
  /**
   * get the next date range for a possible einvoice discovery
   * @deprecated
   */
  readNextDiscovery?: Maybe<DateRange>;
  /**
   * Detail of an offer.
   * @deprecated
   */
  readOfferById: OfferWithTranslations;
  /**
   * List of categories for offers with translations.
   * @deprecated
   */
  readOfferCategories?: Maybe<Array<OfferCategoryWithTranslations>>;
  /**
   * List of categories for offers.
   * @deprecated
   */
  readOfferCategoriesTranslated?: Maybe<Array<OfferCategory>>;
  /**
   * Detail of an offer category.
   * @deprecated
   */
  readOfferCategoryById: OfferCategoryWithTranslations;
  /**
   * Statistics about the offer leads.
   * @deprecated
   */
  readOfferLeadsStatistics: OfferLeadStatistics;
  /**
   * Detail of an offer service.
   * @deprecated
   */
  readOfferServiceById: OfferServiceWithTranslations;
  /**
   * List of services for a specific offer.
   * @deprecated
   */
  readOfferServices?: Maybe<Array<OfferServiceWithTranslations>>;
  /**
   * List of all offers with translations.
   * @deprecated
   */
  readOffers?: Maybe<Array<OfferWithTranslations>>;
  /**
   * List of all active offers grouped by categories.
   * @deprecated
   */
  readOffersGroupedByCategories?: Maybe<Array<OffersGroupedByCategory>>;
  /**
   * List of active offers, one for each category.
   * @deprecated
   */
  readOffersTranslated?: Maybe<Array<Maybe<Offer>>>;
  /**
   * Read game overview (points).
   * @deprecated
   */
  readOverview?: Maybe<GameOverview>;
  /**
   * Reads your own statistics
   * @deprecated
   */
  readOwnUserStatistics?: Maybe<ReadOwnUserStatistics>;
  /**
   * Read users own Zen points history.
   * @deprecated
   */
  readOwnZenHistory: Array<UserZenPointsHistory>;
  /**
   * User reads his own total Zenpoints, available, core, extra including belt level.
   * @deprecated
   */
  readOwnZenTotalPoints?: Maybe<UserOwnZenTotalPoints>;
  /**
   * get paginated list of paid invoices.
   * @deprecated
   */
  readPaidInvoices?: Maybe<InvoiceConnection>;
  /**
   * List all the issuers that have been paid from the user ordered by payment volume; Region by user.
   * @deprecated
   */
  readPaidIssuers?: Maybe<IssuerConnection>;
  /**
   * Retrieve information about user permissions
   * @deprecated
   */
  readPermission?: Maybe<Permission>;
  /**
   * Read profile
   * @deprecated
   */
  readProfile?: Maybe<Profile>;
  /**
   * get PSP transaction by id.
   * @deprecated
   */
  readPspTransactionById?: Maybe<PspTransactionType>;
  /**
   * get PSP transaction logs by id.
   * @deprecated
   */
  readPspTransactionLogsById?: Maybe<PspTransactionLogListType>;
  /**
   * get PSP transactions by period.
   * @deprecated
   */
  readPspTransactions?: Maybe<PspTransactionList>;
  /**
   * Download PSP transcations as .xlsx
   * @deprecated
   */
  readPspTransactionsAsExcel?: Maybe<PspTransactionListAsExcel>;
  /**
   * Read all invitations received by other Bill Kill users.
   * @deprecated
   */
  readReceivedInvitations?: Maybe<Array<Maybe<InvitationItem>>>;
  /**
   * It returns a list of invoice that have been rejected/stopped from remittance because of problems.
   * @deprecated
   */
  readRejectedInvoicesFromRemittance: Array<RemittanceInvoiceAlert>;
  /**
   * Read issuer SCAs by id
   * @deprecated
   */
  readSCAs: ScaOverview;
  /**
   * get paginated list of scheduled invoices.
   * @deprecated
   */
  readScheduledInvoices?: Maybe<InvoiceConnection>;
  /**
   * get list of scheduled invoices.
   * @deprecated
   */
  readScheduledInvoicesWithoutPagination?: Maybe<InvoicesWithoutPagination>;
  /**
   * Read all invitations sent by me to other Bill Kill users.
   * @deprecated
   */
  readSentInvitations?: Maybe<Array<Maybe<InvitationItem>>>;
  /**
   * Read statistics about access
   * @deprecated
   */
  readStatisticsAccess: StatisticsAccess;
  /**
   * Read statistics about churn
   * @deprecated
   */
  readStatisticsChurn?: Maybe<Array<Maybe<ChurnStat>>>;
  /**
   * Read statistics about invoices
   * @deprecated
   */
  readStatisticsInvoices: StatisticsInvoices;
  /**
   * Read statistics about issuers
   * @deprecated
   */
  readStatisticsIssuers: StatisticsIssuers;
  /**
   * get aggregated information about paid invoices for the specified time sorted from the highest to the lowest total amount.
   * @deprecated
   */
  readStatisticsOfPaidInvoices?: Maybe<StatisticsOfPaidInvoices>;
  /**
   * Read statistics about subscriptions
   * @deprecated
   */
  readStatisticsSubscriptions: StatisticsSubscriptions;
  /**
   * Read statistics about users
   * @deprecated
   */
  readStatisticsUsers: StatisticsUsers;
  /**
   * It returns the user eligibility for a specific subscription type.
   * @deprecated
   */
  readSubscriptionEligibility: SubscriptionEligibility;
  /**
   * It returns an overview about the income coming from subscriptions.
   * @deprecated
   */
  readSubscriptionIncomeOverview: SubscriptionIncomeOverview;
  /**
   * It returns the different subscription offers for a specific subscription type.
   * @deprecated
   */
  readSubscriptions?: Maybe<Array<Subscription>>;
  /**
   * Admin requests the system extra balance for a given date.
   * @deprecated
   */
  readSystemZenExtraBalance: Scalars['Int'];
  /**
   * Read translation by code and language.
   * @deprecated
   */
  readTranslation: Translation;
  /**
   * Read translation codes
   * @deprecated
   */
  readTranslationCodes?: Maybe<TranslationCodesConnection>;
  /**
   * Read multi-language translations by code.
   * @deprecated
   */
  readTranslations: Translations;
  /**
   * get paginated list of unpaid invoices.
   * @deprecated
   */
  readUnpaidInvoices?: Maybe<InvoiceConnection>;
  /**
   * get list of unpaid invoices.
   * @deprecated
   */
  readUnpaidInvoicesWithoutPagination?: Maybe<InvoicesWithoutPagination>;
  /**
   * Read user activities using logs.
   * @deprecated
   */
  readUserActivities: Array<LogWithReference>;
  /**
   * Read user by id.
   * @deprecated
   */
  readUserById?: Maybe<User>;
  /**
   * Read user by sid.
   * @deprecated
   */
  readUserBySid?: Maybe<User>;
  /**
   * Read user-group members.
   * @deprecated
   */
  readUserGroupMembers: Array<UserGroupMember>;
  /**
   * Read user groups.
   * @deprecated
   */
  readUserGroups: Array<UserGroup>;
  /**
   * Read user notes.
   * @deprecated
   */
  readUserNotes?: Maybe<UserNoteConnection>;
  /**
   * It returns the user-subscription by id.
   * @deprecated
   */
  readUserSubscriptionById: UserSubscription;
  /**
   * It returns the user-subscriptions of a user.
   * @deprecated
   */
  readUserSubscriptions?: Maybe<SubscriptionConnection>;
  /**
   * It returns the user-subscriptions of a user.
   * @deprecated
   */
  readUserSubscriptionsByUserId?: Maybe<SubscriptionConnection>;
  /**
   * Admin reading a users zen points history.
   * @deprecated
   */
  readUserZenHistory: Array<UserZenPointsHistory>;
  /**
   * Admin reading a users total Zenpoints, available, core and extra.
   * @deprecated
   */
  readUserZenTotalPoints?: Maybe<UserOwnZenTotalPoints>;
  /**
   * Read zen extra balance history [fromDate-to-toDate] inclusive.
   * @deprecated
   */
  readZenExtraBalance: BalanceZenHistoryType;
  /**
   * Read zen extra balance history as .xlsx [fromDate-to-toDate] inclusive.
   * @deprecated
   */
  readZenExtraBalanceAsExcel?: Maybe<ZenExtrabalanceHistoryAsExcelType>;
  /**
   * Get the region for sid and if debug enabled. Used before login so no userid.
   * @deprecated
   */
  region?: Maybe<RegionDebug>;
  /**
   * Used by App, not portal. Read issuer by name using brreg for Norway and internal for Sweden; region is users.
   * @deprecated
   */
  searchIssuerByName?: Maybe<IssuerSearch>;
  /**
   * Use by App, not portal. Read issuer by organisation number using external providers. Region is users.
   * @deprecated
   */
  searchIssuerByOrgNumber?: Maybe<IssuerSearch>;
  /**
   * Search issuers by name,account number or org number both in whitelist and external providers. Region is users.
   * @deprecated
   */
  searchIssuers: Array<IssuerMixSearch>;
  /**
   * Search issuer by name/account number in whitelist. Region is users.
   * @deprecated
   */
  searchWhitelistedIssuer: Array<IssuerWhitelistSearch>;
  /**
   * Public system feature support to client frontside.
   * @deprecated
   */
  systemFeature: SystemFeature;
  /**
   * List of Zen Costs.
   * @deprecated
   */
  zenCostList: Array<ZenCostType>;
};


export type BillkillQueryGetPspTransactionIdByInvoiceIdArgs = {
  invoiceId: Scalars['String'];
};


export type BillkillQueryAdminReadInvoiceByIdentifierArgs = {
  invoiceId: Scalars['ID'];
};


export type BillkillQueryCalculateInvoiceTransactionFeeArgs = {
  amount: Scalars['Float'];
  cardId: Scalars['ID'];
};


export type BillkillQueryCalculatePointsForScheduleInvoiceArgs = {
  invoiceId: Scalars['ID'];
};


export type BillkillQueryCheckAppIncompatibilityArgs = {
  language: LanguageCodeEnum;
  os: DeviceOs;
  version: Scalars['String'];
};


export type BillkillQueryCheckCouponArgs = {
  code: Scalars['String'];
  product: CouponProduct;
};


export type BillkillQueryCheckDeviceArgs = {
  macAddress: Scalars['String'];
};


export type BillkillQueryForecastArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  due?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  isManual?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  received?: InputMaybe<Scalars['DateTime']>;
  scheduled?: InputMaybe<Scalars['DateTime']>;
};


export type BillkillQueryForecastByInvoiceIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  invoiceId: Scalars['ID'];
  last?: InputMaybe<Scalars['Int']>;
};


export type BillkillQueryGetPaidInvoiceInfoArgs = {
  paidInvoiceId: Scalars['ID'];
};


export type BillkillQueryReadAllIndustryCodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterByNoMcc?: InputMaybe<Scalars['Boolean']>;
  filterByRegion?: InputMaybe<RegionEnum>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  merchantCategoryCode?: InputMaybe<Scalars['String']>;
};


export type BillkillQueryReadAllInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dateContext?: InputMaybe<InvoiceDateContext>;
  dateContextSort?: InputMaybe<InvoiceDateContextSort>;
  first?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  invoiceId?: InputMaybe<Scalars['ID']>;
  issuerId?: InputMaybe<Scalars['ID']>;
  kind?: InputMaybe<InvoiceKind>;
  last?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<RegionEnum>;
  searchingTerm?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TransactionStatus>;
  toDate?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type BillkillQueryReadAllIssuersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterByAllowingAmex?: InputMaybe<Scalars['Boolean']>;
  filterByAllowingCreditCards?: InputMaybe<Scalars['Boolean']>;
  filterByExistingAccount?: InputMaybe<Scalars['Boolean']>;
  filterByName?: InputMaybe<Scalars['String']>;
  filterByNoCreditorEmailLoan?: InputMaybe<Scalars['Boolean']>;
  filterByNoEmail?: InputMaybe<Scalars['Boolean']>;
  filterByNoMcc?: InputMaybe<Scalars['Boolean']>;
  filterByNoPicture?: InputMaybe<Scalars['Boolean']>;
  filterByNoRealOrgNo?: InputMaybe<Scalars['Boolean']>;
  filterByRegion?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchType?: InputMaybe<Scalars['String']>;
};


export type BillkillQueryReadAllUsersArgs = {
  acceptEfaktura?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  descId?: InputMaybe<Scalars['Boolean']>;
  filterByActivity?: InputMaybe<UserActivityEnum>;
  filterByCheckedEmail?: InputMaybe<Scalars['Boolean']>;
  filterByCheckedMobile?: InputMaybe<Scalars['Boolean']>;
  filterByName?: InputMaybe<Scalars['String']>;
  filterByRegion?: InputMaybe<RegionEnum>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type BillkillQueryReadBalanceHistoryArgs = {
  fromDate: Scalars['DateTime'];
  region?: InputMaybe<RegionEnum>;
  toDate: Scalars['DateTime'];
};


export type BillkillQueryReadBalanceHistoryAsExcelArgs = {
  fromDate: Scalars['DateTime'];
  region?: InputMaybe<RegionEnum>;
  toDate: Scalars['DateTime'];
};


export type BillkillQueryReadBalanceSnapshotsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type BillkillQueryReadCardByIdArgs = {
  cardId: Scalars['ID'];
};


export type BillkillQueryReadCouponByIdArgs = {
  id: Scalars['ID'];
};


export type BillkillQueryReadCouponsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};


export type BillkillQueryReadCustomerUidsByIssuerArgs = {
  issuerId: Scalars['ID'];
};


export type BillkillQueryReadDebtByIdArgs = {
  debtId?: InputMaybe<Scalars['ID']>;
};


export type BillkillQueryReadDebtPaymentSuggestionsArgs = {
  debtId: Scalars['ID'];
};


export type BillkillQueryReadEmailsForNewsletterArgs = {
  acceptEInvoice?: InputMaybe<Scalars['Boolean']>;
  descId?: InputMaybe<Scalars['Boolean']>;
  filterByActivity?: InputMaybe<UserActivityEnum>;
  filterByCheckedMobile?: InputMaybe<Scalars['Boolean']>;
  filterByName?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<RegionEnum>;
};


export type BillkillQueryReadInvoiceByIdArgs = {
  invoiceId: Scalars['ID'];
};


export type BillkillQueryReadInvoiceHistoryArgs = {
  invoiceId: Scalars['ID'];
};


export type BillkillQueryReadInvoiceTransactionFeesArgs = {
  feeReason: InvoiceTransactionFeeReason;
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
};


export type BillkillQueryReadInvoiceUrlByIdArgs = {
  invoiceId: Scalars['ID'];
};


export type BillkillQueryReadInvoiceUrlByUserIdArgs = {
  invoiceId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type BillkillQueryReadInvoicesStatisticsArgs = {
  dateContext?: InputMaybe<InvoiceDateContext>;
  dateContextSort?: InputMaybe<InvoiceDateContextSort>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<TransactionStatus>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type BillkillQueryReadIssuerByIdArgs = {
  issuerId: Scalars['ID'];
};


export type BillkillQueryReadIssuerByOrgNumberArgs = {
  orgNumber: Scalars['String'];
};


export type BillkillQueryReadIssuerStatisticsArgs = {
  fromDate: Scalars['DateTime'];
  issuerId: Scalars['ID'];
  toDate: Scalars['DateTime'];
};


export type BillkillQueryReadIssuerTransactionRuleArgs = {
  issuerId: Scalars['ID'];
};


export type BillkillQueryReadIssuerWhitelistArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  issuerId: Scalars['ID'];
  last?: InputMaybe<Scalars['Int']>;
};


export type BillkillQueryReadOfferByIdArgs = {
  offerId: Scalars['ID'];
};


export type BillkillQueryReadOfferCategoryByIdArgs = {
  offerCategoryId: Scalars['ID'];
};


export type BillkillQueryReadOfferLeadsStatisticsArgs = {
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
};


export type BillkillQueryReadOfferServiceByIdArgs = {
  offerServiceId: Scalars['ID'];
};


export type BillkillQueryReadOfferServicesArgs = {
  offerId: Scalars['ID'];
};


export type BillkillQueryReadOwnUserStatisticsArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};


export type BillkillQueryReadOwnZenHistoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  startId: Scalars['Int'];
};


export type BillkillQueryReadPaidInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dateContext?: InputMaybe<InvoiceDateContext>;
  dateContextSort?: InputMaybe<InvoiceDateContextSort>;
  first?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  issuerId?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type BillkillQueryReadPaidIssuersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type BillkillQueryReadPspTransactionByIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  last?: InputMaybe<Scalars['Int']>;
};


export type BillkillQueryReadPspTransactionLogsByIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  last?: InputMaybe<Scalars['Int']>;
};


export type BillkillQueryReadPspTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  circuits?: InputMaybe<Array<CardCircuit>>;
  first?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  isConnected?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  statusType?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Scalars['String']>;
};


export type BillkillQueryReadPspTransactionsAsExcelArgs = {
  circuits?: InputMaybe<Array<CardCircuit>>;
  fromDate: Scalars['DateTime'];
  isConnected?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  statusType?: InputMaybe<Scalars['String']>;
  toDate: Scalars['DateTime'];
  type?: InputMaybe<Scalars['String']>;
};


export type BillkillQueryReadRejectedInvoicesFromRemittanceArgs = {
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type BillkillQueryReadScAsArgs = {
  reason: ScaReason;
  reference?: InputMaybe<Scalars['ID']>;
};


export type BillkillQueryReadScheduledInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cardId?: InputMaybe<Scalars['ID']>;
  dateContext?: InputMaybe<InvoiceDateContext>;
  dateContextSort?: InputMaybe<InvoiceDateContextSort>;
  first?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  issuerId?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type BillkillQueryReadScheduledInvoicesWithoutPaginationArgs = {
  cardId?: InputMaybe<Scalars['ID']>;
  dateContext?: InputMaybe<InvoiceDateContext>;
  dateContextSort?: InputMaybe<InvoiceDateContextSort>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  issuerId?: InputMaybe<Scalars['ID']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type BillkillQueryReadStatisticsOfPaidInvoicesArgs = {
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
};


export type BillkillQueryReadSubscriptionEligibilityArgs = {
  subscriptionType: SubscriptionType;
};


export type BillkillQueryReadSubscriptionIncomeOverviewArgs = {
  endsAt: Scalars['DateTime'];
  startsAt: Scalars['DateTime'];
};


export type BillkillQueryReadSubscriptionsArgs = {
  subscriptionType: SubscriptionType;
};


export type BillkillQueryReadSystemZenExtraBalanceArgs = {
  date: Scalars['DateTime'];
};


export type BillkillQueryReadTranslationArgs = {
  codeId: Scalars['ID'];
  languageId: Scalars['ID'];
};


export type BillkillQueryReadTranslationCodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};


export type BillkillQueryReadTranslationsArgs = {
  codeId: Scalars['ID'];
};


export type BillkillQueryReadUnpaidInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dateContext?: InputMaybe<InvoiceDateContext>;
  dateContextSort?: InputMaybe<InvoiceDateContextSort>;
  first?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  issuerId?: InputMaybe<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type BillkillQueryReadUnpaidInvoicesWithoutPaginationArgs = {
  dateContext?: InputMaybe<InvoiceDateContext>;
  dateContextSort?: InputMaybe<InvoiceDateContextSort>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  issuerId?: InputMaybe<Scalars['ID']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type BillkillQueryReadUserActivitiesArgs = {
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  userId: Scalars['ID'];
};


export type BillkillQueryReadUserByIdArgs = {
  userId: Scalars['ID'];
};


export type BillkillQueryReadUserBySidArgs = {
  sid: Scalars['ID'];
};


export type BillkillQueryReadUserGroupMembersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  groupId: Scalars['ID'];
  last?: InputMaybe<Scalars['Int']>;
};


export type BillkillQueryReadUserGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type BillkillQueryReadUserNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  userId: Scalars['ID'];
};


export type BillkillQueryReadUserSubscriptionByIdArgs = {
  userSubscriptionId: Scalars['ID'];
};


export type BillkillQueryReadUserSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type BillkillQueryReadUserSubscriptionsByUserIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  userId: Scalars['ID'];
};


export type BillkillQueryReadUserZenHistoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  startId: Scalars['Int'];
  userId: Scalars['ID'];
};


export type BillkillQueryReadUserZenTotalPointsArgs = {
  userId: Scalars['ID'];
};


export type BillkillQueryReadZenExtraBalanceArgs = {
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
};


export type BillkillQueryReadZenExtraBalanceAsExcelArgs = {
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
};


export type BillkillQueryRegionArgs = {
  sid: Scalars['String'];
};


export type BillkillQuerySearchIssuerByNameArgs = {
  name: Scalars['String'];
};


export type BillkillQuerySearchIssuerByOrgNumberArgs = {
  orgNumber: Scalars['String'];
};


export type BillkillQuerySearchIssuersArgs = {
  searchTerm: Scalars['String'];
};


export type BillkillQuerySearchWhitelistedIssuerArgs = {
  searchTerm: Scalars['String'];
};

export type TypeType = {
  __typename: 'typeType';
  /** @deprecated  */
  Name: Scalars['String'];
  /** @deprecated  */
  Value: Scalars['Int'];
};

export type CreateBankIdSessionMutationVariables = Exact<{
  mobile: Scalars['String'];
  cbSuccess?: InputMaybe<Scalars['String']>;
  cbFailure?: InputMaybe<Scalars['String']>;
  cbAbort?: InputMaybe<Scalars['String']>;
}>;


export type CreateBankIdSessionMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<(
    { __typename: 'SessionRequest' }
    & Pick<SessionRequest, 'id' | 'url'>
    & { callbacks: (
      { __typename: 'CallbacksBankId' }
      & Pick<CallbacksBankId, 'abort' | 'failure' | 'success'>
    ) }
  )> }
);

export type CreateAccessFromSessionMutationVariables = Exact<{
  sessionId: Scalars['String'];
  role: UserRoleEnum;
}>;


export type CreateAccessFromSessionMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<(
    { __typename: 'Token' }
    & Pick<Token, 'token' | 'refreshToken'>
  )> }
);

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshTokenMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<(
    { __typename: 'Token' }
    & Pick<Token, 'token' | 'refreshToken'>
  )> }
);

export type ReadCouponsQueryVariables = Exact<{
  searchTerm?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;


export type ReadCouponsQuery = (
  { __typename: 'billkillQuery' }
  & { coupons?: Maybe<(
    { __typename: 'CouponConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename: 'CouponEdge' }
      & Pick<CouponEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename: 'Coupon' }
        & Pick<Coupon, 'id' | 'amount' | 'code' | 'startsAt' | 'endsAt' | 'period' | 'rule' | 'recipient' | 'description' | 'descriptionId' | 'product' | 'region'>
        & { recipientGroup?: Maybe<(
          { __typename: 'UserGroup' }
          & Pick<UserGroup, 'id' | 'name' | 'description' | 'region'>
        )> }
      )> }
    )>>>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type ReadCouponByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReadCouponByIdQuery = (
  { __typename: 'billkillQuery' }
  & { coupon: (
    { __typename: 'Coupon' }
    & Pick<Coupon, 'id' | 'amount' | 'code' | 'startsAt' | 'endsAt' | 'period' | 'rule' | 'recipient' | 'description' | 'descriptionId' | 'product' | 'region'>
    & { recipientGroup?: Maybe<(
      { __typename: 'UserGroup' }
      & Pick<UserGroup, 'id' | 'name' | 'description' | 'region'>
    )> }
  ) }
);

export type CreateCouponMutationVariables = Exact<{
  code: Scalars['String'];
  descriptionId: Scalars['ID'];
  recipient: CouponRecipient;
  recipientGroupId?: InputMaybe<Scalars['ID']>;
  startsAt: Scalars['DateTime'];
  endsAt?: InputMaybe<Scalars['DateTime']>;
  period: Scalars['Int'];
  product: CouponProduct;
  rule?: InputMaybe<CouponRule>;
  amount: Scalars['Float'];
  region: Scalars['String'];
}>;


export type CreateCouponMutation = (
  { __typename: 'billkillMutation' }
  & { coupon: (
    { __typename: 'Coupon' }
    & Pick<Coupon, 'id' | 'amount' | 'code' | 'startsAt' | 'endsAt' | 'period' | 'rule' | 'recipient' | 'description' | 'descriptionId' | 'product' | 'region'>
    & { recipientGroup?: Maybe<(
      { __typename: 'UserGroup' }
      & Pick<UserGroup, 'id' | 'name' | 'description' | 'region'>
    )> }
  ) }
);

export type UpdateCouponMutationVariables = Exact<{
  couponId: Scalars['ID'];
  startsAt?: InputMaybe<Scalars['DateTime']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  noExpiration?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateCouponMutation = (
  { __typename: 'billkillMutation' }
  & { result: (
    { __typename: 'Coupon' }
    & Pick<Coupon, 'id' | 'amount' | 'code' | 'startsAt' | 'endsAt' | 'period' | 'rule' | 'recipient' | 'description' | 'descriptionId' | 'product' | 'region'>
    & { recipientGroup?: Maybe<(
      { __typename: 'UserGroup' }
      & Pick<UserGroup, 'id' | 'name' | 'description' | 'region'>
    )> }
  ) }
);

export type DeleteCouponMutationVariables = Exact<{
  couponId: Scalars['ID'];
}>;


export type DeleteCouponMutation = (
  { __typename: 'billkillMutation' }
  & { result: (
    { __typename: 'Coupon' }
    & Pick<Coupon, 'id'>
  ) }
);

export type CouponResultFragment = (
  { __typename: 'Coupon' }
  & Pick<Coupon, 'id' | 'amount' | 'code' | 'startsAt' | 'endsAt' | 'period' | 'rule' | 'recipient' | 'description' | 'descriptionId' | 'product' | 'region'>
  & { recipientGroup?: Maybe<(
    { __typename: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'name' | 'description' | 'region'>
  )> }
);

export type IndustryCodeResultFragment = (
  { __typename: 'IndustryCode' }
  & Pick<IndustryCode, 'id' | 'createdAt' | 'country' | 'industryCode' | 'description' | 'merchantCategoryCode' | 'region'>
);

export type InvoiceResultFragment = (
  { __typename: 'Invoice' }
  & Pick<Invoice, 'id' | 'kind' | 'reference' | 'referenceProvider' | 'customerNumber' | 'invoiceDate' | 'dueDate'>
  & { issuer: (
    { __typename: 'IssuerIdentity' }
    & Pick<IssuerIdentity, 'name' | 'orgNumber'>
  ), lastTransaction: (
    { __typename: 'InvoiceTransaction' }
    & Pick<InvoiceTransaction, 'amount'>
  ), currency: (
    { __typename: 'Currency' }
    & Pick<Currency, 'symbol'>
  ), fee?: Maybe<(
    { __typename: 'InvoiceTransactionFee' }
    & Pick<InvoiceTransactionFee, 'amount' | 'percentage' | 'reason' | 'total'>
  )>, user: (
    { __typename: 'UserIdentity' }
    & Pick<UserIdentity, 'id' | 'firstname'>
  ) }
);

export type InvoiceHistoryResultFragment = (
  { __typename: 'InvoiceHistory' }
  & Pick<InvoiceHistory, 'id' | 'userId'>
  & { amount: (
    { __typename: 'InvoiceHistoryAmount' }
    & Pick<InvoiceHistoryAmount, 'original' | 'current'>
  ), payment: (
    { __typename: 'InvoiceHistoryPayment' }
    & Pick<InvoiceHistoryPayment, 'incoming' | 'outgoing'>
  ), lastTransaction: (
    { __typename: 'InvoiceTransaction' }
    & Pick<InvoiceTransaction, 'status' | 'statusDate' | 'remitStatus'>
  ), transactions?: Maybe<Array<Maybe<(
    { __typename: 'InvoiceHistoryTransaction' }
    & Pick<InvoiceHistoryTransaction, 'time' | 'event' | 'status' | 'remitStatus' | 'remitStatusDescription' | 'amount' | 'reference' | 'details' | 'actor' | 'source'>
  )>>> }
);

export type IssuerResultFragment = (
  { __typename: 'Issuer' }
  & Pick<Issuer, 'id' | 'name' | 'orgNumber' | 'realOrgNumber' | 'subMerchantMcc' | 'implicitSubMerchantMcc' | 'industryCode' | 'picture' | 'createdAt' | 'region'>
);

export type IssuerDetailFragment = (
  { __typename: 'Issuer' }
  & Pick<Issuer, 'id' | 'name' | 'orgNumber' | 'realOrgNumber' | 'subMerchantMcc' | 'implicitSubMerchantMcc' | 'industryCode' | 'brregStatusDesc' | 'brregForceUpdate' | 'picture' | 'email' | 'emailLoan' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'region'>
  & { address?: Maybe<(
    { __typename: 'Address' }
    & Pick<Address, 'street' | 'postalCode' | 'city'>
    & { country?: Maybe<(
      { __typename: 'Country' }
      & Pick<Country, 'code' | 'name'>
    )> }
  )> }
);

export type IssuerBlockCompleteFragment = (
  { __typename: 'IssuerTransactionRule' }
  & Pick<IssuerTransactionRule, 'issuerId' | 'allowDebitCard' | 'allowCreditCard' | 'allowAmexCard' | 'maxAmountDebitCard' | 'maxAmountCreditCard' | 'maxAmountAmexCard' | 'isCustom' | 'createdAt' | 'updatedAt'>
);

export type TranslationCodeResultFragment = (
  { __typename: 'TranslationCode' }
  & Pick<TranslationCode, 'id' | 'code'>
);

export type TranslationResultFragment = (
  { __typename: 'Translation' }
  & Pick<Translation, 'id' | 'codeId' | 'languageId' | 'translation'>
);

export type UserResultFragment = (
  { __typename: 'User' }
  & Pick<User, 'id' | 'firstname' | 'lastname' | 'email' | 'emailCheck' | 'mobileCheck' | 'createdAt' | 'region'>
  & { permission: (
    { __typename: 'UserPermission' }
    & { einvoice: (
      { __typename: 'UserPermissionEInvoice' }
      & Pick<UserPermissionEInvoice, 'acceptAllIssuers'>
    ), pep: (
      { __typename: 'UserPermissionPEP' }
      & Pick<UserPermissionPep, 'status'>
    ) }
  ) }
);

export type UserDetailFragment = (
  { __typename: 'User' }
  & Pick<User, 'id' | 'status' | 'firstname' | 'lastname' | 'birthdate' | 'email' | 'emailCheck' | 'mobile' | 'mobileCheck' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'region'>
  & { address?: Maybe<(
    { __typename: 'Address' }
    & Pick<Address, 'street' | 'postalCode' | 'city'>
    & { country?: Maybe<(
      { __typename: 'Country' }
      & Pick<Country, 'code'>
    )> }
  )>, permission: (
    { __typename: 'UserPermission' }
    & { einvoice: (
      { __typename: 'UserPermissionEInvoice' }
      & Pick<UserPermissionEInvoice, 'acceptAllIssuers'>
    ), pep: (
      { __typename: 'UserPermissionPEP' }
      & Pick<UserPermissionPep, 'status'>
    ) }
  ) }
);

export type UserGroupResultFragment = (
  { __typename: 'UserGroup' }
  & Pick<UserGroup, 'id' | 'name' | 'description' | 'region'>
);

export type UserGroupMemberResultFragment = (
  { __typename: 'UserGroupMember' }
  & Pick<UserGroupMember, 'id' | 'name' | 'userId'>
);

export type ReadAllIndustryCodesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filterByNoMcc?: InputMaybe<Scalars['Boolean']>;
  merchantCategoryCode?: InputMaybe<Scalars['String']>;
  filterByRegion?: InputMaybe<RegionEnum>;
}>;


export type ReadAllIndustryCodesQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<(
    { __typename: 'IndustryCodeConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename: 'IndustryCodeEdge' }
      & { node?: Maybe<(
        { __typename: 'IndustryCode' }
        & Pick<IndustryCode, 'id' | 'createdAt' | 'country' | 'industryCode' | 'description' | 'merchantCategoryCode' | 'region'>
      )> }
    )>>>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type SetMerchantCategoryCodeMutationVariables = Exact<{
  id: Scalars['ID'];
  merchantCategoryCode: Scalars['String'];
}>;


export type SetMerchantCategoryCodeMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<(
    { __typename: 'IndustryCode' }
    & Pick<IndustryCode, 'id' | 'industryCode' | 'description' | 'merchantCategoryCode' | 'country' | 'createdAt'>
  )> }
);

export type ReadRejectedInvoicesFromRemittanceQueryVariables = Exact<{
  showArchived?: InputMaybe<Scalars['Boolean']>;
}>;


export type ReadRejectedInvoicesFromRemittanceQuery = (
  { __typename: 'billkillQuery' }
  & { result: Array<(
    { __typename: 'RemittanceInvoiceAlert' }
    & Pick<RemittanceInvoiceAlert, 'id' | 'invoiceId' | 'userId' | 'userName' | 'userEmail' | 'customerUId' | 'customerUIdType' | 'remitBatchId' | 'remitAttempt' | 'archived' | 'supportNote' | 'pushNote' | 'remitStatus' | 'remitStatusDescription' | 'createdAt' | 'amount'>
  )> }
);

export type UpdateRemittedInvoiceCustomerUIdMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
  userId: Scalars['ID'];
  customerUId: Scalars['String'];
  customerUIdType: CustomerUidType;
}>;


export type UpdateRemittedInvoiceCustomerUIdMutation = (
  { __typename: 'billkillMutation' }
  & { result: (
    { __typename: 'Invoice' }
    & Pick<Invoice, 'id' | 'customerUId' | 'customerUIdType'>
  ) }
);

export type SetInvoiceArchivedMutationVariables = Exact<{
  userId: Scalars['ID'];
  invoiceIdentifier: Scalars['ID'];
  archived: Scalars['Boolean'];
}>;


export type SetInvoiceArchivedMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<(
    { __typename: 'BooleanResult' }
    & Pick<BooleanResult, 'isOk'>
  )> }
);

export type SetSupportNoteMutationVariables = Exact<{
  userId: Scalars['ID'];
  invoiceIdentifier: Scalars['ID'];
  supportNote: Scalars['String'];
}>;


export type SetSupportNoteMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<(
    { __typename: 'BooleanResult' }
    & Pick<BooleanResult, 'isOk'>
  )> }
);

export type SendPushMessageToUserMutationVariables = Exact<{
  userId: Scalars['ID'];
  invoiceIdentifier: Scalars['ID'];
  message: Scalars['String'];
  header: Scalars['String'];
}>;


export type SendPushMessageToUserMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<(
    { __typename: 'BooleanResult' }
    & Pick<BooleanResult, 'isOk'>
  )> }
);

export type ReadAllInvoicesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<InvoiceKind>;
  status?: InputMaybe<TransactionStatus>;
  issuerId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
  searchingTerm?: InputMaybe<Scalars['String']>;
  invoiceId?: InputMaybe<Scalars['ID']>;
  region?: InputMaybe<RegionEnum>;
}>;


export type ReadAllInvoicesQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<(
    { __typename: 'InvoiceConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename: 'InvoiceEdge' }
      & { node?: Maybe<(
        { __typename: 'Invoice' }
        & Pick<Invoice, 'id' | 'kind' | 'reference' | 'referenceProvider' | 'customerNumber' | 'invoiceDate' | 'dueDate'>
        & { issuer: (
          { __typename: 'IssuerIdentity' }
          & Pick<IssuerIdentity, 'name' | 'orgNumber'>
        ), lastTransaction: (
          { __typename: 'InvoiceTransaction' }
          & Pick<InvoiceTransaction, 'amount'>
        ), currency: (
          { __typename: 'Currency' }
          & Pick<Currency, 'symbol'>
        ), fee?: Maybe<(
          { __typename: 'InvoiceTransactionFee' }
          & Pick<InvoiceTransactionFee, 'amount' | 'percentage' | 'reason' | 'total'>
        )>, user: (
          { __typename: 'UserIdentity' }
          & Pick<UserIdentity, 'id' | 'firstname'>
        ) }
      )> }
    )>>>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type ReadInvoiceHistoryQueryVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;


export type ReadInvoiceHistoryQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<(
    { __typename: 'InvoiceHistory' }
    & Pick<InvoiceHistory, 'id' | 'userId'>
    & { amount: (
      { __typename: 'InvoiceHistoryAmount' }
      & Pick<InvoiceHistoryAmount, 'original' | 'current'>
    ), payment: (
      { __typename: 'InvoiceHistoryPayment' }
      & Pick<InvoiceHistoryPayment, 'incoming' | 'outgoing'>
    ), lastTransaction: (
      { __typename: 'InvoiceTransaction' }
      & Pick<InvoiceTransaction, 'status' | 'statusDate' | 'remitStatus'>
    ), transactions?: Maybe<Array<Maybe<(
      { __typename: 'InvoiceHistoryTransaction' }
      & Pick<InvoiceHistoryTransaction, 'time' | 'event' | 'status' | 'remitStatus' | 'remitStatusDescription' | 'amount' | 'reference' | 'details' | 'actor' | 'source'>
    )>>> }
  )> }
);

export type AdminReadInvoiceByIdQueryVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;


export type AdminReadInvoiceByIdQuery = (
  { __typename: 'billkillQuery' }
  & { invoice?: Maybe<(
    { __typename: 'Invoice' }
    & Pick<Invoice, 'id' | 'reference' | 'referenceProvider' | 'amount' | 'dueDate' | 'customerNumber' | 'invoiceDate' | 'vat' | 'net' | 'kind' | 'customerUId'>
    & { currency: (
      { __typename: 'Currency' }
      & Pick<Currency, 'code' | 'name' | 'symbol'>
    ), issuer: (
      { __typename: 'IssuerIdentity' }
      & Pick<IssuerIdentity, 'id' | 'orgNumber' | 'picture' | 'name'>
    ), user: (
      { __typename: 'UserIdentity' }
      & Pick<UserIdentity, 'id' | 'sid' | 'firstname' | 'lastname'>
      & { address: (
        { __typename: 'Address' }
        & Pick<Address, 'street' | 'postalCode' | 'city'>
        & { country?: Maybe<(
          { __typename: 'Country' }
          & Pick<Country, 'name'>
        )> }
      ) }
    ), lastTransaction: (
      { __typename: 'InvoiceTransaction' }
      & Pick<InvoiceTransaction, 'event' | 'amount' | 'status' | 'paymentStatus' | 'orderRef' | 'transactionRef'>
    ), support?: Maybe<Array<Maybe<(
      { __typename: 'InvoiceSupport' }
      & Pick<InvoiceSupport, 'type' | 'requestedAt'>
    )>>> }
  )> }
);

export type ReadBalanceHistoryQueryVariables = Exact<{
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  region?: InputMaybe<RegionEnum>;
}>;


export type ReadBalanceHistoryQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<(
    { __typename: 'BalanceHistory' }
    & Pick<BalanceHistory, 'sumIncoming' | 'sumIncomingWithoutFees' | 'sumOutgoing' | 'sumFees' | 'sumZenFeeNOK' | 'sumGrossAmount' | 'balance'>
    & { transactions: Array<(
      { __typename: 'BalanceHistoryTransaction' }
      & Pick<BalanceHistoryTransaction, 'lastTransactionId' | 'lastTransactionTime' | 'invoiceId' | 'invoiceReference' | 'invoiceReferenceProvider' | 'invoiceKind' | 'invoiceIdentifier' | 'invoiceStatus' | 'invoiceStatusDate' | 'issuerId' | 'issuerName' | 'userId' | 'userName' | 'userEmail' | 'balanceInvoiceAmount' | 'balanceCardAmount' | 'balanceRemitAmount' | 'balanceFeeAmount' | 'balanceZenFee' | 'balanceGross' | 'balanceFeePercentage' | 'balanceFeeReason' | 'paymentCardId' | 'paymentAttempt' | 'paymentStatus' | 'paymentId' | 'paymentTime' | 'remitBatchId' | 'remitAttempt' | 'remitStatus' | 'remitStatusDescription' | 'remitTime' | 'reconciliationId'>
    )>, fees: Array<(
      { __typename: 'BalanceHistoryFeeSubtotal' }
      & Pick<BalanceHistoryFeeSubtotal, 'reason' | 'total'>
    )> }
  )> }
);

export type ReadBalanceHistoryAsExcelQueryVariables = Exact<{
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  region?: InputMaybe<RegionEnum>;
}>;


export type ReadBalanceHistoryAsExcelQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<(
    { __typename: 'BalanceHistoryAsExcel' }
    & Pick<BalanceHistoryAsExcel, 'data'>
  )> }
);

export type ReadZenExtraBalanceAsExcelQueryVariables = Exact<{
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
}>;


export type ReadZenExtraBalanceAsExcelQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<(
    { __typename: 'ZenExtrabalanceHistoryAsExcelType' }
    & Pick<ZenExtrabalanceHistoryAsExcelType, 'data'>
  )> }
);

export type ReadBalanceForecastingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadBalanceForecastingQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<Array<Maybe<(
    { __typename: 'BufferForecasting' }
    & Pick<BufferForecasting, 'time' | 'buffer' | 'incoming' | 'outgoing'>
  )>>> }
);

export type StopRemittanceMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;


export type StopRemittanceMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<(
    { __typename: 'BooleanResult' }
    & Pick<BooleanResult, 'isOk'>
  )> }
);

export type RestartRemittanceMutationVariables = Exact<{
  invoiceId: Scalars['ID'];
}>;


export type RestartRemittanceMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<(
    { __typename: 'BooleanResult' }
    & Pick<BooleanResult, 'isOk'>
  )> }
);

export type ReadBalanceSnapshotsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type ReadBalanceSnapshotsQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<(
    { __typename: 'BalanceSnapshotConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename: 'BalanceSnapshotEdge' }
      & { node?: Maybe<(
        { __typename: 'BalanceSnapshot' }
        & Pick<BalanceSnapshot, 'id' | 'amount' | 'time'>
      )> }
    )>>>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type CreateBalanceSnapshotMutationVariables = Exact<{
  amount: Scalars['Float'];
  time: Scalars['DateTime'];
}>;


export type CreateBalanceSnapshotMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<(
    { __typename: 'BooleanResult' }
    & Pick<BooleanResult, 'isOk'>
  )> }
);

export type RemoveBalanceSnapshotMutationVariables = Exact<{
  snapshotId: Scalars['ID'];
}>;


export type RemoveBalanceSnapshotMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<(
    { __typename: 'BooleanResult' }
    & Pick<BooleanResult, 'isOk'>
  )> }
);

export type ReadAllIssuersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  searchType?: InputMaybe<Scalars['String']>;
  filterByName?: InputMaybe<Scalars['String']>;
  filterByNoEmail?: InputMaybe<Scalars['Boolean']>;
  filterByNoCreditorEmailLoan?: InputMaybe<Scalars['Boolean']>;
  filterByNoPicture?: InputMaybe<Scalars['Boolean']>;
  filterByExistingAccount?: InputMaybe<Scalars['Boolean']>;
  filterByAllowingAmex?: InputMaybe<Scalars['Boolean']>;
  filterByAllowingCreditCards?: InputMaybe<Scalars['Boolean']>;
  filterByNoRealOrgNo?: InputMaybe<Scalars['Boolean']>;
  filterByNoMcc?: InputMaybe<Scalars['Boolean']>;
  filterByRegion?: InputMaybe<Scalars['String']>;
}>;


export type ReadAllIssuersQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<(
    { __typename: 'IssuerConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename: 'IssuerEdge' }
      & { node?: Maybe<(
        { __typename: 'Issuer' }
        & Pick<Issuer, 'id' | 'name' | 'orgNumber' | 'realOrgNumber' | 'subMerchantMcc' | 'implicitSubMerchantMcc' | 'industryCode' | 'picture' | 'createdAt' | 'region'>
      )> }
    )>>>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type ReadIssuerByIdQueryVariables = Exact<{
  issuerId: Scalars['ID'];
}>;


export type ReadIssuerByIdQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<(
    { __typename: 'Issuer' }
    & Pick<Issuer, 'id' | 'name' | 'orgNumber' | 'realOrgNumber' | 'subMerchantMcc' | 'implicitSubMerchantMcc' | 'industryCode' | 'brregStatusDesc' | 'brregForceUpdate' | 'picture' | 'email' | 'emailLoan' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'region'>
    & { address?: Maybe<(
      { __typename: 'Address' }
      & Pick<Address, 'street' | 'postalCode' | 'city'>
      & { country?: Maybe<(
        { __typename: 'Country' }
        & Pick<Country, 'code' | 'name'>
      )> }
    )> }
  )> }
);

export type UpdateIssuerIdentifiersMutationVariables = Exact<{
  issuerId: Scalars['ID'];
  realOrgNumber: Scalars['String'];
  subMerchantMcc: Scalars['String'];
}>;


export type UpdateIssuerIdentifiersMutation = (
  { __typename: 'billkillMutation' }
  & { result: (
    { __typename: 'Issuer' }
    & Pick<Issuer, 'id' | 'name' | 'orgNumber' | 'realOrgNumber' | 'subMerchantMcc' | 'implicitSubMerchantMcc' | 'industryCode' | 'picture' | 'createdAt' | 'region'>
  ) }
);

export type ReadIssuerWhitelistQueryVariables = Exact<{
  issuerId: Scalars['ID'];
}>;


export type ReadIssuerWhitelistQuery = (
  { __typename: 'billkillQuery' }
  & { whitelist: Array<(
    { __typename: 'IssuerWhitelist' }
    & Pick<IssuerWhitelist, 'id' | 'issuerId' | 'origin' | 'accountNumber'>
  )> }
);

export type CreateIssuerWhitelistMutationVariables = Exact<{
  issuerId: Scalars['ID'];
  accountNumber: Scalars['String'];
}>;


export type CreateIssuerWhitelistMutation = (
  { __typename: 'billkillMutation' }
  & { whitelist: (
    { __typename: 'IssuerWhitelist' }
    & Pick<IssuerWhitelist, 'id' | 'issuerId' | 'origin' | 'accountNumber'>
  ) }
);

export type RemoveIssuerWhitelistMutationVariables = Exact<{
  issuerId: Scalars['ID'];
  accountNumber: Scalars['String'];
}>;


export type RemoveIssuerWhitelistMutation = (
  { __typename: 'billkillMutation' }
  & { whitelist: (
    { __typename: 'IssuerWhitelist' }
    & Pick<IssuerWhitelist, 'id' | 'issuerId' | 'origin' | 'accountNumber'>
  ) }
);

export type UpdateIssuerEmailMutationVariables = Exact<{
  issuerId: Scalars['ID'];
  email: Scalars['String'];
}>;


export type UpdateIssuerEmailMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<(
    { __typename: 'Issuer' }
    & Pick<Issuer, 'id' | 'name' | 'orgNumber' | 'realOrgNumber' | 'subMerchantMcc' | 'implicitSubMerchantMcc' | 'industryCode' | 'brregStatusDesc' | 'brregForceUpdate' | 'picture' | 'email' | 'emailLoan' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'region'>
    & { address?: Maybe<(
      { __typename: 'Address' }
      & Pick<Address, 'street' | 'postalCode' | 'city'>
      & { country?: Maybe<(
        { __typename: 'Country' }
        & Pick<Country, 'code' | 'name'>
      )> }
    )> }
  )> }
);

export type UpdateIssuerLoanEmailMutationVariables = Exact<{
  issuerId: Scalars['ID'];
  emailLoan: Scalars['String'];
}>;


export type UpdateIssuerLoanEmailMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<(
    { __typename: 'Issuer' }
    & Pick<Issuer, 'id' | 'name' | 'orgNumber' | 'realOrgNumber' | 'subMerchantMcc' | 'implicitSubMerchantMcc' | 'industryCode' | 'brregStatusDesc' | 'brregForceUpdate' | 'picture' | 'email' | 'emailLoan' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'region'>
    & { address?: Maybe<(
      { __typename: 'Address' }
      & Pick<Address, 'street' | 'postalCode' | 'city'>
      & { country?: Maybe<(
        { __typename: 'Country' }
        & Pick<Country, 'code' | 'name'>
      )> }
    )> }
  )> }
);

export type UpdateIssuerPictureMutationVariables = Exact<{
  issuerId: Scalars['ID'];
  pictureBase64: Scalars['String'];
}>;


export type UpdateIssuerPictureMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<(
    { __typename: 'Issuer' }
    & Pick<Issuer, 'id' | 'name' | 'orgNumber' | 'realOrgNumber' | 'subMerchantMcc' | 'implicitSubMerchantMcc' | 'industryCode' | 'brregStatusDesc' | 'brregForceUpdate' | 'picture' | 'email' | 'emailLoan' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'region'>
    & { address?: Maybe<(
      { __typename: 'Address' }
      & Pick<Address, 'street' | 'postalCode' | 'city'>
      & { country?: Maybe<(
        { __typename: 'Country' }
        & Pick<Country, 'code' | 'name'>
      )> }
    )> }
  )> }
);

export type CopyIssuerInfoMutationVariables = Exact<{
  issuerIdSource: Scalars['ID'];
  issuerIdDestination: Scalars['ID'];
}>;


export type CopyIssuerInfoMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<(
    { __typename: 'Issuer' }
    & Pick<Issuer, 'id' | 'name' | 'orgNumber' | 'realOrgNumber' | 'subMerchantMcc' | 'implicitSubMerchantMcc' | 'industryCode' | 'brregStatusDesc' | 'brregForceUpdate' | 'picture' | 'email' | 'emailLoan' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'region'>
    & { address?: Maybe<(
      { __typename: 'Address' }
      & Pick<Address, 'street' | 'postalCode' | 'city'>
      & { country?: Maybe<(
        { __typename: 'Country' }
        & Pick<Country, 'code' | 'name'>
      )> }
    )> }
  )> }
);

export type SetIssuerTransactionRuleMutationVariables = Exact<{
  issuerId: Scalars['ID'];
  allowDebitCard: Scalars['Boolean'];
  allowCreditCard: Scalars['Boolean'];
  allowAmexCard: Scalars['Boolean'];
  maxAmountDebitCard?: InputMaybe<Scalars['Int']>;
  maxAmountCreditCard?: InputMaybe<Scalars['Int']>;
  maxAmountAmexCard?: InputMaybe<Scalars['Int']>;
}>;


export type SetIssuerTransactionRuleMutation = (
  { __typename: 'billkillMutation' }
  & { rule: (
    { __typename: 'IssuerTransactionRule' }
    & Pick<IssuerTransactionRule, 'issuerId' | 'allowDebitCard' | 'allowCreditCard' | 'allowAmexCard' | 'maxAmountDebitCard' | 'maxAmountCreditCard' | 'maxAmountAmexCard' | 'isCustom' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UnsetIssuerTransactionRuleMutationVariables = Exact<{
  issuerId: Scalars['ID'];
}>;


export type UnsetIssuerTransactionRuleMutation = (
  { __typename: 'billkillMutation' }
  & { rule: (
    { __typename: 'IssuerTransactionRule' }
    & Pick<IssuerTransactionRule, 'issuerId' | 'allowDebitCard' | 'allowCreditCard' | 'allowAmexCard' | 'maxAmountDebitCard' | 'maxAmountCreditCard' | 'maxAmountAmexCard' | 'isCustom' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ReadIssuerTransactionRuleQueryVariables = Exact<{
  issuerId: Scalars['ID'];
}>;


export type ReadIssuerTransactionRuleQuery = (
  { __typename: 'billkillQuery' }
  & { rule: (
    { __typename: 'IssuerTransactionRule' }
    & Pick<IssuerTransactionRule, 'issuerId' | 'allowDebitCard' | 'allowCreditCard' | 'allowAmexCard' | 'maxAmountDebitCard' | 'maxAmountCreditCard' | 'maxAmountAmexCard' | 'isCustom' | 'createdAt' | 'updatedAt'>
  ) }
);

export type ReadOffersQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadOffersQuery = (
  { __typename: 'billkillQuery' }
  & { offers?: Maybe<Array<(
    { __typename: 'OfferWithTranslations' }
    & Pick<OfferWithTranslations, 'id' | 'name' | 'picture' | 'status'>
    & { category: (
      { __typename: 'OfferCategoryWithTranslations' }
      & Pick<OfferCategoryWithTranslations, 'nameEn' | 'descriptionEn' | 'region'>
    ) }
  )>> }
);

export type ReadOfferServicesQueryVariables = Exact<{
  offerId: Scalars['ID'];
}>;


export type ReadOfferServicesQuery = (
  { __typename: 'billkillQuery' }
  & { offerServices?: Maybe<Array<(
    { __typename: 'OfferServiceWithTranslations' }
    & Pick<OfferServiceWithTranslations, 'id' | 'nameEn' | 'nameNo' | 'nameSv' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'url' | 'email' | 'emailDescriptionEn' | 'emailDescriptionNo' | 'emailDescriptionSv' | 'phone' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type ReadOfferByIdQueryVariables = Exact<{
  offerId: Scalars['ID'];
}>;


export type ReadOfferByIdQuery = (
  { __typename: 'billkillQuery' }
  & { offer: (
    { __typename: 'OfferWithTranslations' }
    & Pick<OfferWithTranslations, 'id' | 'name' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'picture' | 'status' | 'url' | 'createdAt' | 'updatedAt'>
    & { category: (
      { __typename: 'OfferCategoryWithTranslations' }
      & Pick<OfferCategoryWithTranslations, 'id' | 'nameEn' | 'nameNo' | 'nameSv' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'position' | 'createdAt' | 'updatedAt' | 'region'>
    ), services?: Maybe<Array<(
      { __typename: 'OfferServiceWithTranslations' }
      & Pick<OfferServiceWithTranslations, 'id' | 'nameEn' | 'nameNo' | 'nameSv' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'url' | 'email' | 'emailDescriptionEn' | 'emailDescriptionNo' | 'phone' | 'createdAt' | 'updatedAt' | 'region'>
    )>> }
  ) }
);

export type ReadOfferServiceByIdQueryVariables = Exact<{
  offerServiceId: Scalars['ID'];
}>;


export type ReadOfferServiceByIdQuery = (
  { __typename: 'billkillQuery' }
  & { service: (
    { __typename: 'OfferServiceWithTranslations' }
    & Pick<OfferServiceWithTranslations, 'id' | 'nameEn' | 'nameNo' | 'nameSv' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'url' | 'email' | 'emailDescriptionEn' | 'emailDescriptionNo' | 'emailDescriptionSv' | 'phone' | 'createdAt' | 'updatedAt' | 'region'>
  ) }
);

export type ReadOfferCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadOfferCategoriesQuery = (
  { __typename: 'billkillQuery' }
  & { categories?: Maybe<Array<(
    { __typename: 'OfferCategoryWithTranslations' }
    & Pick<OfferCategoryWithTranslations, 'id' | 'nameEn' | 'nameNo' | 'nameSv' | 'region' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type ReadOfferCategoryByIdQueryVariables = Exact<{
  offerCategoryId: Scalars['ID'];
}>;


export type ReadOfferCategoryByIdQuery = (
  { __typename: 'billkillQuery' }
  & { category: (
    { __typename: 'OfferCategoryWithTranslations' }
    & Pick<OfferCategoryWithTranslations, 'id' | 'nameEn' | 'nameNo' | 'nameSv' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'position' | 'region' | 'createdAt' | 'updatedAt'>
  ) }
);

export type CreateOfferCategoryMutationVariables = Exact<{
  nameEn: Scalars['String'];
  nameNo: Scalars['String'];
  nameSv: Scalars['String'];
  descriptionEn: Scalars['String'];
  descriptionNo: Scalars['String'];
  descriptionSv: Scalars['String'];
  region: Scalars['String'];
}>;


export type CreateOfferCategoryMutation = (
  { __typename: 'billkillMutation' }
  & { category: (
    { __typename: 'OfferCategoryWithTranslations' }
    & Pick<OfferCategoryWithTranslations, 'id' | 'nameEn' | 'nameNo' | 'nameSv' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'region' | 'position' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateOfferCategoryMutationVariables = Exact<{
  offerCategoryId: Scalars['ID'];
  nameEn: Scalars['String'];
  nameNo: Scalars['String'];
  nameSv: Scalars['String'];
  descriptionEn: Scalars['String'];
  descriptionNo: Scalars['String'];
  descriptionSv: Scalars['String'];
}>;


export type UpdateOfferCategoryMutation = (
  { __typename: 'billkillMutation' }
  & { offer?: Maybe<Array<(
    { __typename: 'OfferCategoryWithTranslations' }
    & Pick<OfferCategoryWithTranslations, 'id' | 'nameEn' | 'nameNo' | 'nameSv' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'position' | 'region' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type ArrangeOfferCategoriesMutationVariables = Exact<{
  positions?: InputMaybe<Array<OfferCategoryNewPosition> | OfferCategoryNewPosition>;
}>;


export type ArrangeOfferCategoriesMutation = (
  { __typename: 'billkillMutation' }
  & { categories?: Maybe<Array<(
    { __typename: 'OfferCategoryWithTranslations' }
    & Pick<OfferCategoryWithTranslations, 'id' | 'nameEn' | 'nameNo' | 'nameSv' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'position' | 'region' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type DeleteOfferCategoryMutationVariables = Exact<{
  offerCategoryId: Scalars['ID'];
}>;


export type DeleteOfferCategoryMutation = (
  { __typename: 'billkillMutation' }
  & { remainingCategories?: Maybe<Array<(
    { __typename: 'OfferCategoryWithTranslations' }
    & Pick<OfferCategoryWithTranslations, 'id' | 'nameEn' | 'nameNo' | 'nameSv' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'position' | 'region' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type CreateOfferMutationVariables = Exact<{
  name: Scalars['String'];
  descriptionEn: Scalars['String'];
  descriptionNo: Scalars['String'];
  descriptionSv: Scalars['String'];
  pictureBase64: Scalars['String'];
  url: Scalars['String'];
  categoryId: Scalars['ID'];
}>;


export type CreateOfferMutation = (
  { __typename: 'billkillMutation' }
  & { offer: (
    { __typename: 'OfferWithTranslations' }
    & Pick<OfferWithTranslations, 'id' | 'name' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'picture' | 'status' | 'url' | 'createdAt' | 'updatedAt'>
    & { category: (
      { __typename: 'OfferCategoryWithTranslations' }
      & Pick<OfferCategoryWithTranslations, 'id' | 'nameEn' | 'nameNo' | 'nameSv' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'createdAt' | 'updatedAt'>
    ) }
  ) }
);

export type CreateOfferServiceMutationVariables = Exact<{
  offerId: Scalars['ID'];
  nameEn: Scalars['String'];
  nameNo: Scalars['String'];
  nameSv: Scalars['String'];
  descriptionEn: Scalars['String'];
  descriptionNo: Scalars['String'];
  descriptionSv: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailDescriptionEn?: InputMaybe<Scalars['String']>;
  emailDescriptionNo?: InputMaybe<Scalars['String']>;
  emailDescriptionSv?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
}>;


export type CreateOfferServiceMutation = (
  { __typename: 'billkillMutation' }
  & { offerService: (
    { __typename: 'OfferServiceWithTranslations' }
    & Pick<OfferServiceWithTranslations, 'id' | 'nameEn' | 'nameNo' | 'nameSv' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'url' | 'email' | 'emailDescriptionEn' | 'emailDescriptionNo' | 'emailDescriptionSv' | 'phone' | 'createdAt' | 'updatedAt'>
  ) }
);

export type UpdateOfferMutationVariables = Exact<{
  offerId: Scalars['ID'];
  categoryId: Scalars['ID'];
  name: Scalars['String'];
  descriptionEn: Scalars['String'];
  descriptionNo: Scalars['String'];
  descriptionSv: Scalars['String'];
  status: OfferStatus;
  pictureBase64?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
}>;


export type UpdateOfferMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<Array<(
    { __typename: 'OfferWithTranslations' }
    & Pick<OfferWithTranslations, 'id' | 'name' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'picture' | 'status' | 'url' | 'createdAt' | 'updatedAt'>
    & { category: (
      { __typename: 'OfferCategoryWithTranslations' }
      & Pick<OfferCategoryWithTranslations, 'id' | 'nameEn' | 'nameNo' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'createdAt' | 'updatedAt'>
    ) }
  )>> }
);

export type UpdateOfferServiceMutationVariables = Exact<{
  offerId: Scalars['ID'];
  offerServiceId: Scalars['ID'];
  nameEn: Scalars['String'];
  nameNo: Scalars['String'];
  nameSv: Scalars['String'];
  descriptionEn: Scalars['String'];
  descriptionNo: Scalars['String'];
  descriptionSv: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailDescriptionEn?: InputMaybe<Scalars['String']>;
  emailDescriptionNo?: InputMaybe<Scalars['String']>;
  emailDescriptionSv?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
}>;


export type UpdateOfferServiceMutation = (
  { __typename: 'billkillMutation' }
  & { offerService?: Maybe<Array<(
    { __typename: 'OfferServiceWithTranslations' }
    & Pick<OfferServiceWithTranslations, 'id' | 'nameEn' | 'nameNo' | 'nameSv' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'url' | 'email' | 'emailDescriptionEn' | 'emailDescriptionNo' | 'emailDescriptionSv' | 'phone' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type DeleteOfferMutationVariables = Exact<{
  offerId: Scalars['ID'];
  categoryId: Scalars['ID'];
}>;


export type DeleteOfferMutation = (
  { __typename: 'billkillMutation' }
  & { offers?: Maybe<Array<(
    { __typename: 'OfferWithTranslations' }
    & Pick<OfferWithTranslations, 'id' | 'name' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'picture' | 'status' | 'url' | 'createdAt' | 'updatedAt'>
    & { category: (
      { __typename: 'OfferCategoryWithTranslations' }
      & Pick<OfferCategoryWithTranslations, 'id' | 'nameEn' | 'nameNo' | 'nameSv' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'createdAt' | 'updatedAt'>
    ) }
  )>> }
);

export type DeleteOfferServiceMutationVariables = Exact<{
  offerId: Scalars['ID'];
  offerServiceId: Scalars['ID'];
}>;


export type DeleteOfferServiceMutation = (
  { __typename: 'billkillMutation' }
  & { remainingOfferServices?: Maybe<Array<(
    { __typename: 'OfferServiceWithTranslations' }
    & Pick<OfferServiceWithTranslations, 'id' | 'nameEn' | 'nameNo' | 'nameSv' | 'descriptionEn' | 'descriptionNo' | 'descriptionSv' | 'url' | 'email' | 'phone' | 'createdAt' | 'updatedAt'>
  )>> }
);

export type ReadOfferLeadsStatisticsQueryVariables = Exact<{
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
}>;


export type ReadOfferLeadsStatisticsQuery = (
  { __typename: 'billkillQuery' }
  & { stats: (
    { __typename: 'OfferLeadStatistics' }
    & Pick<OfferLeadStatistics, 'totalViewCount' | 'totalClickCount' | 'totalEmailSubmitCount'>
    & { offers?: Maybe<Array<(
      { __typename: 'OfferLeadStatisticsOffer' }
      & Pick<OfferLeadStatisticsOffer, 'id' | 'name' | 'viewCount' | 'clickCount' | 'emailSubmitCount'>
      & { services?: Maybe<Array<(
        { __typename: 'OfferLeadStatisticsService' }
        & Pick<OfferLeadStatisticsService, 'id' | 'name' | 'clickCount' | 'emailSubmitCount'>
      )>> }
    )>> }
  ) }
);

export type HandlePepRequestMutationVariables = Exact<{
  userId: Scalars['ID'];
  decision: PepDecision;
}>;


export type HandlePepRequestMutation = (
  { __typename: 'billkillMutation' }
  & { result?: Maybe<(
    { __typename: 'Permission' }
    & { einvoice?: Maybe<(
      { __typename: 'EInvoice' }
      & Pick<EInvoice, 'acceptAllIssuers'>
    )>, pep?: Maybe<(
      { __typename: 'PEP' }
      & Pick<Pep, 'status'>
    )> }
  )> }
);

export type ReadAdminProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadAdminProfileQuery = (
  { __typename: 'billkillQuery' }
  & { profile: (
    { __typename: 'ProfileAdmin' }
    & Pick<ProfileAdmin, 'id' | 'firstname' | 'lastname' | 'subRole'>
  ) }
);

export type RemoveAccountMutationVariables = Exact<{
  userId: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
}>;


export type RemoveAccountMutation = (
  { __typename: 'billkillMutation' }
  & { user: (
    { __typename: 'Profile' }
    & Pick<Profile, 'id' | 'sid' | 'firstname' | 'lastname' | 'birthdate' | 'email' | 'emailCheck' | 'mobile' | 'mobileCheck'>
    & { address?: Maybe<(
      { __typename: 'Address' }
      & Pick<Address, 'street' | 'postalCode' | 'city'>
      & { country?: Maybe<(
        { __typename: 'Country' }
        & Pick<Country, 'name'>
      )> }
    )> }
  ) }
);

export type ReadPspTransactionsQueryVariables = Exact<{
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  isConnected: Scalars['Int'];
  statusType: Scalars['String'];
  status: Scalars['String'];
  type: Scalars['String'];
  provider: Scalars['String'];
  search: Scalars['String'];
  circuits?: InputMaybe<Array<CardCircuit> | CardCircuit>;
}>;


export type ReadPspTransactionsQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<(
    { __typename: 'PspTransactionList' }
    & { transactions: Array<(
      { __typename: 'PspTransactionType' }
      & Pick<PspTransactionType, 'id' | 'remoteCreatedAt' | 'localCreatedAt' | 'bkInvoiceTransactionId' | 'bkUserSubscriptionId' | 'bkInvoiceId' | 'bkUserId' | 'bkType' | 'bkStatus' | 'bkAction' | 'bkProvider' | 'transactionNumber' | 'paymentMethod' | 'type' | 'amount' | 'vatAmount' | 'currency' | 'paymentId' | 'userEmail' | 'userName' | 'issuerName' | 'reconciliationId' | 'subscriptionDuration' | 'userSubscriptionStartsAt' | 'userSubscriptionEndsAt' | 'circuit'>
    )> }
  )> }
);

export type ReadPspTransactionsAsExcelQueryVariables = Exact<{
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  isConnected: Scalars['Int'];
  statusType: Scalars['String'];
  status: Scalars['String'];
  type: Scalars['String'];
  provider: Scalars['String'];
  search: Scalars['String'];
  circuits?: InputMaybe<Array<CardCircuit> | CardCircuit>;
}>;


export type ReadPspTransactionsAsExcelQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<(
    { __typename: 'PspTransactionListAsExcel' }
    & Pick<PspTransactionListAsExcel, 'data'>
  )> }
);

export type ReadPspTransactionByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReadPspTransactionByIdQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<(
    { __typename: 'PspTransactionType' }
    & Pick<PspTransactionType, 'id' | 'remoteCreatedAt' | 'localCreatedAt' | 'bkInvoiceTransactionId' | 'bkUserSubscriptionId' | 'bkInvoiceId' | 'bkUserId' | 'bkType' | 'bkStatus' | 'bkAction' | 'bkProvider' | 'transactionNumber' | 'paymentMethod' | 'type' | 'amount' | 'vatAmount' | 'currency' | 'paymentId' | 'userEmail' | 'userName' | 'issuerName' | 'reconciliationId' | 'subscriptionDuration' | 'userSubscriptionStartsAt' | 'userSubscriptionEndsAt'>
  )> }
);

export type ReadPspTransactionLogsByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReadPspTransactionLogsByIdQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<(
    { __typename: 'PspTransactionLogListType' }
    & { logs: Array<(
      { __typename: 'PspTransactionLogType' }
      & Pick<PspTransactionLogType, 'time' | 'event' | 'ipAddress' | 'url' | 'data'>
    )> }
  )> }
);

export type GetPspTransactionIdByInvoiceIdQueryVariables = Exact<{
  invoiceId: Scalars['String'];
}>;


export type GetPspTransactionIdByInvoiceIdQuery = (
  { __typename: 'billkillQuery' }
  & { pspTransactionId: BillkillQuery['GetPspTransactionIdByInvoiceId'] }
);

export type ReadSubscriptionIncomeOverviewQueryVariables = Exact<{
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
}>;


export type ReadSubscriptionIncomeOverviewQuery = (
  { __typename: 'billkillQuery' }
  & { result: (
    { __typename: 'SubscriptionIncomeOverview' }
    & Pick<SubscriptionIncomeOverview, 'total' | 'totalCoupon' | 'totalEffective'>
    & { subtotals?: Maybe<Array<(
      { __typename: 'SubscriptionIncomeSubtotal' }
      & Pick<SubscriptionIncomeSubtotal, 'amount' | 'count' | 'amountCoupon' | 'countCoupon'>
      & { subscription: (
        { __typename: 'SubscriptionIncomeInfo' }
        & Pick<SubscriptionIncomeInfo, 'id' | 'name'>
      ) }
    )>>, subtotalsCoupons?: Maybe<Array<(
      { __typename: 'SubscriptionCouponSubtotal' }
      & Pick<SubscriptionCouponSubtotal, 'code' | 'count'>
    )>> }
  ) }
);

export type ReadUserSubscriptionsByUserIdQueryVariables = Exact<{
  userId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
}>;


export type ReadUserSubscriptionsByUserIdQuery = (
  { __typename: 'billkillQuery' }
  & { userSubscriptions?: Maybe<(
    { __typename: 'SubscriptionConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename: 'SubscriptionEdge' }
      & { node?: Maybe<(
        { __typename: 'UserSubscription' }
        & Pick<UserSubscription, 'id' | 'userId' | 'startsAt' | 'endsAt' | 'autoRenewal' | 'renewalStatus' | 'isFreeTrial' | 'isRefunded' | 'transactionRef'>
        & { paymentCard: (
          { __typename: 'Card' }
          & Pick<Card, 'id' | 'name' | 'customName' | 'brand' | 'expiration' | 'isDefault'>
        ), renewalCard: (
          { __typename: 'Card' }
          & Pick<Card, 'id' | 'name' | 'customName' | 'brand' | 'expiration' | 'isDefault'>
        ), subscription: (
          { __typename: 'Subscription' }
          & Pick<Subscription, 'id' | 'type' | 'amount' | 'period' | 'welcomePoints'>
        ), coupon?: Maybe<(
          { __typename: 'Coupon' }
          & Pick<Coupon, 'id' | 'code' | 'amount'>
        )> }
      )> }
    )>>> }
  )> }
);

export type RefundSubscriptionMutationVariables = Exact<{
  userId: Scalars['ID'];
  userSubscriptionId: Scalars['ID'];
  amount: Scalars['String'];
}>;


export type RefundSubscriptionMutation = (
  { __typename: 'billkillMutation' }
  & { subscription: (
    { __typename: 'UserSubscription' }
    & Pick<UserSubscription, 'id' | 'userId' | 'startsAt' | 'endsAt' | 'autoRenewal' | 'renewalStatus' | 'isFreeTrial' | 'isRefunded' | 'transactionRef'>
    & { renewalCard: (
      { __typename: 'Card' }
      & Pick<Card, 'id' | 'name' | 'customName' | 'brand' | 'expiration' | 'isDefault'>
    ), subscription: (
      { __typename: 'Subscription' }
      & Pick<Subscription, 'id' | 'type' | 'amount' | 'period' | 'welcomePoints'>
    ) }
  ) }
);

export type AdminChangeSubscriptionMutationVariables = Exact<{
  userId: Scalars['ID'];
  userSubscriptionId: Scalars['ID'];
  autoRenewal?: InputMaybe<Scalars['Boolean']>;
  endsAt?: InputMaybe<Scalars['String']>;
}>;


export type AdminChangeSubscriptionMutation = (
  { __typename: 'billkillMutation' }
  & { subscription: (
    { __typename: 'UserSubscription' }
    & Pick<UserSubscription, 'id' | 'userId' | 'startsAt' | 'endsAt' | 'autoRenewal' | 'renewalStatus' | 'isFreeTrial' | 'isRefunded' | 'transactionRef'>
    & { renewalCard: (
      { __typename: 'Card' }
      & Pick<Card, 'id' | 'name' | 'customName' | 'brand' | 'expiration' | 'isDefault'>
    ), subscription: (
      { __typename: 'Subscription' }
      & Pick<Subscription, 'id' | 'type' | 'amount' | 'period' | 'welcomePoints'>
    ) }
  ) }
);

export type ReadSubscriptionsQueryVariables = Exact<{
  subscriptionType: SubscriptionType;
}>;


export type ReadSubscriptionsQuery = (
  { __typename: 'billkillQuery' }
  & { subscriptions?: Maybe<Array<(
    { __typename: 'Subscription' }
    & Pick<Subscription, 'id' | 'type' | 'amount' | 'period' | 'welcomePoints' | 'couponProduct'>
  )>> }
);

export type CreateSubscriptionMutationVariables = Exact<{
  subscriptionIdToReplace: Scalars['ID'];
  welcomePoints: Scalars['Int'];
  amount: Scalars['Float'];
  replaceMode: SubscriptionReplaceMode;
}>;


export type CreateSubscriptionMutation = (
  { __typename: 'billkillMutation' }
  & { subscription?: Maybe<Array<(
    { __typename: 'Subscription' }
    & Pick<Subscription, 'id' | 'type' | 'amount' | 'period' | 'welcomePoints'>
  )>> }
);

export type ReadJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadJobsQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<Array<Maybe<(
    { __typename: 'Job' }
    & Pick<Job, 'name' | 'description' | 'when'>
  )>>> }
);

export type ReadStatisticsInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadStatisticsInvoicesQuery = (
  { __typename: 'billkillQuery' }
  & { statistics: (
    { __typename: 'StatisticsInvoices' }
    & Pick<StatisticsInvoices, 'lastUpdate'>
    & { overview: (
      { __typename: 'StatisticsInvoicesOverview' }
      & Pick<StatisticsInvoicesOverview, 'totalCount' | 'totalPaidCount' | 'totalAmount'>
    ), histograms: (
      { __typename: 'StatisticsInvoicesHistograms' }
      & { cumulativeCount?: Maybe<Array<Maybe<(
        { __typename: 'HistogramInvoicesCountCumulative' }
        & Pick<HistogramInvoicesCountCumulative, 'count' | 'paidCount' | 'time'>
      )>>>, paidAmount?: Maybe<Array<Maybe<(
        { __typename: 'HistogramInvoicesPaidAmount' }
        & Pick<HistogramInvoicesPaidAmount, 'amount' | 'count' | 'time'>
      )>>> }
    ) }
  ) }
);

export type ReadStatisticsIssuersQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadStatisticsIssuersQuery = (
  { __typename: 'billkillQuery' }
  & { statistics: (
    { __typename: 'StatisticsIssuers' }
    & Pick<StatisticsIssuers, 'lastUpdate'>
    & { overview: (
      { __typename: 'StatisticsIssuersOverview' }
      & Pick<StatisticsIssuersOverview, 'totalCount' | 'totalLogoCount' | 'totalSupportCount' | 'totalInvoiceRequests' | 'totalDebtRequests'>
    ), histograms: (
      { __typename: 'StatisticsIssuersHistograms' }
      & { registration?: Maybe<Array<Maybe<(
        { __typename: 'HistogramIssuersRegistration' }
        & Pick<HistogramIssuersRegistration, 'count' | 'time'>
      )>>>, cumulativeRegistration?: Maybe<Array<Maybe<(
        { __typename: 'HistogramIssuersRegistrationCumulative' }
        & Pick<HistogramIssuersRegistrationCumulative, 'count' | 'logoCount' | 'supportCount' | 'time'>
      )>>>, requests?: Maybe<Array<Maybe<(
        { __typename: 'HistogramIssuersRequests' }
        & Pick<HistogramIssuersRequests, 'debtCount' | 'invoiceCount' | 'time'>
      )>>>, cumulativeRequests?: Maybe<Array<Maybe<(
        { __typename: 'HistogramIssuersRequestsCumulative' }
        & Pick<HistogramIssuersRequestsCumulative, 'debtCount' | 'invoiceCount' | 'time'>
      )>>> }
    ) }
  ) }
);

export type ReadStatisticsUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadStatisticsUsersQuery = (
  { __typename: 'billkillQuery' }
  & { statistics: (
    { __typename: 'StatisticsUsers' }
    & Pick<StatisticsUsers, 'lastUpdate'>
    & { overview: (
      { __typename: 'StatisticsUsersOverview' }
      & Pick<StatisticsUsersOverview, 'totalCount' | 'totalActiveCount' | 'totalInactiveCount' | 'totalDeletedCount' | 'ageAverage'>
    ), histograms: (
      { __typename: 'StatisticsUsersHistograms' }
      & { registration?: Maybe<Array<Maybe<(
        { __typename: 'HistogramUsersRegistration' }
        & Pick<HistogramUsersRegistration, 'count' | 'ageAverage' | 'activeCount' | 'inactiveCount' | 'deletedCount' | 'time'>
      )>>>, cumulativeRegistration?: Maybe<Array<Maybe<(
        { __typename: 'HistogramUsersRegistration' }
        & Pick<HistogramUsersRegistration, 'count' | 'ageAverage' | 'activeCount' | 'inactiveCount' | 'deletedCount' | 'time'>
      )>>> }
    ) }
  ) }
);

export type ReadStatisticsChurnQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadStatisticsChurnQuery = (
  { __typename: 'billkillQuery' }
  & { churnStats?: Maybe<Array<Maybe<(
    { __typename: 'ChurnStat' }
    & Pick<ChurnStat, 'Year' | 'Month' | 'Count'>
  )>>> }
);

export type ReadStatisticsAccessQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadStatisticsAccessQuery = (
  { __typename: 'billkillQuery' }
  & { statistics: (
    { __typename: 'StatisticsAccess' }
    & Pick<StatisticsAccess, 'lastUpdate'>
    & { histograms: (
      { __typename: 'StatisticsAccessHistograms' }
      & { access?: Maybe<Array<Maybe<(
        { __typename: 'HistogramAccess' }
        & Pick<HistogramAccess, 'count' | 'countLogout' | 'countBankIdSignature' | 'countBankIdSession' | 'countDeviceIos' | 'countDeviceAndroid' | 'time'>
      )>>> }
    ) }
  ) }
);

export type ReadStatisticsSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadStatisticsSubscriptionsQuery = (
  { __typename: 'billkillQuery' }
  & { statistics: (
    { __typename: 'StatisticsSubscriptions' }
    & Pick<StatisticsSubscriptions, 'lastUpdate'>
    & { overview: (
      { __typename: 'StatisticsSubscriptionsOverview' }
      & Pick<StatisticsSubscriptionsOverview, 'totalCount' | 'totalRefundedCount' | 'activeCount'>
      & { activeDistribution: Array<(
        { __typename: 'StatisticsSubscriptionsDistribution' }
        & Pick<StatisticsSubscriptionsDistribution, 'count'>
        & { subscription: (
          { __typename: 'StatisticsSubscriptionInfo' }
          & Pick<StatisticsSubscriptionInfo, 'id' | 'type' | 'period' | 'amount'>
        ) }
      )> }
    ), histograms: (
      { __typename: 'StatisticsSubscriptionsHistograms' }
      & { overview: Array<(
        { __typename: 'HistogramSubscriptionsOverview' }
        & Pick<HistogramSubscriptionsOverview, 'count' | 'refundedCount' | 'activeCount' | 'time'>
      )> }
    ) }
  ) }
);

export type ReadLanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadLanguagesQuery = (
  { __typename: 'billkillQuery' }
  & { languages: Array<(
    { __typename: 'Language' }
    & Pick<Language, 'id' | 'code' | 'name'>
  )> }
);

export type ReadTranslationCodesQueryVariables = Exact<{
  searchTerm?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;


export type ReadTranslationCodesQuery = (
  { __typename: 'billkillQuery' }
  & { translationCodes?: Maybe<(
    { __typename: 'TranslationCodesConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename: 'TranslationCodesEdge' }
      & Pick<TranslationCodesEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename: 'TranslationCode' }
        & Pick<TranslationCode, 'id' | 'code'>
      )> }
    )>>>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type ReadTranslationQueryVariables = Exact<{
  codeId: Scalars['ID'];
  languageId: Scalars['ID'];
}>;


export type ReadTranslationQuery = (
  { __typename: 'billkillQuery' }
  & { translation: (
    { __typename: 'Translation' }
    & Pick<Translation, 'id' | 'codeId' | 'languageId' | 'translation'>
  ) }
);

export type ReadTranslationsQueryVariables = Exact<{
  codeId: Scalars['ID'];
}>;


export type ReadTranslationsQuery = (
  { __typename: 'billkillQuery' }
  & { translations: (
    { __typename: 'Translations' }
    & { code: (
      { __typename: 'TranslationCode' }
      & Pick<TranslationCode, 'id' | 'code'>
    ), translations: Array<(
      { __typename: 'TranslationsItem' }
      & { language: (
        { __typename: 'Language' }
        & Pick<Language, 'id' | 'code' | 'name'>
      ), translation: (
        { __typename: 'Translation' }
        & Pick<Translation, 'id' | 'codeId' | 'languageId' | 'translation'>
      ) }
    )> }
  ) }
);

export type SetTranslationMutationVariables = Exact<{
  languageId: Scalars['ID'];
  code: Scalars['String'];
  translation: Scalars['String'];
}>;


export type SetTranslationMutation = (
  { __typename: 'billkillMutation' }
  & { translation: (
    { __typename: 'Translation' }
    & Pick<Translation, 'id' | 'codeId' | 'languageId' | 'translation'>
  ) }
);

export type DeleteTranslationsByCodeMutationVariables = Exact<{
  codeId: Scalars['ID'];
}>;


export type DeleteTranslationsByCodeMutation = (
  { __typename: 'billkillMutation' }
  & { translations: Array<(
    { __typename: 'TranslationCode' }
    & Pick<TranslationCode, 'id' | 'code'>
  )> }
);

export type ReadAllUsersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  filterByName?: InputMaybe<Scalars['String']>;
  filterByCheckedEmail?: InputMaybe<Scalars['Boolean']>;
  filterByCheckedMobile?: InputMaybe<Scalars['Boolean']>;
  filterByActivity?: InputMaybe<UserActivityEnum>;
  filterByRegion?: InputMaybe<RegionEnum>;
  descId?: InputMaybe<Scalars['Boolean']>;
  acceptEfaktura?: InputMaybe<Scalars['Boolean']>;
}>;


export type ReadAllUsersQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<(
    { __typename: 'UserConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename: 'UserEdge' }
      & Pick<UserEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'region' | 'id' | 'firstname' | 'lastname' | 'email' | 'emailCheck' | 'mobileCheck' | 'createdAt'>
        & { permission: (
          { __typename: 'UserPermission' }
          & { einvoice: (
            { __typename: 'UserPermissionEInvoice' }
            & Pick<UserPermissionEInvoice, 'acceptAllIssuers'>
          ), pep: (
            { __typename: 'UserPermissionPEP' }
            & Pick<UserPermissionPep, 'status'>
          ) }
        ) }
      )> }
    )>>>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type ReadUserByIdQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type ReadUserByIdQuery = (
  { __typename: 'billkillQuery' }
  & { result?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'status' | 'firstname' | 'lastname' | 'birthdate' | 'email' | 'emailCheck' | 'mobile' | 'mobileCheck' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'region'>
    & { address?: Maybe<(
      { __typename: 'Address' }
      & Pick<Address, 'street' | 'postalCode' | 'city'>
      & { country?: Maybe<(
        { __typename: 'Country' }
        & Pick<Country, 'code'>
      )> }
    )>, permission: (
      { __typename: 'UserPermission' }
      & { einvoice: (
        { __typename: 'UserPermissionEInvoice' }
        & Pick<UserPermissionEInvoice, 'acceptAllIssuers'>
      ), pep: (
        { __typename: 'UserPermissionPEP' }
        & Pick<UserPermissionPep, 'status'>
      ) }
    ) }
  )> }
);

export type ReadUserActivitiesQueryVariables = Exact<{
  userId: Scalars['ID'];
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
}>;


export type ReadUserActivitiesQuery = (
  { __typename: 'billkillQuery' }
  & { result: Array<(
    { __typename: 'LogWithReference' }
    & Pick<LogWithReference, 'time' | 'component' | 'event' | 'status' | 'error' | 'userId' | 'issuerId' | 'cardId' | 'invoiceId'>
    & { details: (
      { __typename: 'LogDetails' }
      & Pick<LogDetails, 'context' | 'stackTrace'>
    ) }
  )> }
);

export type ReadEmailsForNewsletterQueryVariables = Exact<{
  acceptEInvoice?: InputMaybe<Scalars['Boolean']>;
  filterByActivity?: InputMaybe<UserActivityEnum>;
  filterByCheckedMobile?: InputMaybe<Scalars['Boolean']>;
  filterByName?: InputMaybe<Scalars['String']>;
  descId?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<RegionEnum>;
}>;


export type ReadEmailsForNewsletterQuery = (
  { __typename: 'billkillQuery' }
  & { readEmailsForNewsletter?: Maybe<(
    { __typename: 'NewsletterEmails' }
    & Pick<NewsletterEmails, 'emails'>
  )> }
);

export type UpdateUserStatusMutationVariables = Exact<{
  userId: Scalars['ID'];
  status: UserStatusEnum;
}>;


export type UpdateUserStatusMutation = (
  { __typename: 'billkillMutation' }
  & { user: (
    { __typename: 'User' }
    & Pick<User, 'id' | 'status' | 'firstname' | 'lastname' | 'birthdate' | 'email' | 'emailCheck' | 'mobile' | 'mobileCheck' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'region'>
    & { address?: Maybe<(
      { __typename: 'Address' }
      & Pick<Address, 'street' | 'postalCode' | 'city'>
      & { country?: Maybe<(
        { __typename: 'Country' }
        & Pick<Country, 'code'>
      )> }
    )>, permission: (
      { __typename: 'UserPermission' }
      & { einvoice: (
        { __typename: 'UserPermissionEInvoice' }
        & Pick<UserPermissionEInvoice, 'acceptAllIssuers'>
      ), pep: (
        { __typename: 'UserPermissionPEP' }
        & Pick<UserPermissionPep, 'status'>
      ) }
    ) }
  ) }
);

export type ReadUserGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadUserGroupsQuery = (
  { __typename: 'billkillQuery' }
  & { userGroups: Array<(
    { __typename: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'name' | 'description' | 'region'>
  )> }
);

export type CreateUserGroupMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
  region: RegionEnum;
}>;


export type CreateUserGroupMutation = (
  { __typename: 'billkillMutation' }
  & { userGroup: (
    { __typename: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'name' | 'description' | 'region'>
  ) }
);

export type UpdateUserGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
}>;


export type UpdateUserGroupMutation = (
  { __typename: 'billkillMutation' }
  & { userGroup: (
    { __typename: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'name' | 'description' | 'region'>
  ) }
);

export type DeleteUserGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type DeleteUserGroupMutation = (
  { __typename: 'billkillMutation' }
  & { userGroups: Array<(
    { __typename: 'UserGroup' }
    & Pick<UserGroup, 'id' | 'name' | 'description' | 'region'>
  )> }
);

export type ReadUserGroupMembersQueryVariables = Exact<{
  groupId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
}>;


export type ReadUserGroupMembersQuery = (
  { __typename: 'billkillQuery' }
  & { userGroupMembers: Array<(
    { __typename: 'UserGroupMember' }
    & Pick<UserGroupMember, 'id' | 'name' | 'userId'>
  )> }
);

export type CreateUserGroupMemberMutationVariables = Exact<{
  userId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type CreateUserGroupMemberMutation = (
  { __typename: 'billkillMutation' }
  & { userGroupMember: (
    { __typename: 'UserGroupMember' }
    & Pick<UserGroupMember, 'id' | 'name' | 'userId'>
  ) }
);

export type DeleteUserGroupMemberMutationVariables = Exact<{
  memberId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type DeleteUserGroupMemberMutation = (
  { __typename: 'billkillMutation' }
  & { userGroupMembers: Array<(
    { __typename: 'UserGroupMember' }
    & Pick<UserGroupMember, 'id' | 'name' | 'userId'>
  )> }
);

export type CreateUserNoteMutationVariables = Exact<{
  userId: Scalars['ID'];
  content: Scalars['String'];
  noteTime: Scalars['DateTime'];
}>;


export type CreateUserNoteMutation = (
  { __typename: 'billkillMutation' }
  & { note: (
    { __typename: 'UserNote' }
    & Pick<UserNote, 'id' | 'content' | 'noteTime'>
  ) }
);

export type UpdateUserNoteMutationVariables = Exact<{
  id: Scalars['ID'];
  userId: Scalars['ID'];
  content?: InputMaybe<Scalars['String']>;
  noteTime?: InputMaybe<Scalars['DateTime']>;
}>;


export type UpdateUserNoteMutation = (
  { __typename: 'billkillMutation' }
  & { note: (
    { __typename: 'UserNote' }
    & Pick<UserNote, 'id' | 'content' | 'noteTime'>
  ) }
);

export type ReadUserNotesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
}>;


export type ReadUserNotesQuery = (
  { __typename: 'billkillQuery' }
  & { notes?: Maybe<(
    { __typename: 'UserNoteConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename: 'UserNoteEdge' }
      & { node?: Maybe<(
        { __typename: 'UserNote' }
        & Pick<UserNote, 'id' | 'content' | 'noteTime'>
      )> }
    )>>>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type DeleteUserNoteMutationVariables = Exact<{
  id: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type DeleteUserNoteMutation = (
  { __typename: 'billkillMutation' }
  & { note: (
    { __typename: 'UserNote' }
    & Pick<UserNote, 'id' | 'content' | 'noteTime'>
  ) }
);

export type ReadZenExtraBalanceQueryVariables = Exact<{
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
}>;


export type ReadZenExtraBalanceQuery = (
  { __typename: 'billkillQuery' }
  & { readZenExtraBalance: (
    { __typename: 'BalanceZenHistoryType' }
    & Pick<BalanceZenHistoryType, 'TotalEarnedExtra' | 'TotalUsedExtra' | 'TotalUnusedExtra' | 'TotalEarnedExtraByInvoice' | 'TotalEarnedExtraByInvitation' | 'TotalEarnedExtraStandaloneSubscription'>
    & { ZenPointExt?: Maybe<Array<(
      { __typename: 'ZenPointExtType' }
      & Pick<ZenPointExtType, 'UserName' | 'UserEmail' | 'InvoiceReference' | 'IssuerId' | 'IssuerName' | 'Reference' | 'Amount' | 'CurrencyCode' | 'RemitDate' | 'ZenFee' | 'FeeAmount'>
      & { ZenPoint: (
        { __typename: 'ZenPointType' }
        & Pick<ZenPointType, 'Id' | 'UserId' | 'RefId' | 'InviterId' | 'FriendId' | 'InvoiceId' | 'InvitationCode' | 'Memo' | 'BeltNo' | 'ProcessDate' | 'CreatedAt' | 'UpdatedAt'>
        & { Points: (
          { __typename: 'Points' }
          & Pick<Points, 'Basic' | 'Extra' | 'Proposal'>
        ), Balance: (
          { __typename: 'Balance' }
          & Pick<Balance, 'Basic' | 'Extra'>
        ), ProcessState: (
          { __typename: 'ProcessState' }
          & Pick<ProcessState, 'Name' | 'Value'>
        ), Status: (
          { __typename: 'Status' }
          & Pick<Status, 'Name' | 'Value'>
        ), Type: (
          { __typename: 'typeType' }
          & Pick<TypeType, 'Name' | 'Value'>
        ), Reason: (
          { __typename: 'Reason' }
          & Pick<Reason, 'Name' | 'Value'>
        ), Role: (
          { __typename: 'RoleType' }
          & Pick<RoleType, 'Name' | 'Value'>
        ) }
      ) }
    )>> }
  ) }
);

export type AdminReadUserZenHistoryQueryVariables = Exact<{
  startId: Scalars['Int'];
  userId: Scalars['ID'];
}>;


export type AdminReadUserZenHistoryQuery = (
  { __typename: 'billkillQuery' }
  & { readUserZenHistory: Array<(
    { __typename: 'UserZenPointsHistory' }
    & Pick<UserZenPointsHistory, 'Id' | 'FriendId' | 'InvoiceIdentifier' | 'InvitationCode' | 'Memo' | 'UpdatedAt' | 'ProcessDate'>
    & { Points: (
      { __typename: 'Points' }
      & Pick<Points, 'Proposal' | 'Basic' | 'Extra'>
    ), Balance: (
      { __typename: 'Balance' }
      & Pick<Balance, 'Basic' | 'Extra'>
    ), Status: (
      { __typename: 'Status' }
      & Pick<Status, 'Value' | 'Name'>
    ), ProcessState: (
      { __typename: 'ProcessState' }
      & Pick<ProcessState, 'Value' | 'Name'>
    ), Type: (
      { __typename: 'typeType' }
      & Pick<TypeType, 'Value' | 'Name'>
    ), Role: (
      { __typename: 'RoleType' }
      & Pick<RoleType, 'Value' | 'Name'>
    ), Reason: (
      { __typename: 'Reason' }
      & Pick<Reason, 'Value' | 'Name'>
    ), Invoice?: Maybe<(
      { __typename: 'Invoice' }
      & Pick<Invoice, 'id' | 'reference'>
    )> }
  )> }
);

export type ReadSystemZenExtraBalanceQueryVariables = Exact<{
  date: Scalars['DateTime'];
}>;


export type ReadSystemZenExtraBalanceQuery = (
  { __typename: 'billkillQuery' }
  & Pick<BillkillQuery, 'readSystemZenExtraBalance'>
);

export type ZenCostListQueryVariables = Exact<{ [key: string]: never; }>;


export type ZenCostListQuery = (
  { __typename: 'billkillQuery' }
  & { zenCostList: Array<(
    { __typename: 'ZenCostType' }
    & Pick<ZenCostType, 'id' | 'typ' | 'typeName' | 'static' | 'multiplier' | 'ts'>
  )> }
);

export type UpdateZenCostMutationVariables = Exact<{
  typeId: Scalars['Int'];
  multiplier?: InputMaybe<Scalars['Int']>;
  static?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateZenCostMutation = (
  { __typename: 'billkillMutation' }
  & { updateZenCost: (
    { __typename: 'ZenCostType' }
    & Pick<ZenCostType, 'id' | 'typ' | 'typeName' | 'static' | 'multiplier' | 'ts'>
  ) }
);

export type ManualZenCorrectionMutationVariables = Exact<{
  userId: Scalars['ID'];
  extra?: InputMaybe<Scalars['Int']>;
  basic?: InputMaybe<Scalars['Int']>;
  memo: Scalars['String'];
}>;


export type ManualZenCorrectionMutation = (
  { __typename: 'billkillMutation' }
  & { manualZenCorrection?: Maybe<(
    { __typename: 'BooleanResult' }
    & Pick<BooleanResult, 'isOk'>
  )> }
);

export const CouponResultFragmentDoc = gql`
    fragment CouponResult on Coupon {
  id
  amount
  code
  startsAt
  endsAt
  period
  rule
  recipient
  recipientGroup {
    id
    name
    description
    region
  }
  description
  descriptionId
  product
  region
}
    `;
export const IndustryCodeResultFragmentDoc = gql`
    fragment IndustryCodeResult on IndustryCode {
  id
  createdAt
  country
  industryCode
  description
  merchantCategoryCode
  region
}
    `;
export const InvoiceResultFragmentDoc = gql`
    fragment InvoiceResult on Invoice {
  id
  issuer {
    name
    orgNumber
  }
  kind
  lastTransaction {
    amount
  }
  currency {
    symbol
  }
  fee {
    amount
    percentage
    reason
    total
  }
  reference
  referenceProvider
  customerNumber
  invoiceDate
  dueDate
  user {
    id
    firstname
  }
}
    `;
export const InvoiceHistoryResultFragmentDoc = gql`
    fragment InvoiceHistoryResult on InvoiceHistory {
  id
  userId
  amount {
    original
    current
  }
  payment {
    incoming
    outgoing
  }
  lastTransaction {
    status
    statusDate
    remitStatus
  }
  transactions {
    time
    event
    status
    remitStatus
    remitStatusDescription
    amount
    reference
    details
    actor
    source
  }
}
    `;
export const IssuerResultFragmentDoc = gql`
    fragment IssuerResult on Issuer {
  id
  name
  orgNumber
  realOrgNumber
  subMerchantMcc
  implicitSubMerchantMcc
  industryCode
  picture
  createdAt
  region
}
    `;
export const IssuerDetailFragmentDoc = gql`
    fragment IssuerDetail on Issuer {
  id
  name
  orgNumber
  realOrgNumber
  subMerchantMcc
  implicitSubMerchantMcc
  industryCode
  brregStatusDesc
  brregForceUpdate
  picture
  email
  emailLoan
  createdAt
  updatedAt
  deletedAt
  region
  address {
    street
    postalCode
    city
    country {
      code
      name
    }
  }
}
    `;
export const IssuerBlockCompleteFragmentDoc = gql`
    fragment IssuerBlockComplete on IssuerTransactionRule {
  issuerId
  allowDebitCard
  allowCreditCard
  allowAmexCard
  maxAmountDebitCard
  maxAmountCreditCard
  maxAmountAmexCard
  isCustom
  createdAt
  updatedAt
}
    `;
export const TranslationCodeResultFragmentDoc = gql`
    fragment TranslationCodeResult on TranslationCode {
  id
  code
}
    `;
export const TranslationResultFragmentDoc = gql`
    fragment TranslationResult on Translation {
  id
  codeId
  languageId
  translation
}
    `;
export const UserResultFragmentDoc = gql`
    fragment UserResult on User {
  id
  firstname
  lastname
  email
  emailCheck
  mobileCheck
  createdAt
  region
  permission {
    einvoice {
      acceptAllIssuers
    }
    pep {
      status
    }
  }
}
    `;
export const UserDetailFragmentDoc = gql`
    fragment UserDetail on User {
  id
  status
  firstname
  lastname
  birthdate
  email
  emailCheck
  mobile
  mobileCheck
  createdAt
  updatedAt
  deletedAt
  region
  address {
    street
    postalCode
    city
    country {
      code
    }
  }
  permission {
    einvoice {
      acceptAllIssuers
    }
    pep {
      status
    }
  }
}
    `;
export const UserGroupResultFragmentDoc = gql`
    fragment UserGroupResult on UserGroup {
  id
  name
  description
  region
}
    `;
export const UserGroupMemberResultFragmentDoc = gql`
    fragment UserGroupMemberResult on UserGroupMember {
  id
  name
  userId
}
    `;
export const CreateBankIdSessionDocument = gql`
    mutation createBankIdSession($mobile: String!, $cbSuccess: String, $cbFailure: String, $cbAbort: String) {
  result: createBankIdSession(
    mobile: $mobile
    cbSuccess: $cbSuccess
    cbFailure: $cbFailure
    cbAbort: $cbAbort
  ) {
    id
    url
    callbacks {
      abort
      failure
      success
    }
  }
}
    `;
export type CreateBankIdSessionMutationFn = Apollo.MutationFunction<CreateBankIdSessionMutation, CreateBankIdSessionMutationVariables>;

/**
 * __useCreateBankIdSessionMutation__
 *
 * To run a mutation, you first call `useCreateBankIdSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankIdSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankIdSessionMutation, { data, loading, error }] = useCreateBankIdSessionMutation({
 *   variables: {
 *      mobile: // value for 'mobile'
 *      cbSuccess: // value for 'cbSuccess'
 *      cbFailure: // value for 'cbFailure'
 *      cbAbort: // value for 'cbAbort'
 *   },
 * });
 */
export function useCreateBankIdSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateBankIdSessionMutation, CreateBankIdSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBankIdSessionMutation, CreateBankIdSessionMutationVariables>(CreateBankIdSessionDocument, options);
      }
export type CreateBankIdSessionMutationHookResult = ReturnType<typeof useCreateBankIdSessionMutation>;
export type CreateBankIdSessionMutationResult = Apollo.MutationResult<CreateBankIdSessionMutation>;
export type CreateBankIdSessionMutationOptions = Apollo.BaseMutationOptions<CreateBankIdSessionMutation, CreateBankIdSessionMutationVariables>;
export const CreateAccessFromSessionDocument = gql`
    mutation createAccessFromSession($sessionId: String!, $role: UserRoleEnum!) {
  result: createAccessFromSession(sessionId: $sessionId, role: $role) {
    token
    refreshToken
  }
}
    `;
export type CreateAccessFromSessionMutationFn = Apollo.MutationFunction<CreateAccessFromSessionMutation, CreateAccessFromSessionMutationVariables>;

/**
 * __useCreateAccessFromSessionMutation__
 *
 * To run a mutation, you first call `useCreateAccessFromSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccessFromSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccessFromSessionMutation, { data, loading, error }] = useCreateAccessFromSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useCreateAccessFromSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccessFromSessionMutation, CreateAccessFromSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccessFromSessionMutation, CreateAccessFromSessionMutationVariables>(CreateAccessFromSessionDocument, options);
      }
export type CreateAccessFromSessionMutationHookResult = ReturnType<typeof useCreateAccessFromSessionMutation>;
export type CreateAccessFromSessionMutationResult = Apollo.MutationResult<CreateAccessFromSessionMutation>;
export type CreateAccessFromSessionMutationOptions = Apollo.BaseMutationOptions<CreateAccessFromSessionMutation, CreateAccessFromSessionMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken($refreshToken: String!) {
  result: refreshToken(refreshToken: $refreshToken) {
    token
    refreshToken
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const ReadCouponsDocument = gql`
    query readCoupons($searchTerm: String, $before: String, $after: String, $first: Int, $last: Int) {
  coupons: readCoupons(
    searchTerm: $searchTerm
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    edges {
      cursor
      node {
        ...CouponResult
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${CouponResultFragmentDoc}`;

/**
 * __useReadCouponsQuery__
 *
 * To run a query within a React component, call `useReadCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCouponsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useReadCouponsQuery(baseOptions?: Apollo.QueryHookOptions<ReadCouponsQuery, ReadCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadCouponsQuery, ReadCouponsQueryVariables>(ReadCouponsDocument, options);
      }
export function useReadCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadCouponsQuery, ReadCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadCouponsQuery, ReadCouponsQueryVariables>(ReadCouponsDocument, options);
        }
export type ReadCouponsQueryHookResult = ReturnType<typeof useReadCouponsQuery>;
export type ReadCouponsLazyQueryHookResult = ReturnType<typeof useReadCouponsLazyQuery>;
export type ReadCouponsQueryResult = Apollo.QueryResult<ReadCouponsQuery, ReadCouponsQueryVariables>;
export const ReadCouponByIdDocument = gql`
    query readCouponById($id: ID!) {
  coupon: readCouponById(id: $id) {
    ...CouponResult
  }
}
    ${CouponResultFragmentDoc}`;

/**
 * __useReadCouponByIdQuery__
 *
 * To run a query within a React component, call `useReadCouponByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCouponByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCouponByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadCouponByIdQuery(baseOptions: Apollo.QueryHookOptions<ReadCouponByIdQuery, ReadCouponByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadCouponByIdQuery, ReadCouponByIdQueryVariables>(ReadCouponByIdDocument, options);
      }
export function useReadCouponByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadCouponByIdQuery, ReadCouponByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadCouponByIdQuery, ReadCouponByIdQueryVariables>(ReadCouponByIdDocument, options);
        }
export type ReadCouponByIdQueryHookResult = ReturnType<typeof useReadCouponByIdQuery>;
export type ReadCouponByIdLazyQueryHookResult = ReturnType<typeof useReadCouponByIdLazyQuery>;
export type ReadCouponByIdQueryResult = Apollo.QueryResult<ReadCouponByIdQuery, ReadCouponByIdQueryVariables>;
export const CreateCouponDocument = gql`
    mutation createCoupon($code: String!, $descriptionId: ID!, $recipient: CouponRecipient!, $recipientGroupId: ID, $startsAt: DateTime!, $endsAt: DateTime, $period: Int!, $product: CouponProduct!, $rule: CouponRule, $amount: Float!, $region: String!) {
  coupon: createCoupon(
    code: $code
    descriptionId: $descriptionId
    recipient: $recipient
    recipientGroupId: $recipientGroupId
    startsAt: $startsAt
    endsAt: $endsAt
    period: $period
    product: $product
    rule: $rule
    amount: $amount
    region: $region
  ) {
    ...CouponResult
  }
}
    ${CouponResultFragmentDoc}`;
export type CreateCouponMutationFn = Apollo.MutationFunction<CreateCouponMutation, CreateCouponMutationVariables>;

/**
 * __useCreateCouponMutation__
 *
 * To run a mutation, you first call `useCreateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponMutation, { data, loading, error }] = useCreateCouponMutation({
 *   variables: {
 *      code: // value for 'code'
 *      descriptionId: // value for 'descriptionId'
 *      recipient: // value for 'recipient'
 *      recipientGroupId: // value for 'recipientGroupId'
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *      period: // value for 'period'
 *      product: // value for 'product'
 *      rule: // value for 'rule'
 *      amount: // value for 'amount'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useCreateCouponMutation(baseOptions?: Apollo.MutationHookOptions<CreateCouponMutation, CreateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCouponMutation, CreateCouponMutationVariables>(CreateCouponDocument, options);
      }
export type CreateCouponMutationHookResult = ReturnType<typeof useCreateCouponMutation>;
export type CreateCouponMutationResult = Apollo.MutationResult<CreateCouponMutation>;
export type CreateCouponMutationOptions = Apollo.BaseMutationOptions<CreateCouponMutation, CreateCouponMutationVariables>;
export const UpdateCouponDocument = gql`
    mutation updateCoupon($couponId: ID!, $startsAt: DateTime, $endsAt: DateTime, $noExpiration: Boolean) {
  result: updateCoupon(
    couponId: $couponId
    startsAt: $startsAt
    endsAt: $endsAt
    noExpiration: $noExpiration
  ) {
    ...CouponResult
  }
}
    ${CouponResultFragmentDoc}`;
export type UpdateCouponMutationFn = Apollo.MutationFunction<UpdateCouponMutation, UpdateCouponMutationVariables>;

/**
 * __useUpdateCouponMutation__
 *
 * To run a mutation, you first call `useUpdateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponMutation, { data, loading, error }] = useUpdateCouponMutation({
 *   variables: {
 *      couponId: // value for 'couponId'
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *      noExpiration: // value for 'noExpiration'
 *   },
 * });
 */
export function useUpdateCouponMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCouponMutation, UpdateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCouponMutation, UpdateCouponMutationVariables>(UpdateCouponDocument, options);
      }
export type UpdateCouponMutationHookResult = ReturnType<typeof useUpdateCouponMutation>;
export type UpdateCouponMutationResult = Apollo.MutationResult<UpdateCouponMutation>;
export type UpdateCouponMutationOptions = Apollo.BaseMutationOptions<UpdateCouponMutation, UpdateCouponMutationVariables>;
export const DeleteCouponDocument = gql`
    mutation deleteCoupon($couponId: ID!) {
  result: deleteCoupon(couponId: $couponId) {
    id
  }
}
    `;
export type DeleteCouponMutationFn = Apollo.MutationFunction<DeleteCouponMutation, DeleteCouponMutationVariables>;

/**
 * __useDeleteCouponMutation__
 *
 * To run a mutation, you first call `useDeleteCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCouponMutation, { data, loading, error }] = useDeleteCouponMutation({
 *   variables: {
 *      couponId: // value for 'couponId'
 *   },
 * });
 */
export function useDeleteCouponMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCouponMutation, DeleteCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCouponMutation, DeleteCouponMutationVariables>(DeleteCouponDocument, options);
      }
export type DeleteCouponMutationHookResult = ReturnType<typeof useDeleteCouponMutation>;
export type DeleteCouponMutationResult = Apollo.MutationResult<DeleteCouponMutation>;
export type DeleteCouponMutationOptions = Apollo.BaseMutationOptions<DeleteCouponMutation, DeleteCouponMutationVariables>;
export const ReadAllIndustryCodesDocument = gql`
    query readAllIndustryCodes($first: Int, $last: Int, $before: String, $after: String, $filterByNoMcc: Boolean, $merchantCategoryCode: String, $filterByRegion: RegionEnum) {
  result: readAllIndustryCodes(
    first: $first
    last: $last
    before: $before
    after: $after
    filterByNoMcc: $filterByNoMcc
    merchantCategoryCode: $merchantCategoryCode
    filterByRegion: $filterByRegion
  ) {
    edges {
      node {
        ...IndustryCodeResult
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${IndustryCodeResultFragmentDoc}`;

/**
 * __useReadAllIndustryCodesQuery__
 *
 * To run a query within a React component, call `useReadAllIndustryCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAllIndustryCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAllIndustryCodesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filterByNoMcc: // value for 'filterByNoMcc'
 *      merchantCategoryCode: // value for 'merchantCategoryCode'
 *      filterByRegion: // value for 'filterByRegion'
 *   },
 * });
 */
export function useReadAllIndustryCodesQuery(baseOptions?: Apollo.QueryHookOptions<ReadAllIndustryCodesQuery, ReadAllIndustryCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAllIndustryCodesQuery, ReadAllIndustryCodesQueryVariables>(ReadAllIndustryCodesDocument, options);
      }
export function useReadAllIndustryCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAllIndustryCodesQuery, ReadAllIndustryCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAllIndustryCodesQuery, ReadAllIndustryCodesQueryVariables>(ReadAllIndustryCodesDocument, options);
        }
export type ReadAllIndustryCodesQueryHookResult = ReturnType<typeof useReadAllIndustryCodesQuery>;
export type ReadAllIndustryCodesLazyQueryHookResult = ReturnType<typeof useReadAllIndustryCodesLazyQuery>;
export type ReadAllIndustryCodesQueryResult = Apollo.QueryResult<ReadAllIndustryCodesQuery, ReadAllIndustryCodesQueryVariables>;
export const SetMerchantCategoryCodeDocument = gql`
    mutation setMerchantCategoryCode($id: ID!, $merchantCategoryCode: String!) {
  result: setMerchantCategoryCode(
    id: $id
    merchantCategoryCode: $merchantCategoryCode
  ) {
    id
    industryCode
    description
    merchantCategoryCode
    country
    createdAt
  }
}
    `;
export type SetMerchantCategoryCodeMutationFn = Apollo.MutationFunction<SetMerchantCategoryCodeMutation, SetMerchantCategoryCodeMutationVariables>;

/**
 * __useSetMerchantCategoryCodeMutation__
 *
 * To run a mutation, you first call `useSetMerchantCategoryCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMerchantCategoryCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMerchantCategoryCodeMutation, { data, loading, error }] = useSetMerchantCategoryCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      merchantCategoryCode: // value for 'merchantCategoryCode'
 *   },
 * });
 */
export function useSetMerchantCategoryCodeMutation(baseOptions?: Apollo.MutationHookOptions<SetMerchantCategoryCodeMutation, SetMerchantCategoryCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMerchantCategoryCodeMutation, SetMerchantCategoryCodeMutationVariables>(SetMerchantCategoryCodeDocument, options);
      }
export type SetMerchantCategoryCodeMutationHookResult = ReturnType<typeof useSetMerchantCategoryCodeMutation>;
export type SetMerchantCategoryCodeMutationResult = Apollo.MutationResult<SetMerchantCategoryCodeMutation>;
export type SetMerchantCategoryCodeMutationOptions = Apollo.BaseMutationOptions<SetMerchantCategoryCodeMutation, SetMerchantCategoryCodeMutationVariables>;
export const ReadRejectedInvoicesFromRemittanceDocument = gql`
    query readRejectedInvoicesFromRemittance($showArchived: Boolean) {
  result: readRejectedInvoicesFromRemittance(showArchived: $showArchived) {
    id
    invoiceId
    userId
    userName
    userEmail
    customerUId
    customerUIdType
    remitBatchId
    remitAttempt
    archived
    supportNote
    pushNote
    remitStatus
    remitStatusDescription
    createdAt
    amount
  }
}
    `;

/**
 * __useReadRejectedInvoicesFromRemittanceQuery__
 *
 * To run a query within a React component, call `useReadRejectedInvoicesFromRemittanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadRejectedInvoicesFromRemittanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadRejectedInvoicesFromRemittanceQuery({
 *   variables: {
 *      showArchived: // value for 'showArchived'
 *   },
 * });
 */
export function useReadRejectedInvoicesFromRemittanceQuery(baseOptions?: Apollo.QueryHookOptions<ReadRejectedInvoicesFromRemittanceQuery, ReadRejectedInvoicesFromRemittanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadRejectedInvoicesFromRemittanceQuery, ReadRejectedInvoicesFromRemittanceQueryVariables>(ReadRejectedInvoicesFromRemittanceDocument, options);
      }
export function useReadRejectedInvoicesFromRemittanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadRejectedInvoicesFromRemittanceQuery, ReadRejectedInvoicesFromRemittanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadRejectedInvoicesFromRemittanceQuery, ReadRejectedInvoicesFromRemittanceQueryVariables>(ReadRejectedInvoicesFromRemittanceDocument, options);
        }
export type ReadRejectedInvoicesFromRemittanceQueryHookResult = ReturnType<typeof useReadRejectedInvoicesFromRemittanceQuery>;
export type ReadRejectedInvoicesFromRemittanceLazyQueryHookResult = ReturnType<typeof useReadRejectedInvoicesFromRemittanceLazyQuery>;
export type ReadRejectedInvoicesFromRemittanceQueryResult = Apollo.QueryResult<ReadRejectedInvoicesFromRemittanceQuery, ReadRejectedInvoicesFromRemittanceQueryVariables>;
export const UpdateRemittedInvoiceCustomerUIdDocument = gql`
    mutation updateRemittedInvoiceCustomerUId($invoiceId: ID!, $userId: ID!, $customerUId: String!, $customerUIdType: CustomerUidType!) {
  result: updateRemittedInvoiceCustomerUId(
    invoiceId: $invoiceId
    userId: $userId
    customerUId: $customerUId
    customerUIdType: $customerUIdType
  ) {
    id
    customerUId
    customerUIdType
  }
}
    `;
export type UpdateRemittedInvoiceCustomerUIdMutationFn = Apollo.MutationFunction<UpdateRemittedInvoiceCustomerUIdMutation, UpdateRemittedInvoiceCustomerUIdMutationVariables>;

/**
 * __useUpdateRemittedInvoiceCustomerUIdMutation__
 *
 * To run a mutation, you first call `useUpdateRemittedInvoiceCustomerUIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRemittedInvoiceCustomerUIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRemittedInvoiceCustomerUIdMutation, { data, loading, error }] = useUpdateRemittedInvoiceCustomerUIdMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      userId: // value for 'userId'
 *      customerUId: // value for 'customerUId'
 *      customerUIdType: // value for 'customerUIdType'
 *   },
 * });
 */
export function useUpdateRemittedInvoiceCustomerUIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRemittedInvoiceCustomerUIdMutation, UpdateRemittedInvoiceCustomerUIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRemittedInvoiceCustomerUIdMutation, UpdateRemittedInvoiceCustomerUIdMutationVariables>(UpdateRemittedInvoiceCustomerUIdDocument, options);
      }
export type UpdateRemittedInvoiceCustomerUIdMutationHookResult = ReturnType<typeof useUpdateRemittedInvoiceCustomerUIdMutation>;
export type UpdateRemittedInvoiceCustomerUIdMutationResult = Apollo.MutationResult<UpdateRemittedInvoiceCustomerUIdMutation>;
export type UpdateRemittedInvoiceCustomerUIdMutationOptions = Apollo.BaseMutationOptions<UpdateRemittedInvoiceCustomerUIdMutation, UpdateRemittedInvoiceCustomerUIdMutationVariables>;
export const SetInvoiceArchivedDocument = gql`
    mutation setInvoiceArchived($userId: ID!, $invoiceIdentifier: ID!, $archived: Boolean!) {
  result: setInvoiceArchived(
    userId: $userId
    invoiceIdentifier: $invoiceIdentifier
    archived: $archived
  ) {
    isOk
  }
}
    `;
export type SetInvoiceArchivedMutationFn = Apollo.MutationFunction<SetInvoiceArchivedMutation, SetInvoiceArchivedMutationVariables>;

/**
 * __useSetInvoiceArchivedMutation__
 *
 * To run a mutation, you first call `useSetInvoiceArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInvoiceArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInvoiceArchivedMutation, { data, loading, error }] = useSetInvoiceArchivedMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      invoiceIdentifier: // value for 'invoiceIdentifier'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useSetInvoiceArchivedMutation(baseOptions?: Apollo.MutationHookOptions<SetInvoiceArchivedMutation, SetInvoiceArchivedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetInvoiceArchivedMutation, SetInvoiceArchivedMutationVariables>(SetInvoiceArchivedDocument, options);
      }
export type SetInvoiceArchivedMutationHookResult = ReturnType<typeof useSetInvoiceArchivedMutation>;
export type SetInvoiceArchivedMutationResult = Apollo.MutationResult<SetInvoiceArchivedMutation>;
export type SetInvoiceArchivedMutationOptions = Apollo.BaseMutationOptions<SetInvoiceArchivedMutation, SetInvoiceArchivedMutationVariables>;
export const SetSupportNoteDocument = gql`
    mutation setSupportNote($userId: ID!, $invoiceIdentifier: ID!, $supportNote: String!) {
  result: setSupportNote(
    userId: $userId
    invoiceIdentifier: $invoiceIdentifier
    supportNote: $supportNote
  ) {
    isOk
  }
}
    `;
export type SetSupportNoteMutationFn = Apollo.MutationFunction<SetSupportNoteMutation, SetSupportNoteMutationVariables>;

/**
 * __useSetSupportNoteMutation__
 *
 * To run a mutation, you first call `useSetSupportNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSupportNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSupportNoteMutation, { data, loading, error }] = useSetSupportNoteMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      invoiceIdentifier: // value for 'invoiceIdentifier'
 *      supportNote: // value for 'supportNote'
 *   },
 * });
 */
export function useSetSupportNoteMutation(baseOptions?: Apollo.MutationHookOptions<SetSupportNoteMutation, SetSupportNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSupportNoteMutation, SetSupportNoteMutationVariables>(SetSupportNoteDocument, options);
      }
export type SetSupportNoteMutationHookResult = ReturnType<typeof useSetSupportNoteMutation>;
export type SetSupportNoteMutationResult = Apollo.MutationResult<SetSupportNoteMutation>;
export type SetSupportNoteMutationOptions = Apollo.BaseMutationOptions<SetSupportNoteMutation, SetSupportNoteMutationVariables>;
export const SendPushMessageToUserDocument = gql`
    mutation sendPushMessageToUser($userId: ID!, $invoiceIdentifier: ID!, $message: String!, $header: String!) {
  result: sendPushMessageToUser(
    userId: $userId
    invoiceIdentifier: $invoiceIdentifier
    message: $message
    header: $header
  ) {
    isOk
  }
}
    `;
export type SendPushMessageToUserMutationFn = Apollo.MutationFunction<SendPushMessageToUserMutation, SendPushMessageToUserMutationVariables>;

/**
 * __useSendPushMessageToUserMutation__
 *
 * To run a mutation, you first call `useSendPushMessageToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPushMessageToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPushMessageToUserMutation, { data, loading, error }] = useSendPushMessageToUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      invoiceIdentifier: // value for 'invoiceIdentifier'
 *      message: // value for 'message'
 *      header: // value for 'header'
 *   },
 * });
 */
export function useSendPushMessageToUserMutation(baseOptions?: Apollo.MutationHookOptions<SendPushMessageToUserMutation, SendPushMessageToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPushMessageToUserMutation, SendPushMessageToUserMutationVariables>(SendPushMessageToUserDocument, options);
      }
export type SendPushMessageToUserMutationHookResult = ReturnType<typeof useSendPushMessageToUserMutation>;
export type SendPushMessageToUserMutationResult = Apollo.MutationResult<SendPushMessageToUserMutation>;
export type SendPushMessageToUserMutationOptions = Apollo.BaseMutationOptions<SendPushMessageToUserMutation, SendPushMessageToUserMutationVariables>;
export const ReadAllInvoicesDocument = gql`
    query readAllInvoices($first: Int, $last: Int, $before: String, $after: String, $kind: InvoiceKind, $status: TransactionStatus, $issuerId: ID, $userId: ID, $fromDate: DateTime, $toDate: DateTime, $searchingTerm: String, $invoiceId: ID, $region: RegionEnum) {
  result: readAllInvoices(
    first: $first
    last: $last
    before: $before
    after: $after
    kind: $kind
    status: $status
    issuerId: $issuerId
    userId: $userId
    fromDate: $fromDate
    toDate: $toDate
    searchingTerm: $searchingTerm
    invoiceId: $invoiceId
    region: $region
  ) {
    edges {
      node {
        ...InvoiceResult
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${InvoiceResultFragmentDoc}`;

/**
 * __useReadAllInvoicesQuery__
 *
 * To run a query within a React component, call `useReadAllInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAllInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAllInvoicesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      kind: // value for 'kind'
 *      status: // value for 'status'
 *      issuerId: // value for 'issuerId'
 *      userId: // value for 'userId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      searchingTerm: // value for 'searchingTerm'
 *      invoiceId: // value for 'invoiceId'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useReadAllInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<ReadAllInvoicesQuery, ReadAllInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAllInvoicesQuery, ReadAllInvoicesQueryVariables>(ReadAllInvoicesDocument, options);
      }
export function useReadAllInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAllInvoicesQuery, ReadAllInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAllInvoicesQuery, ReadAllInvoicesQueryVariables>(ReadAllInvoicesDocument, options);
        }
export type ReadAllInvoicesQueryHookResult = ReturnType<typeof useReadAllInvoicesQuery>;
export type ReadAllInvoicesLazyQueryHookResult = ReturnType<typeof useReadAllInvoicesLazyQuery>;
export type ReadAllInvoicesQueryResult = Apollo.QueryResult<ReadAllInvoicesQuery, ReadAllInvoicesQueryVariables>;
export const ReadInvoiceHistoryDocument = gql`
    query readInvoiceHistory($invoiceId: ID!) {
  result: readInvoiceHistory(invoiceId: $invoiceId) {
    ...InvoiceHistoryResult
  }
}
    ${InvoiceHistoryResultFragmentDoc}`;

/**
 * __useReadInvoiceHistoryQuery__
 *
 * To run a query within a React component, call `useReadInvoiceHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadInvoiceHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadInvoiceHistoryQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useReadInvoiceHistoryQuery(baseOptions: Apollo.QueryHookOptions<ReadInvoiceHistoryQuery, ReadInvoiceHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadInvoiceHistoryQuery, ReadInvoiceHistoryQueryVariables>(ReadInvoiceHistoryDocument, options);
      }
export function useReadInvoiceHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadInvoiceHistoryQuery, ReadInvoiceHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadInvoiceHistoryQuery, ReadInvoiceHistoryQueryVariables>(ReadInvoiceHistoryDocument, options);
        }
export type ReadInvoiceHistoryQueryHookResult = ReturnType<typeof useReadInvoiceHistoryQuery>;
export type ReadInvoiceHistoryLazyQueryHookResult = ReturnType<typeof useReadInvoiceHistoryLazyQuery>;
export type ReadInvoiceHistoryQueryResult = Apollo.QueryResult<ReadInvoiceHistoryQuery, ReadInvoiceHistoryQueryVariables>;
export const AdminReadInvoiceByIdDocument = gql`
    query AdminReadInvoiceById($invoiceId: ID!) {
  invoice: adminReadInvoiceByIdentifier(invoiceId: $invoiceId) {
    id
    reference
    referenceProvider
    amount
    currency {
      code
      name
      symbol
    }
    dueDate
    customerNumber
    invoiceDate
    issuer {
      id
      orgNumber
      picture
      name
    }
    vat
    user {
      id
      sid
      firstname
      lastname
      address {
        street
        postalCode
        city
        country {
          name
        }
      }
    }
    reference
    lastTransaction {
      event
      amount
      status
      paymentStatus
      orderRef
      transactionRef
    }
    net
    kind
    customerUId
    support {
      type
      requestedAt
    }
  }
}
    `;

/**
 * __useAdminReadInvoiceByIdQuery__
 *
 * To run a query within a React component, call `useAdminReadInvoiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminReadInvoiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminReadInvoiceByIdQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useAdminReadInvoiceByIdQuery(baseOptions: Apollo.QueryHookOptions<AdminReadInvoiceByIdQuery, AdminReadInvoiceByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminReadInvoiceByIdQuery, AdminReadInvoiceByIdQueryVariables>(AdminReadInvoiceByIdDocument, options);
      }
export function useAdminReadInvoiceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminReadInvoiceByIdQuery, AdminReadInvoiceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminReadInvoiceByIdQuery, AdminReadInvoiceByIdQueryVariables>(AdminReadInvoiceByIdDocument, options);
        }
export type AdminReadInvoiceByIdQueryHookResult = ReturnType<typeof useAdminReadInvoiceByIdQuery>;
export type AdminReadInvoiceByIdLazyQueryHookResult = ReturnType<typeof useAdminReadInvoiceByIdLazyQuery>;
export type AdminReadInvoiceByIdQueryResult = Apollo.QueryResult<AdminReadInvoiceByIdQuery, AdminReadInvoiceByIdQueryVariables>;
export const ReadBalanceHistoryDocument = gql`
    query readBalanceHistory($fromDate: DateTime!, $toDate: DateTime!, $region: RegionEnum) {
  result: readBalanceHistory(
    fromDate: $fromDate
    toDate: $toDate
    region: $region
  ) {
    sumIncoming
    sumIncomingWithoutFees
    sumOutgoing
    sumFees
    sumZenFeeNOK
    sumGrossAmount
    balance
    transactions {
      lastTransactionId
      lastTransactionTime
      invoiceId
      invoiceReference
      invoiceReferenceProvider
      invoiceKind
      invoiceIdentifier
      invoiceStatus
      invoiceStatusDate
      issuerId
      issuerName
      userId
      userName
      userEmail
      balanceInvoiceAmount
      balanceCardAmount
      balanceRemitAmount
      balanceFeeAmount
      balanceZenFee
      balanceGross
      balanceFeePercentage
      balanceFeeReason
      paymentCardId
      paymentAttempt
      paymentStatus
      paymentId
      paymentTime
      remitBatchId
      remitAttempt
      remitStatus
      remitStatusDescription
      remitTime
      reconciliationId
    }
    fees {
      reason
      total
    }
  }
}
    `;

/**
 * __useReadBalanceHistoryQuery__
 *
 * To run a query within a React component, call `useReadBalanceHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadBalanceHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadBalanceHistoryQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useReadBalanceHistoryQuery(baseOptions: Apollo.QueryHookOptions<ReadBalanceHistoryQuery, ReadBalanceHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadBalanceHistoryQuery, ReadBalanceHistoryQueryVariables>(ReadBalanceHistoryDocument, options);
      }
export function useReadBalanceHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadBalanceHistoryQuery, ReadBalanceHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadBalanceHistoryQuery, ReadBalanceHistoryQueryVariables>(ReadBalanceHistoryDocument, options);
        }
export type ReadBalanceHistoryQueryHookResult = ReturnType<typeof useReadBalanceHistoryQuery>;
export type ReadBalanceHistoryLazyQueryHookResult = ReturnType<typeof useReadBalanceHistoryLazyQuery>;
export type ReadBalanceHistoryQueryResult = Apollo.QueryResult<ReadBalanceHistoryQuery, ReadBalanceHistoryQueryVariables>;
export const ReadBalanceHistoryAsExcelDocument = gql`
    query readBalanceHistoryAsExcel($fromDate: DateTime!, $toDate: DateTime!, $region: RegionEnum) {
  result: readBalanceHistoryAsExcel(
    fromDate: $fromDate
    toDate: $toDate
    region: $region
  ) {
    data
  }
}
    `;

/**
 * __useReadBalanceHistoryAsExcelQuery__
 *
 * To run a query within a React component, call `useReadBalanceHistoryAsExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadBalanceHistoryAsExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadBalanceHistoryAsExcelQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useReadBalanceHistoryAsExcelQuery(baseOptions: Apollo.QueryHookOptions<ReadBalanceHistoryAsExcelQuery, ReadBalanceHistoryAsExcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadBalanceHistoryAsExcelQuery, ReadBalanceHistoryAsExcelQueryVariables>(ReadBalanceHistoryAsExcelDocument, options);
      }
export function useReadBalanceHistoryAsExcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadBalanceHistoryAsExcelQuery, ReadBalanceHistoryAsExcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadBalanceHistoryAsExcelQuery, ReadBalanceHistoryAsExcelQueryVariables>(ReadBalanceHistoryAsExcelDocument, options);
        }
export type ReadBalanceHistoryAsExcelQueryHookResult = ReturnType<typeof useReadBalanceHistoryAsExcelQuery>;
export type ReadBalanceHistoryAsExcelLazyQueryHookResult = ReturnType<typeof useReadBalanceHistoryAsExcelLazyQuery>;
export type ReadBalanceHistoryAsExcelQueryResult = Apollo.QueryResult<ReadBalanceHistoryAsExcelQuery, ReadBalanceHistoryAsExcelQueryVariables>;
export const ReadZenExtraBalanceAsExcelDocument = gql`
    query readZenExtraBalanceAsExcel($fromDate: DateTime!, $toDate: DateTime!) {
  result: readZenExtraBalanceAsExcel(fromDate: $fromDate, toDate: $toDate) {
    data
  }
}
    `;

/**
 * __useReadZenExtraBalanceAsExcelQuery__
 *
 * To run a query within a React component, call `useReadZenExtraBalanceAsExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadZenExtraBalanceAsExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadZenExtraBalanceAsExcelQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useReadZenExtraBalanceAsExcelQuery(baseOptions: Apollo.QueryHookOptions<ReadZenExtraBalanceAsExcelQuery, ReadZenExtraBalanceAsExcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadZenExtraBalanceAsExcelQuery, ReadZenExtraBalanceAsExcelQueryVariables>(ReadZenExtraBalanceAsExcelDocument, options);
      }
export function useReadZenExtraBalanceAsExcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadZenExtraBalanceAsExcelQuery, ReadZenExtraBalanceAsExcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadZenExtraBalanceAsExcelQuery, ReadZenExtraBalanceAsExcelQueryVariables>(ReadZenExtraBalanceAsExcelDocument, options);
        }
export type ReadZenExtraBalanceAsExcelQueryHookResult = ReturnType<typeof useReadZenExtraBalanceAsExcelQuery>;
export type ReadZenExtraBalanceAsExcelLazyQueryHookResult = ReturnType<typeof useReadZenExtraBalanceAsExcelLazyQuery>;
export type ReadZenExtraBalanceAsExcelQueryResult = Apollo.QueryResult<ReadZenExtraBalanceAsExcelQuery, ReadZenExtraBalanceAsExcelQueryVariables>;
export const ReadBalanceForecastingDocument = gql`
    query readBalanceForecasting {
  result: readBalanceForecasting {
    time
    buffer
    incoming
    outgoing
  }
}
    `;

/**
 * __useReadBalanceForecastingQuery__
 *
 * To run a query within a React component, call `useReadBalanceForecastingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadBalanceForecastingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadBalanceForecastingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadBalanceForecastingQuery(baseOptions?: Apollo.QueryHookOptions<ReadBalanceForecastingQuery, ReadBalanceForecastingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadBalanceForecastingQuery, ReadBalanceForecastingQueryVariables>(ReadBalanceForecastingDocument, options);
      }
export function useReadBalanceForecastingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadBalanceForecastingQuery, ReadBalanceForecastingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadBalanceForecastingQuery, ReadBalanceForecastingQueryVariables>(ReadBalanceForecastingDocument, options);
        }
export type ReadBalanceForecastingQueryHookResult = ReturnType<typeof useReadBalanceForecastingQuery>;
export type ReadBalanceForecastingLazyQueryHookResult = ReturnType<typeof useReadBalanceForecastingLazyQuery>;
export type ReadBalanceForecastingQueryResult = Apollo.QueryResult<ReadBalanceForecastingQuery, ReadBalanceForecastingQueryVariables>;
export const StopRemittanceDocument = gql`
    mutation stopRemittance($invoiceId: ID!) {
  result: stopRemittance(invoiceId: $invoiceId) {
    isOk
  }
}
    `;
export type StopRemittanceMutationFn = Apollo.MutationFunction<StopRemittanceMutation, StopRemittanceMutationVariables>;

/**
 * __useStopRemittanceMutation__
 *
 * To run a mutation, you first call `useStopRemittanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopRemittanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopRemittanceMutation, { data, loading, error }] = useStopRemittanceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useStopRemittanceMutation(baseOptions?: Apollo.MutationHookOptions<StopRemittanceMutation, StopRemittanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopRemittanceMutation, StopRemittanceMutationVariables>(StopRemittanceDocument, options);
      }
export type StopRemittanceMutationHookResult = ReturnType<typeof useStopRemittanceMutation>;
export type StopRemittanceMutationResult = Apollo.MutationResult<StopRemittanceMutation>;
export type StopRemittanceMutationOptions = Apollo.BaseMutationOptions<StopRemittanceMutation, StopRemittanceMutationVariables>;
export const RestartRemittanceDocument = gql`
    mutation restartRemittance($invoiceId: ID!) {
  result: restartRemittance(invoiceId: $invoiceId) {
    isOk
  }
}
    `;
export type RestartRemittanceMutationFn = Apollo.MutationFunction<RestartRemittanceMutation, RestartRemittanceMutationVariables>;

/**
 * __useRestartRemittanceMutation__
 *
 * To run a mutation, you first call `useRestartRemittanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartRemittanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartRemittanceMutation, { data, loading, error }] = useRestartRemittanceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useRestartRemittanceMutation(baseOptions?: Apollo.MutationHookOptions<RestartRemittanceMutation, RestartRemittanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestartRemittanceMutation, RestartRemittanceMutationVariables>(RestartRemittanceDocument, options);
      }
export type RestartRemittanceMutationHookResult = ReturnType<typeof useRestartRemittanceMutation>;
export type RestartRemittanceMutationResult = Apollo.MutationResult<RestartRemittanceMutation>;
export type RestartRemittanceMutationOptions = Apollo.BaseMutationOptions<RestartRemittanceMutation, RestartRemittanceMutationVariables>;
export const ReadBalanceSnapshotsDocument = gql`
    query readBalanceSnapshots($first: Int, $last: Int, $before: String, $after: String) {
  result: readBalanceSnapshots(
    first: $first
    last: $last
    before: $before
    after: $after
  ) {
    edges {
      node {
        id
        amount
        time
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useReadBalanceSnapshotsQuery__
 *
 * To run a query within a React component, call `useReadBalanceSnapshotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadBalanceSnapshotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadBalanceSnapshotsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useReadBalanceSnapshotsQuery(baseOptions?: Apollo.QueryHookOptions<ReadBalanceSnapshotsQuery, ReadBalanceSnapshotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadBalanceSnapshotsQuery, ReadBalanceSnapshotsQueryVariables>(ReadBalanceSnapshotsDocument, options);
      }
export function useReadBalanceSnapshotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadBalanceSnapshotsQuery, ReadBalanceSnapshotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadBalanceSnapshotsQuery, ReadBalanceSnapshotsQueryVariables>(ReadBalanceSnapshotsDocument, options);
        }
export type ReadBalanceSnapshotsQueryHookResult = ReturnType<typeof useReadBalanceSnapshotsQuery>;
export type ReadBalanceSnapshotsLazyQueryHookResult = ReturnType<typeof useReadBalanceSnapshotsLazyQuery>;
export type ReadBalanceSnapshotsQueryResult = Apollo.QueryResult<ReadBalanceSnapshotsQuery, ReadBalanceSnapshotsQueryVariables>;
export const CreateBalanceSnapshotDocument = gql`
    mutation createBalanceSnapshot($amount: Float!, $time: DateTime!) {
  result: createBalanceSnapshot(amount: $amount, time: $time) {
    isOk
  }
}
    `;
export type CreateBalanceSnapshotMutationFn = Apollo.MutationFunction<CreateBalanceSnapshotMutation, CreateBalanceSnapshotMutationVariables>;

/**
 * __useCreateBalanceSnapshotMutation__
 *
 * To run a mutation, you first call `useCreateBalanceSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBalanceSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBalanceSnapshotMutation, { data, loading, error }] = useCreateBalanceSnapshotMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      time: // value for 'time'
 *   },
 * });
 */
export function useCreateBalanceSnapshotMutation(baseOptions?: Apollo.MutationHookOptions<CreateBalanceSnapshotMutation, CreateBalanceSnapshotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBalanceSnapshotMutation, CreateBalanceSnapshotMutationVariables>(CreateBalanceSnapshotDocument, options);
      }
export type CreateBalanceSnapshotMutationHookResult = ReturnType<typeof useCreateBalanceSnapshotMutation>;
export type CreateBalanceSnapshotMutationResult = Apollo.MutationResult<CreateBalanceSnapshotMutation>;
export type CreateBalanceSnapshotMutationOptions = Apollo.BaseMutationOptions<CreateBalanceSnapshotMutation, CreateBalanceSnapshotMutationVariables>;
export const RemoveBalanceSnapshotDocument = gql`
    mutation removeBalanceSnapshot($snapshotId: ID!) {
  result: removeBalanceSnapshot(snapshotId: $snapshotId) {
    isOk
  }
}
    `;
export type RemoveBalanceSnapshotMutationFn = Apollo.MutationFunction<RemoveBalanceSnapshotMutation, RemoveBalanceSnapshotMutationVariables>;

/**
 * __useRemoveBalanceSnapshotMutation__
 *
 * To run a mutation, you first call `useRemoveBalanceSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBalanceSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBalanceSnapshotMutation, { data, loading, error }] = useRemoveBalanceSnapshotMutation({
 *   variables: {
 *      snapshotId: // value for 'snapshotId'
 *   },
 * });
 */
export function useRemoveBalanceSnapshotMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBalanceSnapshotMutation, RemoveBalanceSnapshotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveBalanceSnapshotMutation, RemoveBalanceSnapshotMutationVariables>(RemoveBalanceSnapshotDocument, options);
      }
export type RemoveBalanceSnapshotMutationHookResult = ReturnType<typeof useRemoveBalanceSnapshotMutation>;
export type RemoveBalanceSnapshotMutationResult = Apollo.MutationResult<RemoveBalanceSnapshotMutation>;
export type RemoveBalanceSnapshotMutationOptions = Apollo.BaseMutationOptions<RemoveBalanceSnapshotMutation, RemoveBalanceSnapshotMutationVariables>;
export const ReadAllIssuersDocument = gql`
    query readAllIssuers($first: Int, $last: Int, $before: String, $after: String, $searchType: String, $filterByName: String, $filterByNoEmail: Boolean, $filterByNoCreditorEmailLoan: Boolean, $filterByNoPicture: Boolean, $filterByExistingAccount: Boolean, $filterByAllowingAmex: Boolean, $filterByAllowingCreditCards: Boolean, $filterByNoRealOrgNo: Boolean, $filterByNoMcc: Boolean, $filterByRegion: String) {
  result: readAllIssuers(
    first: $first
    last: $last
    before: $before
    after: $after
    searchType: $searchType
    filterByName: $filterByName
    filterByNoEmail: $filterByNoEmail
    filterByNoCreditorEmailLoan: $filterByNoCreditorEmailLoan
    filterByNoPicture: $filterByNoPicture
    filterByExistingAccount: $filterByExistingAccount
    filterByAllowingAmex: $filterByAllowingAmex
    filterByAllowingCreditCards: $filterByAllowingCreditCards
    filterByNoRealOrgNo: $filterByNoRealOrgNo
    filterByNoMcc: $filterByNoMcc
    filterByRegion: $filterByRegion
  ) {
    edges {
      node {
        ...IssuerResult
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${IssuerResultFragmentDoc}`;

/**
 * __useReadAllIssuersQuery__
 *
 * To run a query within a React component, call `useReadAllIssuersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAllIssuersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAllIssuersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      searchType: // value for 'searchType'
 *      filterByName: // value for 'filterByName'
 *      filterByNoEmail: // value for 'filterByNoEmail'
 *      filterByNoCreditorEmailLoan: // value for 'filterByNoCreditorEmailLoan'
 *      filterByNoPicture: // value for 'filterByNoPicture'
 *      filterByExistingAccount: // value for 'filterByExistingAccount'
 *      filterByAllowingAmex: // value for 'filterByAllowingAmex'
 *      filterByAllowingCreditCards: // value for 'filterByAllowingCreditCards'
 *      filterByNoRealOrgNo: // value for 'filterByNoRealOrgNo'
 *      filterByNoMcc: // value for 'filterByNoMcc'
 *      filterByRegion: // value for 'filterByRegion'
 *   },
 * });
 */
export function useReadAllIssuersQuery(baseOptions?: Apollo.QueryHookOptions<ReadAllIssuersQuery, ReadAllIssuersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAllIssuersQuery, ReadAllIssuersQueryVariables>(ReadAllIssuersDocument, options);
      }
export function useReadAllIssuersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAllIssuersQuery, ReadAllIssuersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAllIssuersQuery, ReadAllIssuersQueryVariables>(ReadAllIssuersDocument, options);
        }
export type ReadAllIssuersQueryHookResult = ReturnType<typeof useReadAllIssuersQuery>;
export type ReadAllIssuersLazyQueryHookResult = ReturnType<typeof useReadAllIssuersLazyQuery>;
export type ReadAllIssuersQueryResult = Apollo.QueryResult<ReadAllIssuersQuery, ReadAllIssuersQueryVariables>;
export const ReadIssuerByIdDocument = gql`
    query readIssuerById($issuerId: ID!) {
  result: readIssuerById(issuerId: $issuerId) {
    ...IssuerDetail
  }
}
    ${IssuerDetailFragmentDoc}`;

/**
 * __useReadIssuerByIdQuery__
 *
 * To run a query within a React component, call `useReadIssuerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadIssuerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadIssuerByIdQuery({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *   },
 * });
 */
export function useReadIssuerByIdQuery(baseOptions: Apollo.QueryHookOptions<ReadIssuerByIdQuery, ReadIssuerByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadIssuerByIdQuery, ReadIssuerByIdQueryVariables>(ReadIssuerByIdDocument, options);
      }
export function useReadIssuerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadIssuerByIdQuery, ReadIssuerByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadIssuerByIdQuery, ReadIssuerByIdQueryVariables>(ReadIssuerByIdDocument, options);
        }
export type ReadIssuerByIdQueryHookResult = ReturnType<typeof useReadIssuerByIdQuery>;
export type ReadIssuerByIdLazyQueryHookResult = ReturnType<typeof useReadIssuerByIdLazyQuery>;
export type ReadIssuerByIdQueryResult = Apollo.QueryResult<ReadIssuerByIdQuery, ReadIssuerByIdQueryVariables>;
export const UpdateIssuerIdentifiersDocument = gql`
    mutation updateIssuerIdentifiers($issuerId: ID!, $realOrgNumber: String!, $subMerchantMcc: String!) {
  result: updateIssuerIdentifiers(
    issuerId: $issuerId
    realOrgNumber: $realOrgNumber
    subMerchantMcc: $subMerchantMcc
  ) {
    ...IssuerResult
  }
}
    ${IssuerResultFragmentDoc}`;
export type UpdateIssuerIdentifiersMutationFn = Apollo.MutationFunction<UpdateIssuerIdentifiersMutation, UpdateIssuerIdentifiersMutationVariables>;

/**
 * __useUpdateIssuerIdentifiersMutation__
 *
 * To run a mutation, you first call `useUpdateIssuerIdentifiersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIssuerIdentifiersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIssuerIdentifiersMutation, { data, loading, error }] = useUpdateIssuerIdentifiersMutation({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *      realOrgNumber: // value for 'realOrgNumber'
 *      subMerchantMcc: // value for 'subMerchantMcc'
 *   },
 * });
 */
export function useUpdateIssuerIdentifiersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIssuerIdentifiersMutation, UpdateIssuerIdentifiersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIssuerIdentifiersMutation, UpdateIssuerIdentifiersMutationVariables>(UpdateIssuerIdentifiersDocument, options);
      }
export type UpdateIssuerIdentifiersMutationHookResult = ReturnType<typeof useUpdateIssuerIdentifiersMutation>;
export type UpdateIssuerIdentifiersMutationResult = Apollo.MutationResult<UpdateIssuerIdentifiersMutation>;
export type UpdateIssuerIdentifiersMutationOptions = Apollo.BaseMutationOptions<UpdateIssuerIdentifiersMutation, UpdateIssuerIdentifiersMutationVariables>;
export const ReadIssuerWhitelistDocument = gql`
    query readIssuerWhitelist($issuerId: ID!) {
  whitelist: readIssuerWhitelist(issuerId: $issuerId) {
    id
    issuerId
    origin
    accountNumber
  }
}
    `;

/**
 * __useReadIssuerWhitelistQuery__
 *
 * To run a query within a React component, call `useReadIssuerWhitelistQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadIssuerWhitelistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadIssuerWhitelistQuery({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *   },
 * });
 */
export function useReadIssuerWhitelistQuery(baseOptions: Apollo.QueryHookOptions<ReadIssuerWhitelistQuery, ReadIssuerWhitelistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadIssuerWhitelistQuery, ReadIssuerWhitelistQueryVariables>(ReadIssuerWhitelistDocument, options);
      }
export function useReadIssuerWhitelistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadIssuerWhitelistQuery, ReadIssuerWhitelistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadIssuerWhitelistQuery, ReadIssuerWhitelistQueryVariables>(ReadIssuerWhitelistDocument, options);
        }
export type ReadIssuerWhitelistQueryHookResult = ReturnType<typeof useReadIssuerWhitelistQuery>;
export type ReadIssuerWhitelistLazyQueryHookResult = ReturnType<typeof useReadIssuerWhitelistLazyQuery>;
export type ReadIssuerWhitelistQueryResult = Apollo.QueryResult<ReadIssuerWhitelistQuery, ReadIssuerWhitelistQueryVariables>;
export const CreateIssuerWhitelistDocument = gql`
    mutation createIssuerWhitelist($issuerId: ID!, $accountNumber: String!) {
  whitelist: createIssuerWhitelist(
    issuerId: $issuerId
    accountNumber: $accountNumber
  ) {
    id
    issuerId
    origin
    accountNumber
  }
}
    `;
export type CreateIssuerWhitelistMutationFn = Apollo.MutationFunction<CreateIssuerWhitelistMutation, CreateIssuerWhitelistMutationVariables>;

/**
 * __useCreateIssuerWhitelistMutation__
 *
 * To run a mutation, you first call `useCreateIssuerWhitelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIssuerWhitelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIssuerWhitelistMutation, { data, loading, error }] = useCreateIssuerWhitelistMutation({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *      accountNumber: // value for 'accountNumber'
 *   },
 * });
 */
export function useCreateIssuerWhitelistMutation(baseOptions?: Apollo.MutationHookOptions<CreateIssuerWhitelistMutation, CreateIssuerWhitelistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIssuerWhitelistMutation, CreateIssuerWhitelistMutationVariables>(CreateIssuerWhitelistDocument, options);
      }
export type CreateIssuerWhitelistMutationHookResult = ReturnType<typeof useCreateIssuerWhitelistMutation>;
export type CreateIssuerWhitelistMutationResult = Apollo.MutationResult<CreateIssuerWhitelistMutation>;
export type CreateIssuerWhitelistMutationOptions = Apollo.BaseMutationOptions<CreateIssuerWhitelistMutation, CreateIssuerWhitelistMutationVariables>;
export const RemoveIssuerWhitelistDocument = gql`
    mutation removeIssuerWhitelist($issuerId: ID!, $accountNumber: String!) {
  whitelist: removeIssuerWhitelist(
    issuerId: $issuerId
    accountNumber: $accountNumber
  ) {
    id
    issuerId
    origin
    accountNumber
  }
}
    `;
export type RemoveIssuerWhitelistMutationFn = Apollo.MutationFunction<RemoveIssuerWhitelistMutation, RemoveIssuerWhitelistMutationVariables>;

/**
 * __useRemoveIssuerWhitelistMutation__
 *
 * To run a mutation, you first call `useRemoveIssuerWhitelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveIssuerWhitelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeIssuerWhitelistMutation, { data, loading, error }] = useRemoveIssuerWhitelistMutation({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *      accountNumber: // value for 'accountNumber'
 *   },
 * });
 */
export function useRemoveIssuerWhitelistMutation(baseOptions?: Apollo.MutationHookOptions<RemoveIssuerWhitelistMutation, RemoveIssuerWhitelistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveIssuerWhitelistMutation, RemoveIssuerWhitelistMutationVariables>(RemoveIssuerWhitelistDocument, options);
      }
export type RemoveIssuerWhitelistMutationHookResult = ReturnType<typeof useRemoveIssuerWhitelistMutation>;
export type RemoveIssuerWhitelistMutationResult = Apollo.MutationResult<RemoveIssuerWhitelistMutation>;
export type RemoveIssuerWhitelistMutationOptions = Apollo.BaseMutationOptions<RemoveIssuerWhitelistMutation, RemoveIssuerWhitelistMutationVariables>;
export const UpdateIssuerEmailDocument = gql`
    mutation updateIssuerEmail($issuerId: ID!, $email: String!) {
  result: updateIssuerEmail(issuerId: $issuerId, email: $email) {
    ...IssuerDetail
  }
}
    ${IssuerDetailFragmentDoc}`;
export type UpdateIssuerEmailMutationFn = Apollo.MutationFunction<UpdateIssuerEmailMutation, UpdateIssuerEmailMutationVariables>;

/**
 * __useUpdateIssuerEmailMutation__
 *
 * To run a mutation, you first call `useUpdateIssuerEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIssuerEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIssuerEmailMutation, { data, loading, error }] = useUpdateIssuerEmailMutation({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateIssuerEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIssuerEmailMutation, UpdateIssuerEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIssuerEmailMutation, UpdateIssuerEmailMutationVariables>(UpdateIssuerEmailDocument, options);
      }
export type UpdateIssuerEmailMutationHookResult = ReturnType<typeof useUpdateIssuerEmailMutation>;
export type UpdateIssuerEmailMutationResult = Apollo.MutationResult<UpdateIssuerEmailMutation>;
export type UpdateIssuerEmailMutationOptions = Apollo.BaseMutationOptions<UpdateIssuerEmailMutation, UpdateIssuerEmailMutationVariables>;
export const UpdateIssuerLoanEmailDocument = gql`
    mutation updateIssuerLoanEmail($issuerId: ID!, $emailLoan: String!) {
  result: updateIssuerLoanEmail(issuerId: $issuerId, emailLoan: $emailLoan) {
    ...IssuerDetail
  }
}
    ${IssuerDetailFragmentDoc}`;
export type UpdateIssuerLoanEmailMutationFn = Apollo.MutationFunction<UpdateIssuerLoanEmailMutation, UpdateIssuerLoanEmailMutationVariables>;

/**
 * __useUpdateIssuerLoanEmailMutation__
 *
 * To run a mutation, you first call `useUpdateIssuerLoanEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIssuerLoanEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIssuerLoanEmailMutation, { data, loading, error }] = useUpdateIssuerLoanEmailMutation({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *      emailLoan: // value for 'emailLoan'
 *   },
 * });
 */
export function useUpdateIssuerLoanEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIssuerLoanEmailMutation, UpdateIssuerLoanEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIssuerLoanEmailMutation, UpdateIssuerLoanEmailMutationVariables>(UpdateIssuerLoanEmailDocument, options);
      }
export type UpdateIssuerLoanEmailMutationHookResult = ReturnType<typeof useUpdateIssuerLoanEmailMutation>;
export type UpdateIssuerLoanEmailMutationResult = Apollo.MutationResult<UpdateIssuerLoanEmailMutation>;
export type UpdateIssuerLoanEmailMutationOptions = Apollo.BaseMutationOptions<UpdateIssuerLoanEmailMutation, UpdateIssuerLoanEmailMutationVariables>;
export const UpdateIssuerPictureDocument = gql`
    mutation updateIssuerPicture($issuerId: ID!, $pictureBase64: String!) {
  result: updateIssuerPicture(issuerId: $issuerId, pictureBase64: $pictureBase64) {
    ...IssuerDetail
  }
}
    ${IssuerDetailFragmentDoc}`;
export type UpdateIssuerPictureMutationFn = Apollo.MutationFunction<UpdateIssuerPictureMutation, UpdateIssuerPictureMutationVariables>;

/**
 * __useUpdateIssuerPictureMutation__
 *
 * To run a mutation, you first call `useUpdateIssuerPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIssuerPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIssuerPictureMutation, { data, loading, error }] = useUpdateIssuerPictureMutation({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *      pictureBase64: // value for 'pictureBase64'
 *   },
 * });
 */
export function useUpdateIssuerPictureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIssuerPictureMutation, UpdateIssuerPictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIssuerPictureMutation, UpdateIssuerPictureMutationVariables>(UpdateIssuerPictureDocument, options);
      }
export type UpdateIssuerPictureMutationHookResult = ReturnType<typeof useUpdateIssuerPictureMutation>;
export type UpdateIssuerPictureMutationResult = Apollo.MutationResult<UpdateIssuerPictureMutation>;
export type UpdateIssuerPictureMutationOptions = Apollo.BaseMutationOptions<UpdateIssuerPictureMutation, UpdateIssuerPictureMutationVariables>;
export const CopyIssuerInfoDocument = gql`
    mutation copyIssuerInfo($issuerIdSource: ID!, $issuerIdDestination: ID!) {
  result: copyIssuerInfo(
    issuerIdSource: $issuerIdSource
    issuerIdDestination: $issuerIdDestination
  ) {
    ...IssuerDetail
  }
}
    ${IssuerDetailFragmentDoc}`;
export type CopyIssuerInfoMutationFn = Apollo.MutationFunction<CopyIssuerInfoMutation, CopyIssuerInfoMutationVariables>;

/**
 * __useCopyIssuerInfoMutation__
 *
 * To run a mutation, you first call `useCopyIssuerInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyIssuerInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyIssuerInfoMutation, { data, loading, error }] = useCopyIssuerInfoMutation({
 *   variables: {
 *      issuerIdSource: // value for 'issuerIdSource'
 *      issuerIdDestination: // value for 'issuerIdDestination'
 *   },
 * });
 */
export function useCopyIssuerInfoMutation(baseOptions?: Apollo.MutationHookOptions<CopyIssuerInfoMutation, CopyIssuerInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyIssuerInfoMutation, CopyIssuerInfoMutationVariables>(CopyIssuerInfoDocument, options);
      }
export type CopyIssuerInfoMutationHookResult = ReturnType<typeof useCopyIssuerInfoMutation>;
export type CopyIssuerInfoMutationResult = Apollo.MutationResult<CopyIssuerInfoMutation>;
export type CopyIssuerInfoMutationOptions = Apollo.BaseMutationOptions<CopyIssuerInfoMutation, CopyIssuerInfoMutationVariables>;
export const SetIssuerTransactionRuleDocument = gql`
    mutation setIssuerTransactionRule($issuerId: ID!, $allowDebitCard: Boolean!, $allowCreditCard: Boolean!, $allowAmexCard: Boolean!, $maxAmountDebitCard: Int, $maxAmountCreditCard: Int, $maxAmountAmexCard: Int) {
  rule: setIssuerTransactionRule(
    issuerId: $issuerId
    allowDebitCard: $allowDebitCard
    allowCreditCard: $allowCreditCard
    allowAmexCard: $allowAmexCard
    maxAmountDebitCard: $maxAmountDebitCard
    maxAmountCreditCard: $maxAmountCreditCard
    maxAmountAmexCard: $maxAmountAmexCard
  ) {
    ...IssuerBlockComplete
  }
}
    ${IssuerBlockCompleteFragmentDoc}`;
export type SetIssuerTransactionRuleMutationFn = Apollo.MutationFunction<SetIssuerTransactionRuleMutation, SetIssuerTransactionRuleMutationVariables>;

/**
 * __useSetIssuerTransactionRuleMutation__
 *
 * To run a mutation, you first call `useSetIssuerTransactionRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIssuerTransactionRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIssuerTransactionRuleMutation, { data, loading, error }] = useSetIssuerTransactionRuleMutation({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *      allowDebitCard: // value for 'allowDebitCard'
 *      allowCreditCard: // value for 'allowCreditCard'
 *      allowAmexCard: // value for 'allowAmexCard'
 *      maxAmountDebitCard: // value for 'maxAmountDebitCard'
 *      maxAmountCreditCard: // value for 'maxAmountCreditCard'
 *      maxAmountAmexCard: // value for 'maxAmountAmexCard'
 *   },
 * });
 */
export function useSetIssuerTransactionRuleMutation(baseOptions?: Apollo.MutationHookOptions<SetIssuerTransactionRuleMutation, SetIssuerTransactionRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetIssuerTransactionRuleMutation, SetIssuerTransactionRuleMutationVariables>(SetIssuerTransactionRuleDocument, options);
      }
export type SetIssuerTransactionRuleMutationHookResult = ReturnType<typeof useSetIssuerTransactionRuleMutation>;
export type SetIssuerTransactionRuleMutationResult = Apollo.MutationResult<SetIssuerTransactionRuleMutation>;
export type SetIssuerTransactionRuleMutationOptions = Apollo.BaseMutationOptions<SetIssuerTransactionRuleMutation, SetIssuerTransactionRuleMutationVariables>;
export const UnsetIssuerTransactionRuleDocument = gql`
    mutation unsetIssuerTransactionRule($issuerId: ID!) {
  rule: unsetIssuerTransactionRule(issuerId: $issuerId) {
    ...IssuerBlockComplete
  }
}
    ${IssuerBlockCompleteFragmentDoc}`;
export type UnsetIssuerTransactionRuleMutationFn = Apollo.MutationFunction<UnsetIssuerTransactionRuleMutation, UnsetIssuerTransactionRuleMutationVariables>;

/**
 * __useUnsetIssuerTransactionRuleMutation__
 *
 * To run a mutation, you first call `useUnsetIssuerTransactionRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsetIssuerTransactionRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsetIssuerTransactionRuleMutation, { data, loading, error }] = useUnsetIssuerTransactionRuleMutation({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *   },
 * });
 */
export function useUnsetIssuerTransactionRuleMutation(baseOptions?: Apollo.MutationHookOptions<UnsetIssuerTransactionRuleMutation, UnsetIssuerTransactionRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsetIssuerTransactionRuleMutation, UnsetIssuerTransactionRuleMutationVariables>(UnsetIssuerTransactionRuleDocument, options);
      }
export type UnsetIssuerTransactionRuleMutationHookResult = ReturnType<typeof useUnsetIssuerTransactionRuleMutation>;
export type UnsetIssuerTransactionRuleMutationResult = Apollo.MutationResult<UnsetIssuerTransactionRuleMutation>;
export type UnsetIssuerTransactionRuleMutationOptions = Apollo.BaseMutationOptions<UnsetIssuerTransactionRuleMutation, UnsetIssuerTransactionRuleMutationVariables>;
export const ReadIssuerTransactionRuleDocument = gql`
    query ReadIssuerTransactionRule($issuerId: ID!) {
  rule: readIssuerTransactionRule(issuerId: $issuerId) {
    ...IssuerBlockComplete
  }
}
    ${IssuerBlockCompleteFragmentDoc}`;

/**
 * __useReadIssuerTransactionRuleQuery__
 *
 * To run a query within a React component, call `useReadIssuerTransactionRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadIssuerTransactionRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadIssuerTransactionRuleQuery({
 *   variables: {
 *      issuerId: // value for 'issuerId'
 *   },
 * });
 */
export function useReadIssuerTransactionRuleQuery(baseOptions: Apollo.QueryHookOptions<ReadIssuerTransactionRuleQuery, ReadIssuerTransactionRuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadIssuerTransactionRuleQuery, ReadIssuerTransactionRuleQueryVariables>(ReadIssuerTransactionRuleDocument, options);
      }
export function useReadIssuerTransactionRuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadIssuerTransactionRuleQuery, ReadIssuerTransactionRuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadIssuerTransactionRuleQuery, ReadIssuerTransactionRuleQueryVariables>(ReadIssuerTransactionRuleDocument, options);
        }
export type ReadIssuerTransactionRuleQueryHookResult = ReturnType<typeof useReadIssuerTransactionRuleQuery>;
export type ReadIssuerTransactionRuleLazyQueryHookResult = ReturnType<typeof useReadIssuerTransactionRuleLazyQuery>;
export type ReadIssuerTransactionRuleQueryResult = Apollo.QueryResult<ReadIssuerTransactionRuleQuery, ReadIssuerTransactionRuleQueryVariables>;
export const ReadOffersDocument = gql`
    query readOffers {
  offers: readOffers {
    id
    name
    picture
    status
    category {
      nameEn
      descriptionEn
      region
    }
  }
}
    `;

/**
 * __useReadOffersQuery__
 *
 * To run a query within a React component, call `useReadOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOffersQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadOffersQuery(baseOptions?: Apollo.QueryHookOptions<ReadOffersQuery, ReadOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadOffersQuery, ReadOffersQueryVariables>(ReadOffersDocument, options);
      }
export function useReadOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOffersQuery, ReadOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadOffersQuery, ReadOffersQueryVariables>(ReadOffersDocument, options);
        }
export type ReadOffersQueryHookResult = ReturnType<typeof useReadOffersQuery>;
export type ReadOffersLazyQueryHookResult = ReturnType<typeof useReadOffersLazyQuery>;
export type ReadOffersQueryResult = Apollo.QueryResult<ReadOffersQuery, ReadOffersQueryVariables>;
export const ReadOfferServicesDocument = gql`
    query readOfferServices($offerId: ID!) {
  offerServices: readOfferServices(offerId: $offerId) {
    id
    nameEn
    nameNo
    nameSv
    descriptionEn
    descriptionNo
    descriptionSv
    url
    email
    emailDescriptionEn
    emailDescriptionNo
    emailDescriptionSv
    phone
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useReadOfferServicesQuery__
 *
 * To run a query within a React component, call `useReadOfferServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOfferServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOfferServicesQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useReadOfferServicesQuery(baseOptions: Apollo.QueryHookOptions<ReadOfferServicesQuery, ReadOfferServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadOfferServicesQuery, ReadOfferServicesQueryVariables>(ReadOfferServicesDocument, options);
      }
export function useReadOfferServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOfferServicesQuery, ReadOfferServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadOfferServicesQuery, ReadOfferServicesQueryVariables>(ReadOfferServicesDocument, options);
        }
export type ReadOfferServicesQueryHookResult = ReturnType<typeof useReadOfferServicesQuery>;
export type ReadOfferServicesLazyQueryHookResult = ReturnType<typeof useReadOfferServicesLazyQuery>;
export type ReadOfferServicesQueryResult = Apollo.QueryResult<ReadOfferServicesQuery, ReadOfferServicesQueryVariables>;
export const ReadOfferByIdDocument = gql`
    query readOfferById($offerId: ID!) {
  offer: readOfferById(offerId: $offerId) {
    id
    name
    descriptionEn
    descriptionNo
    descriptionSv
    picture
    status
    url
    createdAt
    updatedAt
    category {
      id
      nameEn
      nameNo
      nameSv
      descriptionEn
      descriptionNo
      descriptionSv
      position
      createdAt
      updatedAt
      region
    }
    services {
      id
      nameEn
      nameNo
      nameSv
      descriptionEn
      descriptionNo
      descriptionSv
      url
      email
      emailDescriptionEn
      emailDescriptionNo
      phone
      createdAt
      updatedAt
      region
    }
  }
}
    `;

/**
 * __useReadOfferByIdQuery__
 *
 * To run a query within a React component, call `useReadOfferByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOfferByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOfferByIdQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useReadOfferByIdQuery(baseOptions: Apollo.QueryHookOptions<ReadOfferByIdQuery, ReadOfferByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadOfferByIdQuery, ReadOfferByIdQueryVariables>(ReadOfferByIdDocument, options);
      }
export function useReadOfferByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOfferByIdQuery, ReadOfferByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadOfferByIdQuery, ReadOfferByIdQueryVariables>(ReadOfferByIdDocument, options);
        }
export type ReadOfferByIdQueryHookResult = ReturnType<typeof useReadOfferByIdQuery>;
export type ReadOfferByIdLazyQueryHookResult = ReturnType<typeof useReadOfferByIdLazyQuery>;
export type ReadOfferByIdQueryResult = Apollo.QueryResult<ReadOfferByIdQuery, ReadOfferByIdQueryVariables>;
export const ReadOfferServiceByIdDocument = gql`
    query readOfferServiceById($offerServiceId: ID!) {
  service: readOfferServiceById(offerServiceId: $offerServiceId) {
    id
    nameEn
    nameNo
    nameSv
    descriptionEn
    descriptionNo
    descriptionSv
    url
    email
    emailDescriptionEn
    emailDescriptionNo
    emailDescriptionSv
    phone
    createdAt
    updatedAt
    region
  }
}
    `;

/**
 * __useReadOfferServiceByIdQuery__
 *
 * To run a query within a React component, call `useReadOfferServiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOfferServiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOfferServiceByIdQuery({
 *   variables: {
 *      offerServiceId: // value for 'offerServiceId'
 *   },
 * });
 */
export function useReadOfferServiceByIdQuery(baseOptions: Apollo.QueryHookOptions<ReadOfferServiceByIdQuery, ReadOfferServiceByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadOfferServiceByIdQuery, ReadOfferServiceByIdQueryVariables>(ReadOfferServiceByIdDocument, options);
      }
export function useReadOfferServiceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOfferServiceByIdQuery, ReadOfferServiceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadOfferServiceByIdQuery, ReadOfferServiceByIdQueryVariables>(ReadOfferServiceByIdDocument, options);
        }
export type ReadOfferServiceByIdQueryHookResult = ReturnType<typeof useReadOfferServiceByIdQuery>;
export type ReadOfferServiceByIdLazyQueryHookResult = ReturnType<typeof useReadOfferServiceByIdLazyQuery>;
export type ReadOfferServiceByIdQueryResult = Apollo.QueryResult<ReadOfferServiceByIdQuery, ReadOfferServiceByIdQueryVariables>;
export const ReadOfferCategoriesDocument = gql`
    query readOfferCategories {
  categories: readOfferCategories {
    id
    nameEn
    nameNo
    nameSv
    region
    descriptionEn
    descriptionNo
    descriptionSv
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useReadOfferCategoriesQuery__
 *
 * To run a query within a React component, call `useReadOfferCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOfferCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOfferCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadOfferCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ReadOfferCategoriesQuery, ReadOfferCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadOfferCategoriesQuery, ReadOfferCategoriesQueryVariables>(ReadOfferCategoriesDocument, options);
      }
export function useReadOfferCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOfferCategoriesQuery, ReadOfferCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadOfferCategoriesQuery, ReadOfferCategoriesQueryVariables>(ReadOfferCategoriesDocument, options);
        }
export type ReadOfferCategoriesQueryHookResult = ReturnType<typeof useReadOfferCategoriesQuery>;
export type ReadOfferCategoriesLazyQueryHookResult = ReturnType<typeof useReadOfferCategoriesLazyQuery>;
export type ReadOfferCategoriesQueryResult = Apollo.QueryResult<ReadOfferCategoriesQuery, ReadOfferCategoriesQueryVariables>;
export const ReadOfferCategoryByIdDocument = gql`
    query readOfferCategoryById($offerCategoryId: ID!) {
  category: readOfferCategoryById(offerCategoryId: $offerCategoryId) {
    id
    nameEn
    nameNo
    nameSv
    descriptionEn
    descriptionNo
    descriptionSv
    position
    region
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useReadOfferCategoryByIdQuery__
 *
 * To run a query within a React component, call `useReadOfferCategoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOfferCategoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOfferCategoryByIdQuery({
 *   variables: {
 *      offerCategoryId: // value for 'offerCategoryId'
 *   },
 * });
 */
export function useReadOfferCategoryByIdQuery(baseOptions: Apollo.QueryHookOptions<ReadOfferCategoryByIdQuery, ReadOfferCategoryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadOfferCategoryByIdQuery, ReadOfferCategoryByIdQueryVariables>(ReadOfferCategoryByIdDocument, options);
      }
export function useReadOfferCategoryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOfferCategoryByIdQuery, ReadOfferCategoryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadOfferCategoryByIdQuery, ReadOfferCategoryByIdQueryVariables>(ReadOfferCategoryByIdDocument, options);
        }
export type ReadOfferCategoryByIdQueryHookResult = ReturnType<typeof useReadOfferCategoryByIdQuery>;
export type ReadOfferCategoryByIdLazyQueryHookResult = ReturnType<typeof useReadOfferCategoryByIdLazyQuery>;
export type ReadOfferCategoryByIdQueryResult = Apollo.QueryResult<ReadOfferCategoryByIdQuery, ReadOfferCategoryByIdQueryVariables>;
export const CreateOfferCategoryDocument = gql`
    mutation createOfferCategory($nameEn: String!, $nameNo: String!, $nameSv: String!, $descriptionEn: String!, $descriptionNo: String!, $descriptionSv: String!, $region: String!) {
  category: createOfferCategory(
    nameEn: $nameEn
    nameNo: $nameNo
    nameSv: $nameSv
    descriptionEn: $descriptionEn
    descriptionNo: $descriptionNo
    descriptionSv: $descriptionSv
    region: $region
  ) {
    id
    nameEn
    nameNo
    nameSv
    descriptionEn
    descriptionNo
    descriptionSv
    region
    position
    createdAt
    updatedAt
  }
}
    `;
export type CreateOfferCategoryMutationFn = Apollo.MutationFunction<CreateOfferCategoryMutation, CreateOfferCategoryMutationVariables>;

/**
 * __useCreateOfferCategoryMutation__
 *
 * To run a mutation, you first call `useCreateOfferCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfferCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfferCategoryMutation, { data, loading, error }] = useCreateOfferCategoryMutation({
 *   variables: {
 *      nameEn: // value for 'nameEn'
 *      nameNo: // value for 'nameNo'
 *      nameSv: // value for 'nameSv'
 *      descriptionEn: // value for 'descriptionEn'
 *      descriptionNo: // value for 'descriptionNo'
 *      descriptionSv: // value for 'descriptionSv'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useCreateOfferCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateOfferCategoryMutation, CreateOfferCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOfferCategoryMutation, CreateOfferCategoryMutationVariables>(CreateOfferCategoryDocument, options);
      }
export type CreateOfferCategoryMutationHookResult = ReturnType<typeof useCreateOfferCategoryMutation>;
export type CreateOfferCategoryMutationResult = Apollo.MutationResult<CreateOfferCategoryMutation>;
export type CreateOfferCategoryMutationOptions = Apollo.BaseMutationOptions<CreateOfferCategoryMutation, CreateOfferCategoryMutationVariables>;
export const UpdateOfferCategoryDocument = gql`
    mutation updateOfferCategory($offerCategoryId: ID!, $nameEn: String!, $nameNo: String!, $nameSv: String!, $descriptionEn: String!, $descriptionNo: String!, $descriptionSv: String!) {
  offer: updateOfferCategory(
    offerCategoryId: $offerCategoryId
    nameEn: $nameEn
    nameNo: $nameNo
    nameSv: $nameSv
    descriptionEn: $descriptionEn
    descriptionNo: $descriptionNo
    descriptionSv: $descriptionSv
  ) {
    id
    nameEn
    nameNo
    nameSv
    descriptionEn
    descriptionNo
    descriptionSv
    position
    region
    createdAt
    updatedAt
  }
}
    `;
export type UpdateOfferCategoryMutationFn = Apollo.MutationFunction<UpdateOfferCategoryMutation, UpdateOfferCategoryMutationVariables>;

/**
 * __useUpdateOfferCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateOfferCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferCategoryMutation, { data, loading, error }] = useUpdateOfferCategoryMutation({
 *   variables: {
 *      offerCategoryId: // value for 'offerCategoryId'
 *      nameEn: // value for 'nameEn'
 *      nameNo: // value for 'nameNo'
 *      nameSv: // value for 'nameSv'
 *      descriptionEn: // value for 'descriptionEn'
 *      descriptionNo: // value for 'descriptionNo'
 *      descriptionSv: // value for 'descriptionSv'
 *   },
 * });
 */
export function useUpdateOfferCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOfferCategoryMutation, UpdateOfferCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOfferCategoryMutation, UpdateOfferCategoryMutationVariables>(UpdateOfferCategoryDocument, options);
      }
export type UpdateOfferCategoryMutationHookResult = ReturnType<typeof useUpdateOfferCategoryMutation>;
export type UpdateOfferCategoryMutationResult = Apollo.MutationResult<UpdateOfferCategoryMutation>;
export type UpdateOfferCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateOfferCategoryMutation, UpdateOfferCategoryMutationVariables>;
export const ArrangeOfferCategoriesDocument = gql`
    mutation arrangeOfferCategories($positions: [OfferCategoryNewPosition!]) {
  categories: arrangeOfferCategories(positions: $positions) {
    id
    nameEn
    nameNo
    nameSv
    descriptionEn
    descriptionNo
    descriptionSv
    position
    region
    createdAt
    updatedAt
  }
}
    `;
export type ArrangeOfferCategoriesMutationFn = Apollo.MutationFunction<ArrangeOfferCategoriesMutation, ArrangeOfferCategoriesMutationVariables>;

/**
 * __useArrangeOfferCategoriesMutation__
 *
 * To run a mutation, you first call `useArrangeOfferCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArrangeOfferCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [arrangeOfferCategoriesMutation, { data, loading, error }] = useArrangeOfferCategoriesMutation({
 *   variables: {
 *      positions: // value for 'positions'
 *   },
 * });
 */
export function useArrangeOfferCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<ArrangeOfferCategoriesMutation, ArrangeOfferCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArrangeOfferCategoriesMutation, ArrangeOfferCategoriesMutationVariables>(ArrangeOfferCategoriesDocument, options);
      }
export type ArrangeOfferCategoriesMutationHookResult = ReturnType<typeof useArrangeOfferCategoriesMutation>;
export type ArrangeOfferCategoriesMutationResult = Apollo.MutationResult<ArrangeOfferCategoriesMutation>;
export type ArrangeOfferCategoriesMutationOptions = Apollo.BaseMutationOptions<ArrangeOfferCategoriesMutation, ArrangeOfferCategoriesMutationVariables>;
export const DeleteOfferCategoryDocument = gql`
    mutation deleteOfferCategory($offerCategoryId: ID!) {
  remainingCategories: deleteOfferCategory(offerCategoryId: $offerCategoryId) {
    id
    nameEn
    nameNo
    nameSv
    descriptionEn
    descriptionNo
    descriptionSv
    position
    region
    createdAt
    updatedAt
  }
}
    `;
export type DeleteOfferCategoryMutationFn = Apollo.MutationFunction<DeleteOfferCategoryMutation, DeleteOfferCategoryMutationVariables>;

/**
 * __useDeleteOfferCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteOfferCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferCategoryMutation, { data, loading, error }] = useDeleteOfferCategoryMutation({
 *   variables: {
 *      offerCategoryId: // value for 'offerCategoryId'
 *   },
 * });
 */
export function useDeleteOfferCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOfferCategoryMutation, DeleteOfferCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOfferCategoryMutation, DeleteOfferCategoryMutationVariables>(DeleteOfferCategoryDocument, options);
      }
export type DeleteOfferCategoryMutationHookResult = ReturnType<typeof useDeleteOfferCategoryMutation>;
export type DeleteOfferCategoryMutationResult = Apollo.MutationResult<DeleteOfferCategoryMutation>;
export type DeleteOfferCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteOfferCategoryMutation, DeleteOfferCategoryMutationVariables>;
export const CreateOfferDocument = gql`
    mutation createOffer($name: String!, $descriptionEn: String!, $descriptionNo: String!, $descriptionSv: String!, $pictureBase64: String!, $url: String!, $categoryId: ID!) {
  offer: createOffer(
    name: $name
    descriptionEn: $descriptionEn
    descriptionNo: $descriptionNo
    descriptionSv: $descriptionSv
    pictureBase64: $pictureBase64
    url: $url
    categoryId: $categoryId
  ) {
    category {
      id
      nameEn
      nameNo
      nameSv
      descriptionEn
      descriptionNo
      descriptionSv
      createdAt
      updatedAt
    }
    id
    name
    descriptionEn
    descriptionNo
    descriptionSv
    picture
    status
    url
    createdAt
    updatedAt
  }
}
    `;
export type CreateOfferMutationFn = Apollo.MutationFunction<CreateOfferMutation, CreateOfferMutationVariables>;

/**
 * __useCreateOfferMutation__
 *
 * To run a mutation, you first call `useCreateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfferMutation, { data, loading, error }] = useCreateOfferMutation({
 *   variables: {
 *      name: // value for 'name'
 *      descriptionEn: // value for 'descriptionEn'
 *      descriptionNo: // value for 'descriptionNo'
 *      descriptionSv: // value for 'descriptionSv'
 *      pictureBase64: // value for 'pictureBase64'
 *      url: // value for 'url'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useCreateOfferMutation(baseOptions?: Apollo.MutationHookOptions<CreateOfferMutation, CreateOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOfferMutation, CreateOfferMutationVariables>(CreateOfferDocument, options);
      }
export type CreateOfferMutationHookResult = ReturnType<typeof useCreateOfferMutation>;
export type CreateOfferMutationResult = Apollo.MutationResult<CreateOfferMutation>;
export type CreateOfferMutationOptions = Apollo.BaseMutationOptions<CreateOfferMutation, CreateOfferMutationVariables>;
export const CreateOfferServiceDocument = gql`
    mutation createOfferService($offerId: ID!, $nameEn: String!, $nameNo: String!, $nameSv: String!, $descriptionEn: String!, $descriptionNo: String!, $descriptionSv: String!, $url: String, $email: String, $emailDescriptionEn: String, $emailDescriptionNo: String, $emailDescriptionSv: String, $phone: String) {
  offerService: createOfferService(
    offerId: $offerId
    nameEn: $nameEn
    nameNo: $nameNo
    nameSv: $nameSv
    descriptionEn: $descriptionEn
    descriptionNo: $descriptionNo
    descriptionSv: $descriptionSv
    url: $url
    email: $email
    emailDescriptionEn: $emailDescriptionEn
    emailDescriptionNo: $emailDescriptionNo
    emailDescriptionSv: $emailDescriptionSv
    phone: $phone
  ) {
    id
    nameEn
    nameNo
    nameSv
    descriptionEn
    descriptionNo
    descriptionSv
    url
    email
    emailDescriptionEn
    emailDescriptionNo
    emailDescriptionSv
    phone
    createdAt
    updatedAt
  }
}
    `;
export type CreateOfferServiceMutationFn = Apollo.MutationFunction<CreateOfferServiceMutation, CreateOfferServiceMutationVariables>;

/**
 * __useCreateOfferServiceMutation__
 *
 * To run a mutation, you first call `useCreateOfferServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfferServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfferServiceMutation, { data, loading, error }] = useCreateOfferServiceMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      nameEn: // value for 'nameEn'
 *      nameNo: // value for 'nameNo'
 *      nameSv: // value for 'nameSv'
 *      descriptionEn: // value for 'descriptionEn'
 *      descriptionNo: // value for 'descriptionNo'
 *      descriptionSv: // value for 'descriptionSv'
 *      url: // value for 'url'
 *      email: // value for 'email'
 *      emailDescriptionEn: // value for 'emailDescriptionEn'
 *      emailDescriptionNo: // value for 'emailDescriptionNo'
 *      emailDescriptionSv: // value for 'emailDescriptionSv'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useCreateOfferServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateOfferServiceMutation, CreateOfferServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOfferServiceMutation, CreateOfferServiceMutationVariables>(CreateOfferServiceDocument, options);
      }
export type CreateOfferServiceMutationHookResult = ReturnType<typeof useCreateOfferServiceMutation>;
export type CreateOfferServiceMutationResult = Apollo.MutationResult<CreateOfferServiceMutation>;
export type CreateOfferServiceMutationOptions = Apollo.BaseMutationOptions<CreateOfferServiceMutation, CreateOfferServiceMutationVariables>;
export const UpdateOfferDocument = gql`
    mutation updateOffer($offerId: ID!, $categoryId: ID!, $name: String!, $descriptionEn: String!, $descriptionNo: String!, $descriptionSv: String!, $status: OfferStatus!, $pictureBase64: String, $url: String!) {
  result: updateOffer(
    offerId: $offerId
    categoryId: $categoryId
    name: $name
    descriptionEn: $descriptionEn
    descriptionNo: $descriptionNo
    descriptionSv: $descriptionSv
    status: $status
    pictureBase64: $pictureBase64
    url: $url
  ) {
    category {
      id
      nameEn
      nameNo
      descriptionEn
      descriptionNo
      descriptionSv
      createdAt
      updatedAt
    }
    id
    name
    descriptionEn
    descriptionNo
    descriptionSv
    picture
    status
    url
    createdAt
    updatedAt
  }
}
    `;
export type UpdateOfferMutationFn = Apollo.MutationFunction<UpdateOfferMutation, UpdateOfferMutationVariables>;

/**
 * __useUpdateOfferMutation__
 *
 * To run a mutation, you first call `useUpdateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferMutation, { data, loading, error }] = useUpdateOfferMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      categoryId: // value for 'categoryId'
 *      name: // value for 'name'
 *      descriptionEn: // value for 'descriptionEn'
 *      descriptionNo: // value for 'descriptionNo'
 *      descriptionSv: // value for 'descriptionSv'
 *      status: // value for 'status'
 *      pictureBase64: // value for 'pictureBase64'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useUpdateOfferMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOfferMutation, UpdateOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOfferMutation, UpdateOfferMutationVariables>(UpdateOfferDocument, options);
      }
export type UpdateOfferMutationHookResult = ReturnType<typeof useUpdateOfferMutation>;
export type UpdateOfferMutationResult = Apollo.MutationResult<UpdateOfferMutation>;
export type UpdateOfferMutationOptions = Apollo.BaseMutationOptions<UpdateOfferMutation, UpdateOfferMutationVariables>;
export const UpdateOfferServiceDocument = gql`
    mutation updateOfferService($offerId: ID!, $offerServiceId: ID!, $nameEn: String!, $nameNo: String!, $nameSv: String!, $descriptionEn: String!, $descriptionNo: String!, $descriptionSv: String!, $url: String, $email: String, $emailDescriptionEn: String, $emailDescriptionNo: String, $emailDescriptionSv: String, $phone: String) {
  offerService: updateOfferService(
    offerId: $offerId
    offerServiceId: $offerServiceId
    nameEn: $nameEn
    nameNo: $nameNo
    nameSv: $nameSv
    descriptionEn: $descriptionEn
    descriptionNo: $descriptionNo
    descriptionSv: $descriptionSv
    url: $url
    email: $email
    emailDescriptionEn: $emailDescriptionEn
    emailDescriptionNo: $emailDescriptionNo
    emailDescriptionSv: $emailDescriptionSv
    phone: $phone
  ) {
    id
    nameEn
    nameNo
    nameSv
    descriptionEn
    descriptionNo
    descriptionSv
    url
    email
    emailDescriptionEn
    emailDescriptionNo
    emailDescriptionSv
    phone
    createdAt
    updatedAt
  }
}
    `;
export type UpdateOfferServiceMutationFn = Apollo.MutationFunction<UpdateOfferServiceMutation, UpdateOfferServiceMutationVariables>;

/**
 * __useUpdateOfferServiceMutation__
 *
 * To run a mutation, you first call `useUpdateOfferServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferServiceMutation, { data, loading, error }] = useUpdateOfferServiceMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      offerServiceId: // value for 'offerServiceId'
 *      nameEn: // value for 'nameEn'
 *      nameNo: // value for 'nameNo'
 *      nameSv: // value for 'nameSv'
 *      descriptionEn: // value for 'descriptionEn'
 *      descriptionNo: // value for 'descriptionNo'
 *      descriptionSv: // value for 'descriptionSv'
 *      url: // value for 'url'
 *      email: // value for 'email'
 *      emailDescriptionEn: // value for 'emailDescriptionEn'
 *      emailDescriptionNo: // value for 'emailDescriptionNo'
 *      emailDescriptionSv: // value for 'emailDescriptionSv'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdateOfferServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOfferServiceMutation, UpdateOfferServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOfferServiceMutation, UpdateOfferServiceMutationVariables>(UpdateOfferServiceDocument, options);
      }
export type UpdateOfferServiceMutationHookResult = ReturnType<typeof useUpdateOfferServiceMutation>;
export type UpdateOfferServiceMutationResult = Apollo.MutationResult<UpdateOfferServiceMutation>;
export type UpdateOfferServiceMutationOptions = Apollo.BaseMutationOptions<UpdateOfferServiceMutation, UpdateOfferServiceMutationVariables>;
export const DeleteOfferDocument = gql`
    mutation deleteOffer($offerId: ID!, $categoryId: ID!) {
  offers: deleteOffer(offerId: $offerId, categoryId: $categoryId) {
    category {
      id
      nameEn
      nameNo
      nameSv
      descriptionEn
      descriptionNo
      descriptionSv
      createdAt
      updatedAt
    }
    id
    name
    descriptionEn
    descriptionNo
    descriptionSv
    picture
    status
    url
    createdAt
    updatedAt
  }
}
    `;
export type DeleteOfferMutationFn = Apollo.MutationFunction<DeleteOfferMutation, DeleteOfferMutationVariables>;

/**
 * __useDeleteOfferMutation__
 *
 * To run a mutation, you first call `useDeleteOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferMutation, { data, loading, error }] = useDeleteOfferMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useDeleteOfferMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOfferMutation, DeleteOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOfferMutation, DeleteOfferMutationVariables>(DeleteOfferDocument, options);
      }
export type DeleteOfferMutationHookResult = ReturnType<typeof useDeleteOfferMutation>;
export type DeleteOfferMutationResult = Apollo.MutationResult<DeleteOfferMutation>;
export type DeleteOfferMutationOptions = Apollo.BaseMutationOptions<DeleteOfferMutation, DeleteOfferMutationVariables>;
export const DeleteOfferServiceDocument = gql`
    mutation deleteOfferService($offerId: ID!, $offerServiceId: ID!) {
  remainingOfferServices: deleteOfferService(
    offerId: $offerId
    offerServiceId: $offerServiceId
  ) {
    id
    nameEn
    nameNo
    nameSv
    descriptionEn
    descriptionNo
    descriptionSv
    url
    email
    phone
    createdAt
    updatedAt
  }
}
    `;
export type DeleteOfferServiceMutationFn = Apollo.MutationFunction<DeleteOfferServiceMutation, DeleteOfferServiceMutationVariables>;

/**
 * __useDeleteOfferServiceMutation__
 *
 * To run a mutation, you first call `useDeleteOfferServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfferServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfferServiceMutation, { data, loading, error }] = useDeleteOfferServiceMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      offerServiceId: // value for 'offerServiceId'
 *   },
 * });
 */
export function useDeleteOfferServiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOfferServiceMutation, DeleteOfferServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOfferServiceMutation, DeleteOfferServiceMutationVariables>(DeleteOfferServiceDocument, options);
      }
export type DeleteOfferServiceMutationHookResult = ReturnType<typeof useDeleteOfferServiceMutation>;
export type DeleteOfferServiceMutationResult = Apollo.MutationResult<DeleteOfferServiceMutation>;
export type DeleteOfferServiceMutationOptions = Apollo.BaseMutationOptions<DeleteOfferServiceMutation, DeleteOfferServiceMutationVariables>;
export const ReadOfferLeadsStatisticsDocument = gql`
    query readOfferLeadsStatistics($fromDate: DateTime!, $toDate: DateTime!) {
  stats: readOfferLeadsStatistics(fromDate: $fromDate, toDate: $toDate) {
    offers {
      id
      name
      viewCount
      clickCount
      emailSubmitCount
      services {
        id
        name
        clickCount
        emailSubmitCount
      }
    }
    totalViewCount
    totalClickCount
    totalEmailSubmitCount
  }
}
    `;

/**
 * __useReadOfferLeadsStatisticsQuery__
 *
 * To run a query within a React component, call `useReadOfferLeadsStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOfferLeadsStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOfferLeadsStatisticsQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useReadOfferLeadsStatisticsQuery(baseOptions: Apollo.QueryHookOptions<ReadOfferLeadsStatisticsQuery, ReadOfferLeadsStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadOfferLeadsStatisticsQuery, ReadOfferLeadsStatisticsQueryVariables>(ReadOfferLeadsStatisticsDocument, options);
      }
export function useReadOfferLeadsStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOfferLeadsStatisticsQuery, ReadOfferLeadsStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadOfferLeadsStatisticsQuery, ReadOfferLeadsStatisticsQueryVariables>(ReadOfferLeadsStatisticsDocument, options);
        }
export type ReadOfferLeadsStatisticsQueryHookResult = ReturnType<typeof useReadOfferLeadsStatisticsQuery>;
export type ReadOfferLeadsStatisticsLazyQueryHookResult = ReturnType<typeof useReadOfferLeadsStatisticsLazyQuery>;
export type ReadOfferLeadsStatisticsQueryResult = Apollo.QueryResult<ReadOfferLeadsStatisticsQuery, ReadOfferLeadsStatisticsQueryVariables>;
export const HandlePepRequestDocument = gql`
    mutation handlePEPRequest($userId: ID!, $decision: PEPDecision!) {
  result: handlePEPRequest(userId: $userId, decision: $decision) {
    einvoice {
      acceptAllIssuers
    }
    pep {
      status
    }
  }
}
    `;
export type HandlePepRequestMutationFn = Apollo.MutationFunction<HandlePepRequestMutation, HandlePepRequestMutationVariables>;

/**
 * __useHandlePepRequestMutation__
 *
 * To run a mutation, you first call `useHandlePepRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandlePepRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handlePepRequestMutation, { data, loading, error }] = useHandlePepRequestMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      decision: // value for 'decision'
 *   },
 * });
 */
export function useHandlePepRequestMutation(baseOptions?: Apollo.MutationHookOptions<HandlePepRequestMutation, HandlePepRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HandlePepRequestMutation, HandlePepRequestMutationVariables>(HandlePepRequestDocument, options);
      }
export type HandlePepRequestMutationHookResult = ReturnType<typeof useHandlePepRequestMutation>;
export type HandlePepRequestMutationResult = Apollo.MutationResult<HandlePepRequestMutation>;
export type HandlePepRequestMutationOptions = Apollo.BaseMutationOptions<HandlePepRequestMutation, HandlePepRequestMutationVariables>;
export const ReadAdminProfileDocument = gql`
    query readAdminProfile {
  profile: readAdminProfile {
    id
    firstname
    lastname
    subRole
  }
}
    `;

/**
 * __useReadAdminProfileQuery__
 *
 * To run a query within a React component, call `useReadAdminProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAdminProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAdminProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadAdminProfileQuery(baseOptions?: Apollo.QueryHookOptions<ReadAdminProfileQuery, ReadAdminProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAdminProfileQuery, ReadAdminProfileQueryVariables>(ReadAdminProfileDocument, options);
      }
export function useReadAdminProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAdminProfileQuery, ReadAdminProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAdminProfileQuery, ReadAdminProfileQueryVariables>(ReadAdminProfileDocument, options);
        }
export type ReadAdminProfileQueryHookResult = ReturnType<typeof useReadAdminProfileQuery>;
export type ReadAdminProfileLazyQueryHookResult = ReturnType<typeof useReadAdminProfileLazyQuery>;
export type ReadAdminProfileQueryResult = Apollo.QueryResult<ReadAdminProfileQuery, ReadAdminProfileQueryVariables>;
export const RemoveAccountDocument = gql`
    mutation removeAccount($userId: ID!, $message: String) {
  user: removeAccount(userId: $userId, message: $message) {
    id
    sid
    firstname
    lastname
    birthdate
    email
    emailCheck
    mobile
    mobileCheck
    address {
      street
      postalCode
      city
      country {
        name
      }
    }
  }
}
    `;
export type RemoveAccountMutationFn = Apollo.MutationFunction<RemoveAccountMutation, RemoveAccountMutationVariables>;

/**
 * __useRemoveAccountMutation__
 *
 * To run a mutation, you first call `useRemoveAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountMutation, { data, loading, error }] = useRemoveAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useRemoveAccountMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAccountMutation, RemoveAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAccountMutation, RemoveAccountMutationVariables>(RemoveAccountDocument, options);
      }
export type RemoveAccountMutationHookResult = ReturnType<typeof useRemoveAccountMutation>;
export type RemoveAccountMutationResult = Apollo.MutationResult<RemoveAccountMutation>;
export type RemoveAccountMutationOptions = Apollo.BaseMutationOptions<RemoveAccountMutation, RemoveAccountMutationVariables>;
export const ReadPspTransactionsDocument = gql`
    query readPspTransactions($fromDate: DateTime!, $toDate: DateTime!, $isConnected: Int!, $statusType: String!, $status: String!, $type: String!, $provider: String!, $search: String!, $circuits: [CardCircuit!]) {
  result: readPspTransactions(
    fromDate: $fromDate
    toDate: $toDate
    isConnected: $isConnected
    statusType: $statusType
    status: $status
    type: $type
    provider: $provider
    search: $search
    circuits: $circuits
  ) {
    transactions {
      id
      remoteCreatedAt
      localCreatedAt
      bkInvoiceTransactionId
      bkUserSubscriptionId
      bkInvoiceId
      bkUserId
      bkType
      bkStatus
      bkAction
      bkProvider
      transactionNumber
      paymentMethod
      type
      amount
      vatAmount
      currency
      paymentId
      userEmail
      userName
      issuerName
      reconciliationId
      subscriptionDuration
      userSubscriptionStartsAt
      userSubscriptionEndsAt
      circuit
    }
  }
}
    `;

/**
 * __useReadPspTransactionsQuery__
 *
 * To run a query within a React component, call `useReadPspTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPspTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPspTransactionsQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      isConnected: // value for 'isConnected'
 *      statusType: // value for 'statusType'
 *      status: // value for 'status'
 *      type: // value for 'type'
 *      provider: // value for 'provider'
 *      search: // value for 'search'
 *      circuits: // value for 'circuits'
 *   },
 * });
 */
export function useReadPspTransactionsQuery(baseOptions: Apollo.QueryHookOptions<ReadPspTransactionsQuery, ReadPspTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadPspTransactionsQuery, ReadPspTransactionsQueryVariables>(ReadPspTransactionsDocument, options);
      }
export function useReadPspTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadPspTransactionsQuery, ReadPspTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadPspTransactionsQuery, ReadPspTransactionsQueryVariables>(ReadPspTransactionsDocument, options);
        }
export type ReadPspTransactionsQueryHookResult = ReturnType<typeof useReadPspTransactionsQuery>;
export type ReadPspTransactionsLazyQueryHookResult = ReturnType<typeof useReadPspTransactionsLazyQuery>;
export type ReadPspTransactionsQueryResult = Apollo.QueryResult<ReadPspTransactionsQuery, ReadPspTransactionsQueryVariables>;
export const ReadPspTransactionsAsExcelDocument = gql`
    query ReadPspTransactionsAsExcel($fromDate: DateTime!, $toDate: DateTime!, $isConnected: Int!, $statusType: String!, $status: String!, $type: String!, $provider: String!, $search: String!, $circuits: [CardCircuit!]) {
  result: readPspTransactionsAsExcel(
    fromDate: $fromDate
    toDate: $toDate
    isConnected: $isConnected
    statusType: $statusType
    status: $status
    type: $type
    provider: $provider
    search: $search
    circuits: $circuits
  ) {
    data
  }
}
    `;

/**
 * __useReadPspTransactionsAsExcelQuery__
 *
 * To run a query within a React component, call `useReadPspTransactionsAsExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPspTransactionsAsExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPspTransactionsAsExcelQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      isConnected: // value for 'isConnected'
 *      statusType: // value for 'statusType'
 *      status: // value for 'status'
 *      type: // value for 'type'
 *      provider: // value for 'provider'
 *      search: // value for 'search'
 *      circuits: // value for 'circuits'
 *   },
 * });
 */
export function useReadPspTransactionsAsExcelQuery(baseOptions: Apollo.QueryHookOptions<ReadPspTransactionsAsExcelQuery, ReadPspTransactionsAsExcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadPspTransactionsAsExcelQuery, ReadPspTransactionsAsExcelQueryVariables>(ReadPspTransactionsAsExcelDocument, options);
      }
export function useReadPspTransactionsAsExcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadPspTransactionsAsExcelQuery, ReadPspTransactionsAsExcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadPspTransactionsAsExcelQuery, ReadPspTransactionsAsExcelQueryVariables>(ReadPspTransactionsAsExcelDocument, options);
        }
export type ReadPspTransactionsAsExcelQueryHookResult = ReturnType<typeof useReadPspTransactionsAsExcelQuery>;
export type ReadPspTransactionsAsExcelLazyQueryHookResult = ReturnType<typeof useReadPspTransactionsAsExcelLazyQuery>;
export type ReadPspTransactionsAsExcelQueryResult = Apollo.QueryResult<ReadPspTransactionsAsExcelQuery, ReadPspTransactionsAsExcelQueryVariables>;
export const ReadPspTransactionByIdDocument = gql`
    query readPspTransactionById($id: Int!) {
  result: readPspTransactionById(id: $id) {
    id
    remoteCreatedAt
    localCreatedAt
    bkInvoiceTransactionId
    bkUserSubscriptionId
    bkInvoiceId
    bkUserId
    bkType
    bkStatus
    bkAction
    bkProvider
    transactionNumber
    paymentMethod
    type
    amount
    vatAmount
    currency
    paymentId
    userEmail
    userName
    issuerName
    reconciliationId
    subscriptionDuration
    userSubscriptionStartsAt
    userSubscriptionEndsAt
  }
}
    `;

/**
 * __useReadPspTransactionByIdQuery__
 *
 * To run a query within a React component, call `useReadPspTransactionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPspTransactionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPspTransactionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadPspTransactionByIdQuery(baseOptions: Apollo.QueryHookOptions<ReadPspTransactionByIdQuery, ReadPspTransactionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadPspTransactionByIdQuery, ReadPspTransactionByIdQueryVariables>(ReadPspTransactionByIdDocument, options);
      }
export function useReadPspTransactionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadPspTransactionByIdQuery, ReadPspTransactionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadPspTransactionByIdQuery, ReadPspTransactionByIdQueryVariables>(ReadPspTransactionByIdDocument, options);
        }
export type ReadPspTransactionByIdQueryHookResult = ReturnType<typeof useReadPspTransactionByIdQuery>;
export type ReadPspTransactionByIdLazyQueryHookResult = ReturnType<typeof useReadPspTransactionByIdLazyQuery>;
export type ReadPspTransactionByIdQueryResult = Apollo.QueryResult<ReadPspTransactionByIdQuery, ReadPspTransactionByIdQueryVariables>;
export const ReadPspTransactionLogsByIdDocument = gql`
    query readPspTransactionLogsById($id: Int!) {
  result: readPspTransactionLogsById(id: $id) {
    logs {
      time
      event
      ipAddress
      url
      data
    }
  }
}
    `;

/**
 * __useReadPspTransactionLogsByIdQuery__
 *
 * To run a query within a React component, call `useReadPspTransactionLogsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPspTransactionLogsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPspTransactionLogsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadPspTransactionLogsByIdQuery(baseOptions: Apollo.QueryHookOptions<ReadPspTransactionLogsByIdQuery, ReadPspTransactionLogsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadPspTransactionLogsByIdQuery, ReadPspTransactionLogsByIdQueryVariables>(ReadPspTransactionLogsByIdDocument, options);
      }
export function useReadPspTransactionLogsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadPspTransactionLogsByIdQuery, ReadPspTransactionLogsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadPspTransactionLogsByIdQuery, ReadPspTransactionLogsByIdQueryVariables>(ReadPspTransactionLogsByIdDocument, options);
        }
export type ReadPspTransactionLogsByIdQueryHookResult = ReturnType<typeof useReadPspTransactionLogsByIdQuery>;
export type ReadPspTransactionLogsByIdLazyQueryHookResult = ReturnType<typeof useReadPspTransactionLogsByIdLazyQuery>;
export type ReadPspTransactionLogsByIdQueryResult = Apollo.QueryResult<ReadPspTransactionLogsByIdQuery, ReadPspTransactionLogsByIdQueryVariables>;
export const GetPspTransactionIdByInvoiceIdDocument = gql`
    query getPspTransactionIdByInvoiceId($invoiceId: String!) {
  pspTransactionId: GetPspTransactionIdByInvoiceId(invoiceId: $invoiceId)
}
    `;

/**
 * __useGetPspTransactionIdByInvoiceIdQuery__
 *
 * To run a query within a React component, call `useGetPspTransactionIdByInvoiceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPspTransactionIdByInvoiceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPspTransactionIdByInvoiceIdQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetPspTransactionIdByInvoiceIdQuery(baseOptions: Apollo.QueryHookOptions<GetPspTransactionIdByInvoiceIdQuery, GetPspTransactionIdByInvoiceIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPspTransactionIdByInvoiceIdQuery, GetPspTransactionIdByInvoiceIdQueryVariables>(GetPspTransactionIdByInvoiceIdDocument, options);
      }
export function useGetPspTransactionIdByInvoiceIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPspTransactionIdByInvoiceIdQuery, GetPspTransactionIdByInvoiceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPspTransactionIdByInvoiceIdQuery, GetPspTransactionIdByInvoiceIdQueryVariables>(GetPspTransactionIdByInvoiceIdDocument, options);
        }
export type GetPspTransactionIdByInvoiceIdQueryHookResult = ReturnType<typeof useGetPspTransactionIdByInvoiceIdQuery>;
export type GetPspTransactionIdByInvoiceIdLazyQueryHookResult = ReturnType<typeof useGetPspTransactionIdByInvoiceIdLazyQuery>;
export type GetPspTransactionIdByInvoiceIdQueryResult = Apollo.QueryResult<GetPspTransactionIdByInvoiceIdQuery, GetPspTransactionIdByInvoiceIdQueryVariables>;
export const ReadSubscriptionIncomeOverviewDocument = gql`
    query readSubscriptionIncomeOverview($startsAt: DateTime!, $endsAt: DateTime!) {
  result: readSubscriptionIncomeOverview(startsAt: $startsAt, endsAt: $endsAt) {
    total
    totalCoupon
    totalEffective
    subtotals {
      subscription {
        id
        name
      }
      amount
      count
      amountCoupon
      countCoupon
    }
    subtotalsCoupons {
      code
      count
    }
  }
}
    `;

/**
 * __useReadSubscriptionIncomeOverviewQuery__
 *
 * To run a query within a React component, call `useReadSubscriptionIncomeOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSubscriptionIncomeOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSubscriptionIncomeOverviewQuery({
 *   variables: {
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *   },
 * });
 */
export function useReadSubscriptionIncomeOverviewQuery(baseOptions: Apollo.QueryHookOptions<ReadSubscriptionIncomeOverviewQuery, ReadSubscriptionIncomeOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadSubscriptionIncomeOverviewQuery, ReadSubscriptionIncomeOverviewQueryVariables>(ReadSubscriptionIncomeOverviewDocument, options);
      }
export function useReadSubscriptionIncomeOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadSubscriptionIncomeOverviewQuery, ReadSubscriptionIncomeOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadSubscriptionIncomeOverviewQuery, ReadSubscriptionIncomeOverviewQueryVariables>(ReadSubscriptionIncomeOverviewDocument, options);
        }
export type ReadSubscriptionIncomeOverviewQueryHookResult = ReturnType<typeof useReadSubscriptionIncomeOverviewQuery>;
export type ReadSubscriptionIncomeOverviewLazyQueryHookResult = ReturnType<typeof useReadSubscriptionIncomeOverviewLazyQuery>;
export type ReadSubscriptionIncomeOverviewQueryResult = Apollo.QueryResult<ReadSubscriptionIncomeOverviewQuery, ReadSubscriptionIncomeOverviewQueryVariables>;
export const ReadUserSubscriptionsByUserIdDocument = gql`
    query readUserSubscriptionsByUserId($userId: ID!, $first: Int) {
  userSubscriptions: readUserSubscriptionsByUserId(userId: $userId, first: $first) {
    edges {
      node {
        id
        userId
        startsAt
        endsAt
        autoRenewal
        renewalStatus
        isFreeTrial
        isRefunded
        transactionRef
        paymentCard {
          id
          name
          customName
          brand
          expiration
          isDefault
        }
        renewalCard {
          id
          name
          customName
          brand
          expiration
          isDefault
        }
        subscription {
          id
          type
          amount
          period
          welcomePoints
        }
        coupon {
          id
          code
          amount
        }
      }
    }
  }
}
    `;

/**
 * __useReadUserSubscriptionsByUserIdQuery__
 *
 * To run a query within a React component, call `useReadUserSubscriptionsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUserSubscriptionsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUserSubscriptionsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useReadUserSubscriptionsByUserIdQuery(baseOptions: Apollo.QueryHookOptions<ReadUserSubscriptionsByUserIdQuery, ReadUserSubscriptionsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadUserSubscriptionsByUserIdQuery, ReadUserSubscriptionsByUserIdQueryVariables>(ReadUserSubscriptionsByUserIdDocument, options);
      }
export function useReadUserSubscriptionsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadUserSubscriptionsByUserIdQuery, ReadUserSubscriptionsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadUserSubscriptionsByUserIdQuery, ReadUserSubscriptionsByUserIdQueryVariables>(ReadUserSubscriptionsByUserIdDocument, options);
        }
export type ReadUserSubscriptionsByUserIdQueryHookResult = ReturnType<typeof useReadUserSubscriptionsByUserIdQuery>;
export type ReadUserSubscriptionsByUserIdLazyQueryHookResult = ReturnType<typeof useReadUserSubscriptionsByUserIdLazyQuery>;
export type ReadUserSubscriptionsByUserIdQueryResult = Apollo.QueryResult<ReadUserSubscriptionsByUserIdQuery, ReadUserSubscriptionsByUserIdQueryVariables>;
export const RefundSubscriptionDocument = gql`
    mutation refundSubscription($userId: ID!, $userSubscriptionId: ID!, $amount: String!) {
  subscription: refundSubscription(
    userId: $userId
    userSubscriptionId: $userSubscriptionId
    amount: $amount
  ) {
    id
    userId
    startsAt
    endsAt
    autoRenewal
    renewalStatus
    isFreeTrial
    isRefunded
    transactionRef
    renewalCard {
      id
      name
      customName
      brand
      expiration
      isDefault
    }
    subscription {
      id
      type
      amount
      period
      welcomePoints
    }
  }
}
    `;
export type RefundSubscriptionMutationFn = Apollo.MutationFunction<RefundSubscriptionMutation, RefundSubscriptionMutationVariables>;

/**
 * __useRefundSubscriptionMutation__
 *
 * To run a mutation, you first call `useRefundSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundSubscriptionMutation, { data, loading, error }] = useRefundSubscriptionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userSubscriptionId: // value for 'userSubscriptionId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useRefundSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<RefundSubscriptionMutation, RefundSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefundSubscriptionMutation, RefundSubscriptionMutationVariables>(RefundSubscriptionDocument, options);
      }
export type RefundSubscriptionMutationHookResult = ReturnType<typeof useRefundSubscriptionMutation>;
export type RefundSubscriptionMutationResult = Apollo.MutationResult<RefundSubscriptionMutation>;
export type RefundSubscriptionMutationOptions = Apollo.BaseMutationOptions<RefundSubscriptionMutation, RefundSubscriptionMutationVariables>;
export const AdminChangeSubscriptionDocument = gql`
    mutation adminChangeSubscription($userId: ID!, $userSubscriptionId: ID!, $autoRenewal: Boolean, $endsAt: String) {
  subscription: adminChangeSubscription(
    userId: $userId
    userSubscriptionId: $userSubscriptionId
    autoRenewal: $autoRenewal
    endsAt: $endsAt
  ) {
    id
    userId
    startsAt
    endsAt
    autoRenewal
    renewalStatus
    isFreeTrial
    isRefunded
    transactionRef
    renewalCard {
      id
      name
      customName
      brand
      expiration
      isDefault
    }
    subscription {
      id
      type
      amount
      period
      welcomePoints
    }
  }
}
    `;
export type AdminChangeSubscriptionMutationFn = Apollo.MutationFunction<AdminChangeSubscriptionMutation, AdminChangeSubscriptionMutationVariables>;

/**
 * __useAdminChangeSubscriptionMutation__
 *
 * To run a mutation, you first call `useAdminChangeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminChangeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminChangeSubscriptionMutation, { data, loading, error }] = useAdminChangeSubscriptionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userSubscriptionId: // value for 'userSubscriptionId'
 *      autoRenewal: // value for 'autoRenewal'
 *      endsAt: // value for 'endsAt'
 *   },
 * });
 */
export function useAdminChangeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<AdminChangeSubscriptionMutation, AdminChangeSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminChangeSubscriptionMutation, AdminChangeSubscriptionMutationVariables>(AdminChangeSubscriptionDocument, options);
      }
export type AdminChangeSubscriptionMutationHookResult = ReturnType<typeof useAdminChangeSubscriptionMutation>;
export type AdminChangeSubscriptionMutationResult = Apollo.MutationResult<AdminChangeSubscriptionMutation>;
export type AdminChangeSubscriptionMutationOptions = Apollo.BaseMutationOptions<AdminChangeSubscriptionMutation, AdminChangeSubscriptionMutationVariables>;
export const ReadSubscriptionsDocument = gql`
    query readSubscriptions($subscriptionType: SubscriptionType!) {
  subscriptions: readSubscriptions(subscriptionType: $subscriptionType) {
    id
    type
    amount
    period
    welcomePoints
    couponProduct
  }
}
    `;

/**
 * __useReadSubscriptionsQuery__
 *
 * To run a query within a React component, call `useReadSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSubscriptionsQuery({
 *   variables: {
 *      subscriptionType: // value for 'subscriptionType'
 *   },
 * });
 */
export function useReadSubscriptionsQuery(baseOptions: Apollo.QueryHookOptions<ReadSubscriptionsQuery, ReadSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadSubscriptionsQuery, ReadSubscriptionsQueryVariables>(ReadSubscriptionsDocument, options);
      }
export function useReadSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadSubscriptionsQuery, ReadSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadSubscriptionsQuery, ReadSubscriptionsQueryVariables>(ReadSubscriptionsDocument, options);
        }
export type ReadSubscriptionsQueryHookResult = ReturnType<typeof useReadSubscriptionsQuery>;
export type ReadSubscriptionsLazyQueryHookResult = ReturnType<typeof useReadSubscriptionsLazyQuery>;
export type ReadSubscriptionsQueryResult = Apollo.QueryResult<ReadSubscriptionsQuery, ReadSubscriptionsQueryVariables>;
export const CreateSubscriptionDocument = gql`
    mutation createSubscription($subscriptionIdToReplace: ID!, $welcomePoints: Int!, $amount: Float!, $replaceMode: SubscriptionReplaceMode!) {
  subscription: createSubscription(
    subscriptionIdToReplace: $subscriptionIdToReplace
    welcomePoints: $welcomePoints
    amount: $amount
    replaceMode: $replaceMode
  ) {
    id
    type
    amount
    period
    welcomePoints
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      subscriptionIdToReplace: // value for 'subscriptionIdToReplace'
 *      welcomePoints: // value for 'welcomePoints'
 *      amount: // value for 'amount'
 *      replaceMode: // value for 'replaceMode'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const ReadJobsDocument = gql`
    query readJobs {
  result: readJobs {
    name
    description
    when
  }
}
    `;

/**
 * __useReadJobsQuery__
 *
 * To run a query within a React component, call `useReadJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadJobsQuery(baseOptions?: Apollo.QueryHookOptions<ReadJobsQuery, ReadJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadJobsQuery, ReadJobsQueryVariables>(ReadJobsDocument, options);
      }
export function useReadJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadJobsQuery, ReadJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadJobsQuery, ReadJobsQueryVariables>(ReadJobsDocument, options);
        }
export type ReadJobsQueryHookResult = ReturnType<typeof useReadJobsQuery>;
export type ReadJobsLazyQueryHookResult = ReturnType<typeof useReadJobsLazyQuery>;
export type ReadJobsQueryResult = Apollo.QueryResult<ReadJobsQuery, ReadJobsQueryVariables>;
export const ReadStatisticsInvoicesDocument = gql`
    query readStatisticsInvoices {
  statistics: readStatisticsInvoices {
    lastUpdate
    overview {
      totalCount
      totalPaidCount
      totalAmount
    }
    histograms {
      cumulativeCount {
        count
        paidCount
        time
      }
      paidAmount {
        amount
        count
        time
      }
    }
  }
}
    `;

/**
 * __useReadStatisticsInvoicesQuery__
 *
 * To run a query within a React component, call `useReadStatisticsInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadStatisticsInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadStatisticsInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadStatisticsInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<ReadStatisticsInvoicesQuery, ReadStatisticsInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadStatisticsInvoicesQuery, ReadStatisticsInvoicesQueryVariables>(ReadStatisticsInvoicesDocument, options);
      }
export function useReadStatisticsInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadStatisticsInvoicesQuery, ReadStatisticsInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadStatisticsInvoicesQuery, ReadStatisticsInvoicesQueryVariables>(ReadStatisticsInvoicesDocument, options);
        }
export type ReadStatisticsInvoicesQueryHookResult = ReturnType<typeof useReadStatisticsInvoicesQuery>;
export type ReadStatisticsInvoicesLazyQueryHookResult = ReturnType<typeof useReadStatisticsInvoicesLazyQuery>;
export type ReadStatisticsInvoicesQueryResult = Apollo.QueryResult<ReadStatisticsInvoicesQuery, ReadStatisticsInvoicesQueryVariables>;
export const ReadStatisticsIssuersDocument = gql`
    query readStatisticsIssuers {
  statistics: readStatisticsIssuers {
    lastUpdate
    overview {
      totalCount
      totalLogoCount
      totalSupportCount
      totalInvoiceRequests
      totalDebtRequests
    }
    histograms {
      registration {
        count
        time
      }
      cumulativeRegistration {
        count
        logoCount
        supportCount
        time
      }
      requests {
        debtCount
        invoiceCount
        time
      }
      cumulativeRequests {
        debtCount
        invoiceCount
        time
      }
    }
  }
}
    `;

/**
 * __useReadStatisticsIssuersQuery__
 *
 * To run a query within a React component, call `useReadStatisticsIssuersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadStatisticsIssuersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadStatisticsIssuersQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadStatisticsIssuersQuery(baseOptions?: Apollo.QueryHookOptions<ReadStatisticsIssuersQuery, ReadStatisticsIssuersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadStatisticsIssuersQuery, ReadStatisticsIssuersQueryVariables>(ReadStatisticsIssuersDocument, options);
      }
export function useReadStatisticsIssuersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadStatisticsIssuersQuery, ReadStatisticsIssuersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadStatisticsIssuersQuery, ReadStatisticsIssuersQueryVariables>(ReadStatisticsIssuersDocument, options);
        }
export type ReadStatisticsIssuersQueryHookResult = ReturnType<typeof useReadStatisticsIssuersQuery>;
export type ReadStatisticsIssuersLazyQueryHookResult = ReturnType<typeof useReadStatisticsIssuersLazyQuery>;
export type ReadStatisticsIssuersQueryResult = Apollo.QueryResult<ReadStatisticsIssuersQuery, ReadStatisticsIssuersQueryVariables>;
export const ReadStatisticsUsersDocument = gql`
    query readStatisticsUsers {
  statistics: readStatisticsUsers {
    lastUpdate
    overview {
      totalCount
      totalActiveCount
      totalInactiveCount
      totalDeletedCount
      ageAverage
    }
    histograms {
      registration {
        count
        ageAverage
        activeCount
        inactiveCount
        deletedCount
        time
      }
      cumulativeRegistration {
        count
        ageAverage
        activeCount
        inactiveCount
        deletedCount
        time
      }
    }
  }
}
    `;

/**
 * __useReadStatisticsUsersQuery__
 *
 * To run a query within a React component, call `useReadStatisticsUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadStatisticsUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadStatisticsUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadStatisticsUsersQuery(baseOptions?: Apollo.QueryHookOptions<ReadStatisticsUsersQuery, ReadStatisticsUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadStatisticsUsersQuery, ReadStatisticsUsersQueryVariables>(ReadStatisticsUsersDocument, options);
      }
export function useReadStatisticsUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadStatisticsUsersQuery, ReadStatisticsUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadStatisticsUsersQuery, ReadStatisticsUsersQueryVariables>(ReadStatisticsUsersDocument, options);
        }
export type ReadStatisticsUsersQueryHookResult = ReturnType<typeof useReadStatisticsUsersQuery>;
export type ReadStatisticsUsersLazyQueryHookResult = ReturnType<typeof useReadStatisticsUsersLazyQuery>;
export type ReadStatisticsUsersQueryResult = Apollo.QueryResult<ReadStatisticsUsersQuery, ReadStatisticsUsersQueryVariables>;
export const ReadStatisticsChurnDocument = gql`
    query readStatisticsChurn {
  churnStats: readStatisticsChurn {
    Year
    Month
    Count
  }
}
    `;

/**
 * __useReadStatisticsChurnQuery__
 *
 * To run a query within a React component, call `useReadStatisticsChurnQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadStatisticsChurnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadStatisticsChurnQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadStatisticsChurnQuery(baseOptions?: Apollo.QueryHookOptions<ReadStatisticsChurnQuery, ReadStatisticsChurnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadStatisticsChurnQuery, ReadStatisticsChurnQueryVariables>(ReadStatisticsChurnDocument, options);
      }
export function useReadStatisticsChurnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadStatisticsChurnQuery, ReadStatisticsChurnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadStatisticsChurnQuery, ReadStatisticsChurnQueryVariables>(ReadStatisticsChurnDocument, options);
        }
export type ReadStatisticsChurnQueryHookResult = ReturnType<typeof useReadStatisticsChurnQuery>;
export type ReadStatisticsChurnLazyQueryHookResult = ReturnType<typeof useReadStatisticsChurnLazyQuery>;
export type ReadStatisticsChurnQueryResult = Apollo.QueryResult<ReadStatisticsChurnQuery, ReadStatisticsChurnQueryVariables>;
export const ReadStatisticsAccessDocument = gql`
    query readStatisticsAccess {
  statistics: readStatisticsAccess {
    lastUpdate
    histograms {
      access {
        count
        countLogout
        countBankIdSignature
        countBankIdSession
        countDeviceIos
        countDeviceAndroid
        time
      }
    }
  }
}
    `;

/**
 * __useReadStatisticsAccessQuery__
 *
 * To run a query within a React component, call `useReadStatisticsAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadStatisticsAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadStatisticsAccessQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadStatisticsAccessQuery(baseOptions?: Apollo.QueryHookOptions<ReadStatisticsAccessQuery, ReadStatisticsAccessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadStatisticsAccessQuery, ReadStatisticsAccessQueryVariables>(ReadStatisticsAccessDocument, options);
      }
export function useReadStatisticsAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadStatisticsAccessQuery, ReadStatisticsAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadStatisticsAccessQuery, ReadStatisticsAccessQueryVariables>(ReadStatisticsAccessDocument, options);
        }
export type ReadStatisticsAccessQueryHookResult = ReturnType<typeof useReadStatisticsAccessQuery>;
export type ReadStatisticsAccessLazyQueryHookResult = ReturnType<typeof useReadStatisticsAccessLazyQuery>;
export type ReadStatisticsAccessQueryResult = Apollo.QueryResult<ReadStatisticsAccessQuery, ReadStatisticsAccessQueryVariables>;
export const ReadStatisticsSubscriptionsDocument = gql`
    query readStatisticsSubscriptions {
  statistics: readStatisticsSubscriptions {
    lastUpdate
    overview {
      totalCount
      totalRefundedCount
      activeCount
      activeDistribution {
        subscription {
          id
          type
          period
          amount
        }
        count
      }
    }
    histograms {
      overview {
        count
        refundedCount
        activeCount
        time
      }
    }
  }
}
    `;

/**
 * __useReadStatisticsSubscriptionsQuery__
 *
 * To run a query within a React component, call `useReadStatisticsSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadStatisticsSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadStatisticsSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadStatisticsSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<ReadStatisticsSubscriptionsQuery, ReadStatisticsSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadStatisticsSubscriptionsQuery, ReadStatisticsSubscriptionsQueryVariables>(ReadStatisticsSubscriptionsDocument, options);
      }
export function useReadStatisticsSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadStatisticsSubscriptionsQuery, ReadStatisticsSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadStatisticsSubscriptionsQuery, ReadStatisticsSubscriptionsQueryVariables>(ReadStatisticsSubscriptionsDocument, options);
        }
export type ReadStatisticsSubscriptionsQueryHookResult = ReturnType<typeof useReadStatisticsSubscriptionsQuery>;
export type ReadStatisticsSubscriptionsLazyQueryHookResult = ReturnType<typeof useReadStatisticsSubscriptionsLazyQuery>;
export type ReadStatisticsSubscriptionsQueryResult = Apollo.QueryResult<ReadStatisticsSubscriptionsQuery, ReadStatisticsSubscriptionsQueryVariables>;
export const ReadLanguagesDocument = gql`
    query readLanguages {
  languages: readLanguages {
    id
    code
    name
  }
}
    `;

/**
 * __useReadLanguagesQuery__
 *
 * To run a query within a React component, call `useReadLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<ReadLanguagesQuery, ReadLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadLanguagesQuery, ReadLanguagesQueryVariables>(ReadLanguagesDocument, options);
      }
export function useReadLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadLanguagesQuery, ReadLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadLanguagesQuery, ReadLanguagesQueryVariables>(ReadLanguagesDocument, options);
        }
export type ReadLanguagesQueryHookResult = ReturnType<typeof useReadLanguagesQuery>;
export type ReadLanguagesLazyQueryHookResult = ReturnType<typeof useReadLanguagesLazyQuery>;
export type ReadLanguagesQueryResult = Apollo.QueryResult<ReadLanguagesQuery, ReadLanguagesQueryVariables>;
export const ReadTranslationCodesDocument = gql`
    query readTranslationCodes($searchTerm: String, $before: String, $after: String, $first: Int, $last: Int) {
  translationCodes: readTranslationCodes(
    searchTerm: $searchTerm
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    edges {
      cursor
      node {
        ...TranslationCodeResult
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${TranslationCodeResultFragmentDoc}`;

/**
 * __useReadTranslationCodesQuery__
 *
 * To run a query within a React component, call `useReadTranslationCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTranslationCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTranslationCodesQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useReadTranslationCodesQuery(baseOptions?: Apollo.QueryHookOptions<ReadTranslationCodesQuery, ReadTranslationCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTranslationCodesQuery, ReadTranslationCodesQueryVariables>(ReadTranslationCodesDocument, options);
      }
export function useReadTranslationCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTranslationCodesQuery, ReadTranslationCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTranslationCodesQuery, ReadTranslationCodesQueryVariables>(ReadTranslationCodesDocument, options);
        }
export type ReadTranslationCodesQueryHookResult = ReturnType<typeof useReadTranslationCodesQuery>;
export type ReadTranslationCodesLazyQueryHookResult = ReturnType<typeof useReadTranslationCodesLazyQuery>;
export type ReadTranslationCodesQueryResult = Apollo.QueryResult<ReadTranslationCodesQuery, ReadTranslationCodesQueryVariables>;
export const ReadTranslationDocument = gql`
    query readTranslation($codeId: ID!, $languageId: ID!) {
  translation: readTranslation(codeId: $codeId, languageId: $languageId) {
    id
    codeId
    languageId
    translation
  }
}
    `;

/**
 * __useReadTranslationQuery__
 *
 * To run a query within a React component, call `useReadTranslationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTranslationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTranslationQuery({
 *   variables: {
 *      codeId: // value for 'codeId'
 *      languageId: // value for 'languageId'
 *   },
 * });
 */
export function useReadTranslationQuery(baseOptions: Apollo.QueryHookOptions<ReadTranslationQuery, ReadTranslationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTranslationQuery, ReadTranslationQueryVariables>(ReadTranslationDocument, options);
      }
export function useReadTranslationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTranslationQuery, ReadTranslationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTranslationQuery, ReadTranslationQueryVariables>(ReadTranslationDocument, options);
        }
export type ReadTranslationQueryHookResult = ReturnType<typeof useReadTranslationQuery>;
export type ReadTranslationLazyQueryHookResult = ReturnType<typeof useReadTranslationLazyQuery>;
export type ReadTranslationQueryResult = Apollo.QueryResult<ReadTranslationQuery, ReadTranslationQueryVariables>;
export const ReadTranslationsDocument = gql`
    query readTranslations($codeId: ID!) {
  translations: readTranslations(codeId: $codeId) {
    code {
      id
      code
    }
    translations {
      language {
        id
        code
        name
      }
      translation {
        ...TranslationResult
      }
    }
  }
}
    ${TranslationResultFragmentDoc}`;

/**
 * __useReadTranslationsQuery__
 *
 * To run a query within a React component, call `useReadTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTranslationsQuery({
 *   variables: {
 *      codeId: // value for 'codeId'
 *   },
 * });
 */
export function useReadTranslationsQuery(baseOptions: Apollo.QueryHookOptions<ReadTranslationsQuery, ReadTranslationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadTranslationsQuery, ReadTranslationsQueryVariables>(ReadTranslationsDocument, options);
      }
export function useReadTranslationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTranslationsQuery, ReadTranslationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadTranslationsQuery, ReadTranslationsQueryVariables>(ReadTranslationsDocument, options);
        }
export type ReadTranslationsQueryHookResult = ReturnType<typeof useReadTranslationsQuery>;
export type ReadTranslationsLazyQueryHookResult = ReturnType<typeof useReadTranslationsLazyQuery>;
export type ReadTranslationsQueryResult = Apollo.QueryResult<ReadTranslationsQuery, ReadTranslationsQueryVariables>;
export const SetTranslationDocument = gql`
    mutation setTranslation($languageId: ID!, $code: String!, $translation: String!) {
  translation: setTranslation(
    languageId: $languageId
    code: $code
    translation: $translation
  ) {
    ...TranslationResult
  }
}
    ${TranslationResultFragmentDoc}`;
export type SetTranslationMutationFn = Apollo.MutationFunction<SetTranslationMutation, SetTranslationMutationVariables>;

/**
 * __useSetTranslationMutation__
 *
 * To run a mutation, you first call `useSetTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTranslationMutation, { data, loading, error }] = useSetTranslationMutation({
 *   variables: {
 *      languageId: // value for 'languageId'
 *      code: // value for 'code'
 *      translation: // value for 'translation'
 *   },
 * });
 */
export function useSetTranslationMutation(baseOptions?: Apollo.MutationHookOptions<SetTranslationMutation, SetTranslationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTranslationMutation, SetTranslationMutationVariables>(SetTranslationDocument, options);
      }
export type SetTranslationMutationHookResult = ReturnType<typeof useSetTranslationMutation>;
export type SetTranslationMutationResult = Apollo.MutationResult<SetTranslationMutation>;
export type SetTranslationMutationOptions = Apollo.BaseMutationOptions<SetTranslationMutation, SetTranslationMutationVariables>;
export const DeleteTranslationsByCodeDocument = gql`
    mutation deleteTranslationsByCode($codeId: ID!) {
  translations: deleteTranslationsByCode(codeId: $codeId) {
    id
    code
  }
}
    `;
export type DeleteTranslationsByCodeMutationFn = Apollo.MutationFunction<DeleteTranslationsByCodeMutation, DeleteTranslationsByCodeMutationVariables>;

/**
 * __useDeleteTranslationsByCodeMutation__
 *
 * To run a mutation, you first call `useDeleteTranslationsByCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTranslationsByCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTranslationsByCodeMutation, { data, loading, error }] = useDeleteTranslationsByCodeMutation({
 *   variables: {
 *      codeId: // value for 'codeId'
 *   },
 * });
 */
export function useDeleteTranslationsByCodeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTranslationsByCodeMutation, DeleteTranslationsByCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTranslationsByCodeMutation, DeleteTranslationsByCodeMutationVariables>(DeleteTranslationsByCodeDocument, options);
      }
export type DeleteTranslationsByCodeMutationHookResult = ReturnType<typeof useDeleteTranslationsByCodeMutation>;
export type DeleteTranslationsByCodeMutationResult = Apollo.MutationResult<DeleteTranslationsByCodeMutation>;
export type DeleteTranslationsByCodeMutationOptions = Apollo.BaseMutationOptions<DeleteTranslationsByCodeMutation, DeleteTranslationsByCodeMutationVariables>;
export const ReadAllUsersDocument = gql`
    query readAllUsers($first: Int, $last: Int, $before: String, $after: String, $filterByName: String, $filterByCheckedEmail: Boolean, $filterByCheckedMobile: Boolean, $filterByActivity: UserActivityEnum, $filterByRegion: RegionEnum, $descId: Boolean, $acceptEfaktura: Boolean) {
  result: readAllUsers(
    first: $first
    last: $last
    before: $before
    after: $after
    filterByName: $filterByName
    filterByCheckedEmail: $filterByCheckedEmail
    filterByCheckedMobile: $filterByCheckedMobile
    filterByActivity: $filterByActivity
    filterByRegion: $filterByRegion
    descId: $descId
    acceptEfaktura: $acceptEfaktura
  ) {
    edges {
      cursor
      node {
        ...UserResult
        region
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${UserResultFragmentDoc}`;

/**
 * __useReadAllUsersQuery__
 *
 * To run a query within a React component, call `useReadAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAllUsersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filterByName: // value for 'filterByName'
 *      filterByCheckedEmail: // value for 'filterByCheckedEmail'
 *      filterByCheckedMobile: // value for 'filterByCheckedMobile'
 *      filterByActivity: // value for 'filterByActivity'
 *      filterByRegion: // value for 'filterByRegion'
 *      descId: // value for 'descId'
 *      acceptEfaktura: // value for 'acceptEfaktura'
 *   },
 * });
 */
export function useReadAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<ReadAllUsersQuery, ReadAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAllUsersQuery, ReadAllUsersQueryVariables>(ReadAllUsersDocument, options);
      }
export function useReadAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAllUsersQuery, ReadAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAllUsersQuery, ReadAllUsersQueryVariables>(ReadAllUsersDocument, options);
        }
export type ReadAllUsersQueryHookResult = ReturnType<typeof useReadAllUsersQuery>;
export type ReadAllUsersLazyQueryHookResult = ReturnType<typeof useReadAllUsersLazyQuery>;
export type ReadAllUsersQueryResult = Apollo.QueryResult<ReadAllUsersQuery, ReadAllUsersQueryVariables>;
export const ReadUserByIdDocument = gql`
    query readUserById($userId: ID!) {
  result: readUserById(userId: $userId) {
    ...UserDetail
  }
}
    ${UserDetailFragmentDoc}`;

/**
 * __useReadUserByIdQuery__
 *
 * To run a query within a React component, call `useReadUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUserByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useReadUserByIdQuery(baseOptions: Apollo.QueryHookOptions<ReadUserByIdQuery, ReadUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadUserByIdQuery, ReadUserByIdQueryVariables>(ReadUserByIdDocument, options);
      }
export function useReadUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadUserByIdQuery, ReadUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadUserByIdQuery, ReadUserByIdQueryVariables>(ReadUserByIdDocument, options);
        }
export type ReadUserByIdQueryHookResult = ReturnType<typeof useReadUserByIdQuery>;
export type ReadUserByIdLazyQueryHookResult = ReturnType<typeof useReadUserByIdLazyQuery>;
export type ReadUserByIdQueryResult = Apollo.QueryResult<ReadUserByIdQuery, ReadUserByIdQueryVariables>;
export const ReadUserActivitiesDocument = gql`
    query readUserActivities($userId: ID!, $fromDate: DateTime!, $toDate: DateTime!) {
  result: readUserActivities(
    userId: $userId
    fromDate: $fromDate
    toDate: $toDate
  ) {
    time
    component
    event
    status
    error
    details {
      context
      stackTrace
    }
    userId
    issuerId
    cardId
    invoiceId
  }
}
    `;

/**
 * __useReadUserActivitiesQuery__
 *
 * To run a query within a React component, call `useReadUserActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUserActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUserActivitiesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useReadUserActivitiesQuery(baseOptions: Apollo.QueryHookOptions<ReadUserActivitiesQuery, ReadUserActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadUserActivitiesQuery, ReadUserActivitiesQueryVariables>(ReadUserActivitiesDocument, options);
      }
export function useReadUserActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadUserActivitiesQuery, ReadUserActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadUserActivitiesQuery, ReadUserActivitiesQueryVariables>(ReadUserActivitiesDocument, options);
        }
export type ReadUserActivitiesQueryHookResult = ReturnType<typeof useReadUserActivitiesQuery>;
export type ReadUserActivitiesLazyQueryHookResult = ReturnType<typeof useReadUserActivitiesLazyQuery>;
export type ReadUserActivitiesQueryResult = Apollo.QueryResult<ReadUserActivitiesQuery, ReadUserActivitiesQueryVariables>;
export const ReadEmailsForNewsletterDocument = gql`
    query readEmailsForNewsletter($acceptEInvoice: Boolean, $filterByActivity: UserActivityEnum, $filterByCheckedMobile: Boolean, $filterByName: String, $descId: Boolean, $isDeleted: Boolean, $region: RegionEnum) {
  readEmailsForNewsletter(
    filterByName: $filterByName
    filterByCheckedMobile: $filterByCheckedMobile
    filterByActivity: $filterByActivity
    acceptEInvoice: $acceptEInvoice
    descId: $descId
    isDeleted: $isDeleted
    region: $region
  ) {
    emails
  }
}
    `;

/**
 * __useReadEmailsForNewsletterQuery__
 *
 * To run a query within a React component, call `useReadEmailsForNewsletterQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadEmailsForNewsletterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadEmailsForNewsletterQuery({
 *   variables: {
 *      acceptEInvoice: // value for 'acceptEInvoice'
 *      filterByActivity: // value for 'filterByActivity'
 *      filterByCheckedMobile: // value for 'filterByCheckedMobile'
 *      filterByName: // value for 'filterByName'
 *      descId: // value for 'descId'
 *      isDeleted: // value for 'isDeleted'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useReadEmailsForNewsletterQuery(baseOptions?: Apollo.QueryHookOptions<ReadEmailsForNewsletterQuery, ReadEmailsForNewsletterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadEmailsForNewsletterQuery, ReadEmailsForNewsletterQueryVariables>(ReadEmailsForNewsletterDocument, options);
      }
export function useReadEmailsForNewsletterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadEmailsForNewsletterQuery, ReadEmailsForNewsletterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadEmailsForNewsletterQuery, ReadEmailsForNewsletterQueryVariables>(ReadEmailsForNewsletterDocument, options);
        }
export type ReadEmailsForNewsletterQueryHookResult = ReturnType<typeof useReadEmailsForNewsletterQuery>;
export type ReadEmailsForNewsletterLazyQueryHookResult = ReturnType<typeof useReadEmailsForNewsletterLazyQuery>;
export type ReadEmailsForNewsletterQueryResult = Apollo.QueryResult<ReadEmailsForNewsletterQuery, ReadEmailsForNewsletterQueryVariables>;
export const UpdateUserStatusDocument = gql`
    mutation updateUserStatus($userId: ID!, $status: UserStatusEnum!) {
  user: updateUserStatus(userId: $userId, status: $status) {
    ...UserDetail
  }
}
    ${UserDetailFragmentDoc}`;
export type UpdateUserStatusMutationFn = Apollo.MutationFunction<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>;

/**
 * __useUpdateUserStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserStatusMutation, { data, loading, error }] = useUpdateUserStatusMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateUserStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>(UpdateUserStatusDocument, options);
      }
export type UpdateUserStatusMutationHookResult = ReturnType<typeof useUpdateUserStatusMutation>;
export type UpdateUserStatusMutationResult = Apollo.MutationResult<UpdateUserStatusMutation>;
export type UpdateUserStatusMutationOptions = Apollo.BaseMutationOptions<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>;
export const ReadUserGroupsDocument = gql`
    query readUserGroups {
  userGroups: readUserGroups {
    ...UserGroupResult
  }
}
    ${UserGroupResultFragmentDoc}`;

/**
 * __useReadUserGroupsQuery__
 *
 * To run a query within a React component, call `useReadUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUserGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadUserGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ReadUserGroupsQuery, ReadUserGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadUserGroupsQuery, ReadUserGroupsQueryVariables>(ReadUserGroupsDocument, options);
      }
export function useReadUserGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadUserGroupsQuery, ReadUserGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadUserGroupsQuery, ReadUserGroupsQueryVariables>(ReadUserGroupsDocument, options);
        }
export type ReadUserGroupsQueryHookResult = ReturnType<typeof useReadUserGroupsQuery>;
export type ReadUserGroupsLazyQueryHookResult = ReturnType<typeof useReadUserGroupsLazyQuery>;
export type ReadUserGroupsQueryResult = Apollo.QueryResult<ReadUserGroupsQuery, ReadUserGroupsQueryVariables>;
export const CreateUserGroupDocument = gql`
    mutation createUserGroup($name: String!, $description: String!, $region: RegionEnum!) {
  userGroup: createUserGroup(
    name: $name
    description: $description
    region: $region
  ) {
    ...UserGroupResult
  }
}
    ${UserGroupResultFragmentDoc}`;
export type CreateUserGroupMutationFn = Apollo.MutationFunction<CreateUserGroupMutation, CreateUserGroupMutationVariables>;

/**
 * __useCreateUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGroupMutation, { data, loading, error }] = useCreateUserGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useCreateUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserGroupMutation, CreateUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserGroupMutation, CreateUserGroupMutationVariables>(CreateUserGroupDocument, options);
      }
export type CreateUserGroupMutationHookResult = ReturnType<typeof useCreateUserGroupMutation>;
export type CreateUserGroupMutationResult = Apollo.MutationResult<CreateUserGroupMutation>;
export type CreateUserGroupMutationOptions = Apollo.BaseMutationOptions<CreateUserGroupMutation, CreateUserGroupMutationVariables>;
export const UpdateUserGroupDocument = gql`
    mutation updateUserGroup($groupId: ID!, $name: String!, $description: String!) {
  userGroup: updateUserGroup(
    groupId: $groupId
    name: $name
    description: $description
  ) {
    ...UserGroupResult
  }
}
    ${UserGroupResultFragmentDoc}`;
export type UpdateUserGroupMutationFn = Apollo.MutationFunction<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>;

/**
 * __useUpdateUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupMutation, { data, loading, error }] = useUpdateUserGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>(UpdateUserGroupDocument, options);
      }
export type UpdateUserGroupMutationHookResult = ReturnType<typeof useUpdateUserGroupMutation>;
export type UpdateUserGroupMutationResult = Apollo.MutationResult<UpdateUserGroupMutation>;
export type UpdateUserGroupMutationOptions = Apollo.BaseMutationOptions<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>;
export const DeleteUserGroupDocument = gql`
    mutation deleteUserGroup($groupId: ID!) {
  userGroups: deleteUserGroup(groupId: $groupId) {
    ...UserGroupResult
  }
}
    ${UserGroupResultFragmentDoc}`;
export type DeleteUserGroupMutationFn = Apollo.MutationFunction<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>;

/**
 * __useDeleteUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserGroupMutation, { data, loading, error }] = useDeleteUserGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeleteUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>(DeleteUserGroupDocument, options);
      }
export type DeleteUserGroupMutationHookResult = ReturnType<typeof useDeleteUserGroupMutation>;
export type DeleteUserGroupMutationResult = Apollo.MutationResult<DeleteUserGroupMutation>;
export type DeleteUserGroupMutationOptions = Apollo.BaseMutationOptions<DeleteUserGroupMutation, DeleteUserGroupMutationVariables>;
export const ReadUserGroupMembersDocument = gql`
    query readUserGroupMembers($groupId: ID!, $first: Int, $last: Int, $after: String, $before: String) {
  userGroupMembers: readUserGroupMembers(
    groupId: $groupId
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    ...UserGroupMemberResult
  }
}
    ${UserGroupMemberResultFragmentDoc}`;

/**
 * __useReadUserGroupMembersQuery__
 *
 * To run a query within a React component, call `useReadUserGroupMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUserGroupMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUserGroupMembersQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useReadUserGroupMembersQuery(baseOptions: Apollo.QueryHookOptions<ReadUserGroupMembersQuery, ReadUserGroupMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadUserGroupMembersQuery, ReadUserGroupMembersQueryVariables>(ReadUserGroupMembersDocument, options);
      }
export function useReadUserGroupMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadUserGroupMembersQuery, ReadUserGroupMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadUserGroupMembersQuery, ReadUserGroupMembersQueryVariables>(ReadUserGroupMembersDocument, options);
        }
export type ReadUserGroupMembersQueryHookResult = ReturnType<typeof useReadUserGroupMembersQuery>;
export type ReadUserGroupMembersLazyQueryHookResult = ReturnType<typeof useReadUserGroupMembersLazyQuery>;
export type ReadUserGroupMembersQueryResult = Apollo.QueryResult<ReadUserGroupMembersQuery, ReadUserGroupMembersQueryVariables>;
export const CreateUserGroupMemberDocument = gql`
    mutation createUserGroupMember($userId: ID!, $groupId: ID!) {
  userGroupMember: createUserGroupMember(userId: $userId, groupId: $groupId) {
    ...UserGroupMemberResult
  }
}
    ${UserGroupMemberResultFragmentDoc}`;
export type CreateUserGroupMemberMutationFn = Apollo.MutationFunction<CreateUserGroupMemberMutation, CreateUserGroupMemberMutationVariables>;

/**
 * __useCreateUserGroupMemberMutation__
 *
 * To run a mutation, you first call `useCreateUserGroupMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGroupMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGroupMemberMutation, { data, loading, error }] = useCreateUserGroupMemberMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useCreateUserGroupMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserGroupMemberMutation, CreateUserGroupMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserGroupMemberMutation, CreateUserGroupMemberMutationVariables>(CreateUserGroupMemberDocument, options);
      }
export type CreateUserGroupMemberMutationHookResult = ReturnType<typeof useCreateUserGroupMemberMutation>;
export type CreateUserGroupMemberMutationResult = Apollo.MutationResult<CreateUserGroupMemberMutation>;
export type CreateUserGroupMemberMutationOptions = Apollo.BaseMutationOptions<CreateUserGroupMemberMutation, CreateUserGroupMemberMutationVariables>;
export const DeleteUserGroupMemberDocument = gql`
    mutation deleteUserGroupMember($memberId: ID!, $groupId: ID!) {
  userGroupMembers: deleteUserGroupMember(memberId: $memberId, groupId: $groupId) {
    ...UserGroupMemberResult
  }
}
    ${UserGroupMemberResultFragmentDoc}`;
export type DeleteUserGroupMemberMutationFn = Apollo.MutationFunction<DeleteUserGroupMemberMutation, DeleteUserGroupMemberMutationVariables>;

/**
 * __useDeleteUserGroupMemberMutation__
 *
 * To run a mutation, you first call `useDeleteUserGroupMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserGroupMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserGroupMemberMutation, { data, loading, error }] = useDeleteUserGroupMemberMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeleteUserGroupMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserGroupMemberMutation, DeleteUserGroupMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserGroupMemberMutation, DeleteUserGroupMemberMutationVariables>(DeleteUserGroupMemberDocument, options);
      }
export type DeleteUserGroupMemberMutationHookResult = ReturnType<typeof useDeleteUserGroupMemberMutation>;
export type DeleteUserGroupMemberMutationResult = Apollo.MutationResult<DeleteUserGroupMemberMutation>;
export type DeleteUserGroupMemberMutationOptions = Apollo.BaseMutationOptions<DeleteUserGroupMemberMutation, DeleteUserGroupMemberMutationVariables>;
export const CreateUserNoteDocument = gql`
    mutation createUserNote($userId: ID!, $content: String!, $noteTime: DateTime!) {
  note: createUserNote(userId: $userId, content: $content, noteTime: $noteTime) {
    id
    content
    noteTime
  }
}
    `;
export type CreateUserNoteMutationFn = Apollo.MutationFunction<CreateUserNoteMutation, CreateUserNoteMutationVariables>;

/**
 * __useCreateUserNoteMutation__
 *
 * To run a mutation, you first call `useCreateUserNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserNoteMutation, { data, loading, error }] = useCreateUserNoteMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      content: // value for 'content'
 *      noteTime: // value for 'noteTime'
 *   },
 * });
 */
export function useCreateUserNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserNoteMutation, CreateUserNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserNoteMutation, CreateUserNoteMutationVariables>(CreateUserNoteDocument, options);
      }
export type CreateUserNoteMutationHookResult = ReturnType<typeof useCreateUserNoteMutation>;
export type CreateUserNoteMutationResult = Apollo.MutationResult<CreateUserNoteMutation>;
export type CreateUserNoteMutationOptions = Apollo.BaseMutationOptions<CreateUserNoteMutation, CreateUserNoteMutationVariables>;
export const UpdateUserNoteDocument = gql`
    mutation updateUserNote($id: ID!, $userId: ID!, $content: String, $noteTime: DateTime) {
  note: updateUserNote(
    id: $id
    userId: $userId
    content: $content
    noteTime: $noteTime
  ) {
    id
    content
    noteTime
  }
}
    `;
export type UpdateUserNoteMutationFn = Apollo.MutationFunction<UpdateUserNoteMutation, UpdateUserNoteMutationVariables>;

/**
 * __useUpdateUserNoteMutation__
 *
 * To run a mutation, you first call `useUpdateUserNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNoteMutation, { data, loading, error }] = useUpdateUserNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *      content: // value for 'content'
 *      noteTime: // value for 'noteTime'
 *   },
 * });
 */
export function useUpdateUserNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserNoteMutation, UpdateUserNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserNoteMutation, UpdateUserNoteMutationVariables>(UpdateUserNoteDocument, options);
      }
export type UpdateUserNoteMutationHookResult = ReturnType<typeof useUpdateUserNoteMutation>;
export type UpdateUserNoteMutationResult = Apollo.MutationResult<UpdateUserNoteMutation>;
export type UpdateUserNoteMutationOptions = Apollo.BaseMutationOptions<UpdateUserNoteMutation, UpdateUserNoteMutationVariables>;
export const ReadUserNotesDocument = gql`
    query readUserNotes($first: Int, $after: String, $userId: ID!) {
  notes: readUserNotes(first: $first, after: $after, userId: $userId) {
    edges {
      node {
        id
        content
        noteTime
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;

/**
 * __useReadUserNotesQuery__
 *
 * To run a query within a React component, call `useReadUserNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUserNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUserNotesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useReadUserNotesQuery(baseOptions: Apollo.QueryHookOptions<ReadUserNotesQuery, ReadUserNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadUserNotesQuery, ReadUserNotesQueryVariables>(ReadUserNotesDocument, options);
      }
export function useReadUserNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadUserNotesQuery, ReadUserNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadUserNotesQuery, ReadUserNotesQueryVariables>(ReadUserNotesDocument, options);
        }
export type ReadUserNotesQueryHookResult = ReturnType<typeof useReadUserNotesQuery>;
export type ReadUserNotesLazyQueryHookResult = ReturnType<typeof useReadUserNotesLazyQuery>;
export type ReadUserNotesQueryResult = Apollo.QueryResult<ReadUserNotesQuery, ReadUserNotesQueryVariables>;
export const DeleteUserNoteDocument = gql`
    mutation deleteUserNote($id: ID!, $userId: ID!) {
  note: deleteUserNote(id: $id, userId: $userId) {
    id
    content
    noteTime
  }
}
    `;
export type DeleteUserNoteMutationFn = Apollo.MutationFunction<DeleteUserNoteMutation, DeleteUserNoteMutationVariables>;

/**
 * __useDeleteUserNoteMutation__
 *
 * To run a mutation, you first call `useDeleteUserNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserNoteMutation, { data, loading, error }] = useDeleteUserNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserNoteMutation, DeleteUserNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserNoteMutation, DeleteUserNoteMutationVariables>(DeleteUserNoteDocument, options);
      }
export type DeleteUserNoteMutationHookResult = ReturnType<typeof useDeleteUserNoteMutation>;
export type DeleteUserNoteMutationResult = Apollo.MutationResult<DeleteUserNoteMutation>;
export type DeleteUserNoteMutationOptions = Apollo.BaseMutationOptions<DeleteUserNoteMutation, DeleteUserNoteMutationVariables>;
export const ReadZenExtraBalanceDocument = gql`
    query ReadZenExtraBalance($fromDate: DateTime!, $toDate: DateTime!) {
  readZenExtraBalance(fromDate: $fromDate, toDate: $toDate) {
    TotalEarnedExtra
    TotalUsedExtra
    TotalUnusedExtra
    TotalEarnedExtraByInvoice
    TotalEarnedExtraByInvitation
    TotalEarnedExtraStandaloneSubscription
    ZenPointExt {
      ZenPoint {
        Id
        UserId
        RefId
        InviterId
        FriendId
        InvoiceId
        Points {
          Basic
          Extra
          Proposal
        }
        Balance {
          Basic
          Extra
        }
        ProcessState {
          Name
          Value
        }
        Status {
          Name
          Value
        }
        Type {
          Name
          Value
        }
        Reason {
          Name
          Value
        }
        Role {
          Name
          Value
        }
        InvitationCode
        Memo
        BeltNo
        ProcessDate
        CreatedAt
        UpdatedAt
      }
      UserName
      UserEmail
      InvoiceReference
      IssuerId
      IssuerName
      Reference
      Amount
      CurrencyCode
      RemitDate
      ZenFee
      FeeAmount
    }
  }
}
    `;

/**
 * __useReadZenExtraBalanceQuery__
 *
 * To run a query within a React component, call `useReadZenExtraBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadZenExtraBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadZenExtraBalanceQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useReadZenExtraBalanceQuery(baseOptions: Apollo.QueryHookOptions<ReadZenExtraBalanceQuery, ReadZenExtraBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadZenExtraBalanceQuery, ReadZenExtraBalanceQueryVariables>(ReadZenExtraBalanceDocument, options);
      }
export function useReadZenExtraBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadZenExtraBalanceQuery, ReadZenExtraBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadZenExtraBalanceQuery, ReadZenExtraBalanceQueryVariables>(ReadZenExtraBalanceDocument, options);
        }
export type ReadZenExtraBalanceQueryHookResult = ReturnType<typeof useReadZenExtraBalanceQuery>;
export type ReadZenExtraBalanceLazyQueryHookResult = ReturnType<typeof useReadZenExtraBalanceLazyQuery>;
export type ReadZenExtraBalanceQueryResult = Apollo.QueryResult<ReadZenExtraBalanceQuery, ReadZenExtraBalanceQueryVariables>;
export const AdminReadUserZenHistoryDocument = gql`
    query AdminReadUserZenHistory($startId: Int!, $userId: ID!) {
  readUserZenHistory(startId: $startId, userId: $userId) {
    Id
    FriendId
    Points {
      Proposal
      Basic
      Extra
    }
    Balance {
      Basic
      Extra
    }
    Status {
      Value
      Name
    }
    ProcessState {
      Value
      Name
    }
    Type {
      Value
      Name
    }
    Role {
      Value
      Name
    }
    Reason {
      Value
      Name
    }
    Invoice {
      id
      reference
    }
    InvoiceIdentifier
    InvitationCode
    Memo
    UpdatedAt
    ProcessDate
  }
}
    `;

/**
 * __useAdminReadUserZenHistoryQuery__
 *
 * To run a query within a React component, call `useAdminReadUserZenHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminReadUserZenHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminReadUserZenHistoryQuery({
 *   variables: {
 *      startId: // value for 'startId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminReadUserZenHistoryQuery(baseOptions: Apollo.QueryHookOptions<AdminReadUserZenHistoryQuery, AdminReadUserZenHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminReadUserZenHistoryQuery, AdminReadUserZenHistoryQueryVariables>(AdminReadUserZenHistoryDocument, options);
      }
export function useAdminReadUserZenHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminReadUserZenHistoryQuery, AdminReadUserZenHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminReadUserZenHistoryQuery, AdminReadUserZenHistoryQueryVariables>(AdminReadUserZenHistoryDocument, options);
        }
export type AdminReadUserZenHistoryQueryHookResult = ReturnType<typeof useAdminReadUserZenHistoryQuery>;
export type AdminReadUserZenHistoryLazyQueryHookResult = ReturnType<typeof useAdminReadUserZenHistoryLazyQuery>;
export type AdminReadUserZenHistoryQueryResult = Apollo.QueryResult<AdminReadUserZenHistoryQuery, AdminReadUserZenHistoryQueryVariables>;
export const ReadSystemZenExtraBalanceDocument = gql`
    query ReadSystemZenExtraBalance($date: DateTime!) {
  readSystemZenExtraBalance(date: $date)
}
    `;

/**
 * __useReadSystemZenExtraBalanceQuery__
 *
 * To run a query within a React component, call `useReadSystemZenExtraBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSystemZenExtraBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSystemZenExtraBalanceQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useReadSystemZenExtraBalanceQuery(baseOptions: Apollo.QueryHookOptions<ReadSystemZenExtraBalanceQuery, ReadSystemZenExtraBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadSystemZenExtraBalanceQuery, ReadSystemZenExtraBalanceQueryVariables>(ReadSystemZenExtraBalanceDocument, options);
      }
export function useReadSystemZenExtraBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadSystemZenExtraBalanceQuery, ReadSystemZenExtraBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadSystemZenExtraBalanceQuery, ReadSystemZenExtraBalanceQueryVariables>(ReadSystemZenExtraBalanceDocument, options);
        }
export type ReadSystemZenExtraBalanceQueryHookResult = ReturnType<typeof useReadSystemZenExtraBalanceQuery>;
export type ReadSystemZenExtraBalanceLazyQueryHookResult = ReturnType<typeof useReadSystemZenExtraBalanceLazyQuery>;
export type ReadSystemZenExtraBalanceQueryResult = Apollo.QueryResult<ReadSystemZenExtraBalanceQuery, ReadSystemZenExtraBalanceQueryVariables>;
export const ZenCostListDocument = gql`
    query ZenCostList {
  zenCostList {
    id
    typ
    typeName
    static
    multiplier
    ts
  }
}
    `;

/**
 * __useZenCostListQuery__
 *
 * To run a query within a React component, call `useZenCostListQuery` and pass it any options that fit your needs.
 * When your component renders, `useZenCostListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZenCostListQuery({
 *   variables: {
 *   },
 * });
 */
export function useZenCostListQuery(baseOptions?: Apollo.QueryHookOptions<ZenCostListQuery, ZenCostListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZenCostListQuery, ZenCostListQueryVariables>(ZenCostListDocument, options);
      }
export function useZenCostListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZenCostListQuery, ZenCostListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZenCostListQuery, ZenCostListQueryVariables>(ZenCostListDocument, options);
        }
export type ZenCostListQueryHookResult = ReturnType<typeof useZenCostListQuery>;
export type ZenCostListLazyQueryHookResult = ReturnType<typeof useZenCostListLazyQuery>;
export type ZenCostListQueryResult = Apollo.QueryResult<ZenCostListQuery, ZenCostListQueryVariables>;
export const UpdateZenCostDocument = gql`
    mutation UpdateZenCost($typeId: Int!, $multiplier: Int, $static: Int) {
  updateZenCost(typeId: $typeId, multiplier: $multiplier, static: $static) {
    id
    typ
    typeName
    static
    multiplier
    ts
  }
}
    `;
export type UpdateZenCostMutationFn = Apollo.MutationFunction<UpdateZenCostMutation, UpdateZenCostMutationVariables>;

/**
 * __useUpdateZenCostMutation__
 *
 * To run a mutation, you first call `useUpdateZenCostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZenCostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZenCostMutation, { data, loading, error }] = useUpdateZenCostMutation({
 *   variables: {
 *      typeId: // value for 'typeId'
 *      multiplier: // value for 'multiplier'
 *      static: // value for 'static'
 *   },
 * });
 */
export function useUpdateZenCostMutation(baseOptions?: Apollo.MutationHookOptions<UpdateZenCostMutation, UpdateZenCostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateZenCostMutation, UpdateZenCostMutationVariables>(UpdateZenCostDocument, options);
      }
export type UpdateZenCostMutationHookResult = ReturnType<typeof useUpdateZenCostMutation>;
export type UpdateZenCostMutationResult = Apollo.MutationResult<UpdateZenCostMutation>;
export type UpdateZenCostMutationOptions = Apollo.BaseMutationOptions<UpdateZenCostMutation, UpdateZenCostMutationVariables>;
export const ManualZenCorrectionDocument = gql`
    mutation ManualZenCorrection($userId: ID!, $extra: Int, $basic: Int, $memo: String!) {
  manualZenCorrection(userId: $userId, extra: $extra, basic: $basic, memo: $memo) {
    isOk
  }
}
    `;
export type ManualZenCorrectionMutationFn = Apollo.MutationFunction<ManualZenCorrectionMutation, ManualZenCorrectionMutationVariables>;

/**
 * __useManualZenCorrectionMutation__
 *
 * To run a mutation, you first call `useManualZenCorrectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManualZenCorrectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manualZenCorrectionMutation, { data, loading, error }] = useManualZenCorrectionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      extra: // value for 'extra'
 *      basic: // value for 'basic'
 *      memo: // value for 'memo'
 *   },
 * });
 */
export function useManualZenCorrectionMutation(baseOptions?: Apollo.MutationHookOptions<ManualZenCorrectionMutation, ManualZenCorrectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManualZenCorrectionMutation, ManualZenCorrectionMutationVariables>(ManualZenCorrectionDocument, options);
      }
export type ManualZenCorrectionMutationHookResult = ReturnType<typeof useManualZenCorrectionMutation>;
export type ManualZenCorrectionMutationResult = Apollo.MutationResult<ManualZenCorrectionMutation>;
export type ManualZenCorrectionMutationOptions = Apollo.BaseMutationOptions<ManualZenCorrectionMutation, ManualZenCorrectionMutationVariables>;