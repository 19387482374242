import React, {useCallback, useMemo} from "react";
import {AsyncPaginate} from "react-select-async-paginate";
import {useReadTranslationCodesQuery} from "../types/graphql";

interface Props {
  value: string | null;
  onChange: (value: any) => void;
  disabled?: boolean;
}

const SelectTranslation = (props: Props) => {
  const {data, loading, error, fetchMore} = useReadTranslationCodesQuery({
    variables: {first: 10},
  });

  // async function loadOptions(search, loadedOptions) {
  //   const response = await fetch(`/awesome-api-url/?search=${search}&offset=${loadedOptions.length}`);
  //   const responseJSON = await response.json();
  //
  //   return {
  //     options: responseJSON.results,
  //     hasMore: responseJSON.has_more,
  //   };
  // }

  const loadMore = useCallback<any>(
    async (searchTerm: string, loadedOptions: any, {after}: { after: string }) => {
      const res = await fetchMore({
        variables: {
          searchTerm,
          after,
        },
      });
      return {
        options: res.data.translationCodes?.edges
          ?.map((edge) => edge?.node)
          .map((code) => ({value: code?.id, label: code?.code})),
        hasMore: res.data.translationCodes?.pageInfo.hasNextPage,
        additional: {
          after: res.data.translationCodes?.pageInfo.endCursor,
        },
      };
    },
    [fetchMore]
  );

  const options = useMemo(
    () =>
      data?.translationCodes?.edges
        ?.map((edge) => edge?.node)
        .map((code) => ({value: code?.id, label: code?.code})),
    [data]
  );

  return (
    <AsyncPaginate
      loadOptions={loadMore}
      debounceTimeout={200}
      defaultOptions
      value={options?.find((option) => option.value === props.value)}
      onChange={props.onChange}
      isDisabled={props.disabled}
    />
  );
};

export default SelectTranslation;
