import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Row} from "react-bootstrap";

interface IProps {
    email: string | null;
    description?: string;
    onSubmit: (email: string) => void;
    required?: boolean;
}

export const FormIssuerUpdateEmail = ({
                                          email: defaultEmail,
                                          description,
                                          onSubmit,
                                          required = true,
                                      }: IProps) => {
    const [email, setEmail] = useState<string>("");
    const [validated, setValidated] = useState<boolean>(false);
    const [isRequired] = useState<boolean>(required);

    useEffect(() => {
        setEmail(defaultEmail || "");
    }, [defaultEmail]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        const isValid = form.checkValidity();

        setValidated(isValid);
        isValid && onSubmit && onSubmit(email);
    };

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Control
                            required={isRequired}
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(event) => setEmail(event.currentTarget.value)}
                            name="email"
                        />
                        <Form.Text className="text-muted">{description}</Form.Text>
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid email.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Button variant="secondary" type="submit">
                            Update email
                        </Button>
                    </Form.Group>
                </Row>
            </Form>
        </>
    );
};
