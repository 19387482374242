import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Container, Card, Button} from "react-bootstrap";
import {BufferForecasting} from "../component/BufferForecasting";

export default () => {

    const history = useNavigate();

    return (
        <Container>
            <Card>
                <Card.Header>
                    <h5 className="dark">Funds forecasting</h5>
                </Card.Header>
                <Card.Body>
                    <h5>Chart</h5>
                    <BufferForecasting/>
                </Card.Body>
                <Card.Footer>
                    <Button variant="primary" onClick={() => history("/balance/snapshots")}>
                        Manage balance snapshots
                    </Button>
                </Card.Footer>
            </Card>

        </Container>
    );

};


